import { BACKEND } from "./api";
import {
  addMultiplePushEventListener,
  addPushEventListener,
  initPushEvent,
  joinPushEventRoom,
  removePushEventListener,
} from "@asaje/sse-push-event-client";

export const ROOMS = "__pushup_ROOMS";

export class PushupSse {
  public addSocketListener: (...args: any[]) => any;
  public addMultipleSocketListener: (...args: any[]) => any;
  public removeEventListener: (eventName: string) => any;
  public isSocketConnected: boolean = false;

  constructor() {
    initPushEvent(BACKEND.baseUrl + BACKEND.core + "/events");
    this.init();
    this.isSocketConnected = true;

    /**
     * @param {string} name
     * @param {any} cb
     * @returns {any}
     */
    this.addSocketListener = (name: string, cb: any): any => {
      if (!(typeof name === "string")) {
        throw new Error(
          "Invalid type for `name` argument, `string` expected, `" +
            typeof name +
            "` got",
        );
      }
      addPushEventListener(name, cb);
    };

    /**
     * @param {string} eventName
     * @returns {any}
     */
    this.removeEventListener = (eventName: string): any => {
      removePushEventListener(eventName);
    };

    /**
     * @param {string[]} names
     * @param {any} cb
     * @returns {any}
     */
    this.addMultipleSocketListener = (names: string[], cb: any): any => {
      if (!Array.isArray(names)) {
        throw new Error(
          "Invalid type for `names` argument, `Array<string>` expected, `" +
            typeof names +
            "` got",
        );
      }
      addMultiplePushEventListener(names, cb);
    };
  }

  init() {
    const rooms = localStorage.getItem(ROOMS);
    if (rooms) {
      const roomsInStore = JSON.parse(rooms) as string[];
      for (const room of roomsInStore) {
        this.joinRoom(room);
      }
    }
  }

  /**
   * Join dispatching room
   * @param id
   */
  joinRoom(id: string): void {
    joinPushEventRoom(id);
    console.log("Joined room: ", id);

    const rooms = localStorage.getItem(ROOMS);
    if (!rooms) {
      localStorage.setItem(ROOMS, JSON.stringify([id]));
    } else {
      const roomsInStore = JSON.parse(rooms) as string[];
      localStorage.setItem(
        ROOMS,
        JSON.stringify([...new Set([...roomsInStore, id])]),
      );
    }
  }

  /**
   * Listen on specific event
   * @param event
   * @param callback
   */
  on(event: string, callback: (...args: any[]) => void) {
    // this.socketIo.on(event, callback);
    addPushEventListener(event, (data: any) => {
      console.log("Received: ", data);
      callback(data);
    });
  }
}

export enum WorkflowWsEvents {
  REPORTS = "REPORTS",
  TRANSACTION_SUCCEED = "TRANSACTION_SUCCEED",
  TRANSACTION_FAILED = "TRANSACTION_FAILED",
  CAMPAIGN_STATUS_CHANGED = "CAMPAIGN_STATUS_CHANGED",
  DISPATCHING_STATUS_CHANGED = "DISPATCHING_STATUS_CHANGED",
  SETTING_CONFIRMED = "SETTING_CONFIRMED",
  PAYEMENT_STATUS_CHANGED = "PAYEMENT_STATUS_CHANGED",
  SUBSCRIPTION_PAYMENT = "SUBSCRIPTION_PAYMENT",
  REVOCATION_CONFIRMED = "REVOCATION_CONFIRMED",
}
