<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26Z"
      fill="#D0E0FB"
    />
    <path
      d="M30.7599 20.6133H21.7839C21.7839 20.6133 15.5 25.9979 15.5 32.2818C15.5 35.8708 18.2023 37.6665 19.988 37.6665H32.5554C34.3486 37.6665 37.0434 35.8707 37.0434 32.2818C37.0434 25.9979 30.7595 20.6133 30.7595 20.6133H30.7599ZM27.1685 33.0877V34.5248H25.3753L25.3751 33.0925C24.0534 32.8468 23.2232 32.0313 23.1643 30.8472H24.7193C24.7954 31.4687 25.4219 31.8692 26.3284 31.8692C27.1636 31.8692 27.7556 31.4639 27.7556 30.8867C27.7556 30.3954 27.3724 30.1152 26.4291 29.9041L25.4293 29.6904C24.029 29.3981 23.3411 28.6685 23.3411 27.4968C23.3411 26.325 24.1224 25.4799 25.3654 25.2023V23.7552H27.1586V25.2045C28.3721 25.4748 29.1804 26.3026 29.2196 27.4153L27.7187 27.4155C27.6427 26.8087 27.0775 26.4035 26.3013 26.4035C25.5251 26.4035 24.9649 26.7769 24.9649 27.3616C24.9649 27.8357 25.3309 28.0986 26.2324 28.2999L27.1586 28.4965C28.7037 28.8207 29.3694 29.479 29.3694 30.6804C29.3696 31.965 28.5442 32.8249 27.1685 33.0877L27.1685 33.0877Z"
      fill="#145EE0"
    />
    <path
      d="M30.7602 15.2259H28.2791C27.7666 14.6575 27.0373 14.333 26.272 14.333C25.5068 14.333 24.7775 14.6576 24.2649 15.2259H21.7839C21.4871 15.2271 21.2102 15.3748 21.0436 15.6204C20.8771 15.8659 20.8425 16.178 20.9511 16.4542L21.7839 18.8149H30.7599L31.5926 16.4542C31.7013 16.178 31.6667 15.8659 31.5001 15.6204C31.3335 15.3748 31.0567 15.2271 30.7599 15.2259H30.7602Z"
      fill="#145EE0"
    />
  </svg>
</template>
