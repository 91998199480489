<template>
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="blue"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5625 1.7628L16.3576 7.89936L16.3603 7.90051C16.3827 7.91017 16.4019 7.92619 16.4153 7.9466C16.4288 7.96701 16.4359 7.99092 16.4359 8.01536C16.4359 8.0398 16.4288 8.06371 16.4153 8.08412C16.4019 8.10453 16.3827 8.12055 16.3603 8.1302L16.3436 8.13739L16.3435 8.1374L1.5625 14.2333V10.6267L9.61952 9.13685L9.61953 9.13686L9.62396 9.13602C9.88496 9.08654 10.1205 8.94751 10.29 8.74292L10.29 8.74291C10.4594 8.53834 10.5521 8.28103 10.5521 8.01536C10.5521 7.74969 10.4594 7.49238 10.29 7.28781L10.2899 7.28779C10.1205 7.0832 9.88496 6.94418 9.62396 6.8947L9.62396 6.89469L9.61952 6.89386L1.5625 5.40408V1.7628ZM1.40799 14.4707L1.40057 14.4747L1.40799 14.4707ZM1.5625 14.4799V14.4792C1.5625 14.4794 1.5625 14.4796 1.5625 14.4799ZM1.34887 1.67418L1.34987 1.67461L1.34887 1.67418Z"
      fill="blue"
      stroke="blue"
      stroke-width="2"
    />
  </svg>
</template>
