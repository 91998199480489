import * as axios from "axios";
/**
 * Define here all the call logic of the different backends that the pushup frontend can reach
 * PLEASE no hard string endpoint (move them into .env)
 */

export const BACKEND = {
  baseUrl: process.env.VUE_APP_BASE_URL,
  core: process.env.VUE_APP_PUSHUP_CORE,
  storage: process.env.VUE_APP_PUSHUP_STORAGE,
  socket: process.env.VUE_APP_PUSHUP_SOCKET,
  kkiapayApiKey: process.env.VUE_APP_KKIAPAY_URL,
};

type MODULE = "core" | "storage" | "none";

// Set default axios config here
axios.default.defaults.baseURL = BACKEND.baseUrl;

axios.default.interceptors.response.use(
  function (response) {
    console.log(response, "response");
    return response;
  },
  function (error) {
    console.log(error.response.data.statusCode, "error");

    if (error.response.data.statusCode === 401) {
      window.location.href = process.env.VUE_APP_LOGIN;
    }
    return Promise.reject(error);
  },
);
/**
 * Request api resources
 * @param module
 * @param config
 * @returns
 */

const $api = async function (
  config: axios.AxiosRequestConfig,
  options?: { module?: MODULE; debug?: boolean },
): Promise<axios.AxiosResponse> {
  if (options && options.module === "storage") {
    config.url = BACKEND.storage + config.url;
  } else if (options && options.module === "none") {
    config.url = config.url;
  } else {
    config.url = BACKEND.core + config.url;
  }
  const authToken = "";
  const token = localStorage.getItem("pushup_token") ?? authToken;

  if (token) {
    if (config.headers) config.headers.Authorization = "Bearer " + token;
    else
      config.headers = {
        Authorization: "Bearer " + token,
      };
  }

  console.log("api.config", config);

  return new Promise((resolve, reject) => {
    axios.default
      .request(config)
      .then((response) => {
        if (options && options.debug) console.log(response.data);
        resolve(response);
      })
      .catch((error) => {
        localStorage.removeItem("requested-token");
        if (options && options.debug) {
          if (error.response) {
            console.error(error.response.status, error.response.data);
          } else if (error.request) {
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error message:", error.message);
          }
        }
        reject(error);
      });
  });
};

export interface ApiCallResult {
  success: boolean;
  data?: any;
  error?: any;
}

export default $api;
export function globalToken() {
  return localStorage.getItem("pushup_token");
}
