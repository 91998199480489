
import Tutorial from "@/components/global/tutorial.vue";
import downloadBlue from "@/components/svg/downloadBlue.vue";
import Change from "@/components/global/change.vue";
import GlobalError from "../components/modals/globalError.vue";
import whiteButton from "@/components/global/redBorderButton.vue";
import eyesBlue from "@/components/svg/eyesBlue.vue";
import progressBar from "@/components/global/progressBar.vue";
import errorNotif from "@/components/modals/errorNotif.vue";
import succesCard from "@/components/global/succesCard.vue";
import Down from "@/components/svg/downBlack.vue";

import {
  watch,
  inject,
  computed,
  ref,
  onBeforeMount,
  onMounted,
  onUnmounted,
} from "vue";
import spinner from "@/components/tables/Spinner.vue";
import add from "@/components/svg/add.vue";
import sendBlue from "@/components/svg/sendBlue.vue";
import status from "@/components/global/status.vue";
import Back from "@/components/svg/back.vue";
import cancel from "@/components/svg/cancel.vue";
import person from "@/components/svg/person.vue";
import device from "@/components/svg/device.vue";
import send from "@/components/svg/send.vue";
import customInput from "@/components/global/customPhone.vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import filteCard from "@/components/global/filterCard.vue";
import TldSideTabs from "@/components/tables/table-tld-side.vue";
import DownloadReport from "@/components/global/downloadReport.vue";
import DataTable from "@/components/tables/DataTable.vue";
import { formatErrors, wait } from "@/utils/index";
import ErrorCard from "@/components/global/errorCard.vue";
import { useRoute, useRouter } from "vue-router";
import Modal from "@/components/global/modal.vue";
import { useStore } from "vuex";
import { ActionTypes, MutationTypes } from "@/store/pushup/store.types";
import { PushupSocket, WorkflowWsEvents } from "@/socket";
import { CampaignTransaction } from "@/store/pushup/state";
import { ExistenceCheckResult } from "@/store/pushup/getters";
import BlackCard from "@/components/global/blackCard.vue";
import dragFile from "@/components/global/dragFile.vue";
import changeFile from "@/components/modals/uploadNewFile.vue";
import topStatus from "@/components/global/topStatus.vue";
import { useResponsive } from "@/utils/hooks/useResponsive";
import { useVariable } from "@/utils/hooks/useVariable";
import LargeModal from "../components/modals/downModal.vue";
import {
  addSuccessListener,
  openKkiapayWidget,
} from "@kkiapay-org/pushup-widget";
import paySlipChoose from "@/components/global/paySlipChoose.vue";
export default {
  components: {
    paySlipChoose,
    Tutorial,
    Change,
    downloadBlue,
    sendBlue,
    LargeModal,
    GlobalError,
    changeFile,
    add,
    topStatus,
    dragFile,
    spinner,
    Modal,
    Down,
    whiteButton,
    progressBar,
    DataTable,
    customInput,
    errorNotif,
    succesCard,
    filteCard,
    person,
    device,
    eyesBlue,
    send,
    cancel,
    status,
    Back,
    PrimaryButton,
    TldSideTabs,
    DownloadReport,
    ErrorCard,
    BlackCard,
  },

  setup() {
    const paySlip = ref(false);

    function selectedMethod(data: string) {
      console.log("data", data);

      if (data == "widget") {
        goToDetails();
      } else {
        route.push({ name: "subscription" });
      }
      paySlip.value = false;
    }
    const $t: any = inject("$t");
    const showNotif: any = inject("$showNotif");
    const PushUpIo: PushupSocket | undefined = inject("$PushUpIo");
    const route = useRouter();
    const router = useRoute();
    const store = useStore();
    const campagnId = ref("");

    const vars = useVariable($t);

    const showDetails = ref({ id: undefined });
    const table = [
      {
        id: 1,
        status: "created",
      },
    ];

    const currentCampagn = computed(() => {
      return store.getters["getCampaign"] ?? "";
    });
    const hoverElm = ref("");
    function selectedRow(data: any) {
      hoverElm.value = data.transactionId;
    }
    function unselectedRow() {
      hoverElm.value = "";
    }
    const allTransactions = computed(() => {
      return store.getters["getAllTransaction"] ?? "";
    });
    const campaignTransactions = computed(() => {
      return store.getters["getCampaignTransactions"];
    });
    const totalTransaction = computed(() => {
      return store.getters["getTotalTransaction"];
    });
    const test = ref({});
    test.value = currentCampagn.value;
    const data = ref({});
    const perPage = ref(10);
    data.value = currentCampagn.value;
    const paginator = ref({ page: 1 });
    const onChange = () => {};

    const paginatorPage = (data: any) => {
      paginator.value = data;
    };

    const paginate = ref({
      limit: perPage.value,
      page: paginator.value.page,
    });

    const showChangeFileModal = ref(false);

    function toggleFileModal() {
      showChangeFileModal.value = !showChangeFileModal.value;
    }
    const showResendModal = ref(false);
    function resend() {
      showResendModal.value = !showResendModal.value;
    }
    const link = ref("");

    async function downloadPdfRepport() {
      try {
        const url = await store.dispatch(
          ActionTypes.GET_REPORT_FILE,
          store.state.campaigns.dispatching.id,
        );
        console.log("url: ", url);
        window.open(url, "_blank");
        console.log(url);
        // window.open(
        //   currentCampagn.value.dispatching.reportFile == null
        //     ? link.value
        //     : currentCampagn.value.dispatching.reportFile,
        //   "_blank",
        // );
        showNotif({
          success: true,
          note: $t("index.oneDownload"),
        });
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    }
    const statusSuccess = ref(false);
    const statusSuccessBar = ref(false);
    const statusErrorBar = ref(false);

    const searched = ref({});
    const searching = ref(false);
    const statusFilter = ref({});
    const getTransaction = async (add: any) => {
      isLoading.value = true;
      const response = await store.dispatch("TRANSACTIONS", {
        params: {
          ...add,
          ...searched.value,
          ...statusFilter.value,
        },
        id: store.state.campaigns.dispatching.id,
      });
      console.log(response, "ee");
      if (searching.value) theFilt.value = response.data.values;

      isLoading.value = false;
    };
    const paginateChoose = (data: any) => {
      perPage.value = data;
    };
    const choiceDate = async (data: any) => {};
    const theFilt = ref([]);
    watch(perPage, async () => {
      searching.value = true;
      paginate.value.limit = perPage.value;

      await getTransaction(paginate.value);
    });
    watch(campaignTransactions, async (val) => {
      fakeCampaignTransactions.value = val;
    });

    watch(paginator, async () => {
      // searching.value = true;
      paginate.value.page = paginator.value.page;
      await getTransaction(paginate.value);
    });
    watch(theFilt, (val) => {
      if ((searching.value = true)) {
        console.log(searching.value, "ddd");

        fakeCampaignTransactions.value = val;
      }
    });

    const searchValue = ref("");

    const search = async (data: any) => {
      searching.value = true;
      if (!data.replace(/\s/g, "").length) searched.value = {};
      else searched.value = { query: data };

      await getTransaction(paginate.value);
    };

    const goBack = () => {
      route.go(-1);
    };

    const notEmptyObject = (someObject: Object) => {
      return Object.keys(someObject).length > 0;
    };

    const reports = ref({});
    const globalErrorsReports = ref({});
    const tableData = ref<Record<string, any>>([]);
    const fakeCampaignTransactions = ref<any[]>([]);

    const beneficary = ref(0);
    const amount = ref(0);
    const reportInfo = ref({
      failedTransactions: 0,
      failedTransactionsAmount: "0",
      successTransactions: 0,
      successTransactionsAmount: "0",
    });
    const totalTransactions = ref(0);
    const fees = ref(0);
    const reason = ref("");
    const openModal = ref(false);
    const typeValidation = ref("");
    const goodLines = ref(0);
    const badLines = ref(0);
    const errorList = ref([]);
    const closeOtpModal = () => {
      openModal.value = false;
    };

    const isLoading = ref(false);

    const openOtpModal = async () => {
      try {
        console.log(
          "do init loading",
          currentCampagn.value.dispatching,
          !currentCampagn.value.dispatching,
        );
        if (!currentCampagn.value.dispatching) {
          await initialLoad();
        }
        await store.dispatch(
          "RUN_DISPACTHING",
          currentCampagn.value.dispatching.id,
        );
        showNotif({
          success: true,
          note: $t("index.succesRun"),
        });

        // typeValidation.value = res.data.data.settingId;
        // if (res.data.data.typeOfAccount === "UNIQUE") {
        //   openModal.value = true;
        // } else {
        //   showNotif({
        //     success: true,
        //     note: $t("index.succesRun"),
        //   });
        // }
      } catch (err: any) {
        console.log(err);
        if (err.response?.status == 422) {
          showNotif({
            error: true,
            note: $t("index.errorMessage"),
          });
        }
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    };
    const load = ref(false);
    // currentCampagn.value.dispatching.id
    const disPlay = ref(false);
    function clickToShowDetails(data: any) {
      showDetails.value = data;
      disPlay.value = !disPlay.value;
    }
    const validationOpt = async () => {
      load.value = true;
      try {
        const data = {
          id: currentCampagn.value.dispatching.id,
        };
        await store.dispatch("RUN_DISPACTHING", data);
        const id = router.params.campaignId;

        load.value = false;
        openModal.value = false;
        showNotif({
          success: true,
          note: $t("index.succesRun"),
        });
      } catch (error) {
        load.value = false;
        openModal.value = false;
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
      // socket.on('')
    };
    const selectedValidator = ref<{ id?: string }>({});

    function selectedElm(data: any) {
      selectedValidator.value = data;
    }
    const infiniteTable = ref<Record<string, any>>([]);
    const loader = ref(false);

    const campagnStatus = computed(() => store.state.campaigns.status);
    const UserInfo = computed(() => {
      return store.getters["getMeInfo"];
    });

    const chooseElm = async (data: any) => {
      console.log(data, "data");
      searching.value = true;
      if (data === "all") {
        statusFilter.value = {};
        await getTransaction(paginate.value);
      }
      if (data === "CREATED") statusFilter.value = { status: "PENDING" };
      else statusFilter.value = { status: data };

      await getTransaction(paginate.value);
    };

    const selected = async (data: any) => {
      searching.value = true;
      if (data == "all") statusFilter.value = {};
      else statusFilter.value = { status: data };
      await getTransaction(paginate.value);
    };
    function closeSuccess() {
      statusSuccess.value = false;
    }
    function goToSettings() {
      route.push({ name: "settings" });
    }
    async function Reloadpage() {
      initialLoad();
    }
    async function closeModal() {
      showChangeFileModal.value = false;
      // const id = router.params.campaignId;
      // isLoading.value = true;
      // await wait(5000);
      // await store.dispatch(ActionTypes.GET_DISPACTHING_INFO, id);
    }

    async function initialLoad() {
      store.commit(MutationTypes.SET_TRANSACTIONS, []);

      isLoading.value = true;

      const id = router.params.campaignId;
      const _params: any = {
        page: 1,
      };

      if (window.innerWidth <= 860) {
        // _params.limit = 10;
      }
      const payload = {
        id: id,
        params: _params,
      };
      loader.value = true;

      await store.dispatch("GET_PLANS");
      await store.dispatch(ActionTypes.GET_DISPACTHING_INFO, payload);
      loader.value = false;

      // infiniteTable.value.push(...(tableData.value as any[]));
      if (campagnStatus.value !== "CREATED") {
        await getTransaction(paginate.value);

        const theId = store.state.campaigns.dispatching.id;
        await store.dispatch("GET_ALL_TRANSACTIONS", theId);
      }
      if (
        campagnStatus.value == "COMPLETED" ||
        campagnStatus.value == "FAILED"
      ) {
        succesStatus.value = "COMPLETED";
        fakeCampaignTransactions.value = campaignTransactions.value;
      } else if (campagnStatus.value == "PROCESSING") {
      }

      isLoading.value = false;

      const channel = store.state.campaigns.filename;
      loader.value = false;

      if (PushUpIo?.isSocketConnected) {
        PushUpIo?.joinRoom(channel);
      } else {
        PushUpIo?.on("connect", () => {
          PushUpIo?.joinRoom(channel);
        });
      }

      campagnId.value = currentCampagn.value.id;
      reason.value = currentCampagn.value.campaign.name;
      amount.value = currentCampagn.value.totalAmount
        ? currentCampagn.value.totalAmount
        : 0;

      const dispatchingReportInfo =
        currentCampagn.value.dispatching &&
        currentCampagn.value.dispatching.DispatchingReport
          ? currentCampagn.value.dispatching.DispatchingReport[0]
          : reportInfo.value;
      console.log("currentCampagn.value:", currentCampagn.value);
      console.log("dispatchingReportInfo:", dispatchingReportInfo);

      reportInfo.value = dispatchingReportInfo;
      console.log("reportInfo.value:", reportInfo.value);
      fees.value = currentCampagn.value.fees ? currentCampagn.value.fees : 0;
      beneficary.value = currentCampagn.value.nbrOfBeneficiairies
        ? currentCampagn.value.nbrOfBeneficiairies
        : 0;
      globalErrorsReports.value = formatErrors(
        currentCampagn.value.globalErrorsReports,
        $t,
      );
      reports.value = formatErrors(currentCampagn.value.reports, $t);
      tableData.value = currentCampagn.value.mapping;
      fakeCampaignTransactions.value =
        campagnStatus.value == "COMPLETED" || campagnStatus.value == "FAILED"
          ? campaignTransactions.value
          : tableData.value.map((elm: any) => {
              const foundTransaction = allTransactions.value.find(
                (tElm: any) => {
                  return tElm.secondaryId === elm.secondaryId;
                },
              );

              let status = "PENDING";

              if (foundTransaction) {
                status = foundTransaction.status;
              }

              return {
                id: elm.secondaryId,
                name: elm.name,
                phone: elm.phone,
                status,
                amount: elm.amount,
                transactionId: foundTransaction
                  ? foundTransaction.transactionId
                  : "",
                secondaryId: elm.secondaryId,
              };
            });

      nbTransaction.value =
        (fakeCampaignTransactions.value.filter((el) => el.status !== "PENDING")
          .length *
          100) /
        fakeCampaignTransactions.value.length;

      goodLines.value = currentCampagn.value.meta.nbrOfGoodLines;
      badLines.value = currentCampagn.value.meta.nbrOfBadLines;
      errorList.value = currentCampagn.value.meta.badLines;

      const businessId = localStorage.getItem("businessId");
      if (businessId) {
        store.dispatch(ActionTypes.SAVE_BUSINESS_ID_TO_STORAGE, businessId);
        // store.dispatch(ActionTypes.GET_BUSSINESS_ACCOUNT);
      }
    }
    const selectedElms = ref<string[]>([]);

    const rowSelected = ({
      rowId,
      operationType,
    }: {
      rowId: string;
      operationType: string;
    }) => {
      console.log(operationType);

      if (operationType === "ADD") {
        selectedElms.value.push(rowId);
      } else {
        const elementIndex = selectedElms.value.findIndex(
          (val: any) => val === rowId,
        );
        if (elementIndex !== -1) selectedElms.value.splice(elementIndex, 1);
      }
    };
    function onAllRowCheck(operationType: string) {
      selectedElms.value =
        operationType === "CHECK_ALL"
          ? campaignTransactions.value.map((el: { id: string }) => el.id)
          : [];
    }
    function checkTransactionExistence(
      transactionSecondaryId: string,
    ): ExistenceCheckResult {
      const transactIndex = fakeCampaignTransactions.value.findIndex(
        (tr) => tr.secondaryId === transactionSecondaryId,
      );

      return {
        exist: transactIndex !== -1,
        index: transactIndex === -1 ? undefined : transactIndex,
      };
    }
    const showCancel = ref(false);
    const payId = ref("");
    function goToDetails() {
      // payId.value = row.id;
      if (UserInfo.value.logoUrl) {
        openKkiapayWidget({
          amount: selectedElms.value.length
            ? selectedElms.value.length * 100
            : 100,
          key: process.env.VUE_APP_PUSHUP_PAY_KEY,
          theme: "primary",
          sandbox: process.env.VUE_APP_KKIAPAY_ENV === "live" ? false : true,
          paymentMethods: ["momo", "card"],
        });
      } else {
        showNotif({
          error: true,
          note: $t("index.noLogo"),
        });
      }

      // route.push(`/detail/${row.id}`);
    }
    async function downloadErrorFile() {
      try {
        await store.dispatch("GET_ERROR_FILES", campagnId.value);
        showNotif({
          success: true,
          note: $t("index.oneDownload"),
        });
      } catch (error) {
        showNotif({
          success: true,
          note: $t("index.error"),
        });
      }
    }
    function cancelRun() {
      showCancel.value = !showCancel.value;
    }
    const formatPrice = (amount: any, delimiter = " ", separator = ",") => {
      const roundedValue = String(Math.round(amount * 100) / 100);

      const putDelimiter = (value: any) => {
        const result = [];

        const reversedValue = value.split("").reverse();

        for (let i = 1; i < reversedValue.length + 1; i++) {
          result.push(reversedValue[i - 1]);

          if (i % 3 === 0 && i !== reversedValue.length) {
            result.push(delimiter);
          }
        }

        return result.reverse().join("");
      };
      if (roundedValue.indexOf(separator) !== -1) {
        const [intPart, decPart] = roundedValue.split(separator);

        return [putDelimiter(intPart), ".", decPart].join("");
      }
      return putDelimiter(roundedValue);
    };
    const infiniteLoader = ref(false);
    const innerWidth = ref(0);

    function infinityScroll() {
      if (innerWidth.value <= 800) {
        window.addEventListener("scroll", async () => {
          if (
            window.innerHeight + window.scrollY >=
            document.body.offsetHeight
          ) {
            const id = router.params.campaignId;
            infiniteLoader.value = true;
            var i = 1;

            const payload = {
              id: id,
              params: {
                limit: 10,
                page: 1,
              },
            };

            await store.dispatch(ActionTypes.GET_DISPACTHING_INFO, payload);
            setTimeout(() => {
              infiniteLoader.value = false;
            }, 1000);
          }
        });
      }
    }
    watch(tableData, () => {
      infiniteTable.value.push(...(tableData.value as any));
    });
    watch(campagnStatus, async (val) => {
      if (
        campagnStatus.value === "PROCESSING" ||
        campagnStatus.value === "APPROVED"
      ) {
        await getTransaction(paginate.value);
      }
      if ((val = "PROCESSING")) {
        searching.value = false;
      }
    });

    const succesStatus = ref("PENDING");
    const percent = computed(() => {
      return nbTransaction.value;
    });
    const incre = ref(0);
    const plans = computed(() => {
      return store.getters["getPlans"];
    });
    const onePaySlip = ref("");
    async function successMessage() {
      try {
        if (onePaySlip.value) {
          await store.dispatch("SEND_PAY_SLIP", onePaySlip.value);
        } else {
        }
        selectedElms.value = [];

        await store.dispatch("SEND_MULTIPLE_PAY_SLIP", selectedElms.value);
        await getTransaction(paginate.value);

        showNotif({
          success: true,
          note: $t("index.sendSms"),
        });
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.uploadImage"),
        });
      }
    }
    const nbTransaction = ref(0);
    const showPlan = ref(false);
    onMounted(async () => {
      // if (!UserInfo.value.permissions.includes("HAS_ACCESS")) {
      //   route.push("/removeAccess");
      // }
      addSuccessListener(successMessage);

      selectedValidator.value = UserInfo.value.validations[0];
      infinityScroll();
      await initialLoad();
      statusSuccess.value = false;
      statusSuccessBar.value = false;
      statusErrorBar.value = false;
      PushUpIo?.on(
        WorkflowWsEvents.TRANSACTION_SUCCEED,
        async (data: CampaignTransaction) => {
          nbTransaction.value = data.percentage;

          const transactionExistence = checkTransactionExistence(
            data.secondaryId,
          );

          if (
            transactionExistence.exist &&
            transactionExistence.index !== undefined
          ) {
            fakeCampaignTransactions.value[transactionExistence.index] = data;
          }
          store.commit(MutationTypes.ADD_TRANSACTION, {
            transaction: data,
            existenceCheckResult: transactionExistence,
          });
        },
      );

      PushUpIo?.on(
        WorkflowWsEvents.TRANSACTION_FAILED,
        async (data: CampaignTransaction) => {
          nbTransaction.value = data.percentage;

          // await getTransaction(paginate.value);

          const transactionExistence = checkTransactionExistence(
            data.secondaryId,
          );

          if (
            transactionExistence.exist &&
            transactionExistence.index !== undefined
          ) {
            fakeCampaignTransactions.value[transactionExistence.index] = data;
          }

          store.commit(MutationTypes.ADD_TRANSACTION, {
            transaction: data,
            existenceCheckResult: transactionExistence,
          });
        },
      );

      PushUpIo?.on(WorkflowWsEvents.CAMPAIGN_STATUS_CHANGED, async (data) => {
        const id = router.params.campaignId;

        const payload = {
          id: id,
          params: {
            page: 1,
            limit: 10,
          },
        };
        setTimeout(async () => {
          await store.dispatch("ME");

          await store.dispatch(ActionTypes.GET_DISPACTHING_INFO, payload);
        }, 1000);
        if (data.status === "APPROVED" || data.status === "PROCESSING") {
        }

        if (data.status === "COMPLETED") {
          succesStatus.value = "COMPLETED";
          link.value = data.reportFile;
          statusSuccess.value = true;
          totalTransactions.value = data.totalTransactions;
          statusSuccessBar.value = true;
        }
        // if (data.status === "COMPLETED") {
        //   succesStatus.value = "FAILED";
        //   link.value = data.reportFile;
        //   statusErrorBar.value = true;
        //   totalTransactions.value = data.totalTransactions;
        //   // statusSuccessBar.value = true;
        // }
      });

      PushUpIo?.on(WorkflowWsEvents.REPORTS, async (data) => {
        showChangeFileModal.value = false;
        await initialLoad();
      });
    });
    onBeforeMount(async () => {
      if (Object.keys(currentCampagn.value).length == 0) {
        await initialLoad();
      }
    });
    onUnmounted(async () => {
      PushUpIo?.removeEventListener(WorkflowWsEvents.TRANSACTION_FAILED);
      PushUpIo?.removeEventListener(WorkflowWsEvents.TRANSACTION_SUCCEED);
      PushUpIo?.removeEventListener(WorkflowWsEvents.CAMPAIGN_STATUS_CHANGED);
      PushUpIo?.removeEventListener(WorkflowWsEvents.REPORTS);
      await store.dispatch(ActionTypes.RESET_PREVIEW_STATE);
    });

    function getStatus(
      key: "CREATED" | "PENDING" | "COMPLETED" | "APPROVED" | "FAILED",
    ) {
      const obj = {
        CREATED: $t("index.newStatut"),
        PENDING: $t("index.pending"),
        COMPLETED: $t("index.finished"),
        INVITED: $t("index.invited"),
        PROCESSING: $t("index.pending"),
        SUCCESS: $t("index.completed"),
        APPROVED: $t("index.pending"),
        FAILED: $t("index.failed"),
      };

      return obj[key];
    }
    async function sendSMS() {
      try {
        let data = {
          id: currentCampagn.value.dispatching.id,
          body: [selectedValidator.value.id],
        };
        if (selectedValidator.value.id == "ALL") {
          data.body = UserInfo.value.validations.map(
            (validation: any) => validation.id,
          );
        }

        const send = await store.dispatch("RESEND_VALIDATION_LINK", data);
        showNotif({
          success: true,
          note: $t("index.sendSMS"),
        });
        showResendModal.value = false;
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    }
    function payOne(id: string) {
      paySlip.value = true;
      console.log(id);
      onePaySlip.value = id;
    }
    function gotoAppro() {
      route.push({ name: "appro" });
    }
    function gotoPlan() {
      route.push({ name: "subscription" });
    }
    function downPay(url: string) {
      window.open(url);
    }
    function getStatusText(key: "PENDING" | "SUCCESS" | "FAILED") {
      const obj = {
        PENDING: $t("index.pendingText"),
        SUCCESS: $t("index.completedText"),
        FAILED: $t("index.canceledText"),
      };

      return obj[key];
    }

    useResponsive(initialLoad);

    return {
      payOne,
      selectedMethod,
      paySlip,
      selectedElms,
      onAllRowCheck,
      rowSelected,
      unselectedRow,
      selectedRow,
      hoverElm,
      plans,
      fakeCampaignTransactions,
      badLines,
      errorList,
      gotoAppro,
      ...vars,
      tableData,
      notEmptyObject,
      amount,
      loader,
      beneficary,
      // columns,
      table,
      // statusColumns,
      reports,
      globalErrorsReports,
      choiceDate,
      openOtpModal,
      goBack,
      data,
      getStatus,
      currentCampagn,
      campagnStatus,
      reason,
      test,
      fees,
      downPay,
      formatErrors,
      openModal,
      closeOtpModal,
      validationOpt,
      goToDetails,
      load,
      UserInfo,
      totalTransactions,
      campaignTransactions,
      isLoading,
      getStatusText,
      chooseElm,
      search,
      paginateChoose,
      paginatorPage,
      perPage,
      selected,
      totalTransaction,
      onChange,
      campagnId,
      showChangeFileModal,
      toggleFileModal,
      closeModal,
      cancelRun,
      showCancel,
      statusSuccessBar,
      resend,
      showResendModal,
      sendSMS,
      statusSuccess,
      closeSuccess,
      formatPrice,
      clickToShowDetails,
      showDetails,
      disPlay,
      infiniteLoader,
      infiniteTable,
      succesStatus,
      gotoPlan,
      downloadErrorFile,
      goodLines,
      incre,
      percent,
      selectedElm,
      selectedValidator,
      showPlan,
      Reloadpage,
      goToSettings,
      downloadPdfRepport,
      paySlipChoose,
      statusErrorBar,
      reportInfo,
    };
  },
};
