<template>
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.340879 0.872129C0.670383 0.542624 1.20462 0.542624 1.53412 0.872129L6 5.33801L10.4659 0.872129C10.7954 0.542624 11.3296 0.542624 11.6591 0.872129C11.9886 1.20163 11.9886 1.73587 11.6591 2.06537L6.59662 7.12787C6.26712 7.45737 5.73288 7.45737 5.40338 7.12787L0.340879 2.06537C0.0113738 1.73587 0.0113738 1.20163 0.340879 0.872129Z"
      fill="white"
    />
  </svg>
</template>
