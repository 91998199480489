<template>
  <div class="theScroll">
    <Head label="index.board" />
    <notif :success="true" class="hidden" />
    <div
      class="gap-4 md:gap-0 grid grid-cols-1 md:grid-cols-4 w-full md:justify-between md:space-x-4"
    >
      <div
        class="flex-grow justify-between bg-white card-cheap shadow-md border-[rgba(0,0,0,0.15)] space-x-3 py-2.5 pr-2.5 flex items-center"
        style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)"
      >
        <div class="flex space-x-3">
          <span class="inline-flex flex-shrink-0">
            <firstIcon />
          </span>
          <div
            class="font-medium text-394452 text-sm leading-[1.225rem] text-left"
          >
            <span class="block stat-card-text" style="color: #5f738c">{{
              $t("index.firstTitle")
            }}</span>
            <span class="label block text-black text-xl font-semibold"
              >{{ formatPrice(amount) }}
              <span class="text-[#5f738c] text-lg">F CFA</span>
            </span>
          </div>
        </div>

        <Plus />
      </div>

      <card-cheap
        class="cursor-pointer"
        :rank="formatPrice(lastSupplyTotal) ?? 0"
        :currency="true"
        :showDate="true"
        :date="lastSupplyDate"
        :label="$t('index.last')"
      >
        <template #icon><lastIcon /> </template>
        <template #custome>
          <!-- <span class="text-sm text-[#5F738C]">Date: </span> -->
        </template>
      </card-cheap>
      <card-cheap
        class="cursor-pointer"
        :rank="totalCount"
        :currency="false"
        :label="$t('index.vers')"
      >
        <template #icon><secondIcon /></template>
      </card-cheap>

      <card-cheap
        class="cursor-pointer"
        :rank="formatPrice(totalVer)"
        :showDate="true"
        :date="lastDispatchingDate ?? 0"
        :label="$t('index.total')"
      >
        <template #icon><thirdIcon /></template>
        <template #custome>
          <span class="text-sm text-[#5F738C]"> </span>
        </template>
      </card-cheap>
      <!-- </div> -->
    </div>
    <div class="text-left mt-10 w-full flex justify-between">
      <span class="text-primaryColor font-semibold text-xl">
        {{ $t("index.lastVers") }}
      </span>
    </div>
    <div
      class="bg-white flex mt-2 justify-between items-center p-2 rounded shadow"
    >
      <search class="w-full" @typing:finished="search" />

      <!-- <calendar
        @dateSelected="dateSelected"
        @dateFilterDisable="dateFilterDisable"
        mode="range"
      /> -->
      <GenerateStatement />
    </div>
    <data-table
      :totalData="getTotal"
      :checkable="true"
      :data="allDispatching.campaigns"
      :columns="columns"
      :loading="loader"
      :perPage="perPage"
      :allChecked="
        allDispatching.campaigns?.length &&
        selectedElm.length == allDispatching.campaigns?.length
          ? true
          : false
      "
      :selectedRows="selectedElm"
      @rowSelected="rowSelected"
      @checkAllRow="onAllRowCheck"
      @mouseleave="unselectedRow"
      @onMouseover="selectedRow"
      @paginate="paginatorPage"
      :count="false"
      @paginateChoose="paginateChoose"
    >
      <template v-slot:card>
        <tld-side-tabs
          :tabs="[
            { name: 'all', libelle: $t('index.all') },
            { name: 'CREATED', libelle: $t('index.newStatut') },
            { name: 'INVITED', libelle: $t('index.invited') },
            { name: 'PENDING', libelle: $t('index.pending') },
            { name: 'COMPLETED', libelle: $t('index.finished') },
            { name: 'FAILED', libelle: $t('index.failed') },
          ]"
          @selected="selectedTab"
        >
        </tld-side-tabs>
      </template>
      <template #status="{ row }">
        <status :status="row.status" :value="getStatus(row.status)" />
      </template>
      <template #nbrOfBeneficiairies="{ row }">
        <span>
          {{ row.nbrOfBeneficiairies ? row.nbrOfBeneficiairies : 0 }}
        </span>
      </template>
      <template #totalAmount="{ row }">
        <span> {{ row.totalAmount ? formatPrice(row.totalAmount) : 0 }} </span>
      </template>
      <template #actions="{ row }">
        <div
          class="flex space-x-4 items-center hide"
          :class="{ show: hoverElm == row.id }"
        >
          <div>
            <eyes
              class="cursor-pointer relative"
              @click="gotoDetails(row)"
              @mouseover="seeDetails = true"
              @mouseleave="seeDetails = false"
            />
            <div
              class="absolute rounded font-bold bg-greyScale text-sm right-[105px] text-white p-1"
              v-if="seeDetails"
            >
              <span> {{ $t("index.details") }} </span>
            </div>
          </div>
          <div>
            <downloadBlue
              class="cursor-pointer relative"
              @mouseover="seeDownload = true"
              @mouseleave="seeDownload = false"
              @click="downloadOneFile(row)"
            />
            <div
              class="absolute font-bold rounded bg-greyScale right-[60px] text-sm text-white p-1"
              v-if="seeDownload"
            >
              <span>{{ $t("index.download") }} </span>
            </div>
          </div>
          <div>
            <archiveBlue
              class="cursor-pointer relative"
              @click="askArchivingValidation(row)"
              @mouseover="seeArchive = true"
              @mouseleave="seeArchive = false"
            />
            <div
              class="absolute rounded bg-greyScale font-bold text-sm right-[35px] text-white p-1"
              v-if="seeArchive"
            >
              <span>{{ $t("index.archive") }} </span>
            </div>
          </div>
        </div>
      </template>
      <template #date="{ row }">
        <span> {{ formatDate(row.createdAt) }} </span>
      </template>
      <template #mobile>
        <div class="w-full md:hidden">
          <div>
            <div v-for="(row, index) in allDispatching.campaigns" :key="index">
              <div
                class="p-2 border-b grid grid-cols-3 border-[rgba(0,0,0,0.15)]"
              >
                <div class="p-2">
                  <div>
                    <div class="">
                      <div>
                        <span class="block text-sm"> {{ row.name }}</span>
                        <span class="block text-sm font-bold">
                          {{ row.totalAmount ?? 0 }} F cfa</span
                        >
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-start">
                  <status :status="row.status" :value="getStatus(row.status)" />
                </div>
                <div class="flex justify-end">
                  <span @click="gotoDetails(row)" class="cursor-pointer">
                    <svg
                      width="10"
                      height="12"
                      viewBox="0 0 6 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.21967 0.96967C0.512563 0.676777 0.987437 0.676777 1.28033 0.96967L5.78033 5.46967C6.07322 5.76256 6.07322 6.23744 5.78033 6.53033L1.28033 11.0303C0.987437 11.3232 0.512563 11.3232 0.21967 11.0303C-0.0732233 10.7374 -0.0732233 10.2626 0.21967 9.96967L4.18934 6L0.21967 2.03033C-0.0732233 1.73744 -0.0732233 1.26256 0.21967 0.96967Z"
                        fill="#808080"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div class="p-2 hidden">
                <div class="flex justify-between">
                  <span> ID: </span>
                  <span>
                    {{ row.id }}
                  </span>
                </div>
                <div class="flex justify-between">
                  <span> N° Mobile Money : </span>
                  <span> </span>
                </div>
                <div class="flex justify-between"></div>
                <div class="flex justify-between"></div>
                <div class="flex justify-between"></div>
                <div class="flex justify-between"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #customEmpty v-if="data.length == 0 && !searching">
        <div class="w-full flex justify-center">
          <div class="flex flex-col items-center">
            <empty class="mb-4" />
            <span class="font-bold" v-html="$t('index.noDispatching')"> </span>
            <primaryButton
              :label="$t('index.createDispatching')"
              class="mt-4 w-fit"
              @click="createDispatching"
            />
          </div>
        </div>
      </template>
    </data-table>
    <black-card v-if="selectedElm.length > 1">
      <template #content="{}">
        <div class="flex space-x-8 items-center">
          <span class="text-sm font-semibold">
            {{ selectedElm.length }} {{ $t("index.selectedLines") }}:
          </span>
          <div
            @click="archiveFile()"
            class="rounded flex items-center border border-white px-2 py-1 space-x-2 cursor-pointer"
          >
            <archive />
            <span class="font-semibold text-sm"
              >{{ $t("index.archive") }}
            </span>
          </div>

          <a @click="dismiss" class="cursor-pointer font-semibold text-sm">
            {{ $t("index.cancel") }}
          </a>
        </div>
      </template>
    </black-card>
    <black-card v-if="selectedElm.length === 1">
      <template #content>
        <div class="flex items-center space-x-3">
          <span class="font-semibold text-sm"
            >1 {{ $t("index.selectedSingleLine") }}
          </span>
          <div
            @click="archiveFile(selectedElm)"
            class="rounded flex items-center border border-white px-2 py-1 space-x-2 cursor-pointer"
          >
            <archive />
            <span class="font-semibold text-sm"
              >{{ $t("index.archive") }}
            </span>
          </div>
          <a @click="dismiss" class="cursor-pointer font-semibold text-sm">
            {{ $t("index.cancel") }}
          </a>
        </div>
      </template>
    </black-card>
  </div>
</template>

<script>
import Search from "../components/global/search.vue";

import { onMounted, ref, computed, watch, onBeforeMount } from "vue";
import MobileTable from "@/components/tables/mobileTable.vue";
import add from "@/components/svg/add.vue";
import Down from "@/components/svg/downBlack.vue";
import firstIcon from "@/components/svg/firstIcon.vue";
import secondIcon from "@/components/svg/secondIcon.vue";
import thirdIcon from "@/components/svg/thirdIcon.vue";
import lastIcon from "@/components/svg/lastIcon.vue";
import eyes from "@/components/svg/eyes.vue";
import downloadWhite from "@/components/svg/downloadWhite.vue";
import archive from "@/components/svg/archive.vue";
import archiveBlue from "@/components/svg/archiveBlue.vue";
import downloadBlue from "@/components/svg/downloadBlue.vue";
import cardCheap from "@/components/global/card-cheap.vue";
import TldSideTabs from "@/components/tables/table-tld-side.vue";
import status from "@/components/global/status.vue";
import blackCard from "@/components/global/blackCard.vue";
import { inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Plus from "@/components/global/plusButton.vue";
import DataTable from "@/components/tables/DataTable.vue";
import notif from "@/components/global/notification.vue";
import { $i18n } from "@/i18n";
import Back from "@/components/svg/back.vue";
import Head from "@/components/global/head.vue";
import { $locale } from "@/main";
import empty from "@/components/global/empty.vue";
import GenerateStatement from "@/components/global/generateStatement.vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import Calendar from "../components/global/calendar.vue";

export default {
  components: {
    Search,
    Plus,
    Down,
    empty,
    MobileTable,
    Calendar,
    Head,
    PrimaryButton,
    add,
    notif,
    archiveBlue,
    DataTable,
    downloadWhite,
    blackCard,
    status,
    TldSideTabs,
    cardCheap,
    firstIcon,
    secondIcon,
    thirdIcon,
    lastIcon,
    archive,
    eyes,
    downloadBlue,
    Back,
    GenerateStatement,
  },
  setup() {
    const store = useStore();
    const $t = inject("$t");
    const router = useRouter();
    const route = useRoute();
    const showNotif = inject("$showNotif");
    const table = ref([]);
    const totalCount = ref(0);
    const lastSupplyTotal = ref(0);
    const data = ref([]);
    const amount = ref(0);
    const totalVer = ref(0);
    const beneficiaries = ref("");

    const hoverElm = ref("");
    const archiveElm = ref({});
    const paginator = ref({});
    const perPage = ref(10);
    const selectedElm = ref([]);
    const loader = ref(false);
    const paginate = ref({
      limit: perPage.value,
      page: paginator.value.page,
    });
    const seeArchive = ref(false);
    const seeDownload = ref(false);
    const seeDetails = ref(false);
    const searched = ref({});
    const statusFilter = ref({});
    const archiveOne = ref(false);
    const modal = ref(false);
    const searching = ref(false);
    const columns = {
      name: $t("index.reason"),
      nbrOfBeneficiairies: $t("index.beneficary"),
      totalAmount: $t("index.total_amount"),
      date: $t("index.sending_date"),
      status: $t("index.statut"),
      actions: "Actions",
    };

    const allDispatching = computed(() => {
      return store.getters["getAllCampaign"];
    });
    const getTotal = computed(() => {
      return store.getters["getTotal"];
    });
    const paginatorPage = async (data) => {
      paginator.value = data;
    };

    const paginateChoose = (data) => {
      console.log(data);
      perPage.value = data;
    };
    const businessId = computed(() => store.getters["getBusinessId"]);

    const getCampaigns = async (add) => {
      loader.value = true;
      await store.dispatch("GET_ALL_DISPACTHING", {
        id: businessId.value,
        params: {
          ...add,
          ...searched.value,
          ...statusFilter.value,
        },
      });
      loader.value = false;
    };
    const gotoCreatevue = () => {
      router.push({ name: "create" });
    };
    const idBusisness = ref("");
    idBusisness.value = store.state.bussinessId;

    watch(idBusisness, async () => {
      console.log(idBusisness.value, "ee----");
      await getCampaigns(paginate.value);
      searching.value = true;
    });

    watch(paginator, async () => {
      paginate.value = {
        limit: perPage.value,
        page: paginator.value.page,
      };

      await getCampaigns(paginate.value);
      searching.value = true;
    });

    const askArchivingValidation = (data) => {
      archiveOne.value = true;
      archiveElm.value = data;
      selectedElm.value.push(data.id);
      // selectedElm.value = [...selectedElm.value, archiveElm.value.id];
      // console.log(archiveElm.value);
    };

    const archiveOneFile = async (data) => {
      try {
        await store.dispatch("ARCHIVE", data.id);
        console.log(data, "archive");
        await getCampaigns(paginate.value);
        searching.value = true;
        selectedElm.value = [];

        showNotif({
          success: true,
          note: $t("index.oneArchive"),
        });
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
      archiveOne.value = false;
    };

    const dismiss = () => {
      selectedElm.value = [];
    };

    const selectedTab = async (data) => {
      if (data == "all") statusFilter.value = {};
      else statusFilter.value = { status: data };
      await getCampaigns(paginate.value);
      searching.value = true;
    };

    const selectedRow = (data) => {
      hoverElm.value = data.id;
    };

    const rowSelected = ({ rowId, operationType }) => {
      if (operationType === "ADD") {
        selectedElm.value.push(rowId);
      } else {
        const elementIndex = selectedElm.value.findIndex(
          (val) => val === rowId,
        );
        if (elementIndex !== -1) selectedElm.value.splice(elementIndex, 1);
      }
    };

    const archiveFile = async () => {
      modal.value = true;
      console.log(selectedElm.value);
      try {
        await store.dispatch("ARCHIVE_MANY", selectedElm.value);
        loader.value = true;
        await getCampaigns(paginate.value);
        loader.value = false;
        selectedElm.value = [];
        showNotif({
          success: true,
          note: $t("index.manyArchive"),
        });
        modal.value = false;
      } catch (error) {
        loader.value = false;

        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
        modal.value = false;
      }
    };

    const unselectedRow = (data) => {
      hoverElm.value = "";
    };

    const formatDate = (data) => {
      const date = new Date(data);
      return date.toLocaleString($locale, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
    };

    const getStatus = (key) => {
      const obj = {
        CREATED: $t("index.newStatut"),
        PENDING: $t("index.pending"),
        PROCESSING: $t("index.pending"),
        INVITED: $t("index.invited"),
        COMPLETED: $t("index.finished"),
        APPROVED: $t("index.approved"),
        CANCELED: $t("index.canceled"),
        FAILED: $t("index.failed"),
      };

      return obj[key];
    };

    const formatPrice = (amount, delimiter = " ", separator = ",") => {
      const roundedValue = String(Math.round(amount * 100) / 100);

      const putDelimiter = (value) => {
        const result = [];

        const reversedValue = value.split("").reverse();

        for (let i = 1; i < reversedValue.length + 1; i++) {
          result.push(reversedValue[i - 1]);

          if (i % 3 === 0 && i !== reversedValue.length) {
            result.push(delimiter);
          }
        }

        return result.reverse().join("");
      };
      if (roundedValue.indexOf(separator) !== -1) {
        const [intPart, decPart] = roundedValue.split(separator);

        return [putDelimiter(intPart), ".", decPart].join("");
      }
      return putDelimiter(roundedValue);
    };

    const downloadOneFile = async (data) => {
      try {
        await store.dispatch("DOWNLOAD", data.id);
        showNotif({
          success: true,
          note: $t("index.oneDownload"),
        });
      } catch (error) {
        console.log(error);
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    };

    const gotoDetails = (data) => {
      const id = data.id;
      router.push({ name: "preview", params: { campaignId: id } });
    };

    function createDispatching() {
      router.push({ name: "create" });
    }
    const me = computed(() => {
      return store.getters["getMeInfo"];
    });
    const lastSupplyDate = ref("");
    const lastDispatchingDate = ref("");
    onMounted(async () => {
      if (me.value.ownerSettings.length === 0) {
        router.push({ name: "noSetting" });
      }

      searching.value = false;

      try {
        await getCampaigns(
          (paginate.value = {
            limit: perPage.value,
            page: 1,
          }),
        );

        data.value = allDispatching.value.campaigns;
        console.log(searching.value, data, "searching");
        console.log(allDispatching.value, "allDispatching.value");
        console.log(allDispatching.value.stats, "allDispatching.value.stats");
        setTimeout(() => {
          amount.value = allDispatching.value.stats.balance ?? 0;
          beneficiaries.value = allDispatching.value.stats.beneficiaries;
          totalVer.value = allDispatching.value.stats.amount;
          totalCount.value = allDispatching.value.stats.totalCount;
          lastSupplyTotal.value =
            allDispatching.value.stats.lastSupplyAmount ?? 0;
          (lastSupplyDate.value = allDispatching.value.stats.lastSupplyDate),
            (lastDispatchingDate.value =
              allDispatching.value.stats.lastDispatchingDate);
        }, 1000);
      } catch (error) {
        console.log(error);
        loader.value = false;
        data.value = [];
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    });

    function onAllRowCheck(operationType) {
      selectedElm.value =
        operationType === "CHECK_ALL"
          ? allDispatching.value.campaigns.map((el) => el.id)
          : [];
    }
    function search(data) {
      console.log(data);
      getCampaigns({
        search: data,
      });
    }

    return {
      search,
      seeArchive,
      seeDownload,
      seeDetails,
      totalVer,
      totalCount,
      hoverElm,
      columns,
      beneficiaries,
      selectedTab,
      formatPrice,
      createDispatching,
      perPage,
      table,
      amount,
      selectedRow,
      getStatus,
      gotoCreatevue,
      unselectedRow,
      gotoDetails,
      rowSelected,
      selectedElm,
      allDispatching,
      data,
      formatDate,
      searching,
      loader,
      paginatorPage,
      paginateChoose,
      archiveFile,
      downloadOneFile,
      archiveOneFile,
      archiveOne,
      askArchivingValidation,
      archiveElm,
      dismiss,
      getTotal,
      onAllRowCheck,
      lastSupplyTotal,
      lastDispatchingDate,
      lastSupplyDate,
    };
  },
};
</script>

<style>
.hide {
  visibility: hidden;
}
.show {
  visibility: visible;
}
/* .theScroll {
  padding-bottom: 20% !important;
}
@media screen and (max-width: 800px) {
  .theScroll {
    padding-bottom: 100% !important;
  }
} */
</style>
