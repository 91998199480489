<template>
  <button
    type="submit"
    @click="$emit('clicked', $event)"
    class="outlined-button appearance-none outline-none select-none inline-flex items-center justify-center hover:opacity-95 text-primaryColor border-primaryColor active:animate-pulse"
    :class="{
      'opacity-75 pointer-events-none': loading,
    }"
  >
    <span v-if="hasIconSlot" class="inline-flex w-4 h-4 flex-shrink-0">
      <slot name="icon" />
    </span>
    <span
      class="text-primaryColor text-base leading-[1.219rem] text-center"
      :class="{ 'mx-0.5': loading || hasIconSlot }"
    >
      {{ label }}
      <!-- {{ $t('index.validate') }} -->
    </span>
    <div class="primary-loader" v-if="loading"></div>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Validation",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },
  },
};
</script>
<style>
.outlined-button {
  border: 2px solid #f11c33;
  border-radius: 4px;
  padding: 8px 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  box-sizing: border-box;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
