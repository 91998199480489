<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.6778 17.6074C35.2732 18.9886 36.8716 20.473 38.452 22.051C40.0348 23.6332 41.518 25.231 42.9004 26.8294L40.3426 29.9998C38.9128 28.3138 37.3444 26.6008 35.6224 24.8794C33.9394 23.1958 32.2198 21.625 30.4966 20.1604L33.6778 17.6074Z"
      fill="#F11C33"
    />
    <path
      d="M53.1393 7.36036C49.6725 3.89116 40.4013 7.27816 30.5007 15.0086L27.3285 17.6C25.7301 18.9836 24.1287 20.4674 22.5465 22.0502C20.9685 23.63 19.4835 25.2284 18.1047 26.825L15.5055 30.0002C7.7805 39.902 4.3911 49.1732 7.8609 52.64C11.3277 56.1086 20.6013 52.7204 30.5013 44.9918L33.6747 42.3992C35.2707 41.0168 36.8709 39.5336 38.4507 37.9526C40.0317 36.3722 41.5149 34.7744 42.8973 33.1772L45.4917 30.0002C53.2215 20.099 56.6097 10.8278 53.1393 7.36036ZM48.5277 17.8814C47.1675 20.714 45.2439 23.7518 42.9003 26.8286L40.3425 29.999C38.8773 31.7192 37.3059 33.4382 35.6211 35.1242C33.9021 36.8456 32.1861 38.4116 30.5025 39.8408L27.3243 42.3974C20.7339 47.4056 14.9433 50.003 11.7711 50.003C11.0385 50.003 10.7259 49.8494 10.6887 49.8134C10.4877 49.6118 9.9651 47.3414 12.4743 42.1196C13.8339 39.2876 15.7557 36.2504 18.0993 33.1736L20.6583 30.0056C22.1211 28.2824 23.6895 26.5664 25.3737 24.8816C27.0969 23.1608 28.8117 21.5906 30.4959 20.1602L33.6771 17.6078C40.2657 12.5966 46.0557 9.99856 49.2285 9.99856C49.9623 9.99856 50.2719 10.151 50.3115 10.19C50.5131 10.3904 51.0351 12.6602 48.5277 17.8814Z"
      fill="#4661B9"
    />
    <path
      d="M27.3241 42.3973C25.7257 41.0155 24.1273 39.5347 22.5493 37.9531C20.9665 36.3709 19.4827 34.7707 18.0991 33.1729L20.6581 30.0049C22.0885 31.6879 23.6569 33.4039 25.3777 35.1241C27.0619 36.8089 28.7815 38.3791 30.5017 39.8407L27.3241 42.3973Z"
      fill="#F11C33"
    />
    <path
      d="M45.4915 30C53.2201 39.9 56.6089 49.1712 53.1391 52.6398C49.6729 56.1084 40.4011 52.7202 30.5005 44.9916L33.6739 42.399C36.7507 44.742 39.7879 46.6656 42.6187 48.0264C47.8399 50.535 50.1097 50.0118 50.3113 49.8102C50.3485 49.7724 50.5021 49.4616 50.5021 48.7296C50.5021 45.555 47.9041 39.7674 42.8959 33.177L45.4915 30Z"
      fill="#F11C33"
    />
    <path
      d="M18.104 26.8252C13.0958 20.2342 10.4972 14.4436 10.4972 11.272C10.4972 10.5412 10.6502 10.2286 10.688 10.1896C10.8872 9.99037 13.1582 9.46597 18.38 11.9752C21.2132 13.3348 24.2486 15.259 27.3278 17.6002L30.5 15.0088C20.597 7.27837 11.3258 3.89317 7.86078 7.36057C4.39098 10.8298 7.77798 20.0992 15.5054 30.0004L18.104 26.8252Z"
      fill="#F11C33"
    />
  </svg>
</template>