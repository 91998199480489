<template>
  <div>
    <div
      class="error_card md:flex mb-6 md:flex-col lg:flex-row"
      style="overflow-y: auto"
    >
      <div class="logo flex items-center md:items-start space-x-3">
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 0.75C8.03984 0.75 0.75 8.03984 0.75 17C0.75 25.9602 8.03984 33.25 17 33.25C25.9602 33.25 33.25 25.9602 33.25 17C33.25 8.03984 25.9602 0.75 17 0.75ZM22.8836 21.1164C23.0046 21.2313 23.1013 21.3693 23.1681 21.5222C23.2349 21.6751 23.2704 21.8398 23.2725 22.0066C23.2747 22.1735 23.2434 22.339 23.1805 22.4936C23.1177 22.6481 23.0245 22.7885 22.9065 22.9065C22.7885 23.0245 22.6481 23.1177 22.4936 23.1805C22.339 23.2434 22.1735 23.2747 22.0066 23.2725C21.8398 23.2704 21.6751 23.2349 21.5222 23.1681C21.3693 23.1013 21.2313 23.0046 21.1164 22.8836L17 18.768L12.8836 22.8836C12.6473 23.1081 12.3326 23.2314 12.0066 23.2273C11.6807 23.2231 11.3692 23.0918 11.1387 22.8613C10.9082 22.6308 10.7769 22.3193 10.7727 21.9934C10.7686 21.6674 10.8919 21.3527 11.1164 21.1164L15.232 17L11.1164 12.8836C10.8919 12.6473 10.7686 12.3326 10.7727 12.0066C10.7769 11.6807 10.9082 11.3692 11.1387 11.1387C11.3692 10.9082 11.6807 10.7769 12.0066 10.7727C12.3326 10.7686 12.6473 10.8919 12.8836 11.1164L17 15.232L21.1164 11.1164C21.3527 10.8919 21.6674 10.7686 21.9934 10.7727C22.3193 10.7769 22.6308 10.9082 22.8613 11.1387C23.0918 11.3692 23.2231 11.6807 23.2273 12.0066C23.2314 12.3326 23.1081 12.6473 22.8836 12.8836L18.768 17L22.8836 21.1164Z"
            fill="#D80D23"
          />
        </svg>
        <span class="font-bold md:hidden">{{ $t("index.error") }} !</span>
      </div>

      <div class="md:flex md:justify-between w-full">
        <div
          class="error_list md:max-h-[100px] max-h-[328px]"
          style="overflow-y: auto"
        >
          <span class="font-bold hidden md:flex"
            >{{ $t("index.error") }} !</span
          >
          <p v-for="error in errors" :key="error" v-html="error"></p>
        </div>
        <div class="flex md:flex-row flex-col-reverse gap-3 items-center">
          <slot name="custom" v-if="show" />
          <button
            v-if="cancel"
            @click="$emit('cancel')"
            class="border-[#000000] border rounded h-fit py-2 w-full md:w-fit px-4 font-semibold text-base"
            style="margin-top: 15px"
          >
            {{ $t("index.cancel") }}
          </button>

          <button
            v-if="!show"
            class="w-full md:w-fit truncate reset-btn bg-[#4661B9]"
            @click="$emit('changeFile')"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.4992 2.99922C19.3329 2.99922 19.0003 2.99922 18.8327 3.16682L17.666 3.99961C15.8328 1.66688 12.9999 0 9.8331 0C4.33351 0 0 4.3322 0 9.6655C0 14.998 4.33351 19.3309 9.8337 19.3309C13.8333 19.3309 17.3334 16.8312 18.8327 13.4981C19.0003 13.3305 19.1666 12.9979 19.1666 12.664C19.1666 11.9982 18.6664 11.496 17.9999 11.496C17.5003 11.496 17.1671 11.8306 17.0001 12.1625C15.9997 14.9974 13.1668 16.9969 10 16.9969C5.83344 16.9969 2.50033 13.6638 2.50033 9.6635C2.50033 5.49759 5.83344 2.33142 10 2.33142C12.4997 2.33142 14.8331 3.66376 16.1667 5.49759H16.333L14.5005 6.66493C14.3335 6.83123 14.1666 6.99883 14.1666 7.16513C14.0003 7.49837 14.3335 7.83097 14.6668 7.99792H15.1656L18.8327 8.4968H19.0003C19.3342 8.4968 19.5005 8.3299 19.6668 8.1636V7.99596L20 3.66245C19.9993 3.33181 19.8331 2.99791 19.4998 2.99791L19.4992 2.99922Z"
                fill="white"
              />
            </svg>

            <span class="ml-2 text-white"> {{ $t("index.change_file") }} </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cancelBtn: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => [
        // "Le numéro de téléphone (61618081) renseigné ne dispose pas d'un compte mobile money valide",
        // "Une erreur est survenue lors de la vérification du numéro de ce bénéficiaire",
      ],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup({ emit }) {},
};
</script>

<style scoped>
.error_card {
  /* height: 100%; */

  overflow-y: auto;
  background: #fff5f4;
  border: 1px solid #f11c33;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
}

.error_list::-webkit-scrollbar {
  display: none;
}

.error_list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

h1 {
  font-family: "SourceSansPro SemiBold";
}

.logo {
  margin-right: 14px;
}

.reset-btn {
  border: 1px solid #0965f6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  height: 42px;
  color: #0965f6;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  margin-left: auto;
  margin-top: 15px;
}
</style>
