<template>
  <div>
    <modal :title="$t('index.resendInvitation')" @close="$emit('close')">
      <div class="text-left dialogContent">
        <span>
          {{ $t("index.aboutToResendInvitation") }}
          <b
            >{{
              validator.lastname +
              " " +
              validator.firstname +
              " (" +
              validator.phone +
              ")"
            }}
          </b></span
        >
      </div>
      <div class="mt-[24px]">
        <primary-button
          class="w-full"
          @click="resendInvitationLink"
          :label="$t('index.resendInvitation')"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "../global/modal.vue";
import { onMounted, toRef, computed, showNotif, inject } from "vue";
import { useStore } from "vuex";
import primaryButton from "../global/primaryButton.vue";
export default {
  props: {
    validator: {
      type: Object,
      required: true,
    },
  },

  components: {
    modal,
    primaryButton,
  },
  setup(props, { emit }) {
    const store = useStore();
    const showNotif = inject("$showNotif");
    const $t = inject("$t");

    const validator = toRef(props, "validator");
    const accountInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    async function resendInvitationLink() {
      console.log("resendInvitationLink", validator.value);
      try {
        await store.dispatch("RESEND_INVITATION", {
          id: accountInfo.value.id,
          validator: validator.value.id,
        });
        showNotif({
          success: true,
          note: $t("index.successResentLink"),
        });
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
      emit("close");
    }
    return {
      resendInvitationLink,
    };
  },
};
</script>

<style></style>
