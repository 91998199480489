<template>
  <div>
    <primary-button @click="showModal = true" :label="$t('index.new')">
      <template #icon>
        <span>
          <add />
        </span>
      </template>
    </primary-button>
    <Modal
      title="Quel type de versement souhaitez-vous faire ?"
      @close="showModal = false"
      v-if="showModal"
    >
      <div class="grid gap-4">
        <div
          @click="selectedMode(elm.mode)"
          v-for="(elm, index) in mode"
          :key="index"
          class="border rounded-md cursor-pointer p-4 border-[#CCCCCC80] gap-4 flex justify-between items-center"
        >
          <img :src="elm.icon" alt="" />
          <div>
            <span class="font-bold text-xl">
              {{ elm.title }}
            </span>
            <span class="block">{{ elm.desc }}</span>
          </div>
          <img src="../../assets/go.svg" alt="" />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import add from "@/components/svg/add.vue";
import Modal from "../global/modal.vue";
import primaryButton from "../global/primaryButton.vue";
import ok from "@/assets/ok.svg";
import Calendar from "@/assets/calendar.svg";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {
    add,
    primaryButton,
    Modal,
  },
  setup() {
    const router = useRouter();

    const store = useStore();
    const showModal = ref(false);
    const mode = [
      {
        icon: ok,
        title: "Versement  instantanné",
        desc: "Le versement est immédiatement lancé dès que vous donnez votre accord.",
        mode: "snap",
      },
      {
        icon: Calendar,
        title: "Versement  programmé",
        desc: "Choisissez la date et l’heure à laquelle vous souhaitez démarrer le versement.",
        mode: "program",
      },
    ];
    function selectedMode(data) {
      store.dispatch("UPDATE_MODE", data);
      showModal.value = false;
      router.push({ name: "create" });
    }
    const sltMode = computed(() => store.getters["getMode"]);
    onMounted(() => {});
    return {
      mode,
      selectedMode,
      showModal,
    };
  },
};
</script>

<style></style>
