<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 4.45508L10.25 8.95508L5.75 13.4551"
      stroke="white"
      stroke-width="1.78125"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
