<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.004" cy="9.40923" r="9.36235" fill="white" />
    <path d="M7.50131 6.48047H5.32031V13.075H7.50131V6.48047Z" fill="#222F5A" />
    <path
      d="M14.6606 7.08143C14.2022 6.57792 13.5956 6.32617 12.8431 6.32617C12.5653 6.32617 12.3129 6.36114 12.086 6.42874C11.8591 6.49867 11.6669 6.59424 11.5118 6.71779C11.3544 6.84133 11.2317 6.95788 11.1391 7.06278C11.0511 7.16302 10.9654 7.2819 10.8821 7.41477V6.47769H8.70801L8.71495 6.79704C8.71958 7.00917 8.7219 7.66652 8.7219 8.76678C8.7219 9.86703 8.71727 11.303 8.70801 13.0722H10.8821V9.39383C10.8821 9.16772 10.9052 8.98823 10.9538 8.85536C11.0464 8.62924 11.1854 8.44043 11.3729 8.28658C11.5604 8.13273 11.792 8.05581 12.0698 8.05581C12.4495 8.05581 12.7273 8.18868 12.9056 8.45209C13.0839 8.71549 13.1742 9.08147 13.1742 9.54768V13.0745H15.3482V9.29592C15.3482 8.32388 15.119 7.58494 14.6606 7.08143Z"
      fill="#222F5A"
    />
    <path
      d="M6.42473 3.30469C6.05891 3.30469 5.76256 3.41192 5.53566 3.62637C5.30876 3.84316 5.19531 4.11356 5.19531 4.44224C5.19531 4.76625 5.30645 5.03665 5.5264 5.25344C5.74635 5.47023 6.03808 5.57979 6.39926 5.57979H6.41315C6.7836 5.57979 7.08227 5.47023 7.30917 5.25344C7.53607 5.03665 7.6472 4.76625 7.64257 4.44224C7.63794 4.11356 7.5268 3.84316 7.30454 3.62637C7.08459 3.41192 6.79054 3.30469 6.42473 3.30469Z"
      fill="#222F5A"
    />
  </svg>
</template>
