<template>
  <div
    data-pushup-item="switch-button"
    :style="`--data-coef:${elements.length}`"
    class="relative select-none bg-switchblue rounded-xl p-2 flex flex-row flex-nowrap gap-5"
    ref="switch_button"
  >
    <button
      type="button"
      v-for="(el, index) in elements"
      :key="index"
      class="md:capitalize textSwitch cursor-pointer inline-block rounded-[3.75rem] outline-none appearance-none w-full"
      :class="{
        'active z-[1]': actualState.key === el.key,
      }"
      @click="change(el, index)"
    >
      {{ el.label }}
    </button>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onBeforeMount,
  ref,
  computed,
  toRefs,
  toRef,
  watch,
} from "vue";

export default {
  props: {
    elements: {
      type: [Array, Object],
      required: true,
    },
    default: {
      type: String,
    },
  },

  setup(props, context) {
    const actualState = ref(props.elements[0]);
    const defaultState = toRef(props, "default");
    const switch_button = ref(null);

    const change = (el, index) => {
      actualState.value = el;
      context.emit("change", el);

      console.log(el, index);

      Array.from(switch_button.value.classList).forEach((c) => {
        if (/switch/i.test(c)) switch_button.value.classList.remove(c);
      });

      switch_button.value.classList.toggle(
        `switch-${index === props.elements.length - 1 ? "end" : index}`,
      );
    };

    onMounted(async () => {
      if (defaultState.value.length === 0)
        context.emit("change", actualState.value);
      else {
        const _s = props.elements.filter((it) => it.key === defaultState.value);
        const _idx = props.elements.findIndex(
          (it) => it.key === defaultState.value,
        );
        change(_s[0], _idx);
      }
    });

    return {
      change,
      actualState,
      switch_button,
    };
  },
};
</script>

<style lang="scss" scoped>
div[data-pushup-item="switch-button"] {
  background-color: #e4ecf6;
  $coef: var(--data-coef);

  button.active {
    font-weight: bold;
    color: #222f5a;
  }

  button {
    padding: 12px 16px;
    line-height: 18px;
    font-size: 16px;
    color: #4a4a4a;
    text-align: center;
  }

  &::before {
    position: absolute;
    display: block;
    width: calc(100% / #{$coef});
    height: calc(100% - 16px);
    content: "";
    background-color: white;

    border-radius: 12px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.24);
    transition: 0.5s ease;
    transition: all 0.2s ease;
  }

  // increment this as needed or use a function who convert to number $coef
  &.switch-end::before {
    transform: translateX(calc(calc(calc(100% * #{$coef}) - 100%) - 16px));
  }

  // increment this as needed or use a function who convert to number $coef
  @for $i from 1 to 10 {
    &.switch-#{$i}::before {
      transform: translateX(calc(#{100 * $i}% - #{8}px));
    }
  }
}
.textSwitch {
  font-size: 16px !important;
}
@media screen and (max-width: 800px) {
  .textSwitch {
    font-size: 12px !important;
  }
}
</style>
