<template>
    
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_4673_90375)">
<path d="M8.64258 10C9.74715 10 10.6426 9.10457 10.6426 8C10.6426 6.89543 9.74715 6 8.64258 6C7.53801 6 6.64258 6.89543 6.64258 8C6.64258 9.10457 7.53801 10 8.64258 10Z" fill="#0965F6"/>
<path d="M15.981 7.45625C15.1541 6.1775 14.0813 5.09125 12.8788 4.31469C11.5485 3.45469 10.0797 3 8.6316 3C7.30285 3 5.99629 3.37969 4.74816 4.12844C3.47535 4.89188 2.32222 6.00719 1.32066 7.44313C1.2076 7.60541 1.14532 7.79764 1.14176 7.9954C1.1382 8.19315 1.19351 8.3875 1.30066 8.55375C2.12597 9.84531 3.18816 10.9331 4.37191 11.6991C5.70472 12.5625 7.1391 13 8.6316 13C10.0913 13 11.5632 12.5491 12.8878 11.6963C14.0897 10.9222 15.1604 9.83188 15.9841 8.5425C16.0876 8.38012 16.1423 8.19146 16.1417 7.99892C16.1411 7.80638 16.0854 7.61804 15.981 7.45625ZM8.64222 11C8.04888 11 7.46886 10.8241 6.97551 10.4944C6.48217 10.1648 6.09765 9.69623 5.87059 9.14805C5.64352 8.59987 5.58411 7.99667 5.69987 7.41473C5.81562 6.83279 6.10135 6.29824 6.5209 5.87868C6.94046 5.45912 7.47501 5.1734 8.05695 5.05764C8.6389 4.94189 9.2421 5.0013 9.79028 5.22836C10.3385 5.45542 10.807 5.83994 11.1366 6.33329C11.4663 6.82664 11.6422 7.40666 11.6422 8C11.6413 8.79537 11.325 9.5579 10.7625 10.1203C10.2001 10.6827 9.4376 10.9991 8.64222 11Z" fill="#0965F6"/>
</g>
<defs>
<clipPath id="clip0_4673_90375">
<rect width="16" height="16" fill="white" transform="translate(0.642578)"/>
</clipPath>
</defs>
</svg>

</template>