
import { ref } from "@vue/reactivity";
import { computed, watch } from "@vue/runtime-core";
export default {
  name: "NotificationBell",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: any, _: any) {
    const fillColor = computed((): string =>
      props.isActive ? "#F11C33" : "#808080",
    );

    return {
      fillColor,
    };
  },
};
