
import identification from "./identification.vue";
import leftTabs from "@/components/global/left-tld-tabs.vue";
import pay_slip from "./pay_slip.vue";
import { onBeforeMount, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { ActionTypes } from "@/store/pushup/store.types";
import Notifications from "./notifications.vue";
import { useRouter } from "vue-router";
import add from "@/components/svg/add.vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import Head from "@/components/global/head.vue";
import modal from "../components/global/modal.vue";
import { inject } from "vue";
import { computed, ref } from "vue";
export default {
  components: {
    pay_slip,
    leftTabs,
    Head,
    identification,
    Notifications,
    add,
    PrimaryButton,
    modal,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const showNotif: any = inject("$showNotif");
    const $t: any = inject("$t");
    const loader = ref(false);
    const passwordModal = ref(false);
    const password = ref("");
    const gotoCreatevue = () => {
      router.push({ name: "create" });
    };
    function goBack() {
      router.push({ name: "board" });
    }
    const userInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    async function verifyPassword() {
      loader.value = true;
      try {
        const verify = await store.dispatch(ActionTypes.VERIFY_PASSWORD, {
          email: userInfo.value.email,
          password: password.value,
        });
        passwordModal.value = false;
      } catch (error: any) {
        if (error.response.status === 403) {
          showNotif({
            error: true,
            note: $t("index.passwordError"),
          });
        }
        if (error.response.status === 401) {
          showNotif({
            error: true,
            note: $t("index.userNotFound"),
          });
        }
        router.push({ name: "board" });
        console.log(error.response.status);
      }
      loader.value = false;
    }

    onMounted(async () => {
      // if (!userInfo.value.permissions.includes("HAS_ACCESS")) {
      //   router.push("/removeAccess");
      // }
      if (!userInfo.value.ownerSettings.length) {
        router.push({ name: "noSetting" });
      }
      passwordModal.value = true;
      if (!store.state.accountValidator) {
        await store.dispatch(ActionTypes.GET_ACCOUNT_VALIDATOR);
      }
    });

    return {
      gotoCreatevue,
      goBack,
      passwordModal,
      verifyPassword,
      password,
      loader,
    };
  },
};
