<template>
  <div class="py-4 theScroll">
    <div
      class="bg-white rounded p-6 md:flex justify-between grid grid-cols-1 gap-4"
    >
      <div class="flex items-center space-x-4 font-semibold">
        <subIcon />
        <div>
          <span class="block text-[#5F738C]">
            {{ $t("index.balance") }}
          </span>
          <span class="block font-bold text-xl">
            {{ UserInfo.balance.balance }} Fiche de paie
          </span>
        </div>
      </div>
      <div>
        <redList :label="$t('index.activeSub')" @toggle="toggle">
          <template #content v-if="show">
            <div
              class="absolute bg-white md:w-[250px] w-full top-full mt-6 md:mt-2 shadow-sm right-0 rounded p-2"
              style="box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15)"
            >
              <div
                v-for="(sub, index) in data"
                :key="index"
                class="md:p-4 px-4 py-2 font-semibold text-sm hover:bg-[#F5F6F6] cursor-pointer"
              >
                <span> {{ sub.count }} Fiche de paie </span>
                ( {{ sub.amount }} FCFA )
              </div>
            </div>
          </template>
        </redList>
      </div>
    </div>
    <div class="py-6">
      <span class="font-semibold text-xl text-[#F11C33]">
        {{ $t("index.history") }}
      </span>
      <div
        class="w-full bg-white flex justify-center mt-6 py-10"
        v-if="userPlans.count == 0 && !searching"
      >
        <div class="flex flex-col items-center">
          <empty class="mb-10" />
          <span class="font-bold" v-html="$t('index.noTransaction')"> </span>
        </div>
      </div>
      <div v-else>
        <data-table
          :columns="columns"
          :totalData="userPlans.count"
          :loading="loader"
          :data="userPlans.data"
          :count="false"
          :perPage="perPage"
          @mouseleave="unselectedRow"
          @onMouseover="selectedRow"
          @paginate="paginatorPage"
          @paginateChoose="paginateChoose"
        >
          <template #card>
            <div></div>
          </template>
          <template #mobile>
            <div
              class="flex justify-between p-2 items-center md:hidden border-b border-[rgba(0,0,0,0.15)]"
              v-for="(row, index) in userPlans.data"
              :key="index"
            >
              <div class="text-sm">
                <div class="flex space-x-[49px]">
                  <span
                    >{{ row.balance }} Fiche de paie
                    <span class="text-[#808080]"> ( {{ row.plan.tag }}) </span>
                  </span>

                  <span class="font-bold"> {{ row.plan.price }} </span>
                </div>
                <div class="flex justify-between">
                  <div>
                    <span class="font-semibold">
                      {{ $t("index.activateAt") }}
                    </span>
                    <span>
                      {{ formatDate(row.startedAt) }}
                    </span>
                  </div>
                  <div>
                    <span class="font-semibold">
                      {{ $t("index.expireAt") }}
                    </span>
                    <span>
                      {{ formatDate(row.expiredAt) }}
                    </span>
                  </div>
                </div>
              </div>
              <DownloadIcon />
            </div>
          </template>
          <template #tag="{ row }">
            <span class="text-sm block">
              {{ row.plan.endCount }} Fiche de paie
            </span>
            <span class="text-sm block text-[#808080]">
              {{ row.plan.tag }}
            </span>
          </template>

          <template #amount="{ row }">
            <span class="text-sm block"> {{ row.plan.price }} F cfa </span>
          </template>
          <template #expiredAt="{ row }">
            <span class="text-sm block">
              {{ formatDate(row.expiredAt, (time = true)) }}
            </span>
          </template>
          <template #startedAt="{ row }">
            <span class="text-sm block">
              {{ formatDate(row.startedAt, (time = true)) }}
            </span>
          </template>
          <template #actions="{ row }">
            <div :class="`${hoverElm.id == row.id ? 'show' : 'hidde'}`">
              <div class="">
                <white-button
                  @click="downloadInvoice(row.id)"
                  :label="$t('index.downloadInvoice')"
                  class="bg-white text-sm text-ellipsis truncate"
                  style="padding: 8px 16px"
                >
                  <template #icon>
                    <red />
                  </template>
                </white-button>
              </div>
            </div>
          </template>
          <template #status="{ row }">
            <status :status="row.status" :value="formatStatus(row.status)" />
          </template>
        </data-table>
      </div>
    </div>
    <LargeModal
      v-if="show"
      @close="toggle"
      :plans="plans"
      @reload="ReloadTable"
      :type="chooseType"
    />
  </div>
</template>

<script>
import DataTable from "@/components/tables/DataTable.vue";
import whiteButton from "@/components/global/redBorderButton.vue";
import subIcon from "@/components/svg/subIcon.vue";
import redList from "@/components/global/redList.vue";
import empty from "@/components/global/empty.vue";
import red from "@/components/svg/downloadRed.vue";
import LargeModal from "@/components/modals/downModal.vue";
import DownloadIcon from "@/components/svg/mobileDownloadRed.vue";
import status from "@/components/global/status.vue";
import { useStore } from "vuex";
import { $locale } from "@/main";
import { WorkflowWsEvents } from "@/socket";

import { onMounted, ref, computed, watch, onBeforeMount, inject } from "vue";
export default {
  components: {
    subIcon,
    status,
    redList,
    DataTable,
    whiteButton,
    LargeModal,
    empty,
    red,
    DownloadIcon,
  },
  setup() {
    const store = useStore();
    const show = ref(false);
    const $t = inject("$t");
    const PushUpIo = inject("$PushUpIo");
    const loader = ref(false);
    const perPage = ref(10);
    const columns = ref({
      tag: $t("index.package"),
      amount: $t("index.amount"),
      startedAt: $t("index.createdAt"),
      expiredAt: $t("index.expiredAt"),
      status: $t("index.statut"),
      actions: "Action",
    });

    const showModal = ref(false);
    const hoverElm = ref({});
    const UserInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    const searched = ref({});
    const paginator = ref({});
    const searching = ref(false);
    const verify = ref([]);
    watch(perPage, async () => {
      await getPlans(paginate.value);
      searching.value = false;
    });
    watch(paginator, async () => {
      await getPlans(paginate.value);
      searching.value = false;
    });
    const paginate = computed(() => {
      return {
        limit: perPage.value,
        page: paginator.value.page,
      };
    });
    function paginatorPage(data) {
      paginator.value = data;
    }
    async function downloadInvoice(data) {
      await store.dispatch("GET_INVOCES_SUB", data);
    }
    const chooseType = ref("");
    function toggle(data) {
      console.log(data);
      chooseType.value = data;
      show.value = !show.value;
    }
    function formatDate(data, time) {
      const date = new Date(data);

      const dateStr = date
        .toLocaleString($locale, {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .split(",");

      if (!time) {
        return dateStr[0];
      }

      return dateStr.join($t("index.at"));
    }

    async function getPlans(add) {
      loader.value = true;

      await store.dispatch("GET_USER_PLAN", {
        params: {
          ...add,
          ...searched.value,
        },
        id: store.state.businessId,
      });
      loader.value = false;
    }

    const userPlans = computed(() => {
      return store.getters["getUserPlan"];
    });

    const selectedRow = (data) => {
      hoverElm.value = data;
    };
    const unselectedRow = (data) => {
      hoverElm.value = "";
    };
    const balance = computed(() => {
      return store.getters["getBalance"];
    });
    function formatStatus(key) {
      const obj = {
        PENDING: $t("index.pending"),
        FINISHED: $t("index.finished"),
        ACTIVE: $t("index.active"),
        EXPIRED: $t("index.expired"),
        FAILED: $t("index.failed"),
      };
      return obj[key];
    }
    function paginateChoose(data) {
      perPage.value = data;
    }
    async function ReloadTable() {
      await getPlans(paginate.value);
    }
    const plans = computed(() => {
      return store.getters["getPlans"];
    });
    onMounted(async () => {
      await store.dispatch("GET_PLANS");
      console.log(UserInfo.value, "UserInfo");
      // ReloadTable, (searching.value = true);
      setTimeout(() => {}, 1000);
      verify.value = userPlans.value.data;
      paginator.value = { start: 1, end: 1, page: 1, perPage: 10, total: 1 };

      // PushUpIo.on(WorkflowWsEvents.PAYEMENT_STATUS_CHANGED, async (data) => {
      //   await getPlans(paginate.value);
      // });
      await getPlans(paginate.value);
      await store.dispatch("GET_BALANCE", store.state.businessId);
    });
    return {
      toggle,
      perPage,
      show,
      columns,
      loader,
      selectedRow,
      downloadInvoice,
      hoverElm,
      unselectedRow,
      userPlans,
      formatDate,
      balance,
      paginatorPage,
      paginateChoose,
      formatStatus,
      ReloadTable,
      verify,
      searching,
      UserInfo,
      plans,
      chooseType,
    };
  },
};
</script>

<style>
.show {
  visibility: visible;
}
.hidde {
  visibility: hidden;
}
/* .theScroll {
  padding-bottom: 20% !important;
}
@media screen and (max-width: 800px) {
  .theScroll {
    padding-bottom: 100% !important;
  }
} */
</style>
