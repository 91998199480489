import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "dialogContent" }
const _hoisted_3 = { class: "mt-[24px] grid grid-cols-2 gap-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_whiteButton = _resolveComponent("whiteButton")!
  const _component_primary_button = _resolveComponent("primary-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("index.confirmationDetails")), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_whiteButton, {
        label: _ctx.$t('index.cancel'),
        onClick: $setup.onCancel
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_primary_button, {
        class: "w-full",
        onClick: $setup.onConfirm,
        loading: $setup.state.isLoading,
        label: _ctx.$t('index.yesValidate')
      }, null, 8, ["onClick", "loading", "label"])
    ])
  ]))
}