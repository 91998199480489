<template>
  <div>
    <div
      class="relative bg-white px-3 pb-3 pt-3 border border-tint rounded-lg w-72"
    >
      <div class="flex flex-col">
        <div class="flex gap-3 pb-3 justify-between">
          <div class="flex-1">
            <p class="text-normal font-semibold">Date de début</p>
            <DatePick class="p-1 h-10" @dateSelected="dateBegin" />
          </div>
          <div class="flex-1">
            <p class="text-normal font-semibold">Date de fin</p>
            <DatePick class="p-1 h-10" @dateSelected="dateEnd" />
          </div>
        </div>

        <primary-button @click="showModal = true" :label="$t('index.apply')">
        </primary-button>
      </div>
    </div>
    <Modal v-if="showModal" @close="showModal = false" :title="' '">
      <div class="justify-center py">
        <div>
          <div class="font-bold text-2xl flex items-center justify-center">
            Votre relevé vous sera envoyé par mail
          </div>

          <div class="flex items-center justify-center text-sm text-center">
            Cela peut prendre quelques miniutes
          </div>
          <br />
        </div>
      </div>
      <form @submit.prevent="generateStatement">
        <label for="" class="font-bold"
          >Veuillez entrer votre adresse email</label
        >
        <input
          type="email"
          class="border px-3 py-2 rounded w-full outline-none my-3 border-greyScale"
          v-model="email"
        />
        <primary-button :loading="loading" label="Envoyer" class="w-full">
        </primary-button>
      </form>
    </Modal>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import DatePick from "@/components/global/datePick.vue";
import Calendar from "@/components/global/calendar.vue";
import store from "@/store";
import Modal from "./modal.vue";
import { inject } from "vue";

export default {
  components: {
    PrimaryButton,
    DatePick,
    Modal,
  },

  setup(_, { emit }) {
    const showModal = ref(false);
    const beginDate = ref(new Date());
    const endDate = ref(new Date());
    const loading = ref(false);
    const $t = inject("$t");
    const showNotif = inject("$showNotif");

    const dateBegin = (date) => {
      beginDate.value = date;
    };
    const dateEnd = (date) => {
      endDate.value = date;
    };
    const email = ref("");

    async function generateStatement() {
      loading.value = true;
      await store.dispatch("GENERATE_STATEMENT", {
        params: {
          from: beginDate.value.toISOString(),
          to: endDate.value.toISOString(),
          email: email.value,
        },
      });

      loading.value = !loading.value;
      // emit("apply");
      showNotif({
        success: true,
        note: "Relevé envoyé avec succès",
      });
    }
    return {
      generateStatement,
      dateBegin,
      dateEnd,
      loading,
      showModal,
      email,
      // dateFilterDisable,
    };
  },
  emits: ["apply"],
};
</script>

<style lang="scss" scoped></style>
