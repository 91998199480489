<template>
  <svg
    width="259"
    height="181"
    viewBox="0 0 259 181"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M129.5 180.22C200.694 180.22 258.408 164.135 258.408 144.294C258.408 124.453 200.694 108.369 129.5 108.369C58.3066 108.369 0.592773 124.453 0.592773 144.294C0.592773 164.135 58.3066 180.22 129.5 180.22Z"
      fill="url(#paint0_linear_1572_108127)"
    />
    <path
      d="M81.6789 13.2061H179.276C181.27 13.2061 182.89 14.8261 182.89 16.8205V141.126C182.89 143.12 181.27 144.74 179.276 144.74H81.6789C79.6845 144.74 78.0645 143.12 78.0645 141.126V16.8205C78.0709 14.8261 79.6845 13.2061 81.6789 13.2061Z"
      fill="#D2D2D2"
    />
    <path
      d="M86.9338 13.2061H184.299C186.357 13.2061 188.029 14.8777 188.029 16.9367V141.01C188.029 143.069 186.357 144.74 184.299 144.74H86.9338C84.8748 144.74 83.2031 143.069 83.2031 141.01V16.9367C83.2031 14.8777 84.8684 13.2061 86.9338 13.2061Z"
      fill="#E1E1E1"
    />
    <path
      d="M95.6667 22.248H176.482C177.515 22.248 178.354 23.0871 178.354 24.1198V134.703C178.354 135.736 177.515 136.575 176.482 136.575H95.6667C94.634 136.575 93.7949 135.736 93.7949 134.703V24.1198C93.7949 23.0871 94.634 22.248 95.6667 22.248Z"
      fill="#FAFAFC"
    />
    <path
      d="M163.25 62.3237H131.275C129.932 62.3237 128.841 61.2264 128.841 59.8904V53.0681C128.841 51.7256 129.939 50.6348 131.275 50.6348H163.25C164.592 50.6348 165.683 51.732 165.683 53.0681V59.8904C165.69 61.2264 164.592 62.3237 163.25 62.3237Z"
      fill="#ECECEC"
    />
    <path
      d="M120.587 61.5171H109.292C107.95 61.5171 106.859 60.4198 106.859 59.0837V52.2614C106.859 50.9189 107.956 49.8281 109.292 49.8281H120.587C121.93 49.8281 123.021 50.9254 123.021 52.2614V59.0837C123.027 60.4198 121.93 61.5171 120.587 61.5171Z"
      fill="#ECECEC"
    />
    <path
      d="M163.25 83.6235H110.827C109.485 83.6235 108.394 82.5263 108.394 81.1902V74.3679C108.394 73.0254 109.491 71.9346 110.827 71.9346H163.25C164.593 71.9346 165.683 73.0318 165.683 74.3679V81.1902C165.69 82.5327 164.593 83.6235 163.25 83.6235Z"
      fill="#ECECEC"
    />
    <path
      d="M163.25 105.73H110.827C109.485 105.73 108.394 104.633 108.394 103.297V96.4743C108.394 95.1318 109.491 94.041 110.827 94.041H163.25C164.593 94.041 165.683 95.1383 165.683 96.4743V103.297C165.69 104.639 164.593 105.73 163.25 105.73Z"
      fill="#ECECEC"
    />
    <path
      d="M106.859 27.4312V12.6442C106.859 10.1399 108.911 8.08737 111.416 8.08737H127.474C128.333 3.48538 132.367 0 137.22 0C142.074 0 146.108 3.48538 146.967 8.08737H161.134C163.638 8.08737 165.691 10.1399 165.691 12.6442V27.4312H106.859Z"
      fill="#D2D2D0"
    />
    <path d="M103.121 22.248L106.858 27.4309V22.248H103.121Z" fill="#BDBDBE" />
    <path
      d="M137.046 15.5426C140.383 15.5426 143.088 12.8378 143.088 9.50129C143.088 6.16476 140.383 3.45996 137.046 3.45996C133.71 3.45996 131.005 6.16476 131.005 9.50129C131.005 12.8378 133.71 15.5426 137.046 15.5426Z"
      fill="#C4C4C4"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1572_108127"
        x1="129.503"
        y1="180.219"
        x2="129.503"
        y2="108.369"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="#E7E7E7" />
      </linearGradient>
    </defs>
  </svg>
</template>
