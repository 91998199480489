<template>
  <button
    type="submit"
    @click="$emit('clicked', $event)"
    class="primary-button appearance-none md:px-3 md:py-2 p-2 outline-none select-none inline-flex items-center justify-center border-0 hover:opacity-90 bg-primaryColor active:animate-pulse"
    :class="{
      'opacity-75  pointer-events-none': loading,
    }"
  >
    <div class="primary-loader" v-if="loading"></div>
    <span v-if="hasIconSlot" class="inline-flex w-4 h-4 flex-shrink-0">
      <slot name="icon" />
    </span>
    <span
      v-if="!loading"
      class="truncate text-white font-semibold text-sm md:text-base leading-[1.219rem] text-center primary-button-label"
      :class="{ 'md:mx-[14px] mx-[9px]': loading || hasIconSlot }"
    >
      {{ label }}
      <!-- {{ $t('index.validate') }} -->
    </span>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasIconSlot() {
      return !!this.$slots.icon;
    },
  },
};
</script>
<style>
.primary-button {
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
