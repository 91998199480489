<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.5004 18H37.846L30.5002 6L23.1556 18H8.5L15.808 30L8.5 42H23.1556L30.5002 54L37.846 42H52.5004L45.1894 30L52.5004 18ZM45.379 37.9998H35.6038L30.5002 46.3374L25.3972 37.9998H15.619L20.491 30L15.619 22.0002H25.3966L30.5002 13.662L35.6038 22.0002H45.379L40.5064 30L45.379 37.9998Z"
      fill="#4661B9"
    />
    <path
      d="M27.6374 34.0002H22.7378L25.1744 30L22.7378 25.9998H27.6374L30.5 21.3252L33.362 25.9998H38.258L35.822 30L38.258 34.0002H33.362L30.5 38.676L27.6374 34.0002Z"
      fill="#F11C33"
    />
  </svg>
</template>