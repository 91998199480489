export const request = {};

export enum ActionTypes {
  UPDATE_MODE = "UPDATE_MODE",
  SET_LOGO = "SET_LOGO",
  SEND_PAY_SLIP = "SEND_PAY_SLIP",
  UPLOAD_LOGO = "UPLOAD_LOGO",
  GET_INVOCES_SUB = "GET_INVOCES_SUB",
  GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS",
  RESEND_CHANGE_VALIDATOR_LINK = "RESEND_CHANGE_VALIDATOR_LINK",
  VERIFY_KYC = "VERIFY_KYC",
  CHANGE_VALIDATOR = "CHANGE_VALIDATOR",
  GET_REVOC_VALIDATORS = "GET_REVOC_VALIDATORS",
  CHANGE_SETTINGS = "CHANGE_SETTINGS",
  ADD_VALIDATOR = "ADD_VALIDATOR",
  GET_CAMPAIGN = "GET_CAMPAIGN",
  CREATE_CAMPAIGN = "CREATE_CAMPAIGN",
  RESEND_VALIDATION_LINK = "RESEND_VALIDATION_LINK",
  UPLOAD_FILE = "UPLOAD_FILE",
  GET_BUSSINESS_ACCOUNT = "GET_BUSSINESS_ACCOUNT",
  GET_PLANS = "GET_PLANS",
  GET_ERROR_FILES = "GET_ERROR_FILES",
  UNIQUE_VALIDATION = "UNIQUE_VALIDATION",
  DOUBLE_VALIDATION = "DOUBLE_VALIDATION",
  VERIFY_PASSWORD = "VERIFY_PASSWORD",
  DOWNLOAD_SUPPLY_TRANSACTION = "DOWNLOAD_SUPPLY_TRANSACTION",
  USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD",
  // GET_USER_BUSSINESS_ACCOUNT = "GET_USER_BUSSINESS_ACCOUNT",
  GET_ALL_DISPACTHING = "GET_ALL_DISPACTHING",
  GET_DISPACTHING_INFO = "GET_DISPACTHING_INFO",
  RUN_DISPACTHING = "RUN_DISPACTHING",
  GET_CAMPAIGN_DISPATCHING = "GET_CAMPAIGN_DISPATCHING",
  GET_CAMPAIGN_BY_ID = "GET_CAMPAIGN_BY_ID",
  SET_VALIDATION_SETTINGS = "SET_VALIDATION_SETTINGS",
  GET_ACCOUNT_VALIDATOR = "GET_ACCOUNT_VALIDATOR",
  ARCHIVE = "ARCHIVE",
  DOWNLOAD = "DOWNLOAD",
  RUN_DISPACTHING_FILE = "RUN_DISPACTHING_FILE",
  ARCHIVE_MANY = "ARCHIVE_MANY",
  SET_NOTIFICATIONS_SETTINGS = "SET_NOTIFICATIONS_SETTINGS",
  SAVE_BUSINESS_ID_TO_STORAGE = "SAVE_BUSINESS_ID_TO_STORAGE",
  TRANSACTIONS = "TRANSACTIONS",
  RESET_PREVIEW_STATE = "RESET_PREVIEW_STATE",
  TEMPLATE_FILE = "TEMPLATE_FILE",
  APPRO_TRANSACTION = "APPRO_TRANSACTION",
  GENERATE_STATEMENT = "GENERATE_STATEMENT",
  SUPPORTED_FUNDING_SOURCE = "SUPPORTED_FUNDING_SOURCE",
  INIT = "INIT",
  ME = "ME",
  GET_DOWNLOAD_URL = "GET_DOWNLOAD_URL",
  RESEND_INVITATION = "RESEND_INVITATION",
  CREATE_PLAN = "CREATE_PLAN",
  GET_USER_PLAN = "GET_USER_PLAN",
  GET_BALANCE = "GET_BALANCE",
  WEBHOOK = "WEBHOOK",
  GET_CONTACTS = "GET_CONTACTS",
  CREATE_CONTACT = "CREATE_CONTACT",
  DELETE_CONTACT = "DELETE_CONTACT",
  CANCEL_VALIDATION = "CANCEL_VALIDATION",
  SEND_MULTIPLE_PAY_SLIP = "SEND_MULTIPLE_PAY_SLIP",
  LOCAL_LOADING = "LOCAL_LOADING",
  GET_REPORT_FILE = "GET_REPORT_FILE",
}

export enum MutationTypes {
  SET_LOCAL_LOADING = "SET_LOCAL_LOADING",
  SET_ALL_TRANSACTIONS = "SET_ALL_TRANSACTIONS",
  SET_MODE = "SET_MODE",
  SET_REVOCATING_VALIDATOR = "SET_REVOCATING_VALIDATOR",
  SET_USER_FORGOT_ACCOUNT = "SET_USER_FORGOT_ACCOUNT",
  SET_CURRENT_CAMPAIGN = "SET_CURRENT_CAMPAIGN",
  SET_ACCOUNT_INFO = "SET_ACCOUNT_INFO",
  ALL_DISPATCHING = "ALL_DISPATCHING",
  DISPATCHING = "DISPATCHING",
  SET_REPORT_DATA = "SET_REPORT_DATA",
  CAMPAIGN_BY_ID = "CAMPAIGN_BY_ID",
  SET_ACCOUNT_VALIDATOR = "SET_ACCOUNT_VALIDATOR",
  SET_BUSINESS_ID = "SET_BUSINESS_ID",
  ADD_TRANSACTION = "ADD_TRANSACTION",
  SET_TRANSACTIONS = "SET_TRANSACTIONS",
  SET_TRANSACTIONS_TOTAL = "SET_TRANSACTIONS_TOTAL",
  AMOUNT = "AMOUNT",
  SET_PLANS = "SET_PLANS",
  RESET = "RESET",
  SET_ME = "SET_ME",
  SET_APPROS = "SET_APPROS",
  FUNDING_SOURCE = "FUNDING_SOURCE",
  SET_BUSINESS_NOTIFICATION_SETTING = "SET_BUSINESS_NOTIFICATION_SETTING",
  TOTAL = "TOTAL",
  TOTAL_APPROS = "TOTAL_APPROS",
  REFERENCE = "REFERENCE",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
  SET_USER_PLAN = "SET_USER_PLAN",
  BALANCE = "BALANCE",
  SET_CONTACTS = "SET_CONTACTS",
}

export enum MutationTypes {}

export interface CreateCampaignPayload {
  name: string;
  reason: string;
}
export interface UploadCampaignFilePayload {
  file: any;
  campaignId: string;
}
export interface SetAccountValidationPayload {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
}

export enum NotificationType {
  CREATE = "CREATE",
  END = "END",
}

export enum TransactionStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
}

export type DownloadFilterType = {
  type: TransactionStatus | "ALL";
  label: string;
};
export interface SetNotificationsSettingsPayload {
  value: boolean;
  eventType: NotificationType;
}

export const MINIMUM_AMOUNT_TO_PROVIDE = 5000;
export const MAXIMUM_AMOUNT_TO_PROVIDE = 200000;
