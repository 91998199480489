<template>
  <div
    v-if="!!$slots.icon"
    class="bg-white card-cheap border-[rgba(0,0,0,0.15)] space-x-3 py-2.5 pr-2.5 flex flex-wrap items-center"
    style="box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1)"
  >
    <span class="inline-flex flex-shrink-0">
      <slot name="icon" />
    </span>
    <div class="font-medium text-394452 text-sm leading-[1.225rem] text-left">
      <span class="block stat-card-text" style="color: #5f738c">{{
        label
      }}</span>
      <span class="label block text-black text-lg font-semibold"
        >{{ rank }}
        <span class="text-[#5f738c] text-sm" v-if="currency">F CFA</span>
      </span>
      <slot name="custome" />
      <span class="text-sm text-[#5F738C]" v-if="showDate">{{
        date ? formatDate(date) : ""
      }}</span>
    </div>
  </div>
</template>

<script>
import { $locale } from "@/main";

export default {
  props: {
    showDate: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
    rank: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    currency: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const formatDate = (data) => {
      const date = new Date(data);
      return date.toLocaleString($locale, {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    };
    return {
      formatDate,
    };
  },
};
</script>
<style>
.label {
  font-size: 24px;
  line-height: 24px;
}
.card-cheap {
  border-radius: 8px;
  padding: 16px;
}

.stat-card-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #5f738c;
  margin-bottom: 4px;
}
</style>
