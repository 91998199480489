// import { io, Socket } from "socket.io-client";
import { BACKEND } from "./api";
import {
  addMultiplePushEventListener,
  addPushEventListener,
  initPushEvent,
  joinPushEventRoom,
  removePushEventListener,
} from "@asaje/sse-push-event-client";
export class PushupSocket {
  // private socketIo: Socket;
  public addSocketListener: (...args: any[]) => any;
  public addMultipleSocketListener: (...args: any[]) => any;
  public removeEventListener: (eventName: string) => any;
  public isSocketConnected: boolean = false;

  constructor() {
    initPushEvent(BACKEND.baseUrl + "/events");
    // this.socketIo = io(BACKEND.baseUrl, {
    //   path: BACKEND.socket,
    //   transports: ["websocket"],
    //   rejectUnauthorized: false,
    // });
    // this.init();

    /**
     * @param {string} name
     * @param {any} cb
     * @returns {any}
     */
    this.addSocketListener = (name: string, cb: any): any => {
      if (!(typeof name === "string")) {
        throw new Error(
          "Invalid type for `name` argument, `string` expected, `" +
            typeof name +
            "` got",
        );
      }
      // this.socketIo.on(name, (data: any) => cb(data));
      addPushEventListener(name, cb);
    };

    /**
     * @param {string} eventName
     * @returns {any}
     */
    this.removeEventListener = (eventName: string): any => {
      // this.socketIo.removeAllListeners(eventName);
      removePushEventListener(eventName);
    };

    /**
     * @param {string[]} names
     * @param {any} cb
     * @returns {any}
     */
    this.addMultipleSocketListener = (names: string[], cb: any): any => {
      if (!Array.isArray(names)) {
        throw new Error(
          "Invalid type for `names` argument, `Array<string>` expected, `" +
            typeof names +
            "` got",
        );
      }
      // names.forEach((name: string) => this.addSocketListener(name, cb));
      addMultiplePushEventListener(names, cb);
    };
  }

  // private init() {
  //   this.socketIo.on("error", (err: any) => {
  //     this.isSocketConnected = false;
  //     console.error("socket error", err);
  //   });

  //   this.socketIo.on("connect_error", (err: any) => {
  //     this.isSocketConnected = false;
  //     console.error(`socket connect_error due to ${err.message}`);
  //   });

  //   this.socketIo.on("connect", () => {
  //     this.isSocketConnected = true;
  //     console.log("socket connected");
  //   });

  //   this.socketIo.on("disconnect", () => {
  //     this.isSocketConnected = false;
  //     console.log("Socket disconnected");
  //   });
  // }

  /**
   * Join dispatching room
   * @param id
   */
  joinRoom(id: string): void {
    // if (this.isSocketConnected) {
    //   this.socketIo.emit("joinRoom", id);
    //   console.log("-----JOINED ROOM", id);
    // }
    joinPushEventRoom(id);
  }

  /**
   * Listen on specific event
   * @param event
   * @param callback
   */
  on(event: string, callback: (...args: any[]) => void) {
    // this.socketIo.on(event, callback);
    addPushEventListener(event, callback);
  }

  /**
   * Emit event to server
   * @param event
   * @param args
   */
  // emit(event: string, ...args: any[]) {
  //   this.socketIo.emit(event, ...args);
  // }
}

export enum WorkflowWsEvents {
  REPORTS = "REPORTS",
  TRANSACTION_SUCCEED = "TRANSACTION_SUCCEED",
  TRANSACTION_FAILED = "TRANSACTION_FAILED",
  CAMPAIGN_STATUS_CHANGED = "CAMPAIGN_STATUS_CHANGED",
  DISPATCHING_STATUS_CHANGED = "DISPATCHING_STATUS_CHANGED",
  SETTING_CONFIRMED = "SETTING_CONFIRMED",
  PAYEMENT_STATUS_CHANGED = "PAYEMENT_STATUS_CHANGED",
  SUBSCRIPTION_PAYMENT = "SUBSCRIPTION_PAYMENT",
  REVOCATION_CONFIRMED = "REVOCATION_CONFIRMED",
}
