<template>
 <div ref="popup" data-pushup-item="notification-modal" class="z-[9999]   space-x-4  text-black select-none cursor-pointer p-2  items-center  text-left   w-fit flex-nowrap   m-[auto] flex left-0 right-0 top-0 fixed justify-center">
     <span class="font-semibold text-base"> {{ label }} </span>
      <div
    
    
    class=" flex-col rounded-full text-black select-none  p-2 justify-between  text-left  flex w-fit flex-nowrap  border m-[auto]"
    :class="{
      'error-notification border-primaryColor bg-[#FFF5F4] ': error,
      'success-notification border-hightGreen  bg-[#E6FAEE]': success,
    }"
  >
    <div class=" space-x-4 flex items-center">
      <span v-if="success">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM8.21875 14.8928L4.44062 10.6942L5.55578 9.69062L8.18078 12.6072L14.4062 5.19344L15.5566 6.15625L8.21875 14.8928Z"
            fill="#27AE60"
          />
        </svg>
      </span>
      <span v-if="error">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.2 12.7C15.3 13.8 13.6 15.4 12.5 14.3C11.6 13.4 10.8 12.5 9.9 11.7L7.3 14.3C6.2 15.4 4.6 13.7 5.6 12.6C6.5 11.7 7.4 10.8 8.3 10L5.7 7.4C4.6 6.3 6.3 4.7 7.3 5.8C8.2 6.7 9.1 7.6 9.9 8.4L12.5 5.8C13.6 4.7 15.2 6.4 14.2 7.5C13.4 8.3 12.5 9.1 11.6 10L14.2 12.7Z"
            fill="#EB5757"
          />
        </svg>
      </span>
      <!-- message -->
      <span class=" text-base leading-[1.225rem]">
        {{ note }}
      </span>
      <!-- close -->
      <span
        class="self-start cursor-pointer inline-flex hover:opacity-95 active:animate-pulse"
        @click="closeModal()"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.7803 6.21967C18.0732 6.51256 18.0732 6.98744 17.7803 7.28033L7.28033 17.7803C6.98744 18.0732 6.51256 18.0732 6.21967 17.7803C5.92678 17.4874 5.92678 17.0126 6.21967 16.7197L16.7197 6.21967C17.0126 5.92678 17.4874 5.92678 17.7803 6.21967Z"
            fill="black"
          />
        </svg>
      </span>
    </div>
  </div>
 </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onBeforeMount,
  ref,
  computed,
  toRefs,
  watch,
  toRef,
} from "vue";
import { debounce } from "lodash";
// import Close from '@/components/global/svg/navigation-close.vue';

export default {
  components: {
    //Close,
  },

  props: {
    out: {
      type: Number,
      default: 6000,
    },
    success: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    note: {
      type: String,
      default: "Notification message...",
    },
    label:{
      type: String,
     
    },
  },

  setup(props, context) {
    const popup = ref(null);
    const time = toRef(props, "out");
    const label = toRef(props, "label");
    const closeModal = () => {
      popup.value.classList.add("slideup");
      context.emit("notification:closed");
      debounce(() => {
        popup.value.remove();
      }, 10);
    };
    const show = ref(false);

    const autoClose = debounce(() => {
      closeModal();
    }, time.value);

    onMounted(async () => {
      setTimeout(() => {
        show.value = true;
      }, 500);
    });

    return {
      popup,
      closeModal,
      autoClose,
      show,
      label
    };
  },
};
</script>

<style lang="scss" scoped>
div[data-pushup-item="notification-modal"] {
  //   box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

  &.slideup {
    transition: 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(-150%);
  }
}
</style>
