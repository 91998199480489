<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.271 7.04285C13.9092 7.59533 14.5485 8.18909 15.1807 8.82029C15.8138 9.45317 16.4071 10.0923 16.9601 10.7316L15.9369 11.9998C15.365 11.3254 14.7377 10.6402 14.0489 9.95165C13.3757 9.27821 12.6878 8.64989 11.9985 8.06405L13.271 7.04285Z"
      fill="#F11C33"
    />
    <path
      d="M21.0559 2.94405C19.6692 1.55637 15.9607 2.91117 12.0005 6.00333L10.7316 7.03989C10.0922 7.59333 9.45167 8.18685 8.81879 8.81997C8.18759 9.45189 7.59359 10.0912 7.04207 10.7299L6.00239 12C2.91239 15.9607 1.55663 19.6692 2.94455 21.0559C4.33127 22.4433 8.04071 21.0881 12.0007 17.9966L13.2701 16.9596C13.9085 16.4066 14.5486 15.8133 15.1805 15.1809C15.8129 14.5488 16.4062 13.9096 16.9591 13.2708L17.9969 12C21.0888 8.03949 22.4441 4.33101 21.0559 2.94405ZM19.2113 7.15245C18.6672 8.28549 17.8978 9.50061 16.9603 10.7313L15.9372 11.9995C15.3511 12.6876 14.7226 13.3752 14.0486 14.0496C13.361 14.7381 12.6746 15.3645 12.0012 15.9362L10.7299 16.9588C8.09375 18.9621 5.77751 20.0011 4.50863 20.0011C4.21559 20.0011 4.09055 19.9397 4.07567 19.9253C3.99527 19.8446 3.78623 18.9364 4.78991 16.8477C5.33375 15.7149 6.10247 14.5 7.03991 13.2693L8.06351 12.0021C8.64863 11.3128 9.27599 10.6264 9.94967 9.95253C10.639 9.26421 11.3249 8.63613 11.9986 8.06397L13.271 7.04301C15.9065 5.03853 18.2225 3.99933 19.4916 3.99933C19.7851 3.99933 19.909 4.06029 19.9248 4.07589C20.0054 4.15605 20.2142 5.06397 19.2113 7.15245Z"
      fill="#4661B9"
    />
    <path
      d="M10.7296 16.9589C10.0902 16.4062 9.45083 15.8139 8.81963 15.1812C8.18651 14.5484 7.59299 13.9083 7.03955 13.2692L8.06315 12.002C8.63531 12.6752 9.26267 13.3616 9.95099 14.0496C10.6247 14.7236 11.3125 15.3516 12.0006 15.9363L10.7296 16.9589Z"
      fill="#F11C33"
    />
    <path
      d="M17.9964 12C21.0878 15.96 22.4434 19.6685 21.0554 21.0559C19.669 22.4434 15.9602 21.0881 12 17.9966L13.2694 16.9596C14.5001 17.8968 15.715 18.6662 16.8473 19.2106C18.9358 20.214 19.8437 20.0047 19.9243 19.9241C19.9392 19.909 20.0006 19.7846 20.0006 19.4918C20.0006 18.222 18.9614 15.907 16.9582 13.2708L17.9964 12Z"
      fill="#F11C33"
    />
    <path
      d="M7.0415 10.7299C5.03822 8.09354 3.99878 5.7773 3.99878 4.50866C3.99878 4.21634 4.05998 4.0913 4.0751 4.0757C4.15478 3.99602 5.06318 3.78626 7.1519 4.78994C8.28518 5.33378 9.49934 6.10346 10.731 7.03994L11.9999 6.00338C8.0387 2.91122 4.33022 1.55714 2.94422 2.9441C1.5563 4.33178 2.9111 8.03954 6.00206 12L7.0415 10.7299Z"
      fill="#F11C33"
    />
  </svg>
</template>
