import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aaa10ff4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-semibold text-medium my-1 text-center md:w-2/3 m-width" }
const _hoisted_2 = ["accept"]
const _hoisted_3 = {
  key: 0,
  class: "text-Grey50 font-normal text-small truncate"
}
const _hoisted_4 = {
  key: 1,
  class: "text-Grey50 font-normal text-small truncate"
}
const _hoisted_5 = {
  key: 0,
  class: "w-full mt-[6px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sheet = _resolveComponent("Sheet")!
  const _component_file = _resolveComponent("file")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["dashed border-none md:p-6 p-4 border-green-70 rounded flex flex-col justify-center items-center", { 'bg-lightRed': $setup.state.error }]),
    onDrop: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.drop && $setup.drop(...args))),
    onDragover: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($setup.dragover && $setup.dragover(...args))),
    onDragleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.dragleave && $setup.dragleave(...args)))
  }, [
    _createVNode(_component_Sheet, { class: "w-12 md:w-auto" }),
    _createElementVNode("div", _hoisted_1, _toDisplayString($props.label), 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("input", {
      type: "file",
      id: "assetsFieldHandle",
      class: "w-px h-px opacity-0 overflow-hidden absolute",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.onChange && $setup.onChange(...args))),
      ref: "file",
      accept: $setup.state.acceptedFileType
    }, null, 40, _hoisted_2),
    _createElementVNode("div", {
      class: "pr-3 pl-2 py-2 cursor-pointer border border-btnBorder h-10 flex items-center rounded w-full",
      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.file.click()))
    }, [
      _createVNode(_component_file, { class: "mr-2" }),
      ($props.name)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($props.name), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("index.clickToUpload")), 1))
    ]),
    ($setup.state.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createTextVNode(_toDisplayString($setup.state.error) + " ", 1),
          _createElementVNode("span", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('download'))),
            class: "text-primaryColor cursor-pointer underline"
          }, _toDisplayString(_ctx.$t("index.fileModel")), 1)
        ]))
      : _createCommentVNode("", true)
  ], 34))
}