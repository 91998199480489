<template>
  <div
    class=" select-none bg-white md:flex flex-wrap justify-between md:border-b border-0 border-greyLith mt-6 items-center  "
  >
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="css">
.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  div[data-d2d-item='table-filter'] > div {
    margin: 5px;
  }
}
</style>
