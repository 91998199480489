<!-- tiny and simple tabs made with tailwindcss -->
<!--
tabs property is required

inactiveTab & activeTab are optional, set to change appearance

just all;

-->

<template>
  <div class="tld-tabs border-b border-greyLith md:border-0 bg-white">
    <div class="w-full">
      <!-- tabs button -->
      <ul class="tld-tabs-buttons m-0 list-none select-none flex flex-row">
        <li
          v-for="tab in tabs"
          :key="tab.name"
          class="tld-tab-button cursor-pointer md:px-8 text-center p-2 md:py-4"
          :class="dynamicClass(tab.name)"
          @click="toggleTabs(tab.name)"
        >
          <span class="truncate md:text-sm font-semibold text-xs">
            {{ tab.libelle }}
          </span>
        </li>
      </ul>
      <!-- tabs area -->
    </div>
  </div>
</template>

<script>
export default {
  name: "tailwind-tabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },

    inactiveTabClass: {
      type: String,
      default: "text-09101D bg-white",
    },

    activeTabClass: {
      type: String,
      default: "text-primaryColor border-b-2 border-primaryColor",
    },

    activeTabIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  data() {
    return {
      openTab: this.tabs[this.activeTabIndex ?? 0].name,
    };
  },
  watch: {
    activeTabIndex: {
      handler(newValue, oldValue) {
        console.log("-----watcher newValue:", newValue);
        this.openTab = this.tabs[newValue].name;
      },
    },
  },
  methods: {
    toggleTabs(tab) {
      this.openTab = tab;
      this.$emit("selected", tab);
    },

    dynamicClass(tabname) {
      if (tabname === this.openTab) return this.activeTabClass;
      return this.inactiveTabClass;
    },
  },
};
</script>
<style scoped>
ul {
  overflow: scroll;
}
ul::-webkit-scrollbar {
  display: none;
}
</style>
