
import {
  onMounted,
  computed,
  ref,
  watch,
  reactive,
  defineComponent,
  watchEffect,
} from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { Field, Form } from "vee-validate";
import { inject } from "vue";
import { useStore } from "vuex";
import userIcon from "@/components/svg/user.vue";
import radioWhite from "@/components/global/radioWhite.vue";
import status from "@/components/global/status.vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import Modal from "@/components/global/modal.vue";
import CustomInput from "@/components/global/customInput.vue";
import TelInput from "@/components/global/telInput.vue";
import {
  ActionTypes,
  SetAccountValidationPayload,
} from "@/store/pushup/store.types";
import { AccountValidator } from "@/store/pushup/state";
import Warning from "@/components/svg/warning.vue";
import SettingsConfirmation from "@/components/modals/SettingsConfirmation.vue";
import { ApiCallResult } from "@/api";
import { WorkflowWsEvents } from "@/socket";
import Check from "@/components/svg/check.vue";
import ThreeDot from "@/components/svg/threeDot.vue";
import { useRouter } from "vue-router";

const MINIMAL_PHONE_NUMBER_LENGTH = 11;

const dropdownOptions = {
  showDialCodeInSelection: true,
  showFlags: true,
};

const telInputOptions = {
  type: "tel",
};

export default {
  components: {
    Field,
    Form,
    VueTelInput,
    status,
    radioWhite,
    userIcon,
    PrimaryButton,
    Modal,
    CustomInput,
    TelInput,
    Warning,
    SettingsConfirmation,
    Check,
    ThreeDot,
  },
  setup() {
    const $t: any = inject("$t");
    const showNotif: any = inject("$showNotif");
    const PushUpIo: any = inject("$PushUpIo");
    const router = useRouter();

    const store = useStore();

    const vuePhoneInputComp = ref(null);

    const inputGroups = ref([
      {
        value: $t("index.unique"),
        id: "unique",
        description: $t("index.auth_uniqueB"),
      },
      {
        description: $t("index.auth_doubleB"),
        value: $t("index.double"),
        id: "double",
      },
    ]);

    const state = reactive({
      isLoading: false,
      validatorNumber: "",
      phoneNumberIsValid: false,
      firstName: "",
      lastName: "",
      showModal: false,
      accountInfo: computed((): any => store.state.meInfo),
      accountValidator: computed(
        (): AccountValidator => store.getters.accountValidator,
      ),
      validatorName: computed(
        (): string =>
          state.accountValidator.firstname +
          " " +
          state.accountValidator.lastname,
      ),
      picked: "",
      activeItemIndex: computed((): number => {
        return state.picked === "unique" ? 0 : 1;
      }),
      isUniqueValidatorType: computed((): boolean => {
        let result;
        if (state.accountInfo && state.accountValidator)
          result = state.accountValidator.id === state.accountInfo.id;
        else result = false;

        return result;
      }),
      showPhoneNumberErrorMessage: false,
      phoneNumberErrorMessage: "",
      dataAvailable: computed(
        (): boolean => state.accountInfo && state.accountValidator,
      ),
      showConfirmationModal: false,
      adddedListenerToVueTelInput: false,
      secondValidatorInvitation: computed(
        (): { status: string; label: string } => {
          return {
            status: state.accountValidator.confirmationStatus ?? "PENDING",
            label:
              state.accountValidator.confirmationStatus === "VALIDATED"
                ? $t("index.validatorValidated")
                : $t("index.validatorWaiting"),
          };
        },
      ),
      askConfirmationToResendNotification: false,
      invitationSent: false,
      invitationSentNotificationText: computed((): string => {
        return $t("index.doubleValidatorRequestMessage").replace(
          "#phoneNumber",
          state.accountValidator.phone ?? state.validatorNumber,
        );
      }),
      showValidatorSettings: false,
      invitationResendLoader: false,
    });

    watch(
      () => state.isUniqueValidatorType,
      (val) => {
        state.picked = val ? "unique" : "double";
      },
      {
        immediate: true,
      },
    );

    watch(
      () => vuePhoneInputComp.value,
      (val) => {
        if (state.adddedListenerToVueTelInput) return;
        (vuePhoneInputComp.value as any).$el
          .querySelector("input")
          .addEventListener("keydown", function (event: any) {
            const letter = event.key;
            if (/[^0-9]/.exec(letter) && letter !== "Backspace")
              event.preventDefault();
          });
        state.adddedListenerToVueTelInput = true;
      },
    );

    // function addValidator() {
    //   state.showModal = false;
    //   showNotif({
    //     success: true,
    //     note: "Ajout",
    //   });
    // }

    function isRequired(value: any) {
      return value ? true : "This field is required";
    }

    async function redirectToAddPage(data: any) {
      console.log(data);

      state.picked = data;
      if (data == 0) {
        try {
          const payload = {
            id: state.accountInfo.id,
            type: "UNIQUE",
            body: {
              keep: [],
            },
          };
          await store.dispatch("CHANGE_SETTINGS", payload);
        } catch (error) {}
        router.push({ name: "addValidator", params: { id: 0 } });
      }
      if (data == 1) {
        try {
          const payload = {
            id: state.accountInfo.id,
            type: "DOUBLE",
            body: {
              keep: [],
            },
          };
          await store.dispatch("CHANGE_SETTINGS", payload);
        } catch (error) {}
        router.push({ name: "addValidator", params: { id: 1 } });
      }
      await store.dispatch("ME");
    }
    function setPicked(data: any) {
      state.picked = data;
      if (data === "unique" && !state.isUniqueValidatorType) {
        // onUniquePick();
        // router.push({ name: "addValidator", params: { id: 1 } });
      }
    }

    function showModal() {
      state.showValidatorSettings = false;
      state.showModal = true;
    }

    function showValidatorSettings() {
      state.showValidatorSettings = !state.showValidatorSettings;
    }
    function joinSettingRoom(settingId: string) {
      if (PushUpIo?.isSocketConnected) {
        PushUpIo?.joinRoom(settingId);
      } else {
        PushUpIo?.on("connect", () => {
          PushUpIo?.joinRoom(settingId);
        });
      }

      PushUpIo.on(WorkflowWsEvents.SETTING_CONFIRMED, async (data: any) => {
        void store.dispatch(ActionTypes.GET_ACCOUNT_VALIDATOR);
      });
    }
    onMounted(async () => {
      // if (!store.state.account) {
      //   await store.dispatch(ActionTypes.GET_BUSSINESS_ACCOUNT);
      // }
      if (state.accountInfo.ownerSettings.length > 0) {
        const settingRoomToJoin = state.accountInfo.ownerSettings[0].id;
        joinSettingRoom(settingRoomToJoin);
      }
    });

    async function onSubmit() {
      if (!state.phoneNumberIsValid) {
        state.phoneNumberErrorMessage = "Invalid phone number";
        state.showPhoneNumberErrorMessage = true;
        return;
      }
      state.showPhoneNumberErrorMessage = false;

      state.isLoading = true;

      const result = await store.dispatch(ActionTypes.SET_VALIDATION_SETTINGS, {
        firstname: state.firstName,
        lastname: state.lastName,
        phone: state.validatorNumber,
      } as SetAccountValidationPayload);

      state.isLoading = false;
      state.showModal = false;

      if (result.success) {
        joinSettingRoom(result.data.id);
        state.invitationSent = result.success;
      } else {
        showNotif({
          error: true,
          note: $t("index.errorOccured"),
        });
      }

      // const notif: any = {
      //   note: result.success
      //     ? $t("index.changesSaved")
      //     : $t("index.errorOccured"),
      // };

      // if (result.success) notif.success = true;
      // else notif.error = true;

      // showNotif(notif);
    }

    function onPhoneNumberChange(number: any) {
      // state.validatorNumber = number;
      state.phoneNumberIsValid = number.valid;
    }

    function cancelUniqueConfirmationRequest() {
      state.showConfirmationModal = false;
      state.picked = "double";
    }
    function createDispatching() {
      router.push({ name: "create" });
    }
    function completedUniqueConfirmationRequest(result: ApiCallResult): void {
      state.showConfirmationModal = false;
      state.picked = "unique";
      const notif: any = {
        note: result.success
          ? $t("index.changesSaved")
          : $t("index.errorOccured"),
      };
      if (result.success) notif.success = true;
      else notif.error = true;

      showNotif(notif);
    }

    async function onUniquePick() {
      state.showConfirmationModal = true;
      return;
    }

    async function resendInvitationLink() {
      state.showValidatorSettings = false;
      state.invitationResendLoader = true;
      const result: ApiCallResult = await store.dispatch(
        ActionTypes.RESEND_INVITATION,
      );
      state.invitationResendLoader = false;
      state.askConfirmationToResendNotification = false;
      state.invitationSent = result.success;
    }

    return {
      state,
      inputGroups,
      setPicked,
      showModal,
      // addValidator,
      isRequired,
      onSubmit,
      onPhoneNumberChange,
      onUniquePick,
      dropdownOptions,
      cancelUniqueConfirmationRequest,
      completedUniqueConfirmationRequest,
      telInputOptions,
      vuePhoneInputComp,
      showValidatorSettings,
      resendInvitationLink,
      createDispatching,
      redirectToAddPage,
    };
  },
};
