<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7999 7.20002H14.9381L11.9998 2.40002L9.06195 7.20002H3.19971L6.12291 12L3.19971 16.8H9.06195L11.9998 21.6L14.9381 16.8H20.7999L17.8755 12L20.7999 7.20002ZM17.9513 15.1999H14.0412L11.9998 18.535L9.95859 15.1999H6.04731L7.99611 12L6.04731 8.8001H9.95835L11.9998 5.46482L14.0412 8.8001H17.9513L16.0023 12L17.9513 15.1999Z"
      fill="#4661B9"
    />
    <path
      d="M10.8549 13.6001H8.89502L9.86966 12L8.89502 10.3999H10.8549L11.9999 8.53009L13.1447 10.3999H15.1031L14.1287 12L15.1031 13.6001H13.1447L11.9999 15.4704L10.8549 13.6001Z"
      fill="#F11C33"
    />
  </svg>
</template>
