<template>
  <div>
    <modal
      @close="$emit('close')"
      :closeIcon="false"
      title=""
      :haveGutter="false"
    >
      <div class="text-center">
        <div class="flex justify-center">
          <check />
        </div>
        <div class="dialogContent">
          <span class="font-semibold text-xl">
            {{ $t("index.doubleValidatorRequestSentTitle") }}
          </span>
          <div class="text-greyScale text-sm" v-if="typeLabel == 'ADD'">
            {{ $t("index.doubleValidatorRequestMessage") }}

            <span class="font-bold">
              {{ formatPhone(validator.phone) }}
            </span>
            {{ $t("index.suite") }}
          </div>
          <div v-if="typeLabel != 'ADD'">
            <span class="text-sm">
              {{ $t("index.doubleValidatorRequestMessage") }}
              {{ $t("index.forRevocation") }}
            </span>
          </div>
        </div>
        <div class="mt-[24px]">
          <primary-button class="w-full" @click="$emit('close')" label="OK" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import primaryButton from "../global/primaryButton.vue";
import modal from "../global/modal.vue";
import Check from "@/components/svg/check.vue";

export default {
  props: {
    typeLabel: {
      type: String,
      default: "ADD",
    },
    validator: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  components: {
    modal,
    Check,
    primaryButton,
  },
  setup() {
    function formatPhone(rawNumber) {
      if (rawNumber) {
        const phoneNumberLength = rawNumber.length;
        let cute = rawNumber.substr(3, phoneNumberLength);
        cute = cute.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, " ");
        return `${rawNumber.substr(0, 3)} ${cute} `;
      }
      return "";
    }
    return {
      formatPhone,
    };
  },
};
</script>

<style></style>
