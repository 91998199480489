<template>
  <div>
    <modal title="Télécharger la fiche de paie">
      <div>
        <div class="flex justify-center gap-4 items-cente pb-8">
          <img src="../../assets/amount.svg" alt="" />
          <div>
            <span class="block">Montant à payer</span>
            <span class="block font-bold text-xl"> 100 Fcfa</span>
          </div>
        </div>
        <span class="font-semibold">Comment souhaitez-vous payer ?</span>
        <div class="grid gap-4 py-4">
          <div
            @click="selectedMethod(elm.mode)"
            v-for="(elm, index) in modes"
            :key="index"
            class="border rounded-md cursor-pointer p-4 border-[#CCCCCC80] gap-4 flex justify-between items-center"
          >
            <img :src="elm.icon" alt="" />
            <div class="w-2/3">
              <span class="font-bold text-base truncate">
                {{ elm.title }}
              </span>
              <span class="block text-sm">
                {{ elm.desc }}
              </span>
            </div>
            <img src="../../assets/go.svg" alt="" />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import modal from "./modal.vue";
import solde from "../../assets/solde.svg";
import widget from "../../assets/widget.svg";
import bundle from "../../assets/bundle.svg";
export default {
  components: {
    modal,
    bundle,
    widget,
    solde,
  },
  setup(_, { emit }) {
    const modes = [
      //   {
      //     icon: solde,
      //     title: "Payer avec mon solde d’appro",
      //     desc: "Utilisez votre solde (20 000 Fcfa)",
      //     mode: "solde",
      //   },
      {
        icon: widget,
        title: "Payer via Mobile Money/carte bancaire",
        desc: "Utilisez l’interface de paiement KKiapay",
        mode: "widget",
      },
      {
        icon: bundle,
        title: "Économisez de l’argent avec nos forfaits",
        desc: "Téléchargez plus de fiches paie à moindre coût",
        mode: "bundle",
      },
    ];
    function selectedMethod(method) {
      console.log(method);
      emit("selectedMethod", method);
    }

    return {
      modes,
      selectedMethod,
    };
  },
};
</script>

<style></style>
