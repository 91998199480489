<template>
  <div
    data-d2d-item="table-pagination"
    class="select-none flex items-center justify-between md:justify-start flex-row flex-nowrap space-x-4 p-2 md:px-4"
  >
    <div class="relative cursor-pointer">
      <div @click="toggle" class="flex justify-between paginationInput">
        <span> {{ actualSelection ? actualSelection.label : 0 }}</span>
        <span><svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.840879 0.872129C1.17038 0.542624 1.70462 0.542624 2.03412 0.872129L6.5 5.33801L10.9659 0.872129C11.2954 0.542624 11.8296 0.542624 12.1591 0.872129C12.4886 1.20163 12.4886 1.73587 12.1591 2.06537L7.09662 7.12787C6.76712 7.45737 6.23288 7.45737 5.90338 7.12787L0.840879 2.06537C0.511374 1.73587 0.511374 1.20163 0.840879 0.872129Z" fill="#4D4C4C"/>
</svg>
</span>
      </div>
      <div
        v-if="show"
        id="paginationSelector"
        class="absolute z-50 flex flex-wrap justify-center right-0 border border-btnBorder w-full rounded mt-1 bg-white"
      >
        <div
          @click="choose(count)"
          class="cursor-pointer p-2 hover:bg-btnBorder w-full"
          v-for="(count, index) in data"
          :key="index"
        >
          <span class="">{{ count.label }} </span>
        </div>
      </div>
    </div>
    <div class="flex space-x-4 items-center">
      <span class="text-394452 font-semibold text-xs tracking-wider">
        {{ pageStart }} - {{ pageEnd }} sur {{ totalSize }}
      </span>
      <div class="flex items-center flex-row flex-nowrap space-x-4">
        <a
          class="inline-flex flex-shrink-0 cursor-pointer hover:opacity-80"
          @click.prevent="gotoPreviousPage()"
        >
          <icon name="keyboard_arrow_left" />
        </a>

        <a
          class="inline-flex flex-shrink-0 cursor-pointer hover:opacity-80"
          @click.prevent="gotoNextPage()"
        >
          <next name="keyboard_arrow_right" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
  watch,
  onMounted,
  onBeforeMount,
  toRefs,
  toRef,
  onUnmounted,
} from "@vue/runtime-core";
import { useRoute,useRouter } from "vue-router";

import Icon from "../svg/prevPage.vue";
import Next from "../svg/nextPage.vue";
export default {
  components: {
    Icon,
    Next,
  },
  props: {
    size: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    data: {
      type: Array,
      default: () => [
        {
          label: 10,
        },
        {
          label: 15,
        },
        {
          label: 20,
        },
        {
          label: 50,
        },
      ],
    },
  },

  setup(props, { emit }) {
    const show = ref(false);
    const properties = toRefs(props);
    const totalSize = toRef(props, "size");
    const perPage = toRef(props, "perPage");
    const route = useRoute();
    const router = useRouter();

    const pages = computed(() => {
      return Math.ceil(totalSize.value / perPage.value);
    });
    const actualSelection = ref(properties.data.value[0]);
    const page = ref(1);
    const pageStart = ref(1);
    const pageEnd = ref(perPage.value);
    const toggleOrChooseFired = ref(false);

    const gotoPreviousPage = () => {
      if (page.value > 1) {
        page.value--;
      }
      replacePaginationParams()

    };

    const gotoNextPage = () => {
      if (page.value < pages.value) {
        page.value++;
      }
      replacePaginationParams()

    };
    const choose = (item) => {
      console.log("-------choose");
      toggleOrChooseFired.value = true;
      actualSelection.value = item;
      emit("change", item.label);
      show.value = false;
      replacePaginationParams()
    };
    const toggle = () => {
      console.log("-------toggle");
      toggleOrChooseFired.value = true;
      show.value = !show.value;
    };

    const computePagination = () => {
      pageStart.value = (page.value - 1) * perPage.value + 1;
      pageEnd.value = Math.min(page.value * perPage.value, totalSize.value);
      emit("currentpagechange", {
        start: pageStart.value,
        end: pageEnd.value,
        page: page.value,
        perPage: perPage.value,
        total: totalSize.value,
      });
    };

    const replacePaginationParams = () => {
      router.replace({
        query: {
          ...route.query,
          page: String(page.value),
          limit: String(actualSelection.value.label)
        }
      })
    }

    watch(page, (newPage) => {
      if (newPage !== page.value) {
        page.value = newPage;
      }
      computePagination();
    });

    watch(totalSize, () => {
      pageEnd.value = Math.min(page.value * perPage.value, totalSize.value);
    });

    watch(perPage, () => {
      computePagination();
    });

    onBeforeMount(() => {
      page.value = route.query.page ?? page.value
      const findedData = properties.data.value.find((item) => item.label == route.query.limit)
      if (route.query.limit && findedData) {
        actualSelection.value = findedData
        emit("change", actualSelection.label);
      }
    })
    onMounted(() => {
      pageEnd.value = Math.min(page.value * perPage.value, totalSize.value);
      computePagination();

      window.addEventListener("click", (event) => {
        setTimeout(() => {
          if (!toggleOrChooseFired.value) {
            show.value = false;
          } else {
            toggleOrChooseFired.value = false;
          }
        }, 200);
        // if (show.value) show.value = false;
      });
    });

    return {
      page,
      pageStart,
      pageEnd,
      computePagination,
      gotoPreviousPage,
      gotoNextPage,
      totalSize,
      actualSelection,
      choose,
      show,
      toggle,
    };
  },
};
</script>

<style>
.paginationInput {
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid #c6d6ec;
  box-sizing: border-box;
  border-radius: 4px;
  width: 69px;
  text-align: start;
  padding: 7px 7px 7px 12px;
  height: 32px;
  align-items: center;
}
</style>

<style scoped>
@media only screen and (max-width: 1200px) {
 
}
</style>
