import { ActionTree } from "vuex";
import { State } from "./state";
import {
  ActionTypes,
  DownloadFilterType,
  MutationTypes,
  SetAccountValidationPayload,
  SetNotificationsSettingsPayload,
  TransactionStatus,
  UploadCampaignFilePayload,
} from "./store.types";
import api, { ApiCallResult, BACKEND, globalToken } from "@/api";
import { queuePostFlushCb } from "vue";

import qs from "qs";
import axios from "axios";

export const actions: ActionTree<State, State> = {
  async [ActionTypes.GET_CAMPAIGN_DISPATCHING](store, campaignId) {
    const theCampaign = await api(
      {
        method: "GET",
        url: `/campaign/${campaignId}`,
      },
      { debug: true },
    );
    if (theCampaign.data) return theCampaign.data.dispatching;
  },
  // /transactions/send-pay-slip/
  async [ActionTypes.GET_CAMPAIGN](store, campaignId) {
    const { commit } = store;

    const theCampaign = await api({
      method: "GET",
      url: `/campaign/${campaignId}`,
    });

    if (theCampaign.data)
      commit(MutationTypes.SET_CURRENT_CAMPAIGN, theCampaign.data);
  },

  async [ActionTypes.CREATE_CAMPAIGN](
    { commit },
    payload,
  ): Promise<ApiCallResult> {
    try {
      const response = await api({
        method: "POST",
        url: "/campaign",
        data: payload,
      });
      commit(MutationTypes.SET_CURRENT_CAMPAIGN, response.data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return { success: false, error };
    }
  },
  async [ActionTypes.SET_LOGO]({ commit }, payload) {
    console.log(payload);

    return await api({
      method: "PUT",
      url: `/settings/set-logo-url/${payload.id}`,
      data: payload.data,
    });
  },
  // async [ActionTypes.UPLOAD_FILE]({ commit }, id) {
  //   return await api(
  //     { method: "POST", url: `/upload`, data: { id } },
  //     { module: "storage" },
  //   );
  // },
  async [ActionTypes.UPLOAD_LOGO]({ commit }, payload) {
    console.log(payload);

    return await api(
      { method: "POST", url: `/upload-logo`, data: payload },
      { module: "storage" },
    );
  },
  async [ActionTypes.RESEND_VALIDATION_LINK]({ commit }, payload) {
    return await api({
      method: "POST",
      url: `/dispatchings/resend-invite-link/${payload.id}`,
      data: {
        validatorIds: payload.body,
      },
    });
  },

  async [ActionTypes.GET_BUSSINESS_ACCOUNT]({ commit }, payload) {
    const account = await api({
      method: "GET",
      url: "/business-account-information",
    });
    commit(MutationTypes.SET_ACCOUNT_INFO, account.data);
  },
  [ActionTypes.LOCAL_LOADING]({ commit }, payload) {
    commit(MutationTypes.SET_LOCAL_LOADING, payload);
  },

  async [ActionTypes.SEND_PAY_SLIP]({ commit }, payload) {
    const pay = await api({
      method: "GET",
      url: `/transactions/send-pay-slip/${payload}`,
    });
  },
  async [ActionTypes.SEND_MULTIPLE_PAY_SLIP]({ commit }, payload) {
    const pay = await api({
      method: "POST",
      url: `/transactions/send-multiple-pay-slip`,
      data: payload,
    });
  },

  async [ActionTypes.USER_FORGOT_PASSWORD]({ commit }, id) {
    const user = await api({
      method: "GET",
      url: `/settings/user-forgot-password/${id}`,
    });

    commit(MutationTypes.SET_USER_FORGOT_ACCOUNT, user.data);
  },
  async [ActionTypes.CANCEL_VALIDATION]({ commit }, id) {
    const cancel = await api({
      method: "GET",
      url: `/settings/cancel-validation/${id}`,
    });
    return cancel;
  },

  async [ActionTypes.UPLOAD_FILE](
    { commit },
    payload: UploadCampaignFilePayload,
  ) {
    return await api(
      { method: "POST", url: `/upload`, data: payload },
      { module: "storage" },
    );
  },
  async [ActionTypes.UPDATE_MODE]({ commit }, mode) {
    commit(MutationTypes.SET_MODE, mode);
  },

  async [ActionTypes.GET_REPORT_FILE]({}, id: string) {
    const response = await api({
      method: "GET",
      url: `/dispatchings/reportFile/${id}`,
    });
    return response.data;
  },

  // async [ActionTypes.GET_USER_BUSSINESS_ACCOUNT]({ commit, state }) {
  //   // TODO: Why this logic in action
  //   if (state.businessId) {
  //     const userBusinessAccount = await api({
  //       method: "GET",
  //       url: `/business-account-information/me/${state.businessId}`,
  //     });
  //     commit(MutationTypes.SET_ACCOUNT_INFO, userBusinessAccount.data);
  //   }
  // },
  async [ActionTypes.GET_PLANS]({ commit, state }) {
    const plans = await api({
      method: "GET",
      url: "/plans",
    });
    commit(MutationTypes.SET_PLANS, plans.data);
  },

  async [ActionTypes.CREATE_PLAN]({ commit }, payload) {
    return await api({
      method: "POST",
      url: "/subscriptions",
      data: payload,
    });
  },
  async [ActionTypes.GET_CONTACTS]({ commit }, id) {
    const contacts = await api({
      method: "GET",
      url: `/business-account-information/facturation-contacts/${id}`,
    });
    commit(MutationTypes.SET_CONTACTS, contacts.data);
  },

  async [ActionTypes.CREATE_CONTACT]({ commit }, payload) {
    return await api({
      method: "POST",
      url: `/business-account-information/add-facturation-contact/${payload.id}`,
      data: { email: payload.email },
    });
  },
  async [ActionTypes.DELETE_CONTACT]({ commit }, payload) {
    return await api({
      method: "DELETE",
      url: `/business-account-information/remove-facturation-contact/${payload.id}/${payload.email}`,
    });
  },
  async [ActionTypes.WEBHOOK]({ commit }, payload) {
    return await api({
      method: "POST",
      url: "/subscriptions/webhook",
      data: payload,
    });
  },

  async [ActionTypes.GET_ALL_DISPACTHING]({ commit, getters }, payload: any) {
    const all = await api({
      method: "GET",
      url: `/dispatchings/all/${payload.id}`,
      params: { ...payload.params },
    });
    console.log("MutationTypes.ALL_DISPATCHING", all.data);
    commit(MutationTypes.ALL_DISPATCHING, all.data);
    commit(MutationTypes.AMOUNT, all.data);
    commit(MutationTypes.TOTAL, all.data);

    return all.data;
  },

  async [ActionTypes.GET_DISPACTHING_INFO]({ commit }, payload) {
    const dispatching = await api({
      method: "GET",
      url: `/dispatchings/${payload.id}`,
      params: { ...payload.params },
    });
    commit(MutationTypes.DISPATCHING, dispatching.data);
    return dispatching.data;
  },
  async [ActionTypes.GET_BALANCE]({ commit }, id) {
    const balance = await api({
      method: "GET",
      url: `/subscriptions/${id}/balance`,
    });
    commit(MutationTypes.BALANCE, balance.data);
  },
  async [ActionTypes.GET_USER_PLAN]({ commit }, payload) {
    const userPlan = await api({
      method: "GET",
      url: `/subscriptions/${payload.id}`,
      params: { ...payload.params },
    });
    commit(MutationTypes.SET_USER_PLAN, userPlan.data);
  },

  async [ActionTypes.TRANSACTIONS]({ commit }, payload) {
    const result = await api({
      method: "get",
      url: `/transactions/${payload.id}`,
      params: { ...payload.params },
    });

    commit(MutationTypes.SET_TRANSACTIONS, result.data.values);
    commit(MutationTypes.SET_TRANSACTIONS_TOTAL, result.data.totalCount);
    return result;
  },
  async [ActionTypes.ARCHIVE]({ commit }, id) {
    return await api({ method: "DELETE", url: `/dispatchings/${id}` });
  },

  async [ActionTypes.ARCHIVE_MANY]({ commit }, idList) {
    return await api({
      method: "POST",
      url: `/dispatchings/archive-many`,
      data: idList,
    });
  },

  async [ActionTypes.DOWNLOAD]({ commit }, id) {
    const access_token = localStorage.getItem("pushup_token");
    // || "ad3061d34d6ac0a85255e0fde67b3f3f13ca8946c6c77d747fc3ea90328324657a675678d4b9e7382abc2df192eacab546cdcda55883f8df89ebf963a269cedc.6bc093572666cb5d60677562f6c5f35a.2ad701289a54eacd8e4892f0e9c2426459eb56da757dd9c87eadd7be354815f1";
    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };

    // console.log(localStorage.getItem("access_token"));
    //axios with authorization

    axios
      .get(`${BACKEND.baseUrl}/pushup-core/dispatchings/download/${id}`, config)
      .then((response) => {
        const _url = response.data.url;
        window.open(_url, "_blank");
      });
  },
  async [ActionTypes.GET_ERROR_FILES]({ commit }, id) {
    const errorFiles = await api({
      method: "GET",
      url: `/dispatchings/download-errors/${id}`,
    });
    window.open(errorFiles.data.url);
    console.log("errorFiles", errorFiles);
  },
  async [ActionTypes.GET_INVOCES_SUB]({ commit }, id) {
    const down = await api({
      method: "GET",
      url: `/subscriptions/download-invoice/${id}`,
    });
    window.open(down.data.url);
  },
  async [ActionTypes.TEMPLATE_FILE]({ commit }) {
    window.open(
      `https://firebasestorage.googleapis.com/v0/b/kk-pushup.appspot.com/o/sample-pushup.xlsx?alt=media&token=fe662f04-a9b8-4522-b250-2058afb6bc09`,
    );
  },

  async [ActionTypes.RUN_DISPACTHING]({ commit }, id) {
    return await api({ method: "GET", url: `/dispatchings/run/${id}` });
  },
  async [ActionTypes.SUPPORTED_FUNDING_SOURCE]({ commit }) {
    const funding = await api({
      method: "GET",
      url: `/supply-transaction/supported-funding-source`,
    });
    const fund = funding.data.map((x: any) => ({
      label: x.name,
      value: x.id,
    }));
    commit(MutationTypes.FUNDING_SOURCE, fund);
  },
  async [ActionTypes.INIT]({ commit }, payload) {
    const run = await api({
      method: "POST",
      url: `/supply-transaction/init/${payload.id}`,
      data: payload.body,
    });
    commit(MutationTypes.REFERENCE, run.data);
    commit(MutationTypes.ACCOUNT_NUMBER, run.data);
  },

  async [ActionTypes.APPRO_TRANSACTION]({ commit }, payload) {
    const buildQuery = (query: any) => {};
    const appro = await api({
      method: "GET",
      url: `/supply-transaction/${payload.id}`,
      params: { ...payload.params },
      paramsSerializer: (params: any) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    commit(MutationTypes.SET_APPROS, appro.data.values);
    commit(MutationTypes.TOTAL_APPROS, appro.data.totalCount);
  },

  async [ActionTypes.RUN_DISPACTHING_FILE]({ commit }, payload) {
    const run = await api({
      method: "POST",
      url: `/settings/validate-pin/${payload.id}/${payload.dispatchingId}`,
    });
  },

  async [ActionTypes.GET_CAMPAIGN_BY_ID]({ commit }, id) {
    const campaign = await api({ method: "GET", url: `/campaign/${id}` });
    commit(MutationTypes.CAMPAIGN_BY_ID, campaign.data);
    return campaign;
  },

  async [ActionTypes.SET_VALIDATION_SETTINGS](
    { commit, state },
    payload: SetAccountValidationPayload,
  ): Promise<ApiCallResult> {
    try {
      if (state.businessId) {
        const result = await api({
          method: "POST",
          url: `/settings/validation/${payload ? "double" : "unique"}/${
            state.businessId
          }`,
          data: payload,
        });
        this.commit(MutationTypes.SET_ACCOUNT_VALIDATOR, result.data.validator);
        return {
          success: true,
          data: result.data,
        };
      }

      return {
        success: false,
        error: "Missing account in vuex store",
      };
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  },
  async [ActionTypes.UNIQUE_VALIDATION]({ commit }, payload) {
    const uniqueValidation = await api({
      method: "POST",
      url: `/settings/validation/unique/${payload.id}`,
      data: payload.body,
    });
    return uniqueValidation;
  },

  async [ActionTypes.GET_REVOC_VALIDATORS]({ commit }, payload) {
    const revocateUser = await api({
      method: "GET",
      url: `/settings/revocating-validators/${payload.id}`,
    });
    commit(MutationTypes.SET_REVOCATING_VALIDATOR, revocateUser.data);
  },

  async [ActionTypes.VERIFY_KYC]({ commit }, payload) {
    const verifyKyc = await api({
      method: "POST",
      url: `/settings/verify-user-info`,
      data: payload,
    });

    return verifyKyc;
  },
  async [ActionTypes.RESEND_CHANGE_VALIDATOR_LINK]({ commit }, payload) {
    const resendChangeValidationLink = await api({
      method: "GET",
      url: `/settings/resend-change-validator-link/${payload.validatorId}/${payload.id}`,
    });
    return resendChangeValidationLink;
  },
  async [ActionTypes.GET_ALL_TRANSACTIONS]({ commit }, payload) {
    const lite = await api({
      method: "GET",
      url: `/transactions/lite/${payload}/`,
    });
    commit(MutationTypes.SET_ALL_TRANSACTIONS, lite.data);
    return lite;
  },

  async [ActionTypes.CHANGE_VALIDATOR]({ commit }, payload) {
    const changeValidation = await api({
      method: "GET",
      url: `/settings/change-validator/${payload.validatorId}/${payload.id}`,
    });
    return changeValidation;
  },
  async [ActionTypes.SET_NOTIFICATIONS_SETTINGS]({ commit }, payload) {
    const result = await api({
      method: "POST",
      url: `/settings/notification/dispatching/${payload.id}`,
      data: payload.body,
    });
  },
  async [ActionTypes.CHANGE_SETTINGS]({ commit }, payload) {
    const result = await api({
      method: "POST",
      url: `/settings/change-setting-mode/${payload.id}?type=${payload.type}`,
      data: payload.body,
    });
  },
  async [ActionTypes.ADD_VALIDATOR]({ commit }, payload) {
    const result = await api({
      method: "POST",
      url: `/settings/add-validator/${payload.id}`,
      data: payload.body,
    });
    return result;
  },
  async [ActionTypes.DOUBLE_VALIDATION]({ commit }, payload) {
    const doubleValidation = await api({
      method: "POST",
      url: `/settings/validation/double/${payload.id}`,
      data: payload.body,
    });
    return doubleValidation;
  },

  async [ActionTypes.VERIFY_PASSWORD]({ commit }, payload) {
    const verify = await axios.post(`${BACKEND.core}/login`, payload);
    return verify;
  },
  async [ActionTypes.RESEND_INVITATION]({ commit }, payload) {
    const result = await api({
      method: "GET",
      url: `/settings/resend-invitation/${payload.id}/${payload.validator}`,
    });
    return result;
  },
  async [ActionTypes.DOWNLOAD_SUPPLY_TRANSACTION]({ commit }, id) {
    const result = await api({
      method: "GET",
      url: `/supply-transaction/download/${id}`,
    });
    window.open(result.data.url);
    return result;
  },
  // GENERATE_STATEMENT
  async [ActionTypes.GENERATE_STATEMENT]({ commit, state }, payload) {
    const result = await api({
      method: "GET",
      url: `/reporting/${state.businessId}`,
      params: { ...payload.params },
    });
    window.open(result.data.url);
    return result;
  },
  async [ActionTypes.GET_ACCOUNT_VALIDATOR]({
    commit,
    state,
  }): Promise<ApiCallResult> {
    try {
      if (state.businessId) {
        const result = await api({
          method: "GET",
          url: `/settings/${state.businessId}`,
        });

        if (result.data) {
          commit(MutationTypes.SET_ACCOUNT_VALIDATOR, {
            ...result.data.validator,
            confirmationStatus: result.data.isConfirmed
              ? "VALIDATED"
              : "PENDING",
          });
        }

        return {
          success: true,
        };
      }

      return {
        success: true,
        error: "Missing account in vuex store",
      };
    } catch (error) {
      return {
        success: false,
        error,
      };
    }
  },
  [ActionTypes.SAVE_BUSINESS_ID_TO_STORAGE]({ commit }, businessId: string) {
    localStorage.setItem("businessId", businessId);

    commit(MutationTypes.SET_BUSINESS_ID, businessId);
  },
  [ActionTypes.RESET_PREVIEW_STATE]({ commit }) {
    commit(MutationTypes.RESET, {});
  },

  async [ActionTypes.GET_DOWNLOAD_URL](
    { state, getters },
    reportType: TransactionStatus | "ALL",
  ): Promise<ApiCallResult> {
    // try {
    const currentCampaign = getters["getCampaign"];
    if (currentCampaign) {
      const access_token = localStorage.getItem("access_token");
      //  || "z8enUJ8PBpcnIEQa8pCQnym0Ondj9-Uf97zGnmjzczs.7LPt5o39JuTZrgVRck6Mckp8DxnVY7T4pPx8ClvP_Do";
      const config = {
        headers: { Authorization: `Bearer ${access_token}` },
      };

      axios
        .get(
          `${BACKEND.baseUrl + BACKEND.core}/transactions/download-report/${
            currentCampaign.dispatching.id
          }/${reportType}`,
          config,
        )
        .then((response) => {
          // console.log(response.headers["content-disposition"]);

          const url = response.data.url;
          window.open(url, "_blank");
        });
      // return {
      //   success: true,

      //   data: {
      //     url: `${
      //       BACKEND.baseUrl + BACKEND.core
      //     }/transactions/download-report/${
      //       currentCampaign.dispatching.id
      //     }/${reportType}`,
      //   },
      // };
    }

    return {
      success: false,
      error: "Missing current campaign in vuex store",
    };
    // } catch (error) {
    //   const result = (error as any).response;

    //   const returnValue: ApiCallResult = {
    //     success: result.status === 200,
    //   };

    //   if (result.status === 404) {
    //     returnValue.error = "EMPTY";
    //   }

    //   console.log("returnValue:", returnValue);
    //   return returnValue;
    // }
  },

  // async [ActionTypes.RESEND_INVITATION]({
  //   state,
  //   getters,
  // }): Promise<ApiCallResult> {
  //   const accountValidator = getters.accountValidator;

  //   try {
  //     // &&
  //     // accountValidator.confirmationStatus !== "VALIDATED"
  //     if (accountValidator) {
  //       const result = await api({
  //         method: "POST",
  //         url: `/settings/resend-invitation/${state.businessId}`,
  //       });

  //       return {
  //         success: true,
  //       };
  //     }
  //     return {
  //       success: false,
  //       error: "Missing account validator",
  //     };
  //   } catch (error) {
  //     return {
  //       success: false,
  //       error,
  //     };
  //   }
  // },
  async [ActionTypes.ME]({ commit }) {
    const result = await api({
      method: "get",
      url: "/me",
    });
    commit(MutationTypes.SET_ME, result.data);
    return result.data;
  },
};
