<template>
      <svg
                    width="53"
                    height="52"
                    viewBox="0 0 53 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.7946 4.87513C14.9138 4.71466 5.21456 14.4139 5.37503 26.2947C5.53347 37.689 14.8112 46.9667 26.2055 47.1251C38.0883 47.2876 47.7855 37.5884 47.623 25.7076C47.4666 14.3113 38.1889 5.03357 26.7946 4.87513ZM39.6341 38.1115C39.5936 38.1552 39.544 38.1895 39.4888 38.212C39.4336 38.2344 39.3742 38.2444 39.3147 38.2413C39.2552 38.2382 39.1971 38.2221 39.1445 38.194C39.0919 38.166 39.0462 38.1267 39.0105 38.079C38.1021 36.8904 36.9896 35.8728 35.7249 35.0737C33.1392 33.4142 29.8628 32.5001 26.5 32.5001C23.1373 32.5001 19.8609 33.4142 17.2751 35.0737C16.0105 35.8725 14.898 36.8897 13.9896 38.0779C13.9539 38.1257 13.9081 38.1649 13.8556 38.193C13.803 38.2211 13.7449 38.2372 13.6854 38.2403C13.6259 38.2434 13.5664 38.2334 13.5112 38.211C13.456 38.1885 13.4064 38.1542 13.366 38.1104C10.3858 34.8933 8.69764 30.6896 8.62503 26.3048C8.45949 16.4218 16.5693 8.14951 26.4564 8.12513C36.3435 8.10076 44.375 16.1293 44.375 26.0001C44.3784 30.4906 42.685 34.8166 39.6341 38.1115Z"
                      fill="#B2B2B2"
                    />
                    <path
                      d="M26.5001 14.6252C24.4973 14.6252 22.6864 15.3757 21.3996 16.7397C20.1128 18.1037 19.4699 19.9897 19.6152 22.0139C19.9097 26.0002 22.9982 29.2502 26.5001 29.2502C30.002 29.2502 33.0844 26.0002 33.385 22.0149C33.5353 20.01 32.8975 18.1413 31.5894 16.7519C30.2975 15.3808 28.4897 14.6252 26.5001 14.6252Z"
                      fill="#B2B2B2"
                    />
                  </svg>
</template>