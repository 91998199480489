<template>
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_4664_90267)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M35 19C35 27.8366 27.8366 35 19 35C10.1634 35 3 27.8366 3 19C3 10.1634 10.1634 3 19 3C27.8366 3 35 10.1634 35 19ZM19 33.359C26.9302 33.359 33.359 26.9302 33.359 19C33.359 11.0698 26.9302 4.64103 19 4.64103C11.0698 4.64103 4.64103 11.0698 4.64103 19C4.64103 26.9302 11.0698 33.359 19 33.359Z" fill="white"/>
</g>
<path d="M33.4042 18.9999C33.4042 26.9551 26.9553 33.4039 19.0002 33.4039C11.0451 33.4039 4.59619 26.9551 4.59619 18.9999C4.59619 11.0448 11.0451 4.59595 19.0002 4.59595C26.9553 4.59595 33.4042 11.0448 33.4042 18.9999Z" fill="#E6E6E6"/>
<path d="M26.4894 13.0857C26.2324 12.8287 25.9234 12.7002 25.5625 12.7002H12.4375C12.0766 12.7002 11.7677 12.8287 11.5105 13.0857C11.2535 13.3427 11.125 13.6516 11.125 14.0126V23.9875C11.125 24.3485 11.2535 24.6574 11.5105 24.9144C11.7677 25.1715 12.0766 25.3 12.4375 25.3H25.5625C25.9234 25.3 26.2323 25.1715 26.4894 24.9144C26.7464 24.6574 26.875 24.3485 26.875 23.9875V14.0126C26.875 13.6516 26.7464 13.3427 26.4894 13.0857ZM25.8249 23.9877C25.8249 24.0588 25.7989 24.1202 25.747 24.1723C25.6951 24.224 25.6335 24.25 25.5625 24.25H12.4375C12.3664 24.25 12.3049 24.224 12.2529 24.1721C12.201 24.12 12.175 24.0586 12.175 23.9875V19.0001H25.8249V23.9877H25.8249ZM25.8249 15.85H12.175V14.0125C12.175 13.9414 12.2009 13.88 12.2529 13.8279C12.3049 13.7761 12.3664 13.7501 12.4375 13.7501H25.5625C25.6335 13.7501 25.695 13.776 25.747 13.8279C25.7989 13.88 25.8249 13.9414 25.8249 14.0125V15.85H25.8249Z" fill="#333333"/>
<path d="M15.3251 22.1499H13.2251V23.2H15.3251V22.1499Z" fill="#333333"/>
<path d="M19.525 22.1499H16.375V23.2H19.525V22.1499Z" fill="#333333"/>
<defs>
<filter id="filter0_d_4664_90267" x="0.948718" y="0.948718" width="36.1026" height="36.1026" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="1.02564"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4664_90267"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4664_90267" result="shape"/>
</filter>
</defs>
</svg>


</template>