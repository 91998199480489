import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.css";
import i18n from "./i18n";
import { showNotif } from "./notif";
import { PushupSse } from "./sse";

const socket = new PushupSse();

const app = createApp(App);
app.use(i18n);

app.use(store);

app.use(router);

app.mount("#app");

app.provide("$showNotif", showNotif);
app.provide("$PushUpIo", socket);

//i18n $t
app.provide("$t", app.config.globalProperties.$t);

export const $t = app.config.globalProperties.$t;
export const $i18n = app.config.globalProperties.$i18n;
export const $locale = app.config.globalProperties.$i18n.locale;

// custom typing directive
// pass a function to this Directive, change inputInterval value to increase or decrease reactivity
app.directive("typing", {
  beforeMount(el, binding, vnode) {
    let inputTimer: any;
    const inputInterval = 300;

    "keydown|keyup".split("|").forEach(function (eitem) {
      el.addEventListener(eitem, (event: any) => {
        if (event.type === "keyup") {
          if (event.key !== "Backspace") binding.value.run(event); // is typing running callback
          clearTimeout(inputTimer);
          inputTimer = setTimeout(() => {
            binding.value.finish(event);
          }, inputInterval); // when typing finished
        } else if (event.type === "keydown") {
          clearTimeout(inputTimer);
        }
      });
    });
  },
});
