<template>
  <div class="relative">
    <div
      class="relative flex items-center cursor-pointer justify-between gap-3 bg-primaryColor text-white md:w-fit px-9 py-3 rounded"
      @click="show = true"
    >
      <span class="font-semibold text-base">
        {{ label }}
      </span>
      <span
        ><svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.3125 4.4555L2.28172 3.5L7 8.162L11.7289 3.5L12.6875 4.445L7.47928 9.5795L7 10.0625L6.52072 9.5795L1.3125 4.4555Z"
            fill="white"
          />
        </svg>
      </span>
    </div>
    <div
      class="absolute bg-white grid shadow p-4 w-full z-40 top-16"
      v-if="show"
    >
      <div
        class="p-3 hover:bg-lightLinkBlue cursor-pointer"
        @click="toggle('transactions')"
      >
        Transactions
      </div>
      <!-- <div
        class="p-3 hover:bg-lightLinkBlue cursor-pointer"
        @click="toggle('paySlip')"
      >
        Fiche de paie
      </div> -->
    </div>
    <slot name="content" />
  </div>
</template>

<script>
import { onMounted, ref, computed, watch, onBeforeMount } from "vue";
import WhiteDown from "@/components/svg/whiteDown.vue";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  components: {
    WhiteDown,
  },
  setup(_, { emit }) {
    const show = ref(false);
    function toggle(data) {
      show.value = !show.value;
      emit("toggle", data);
    }
    return { show, toggle };
  },
};
</script>

<style></style>
