<template>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_4573_2500)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M38 22C38 30.8366 30.8366 38 22 38C13.1634 38 6 30.8366 6 22C6 13.1634 13.1634 6 22 6C30.8366 6 38 13.1634 38 22ZM22 36.7031C30.1203 36.7031 36.7031 30.1203 36.7031 22C36.7031 13.8797 30.1203 7.2969 22 7.2969C13.8797 7.2969 7.2969 13.8797 7.2969 22C7.2969 30.1203 13.8797 36.7031 22 36.7031Z" fill="white"/>
</g>
<path d="M36.8002 22C36.8002 30.1738 30.174 36.8 22.0002 36.8C13.8264 36.8 7.2002 30.1738 7.2002 22C7.2002 13.8261 13.8264 7.19995 22.0002 7.19995C30.174 7.19995 36.8002 13.8261 36.8002 22Z" fill="#005782"/>
<g clip-path="url(#clip0_4573_2500)">
<path d="M13.2388 19.2991L13.3272 18.8027H11.1859V18.8037C10.9612 18.8037 10.7474 18.9835 10.7034 19.2067L9.87672 23.8933H9.87771C9.83574 24.12 9.98389 24.3047 10.2116 24.3057H12.5484L12.6363 23.8103H10.6284L10.9992 21.7061H12.6309L12.7163 21.2186H11.0847L11.4235 19.2991H13.2388Z" fill="white"/>
<path d="M14.2941 24.3871C13.3504 24.3871 12.8037 23.5396 13.0486 22.1475C13.2985 20.7311 14.139 19.9158 15.0986 19.9158C15.626 19.9158 15.8848 20.0842 16.0004 20.2037L15.7608 20.6121C15.6497 20.5158 15.4769 20.4047 15.1569 20.4047C14.3726 20.4047 13.9435 21.1158 13.7657 22.1238C13.583 23.1559 13.8259 23.868 14.5218 23.868C14.8176 23.868 15.0354 23.7717 15.2146 23.6606L15.3129 24.1001C15.1183 24.2517 14.7746 24.3871 14.2941 24.3871Z" fill="white"/>
<path d="M17.9728 20.3642C17.1496 20.3642 16.8266 21.2837 16.672 22.1554C16.5194 23.027 16.5417 23.8996 17.3491 23.8996C18.1733 23.8996 18.4805 23.0191 18.6321 22.1554C18.7852 21.2921 18.7966 20.3642 17.9728 20.3642ZM17.2637 24.3871C16.0873 24.3871 15.7461 23.4191 15.9688 22.1554C16.1955 20.8674 16.8834 19.9158 18.0439 19.9158C19.2277 19.9158 19.5635 20.8674 19.3358 22.1554C19.1131 23.4191 18.4311 24.3871 17.2637 24.3871Z" fill="white"/>
<path d="M25.8645 21.8596C24.7534 21.9949 24.064 22.2265 23.9109 23.0991C23.8008 23.7228 24.1386 23.9786 24.7796 23.9786C25.0502 23.9786 25.3243 23.9312 25.5169 23.8354L25.8645 21.8596ZM24.6507 24.3871C23.5311 24.3871 23.097 23.8996 23.2353 23.1154C23.4368 21.9712 24.5672 21.6358 25.9283 21.4995L25.9742 21.2358C26.0917 20.5721 25.7253 20.3805 25.1727 20.3805C24.7573 20.3805 24.346 20.5321 24.0852 20.6521L23.9899 20.2358C24.2615 20.0995 24.7741 19.9158 25.3658 19.9158C26.1663 19.9158 26.8103 20.26 26.6236 21.3148L26.1352 24.0828C25.7761 24.2675 25.2507 24.3871 24.6507 24.3871Z" fill="white"/>
<path d="M29.105 24.307L29.6507 21.2116C29.7218 20.8116 29.6304 20.3721 28.8956 20.3721C28.4955 20.3721 28.2926 20.4274 28.0531 20.5237L27.3849 24.307H26.6816L27.4017 20.22C27.819 20.0363 28.3434 19.9163 29.0304 19.9163C30.2309 19.9163 30.4655 20.58 30.3573 21.1958L29.8087 24.307H29.105Z" fill="white"/>
<path d="M22.413 22.099C22.2204 23.1874 21.7379 23.9317 20.8504 23.9317C20.6257 23.9317 20.407 23.9075 20.2608 23.8275L20.8435 20.5236C21.0633 20.4125 21.2569 20.3562 21.5769 20.3562C22.3922 20.3562 22.5794 21.1562 22.413 22.099ZM21.8253 19.9399C21.4021 19.9399 21.1463 20.0273 20.9107 20.1394L21.2816 18.0371L20.5062 18.4855L19.5195 24.0833C19.7462 24.2517 20.1694 24.387 20.7541 24.387C22.0337 24.387 22.8777 23.4591 23.1212 22.0753C23.3508 20.771 22.8174 19.9399 21.8253 19.9399Z" fill="white"/>
<path d="M34.0859 19.9962H33.3506L31.4621 21.9953H31.4463L31.9278 19.2713C32.4063 16.5734 29.4946 16.0035 26.4545 16.4692C24.3882 16.7862 22.563 17.6095 22.563 17.6095C22.563 17.6095 24.9463 16.7759 27.0432 16.6569C29.6912 16.5062 31.7663 16.9077 31.1604 19.7128L30.35 24.3085H31.0379L31.43 22.0837H31.4458L32.7017 24.3085H33.5022L32.0992 22.0116L34.0859 19.9962Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_4573_2500" x="0.974504" y="0.974504" width="42.051" height="42.051" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2.51275"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4573_2500"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4573_2500" result="shape"/>
</filter>
<clipPath id="clip0_4573_2500">
<rect width="24.2148" height="8.06501" fill="white" transform="translate(9.93115 16.2576)"/>
</clipPath>
</defs>
</svg>

</template>