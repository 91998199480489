export function useVariable($t: any) {
  const report = [
    {
      label: $t("index.completRepport"),
      value: "complet",
    },
    {
      label: $t("index.succesRepport"),
      value: "success",
    },
    {
      label: $t("index.failedRepport"),
      value: "failed",
    },
    {
      label: $t("index.pendingRepport"),
      value: "pending",
    },
  ];

  const statusColumns = {
    status: "Status",
    transactionId: "ID",
    name: $t("index.fullname"),
    phone: $t("index.number_momo"),
    amount: $t("index.amount"),
    response: $t("index.response"),
    action: "Action",
  };

  const columns = {
    name: $t("index.fullname"),
    phone: $t("index.number_momo"),
    amount: $t("index.amount"),
  };

  return { report, statusColumns, columns };
}
