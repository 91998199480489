
import {
  onMounted,
  computed,
  ref,
  watch,
  reactive,
  defineComponent,
  watchEffect,
  toRef,
} from "vue";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { Field, Form } from "vee-validate";
import { inject } from "vue";
import { useStore } from "vuex";
import userIcon from "@/components/svg/user.vue";
import userIconColor from "@/components/svg/userColor.vue";
import radioWhite from "@/components/global/radioWhite.vue";
import status from "@/components/global/status.vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import Modal from "@/components/global/modal.vue";
import CustomInput from "@/components/global/customInput.vue";
import TelInput from "@/components/global/telInput.vue";
import {
  ActionTypes,
  SetAccountValidationPayload,
} from "@/store/pushup/store.types";
import { AccountValidator } from "@/store/pushup/state";
import Warning from "@/components/svg/warning.vue";
import SettingsConfirmation from "@/components/modals/SettingsConfirmation.vue";
import { ApiCallResult } from "@/api";
import { WorkflowWsEvents } from "@/socket";
import Check from "@/components/svg/check.vue";
import ThreeDot from "@/components/svg/threeDot.vue";
import { useRouter, useRoute } from "vue-router";
import info from "@/components/global/info.vue";
const MINIMAL_PHONE_NUMBER_LENGTH = 11;

const dropdownOptions = {
  showDialCodeInSelection: true,
  showFlags: true,
};

const telInputOptions = {
  type: "tel",
};

export default {
  props: {
    validator: {
      type: Object,
      required: true,
    },
  },
  components: {
    info,
    userIconColor,
    Field,
    Form,
    VueTelInput,
    status,
    radioWhite,
    userIcon,
    PrimaryButton,
    Modal,
    CustomInput,
    TelInput,
    Warning,
    SettingsConfirmation,
    Check,
    ThreeDot,
  },
  setup(props: any, { emit }: any) {
    const validator = toRef(props, "validator");
    const $t: any = inject("$t");
    const showNotif: any = inject("$showNotif");
    const PushUpIo: any = inject("$PushUpIo");
    const router = useRouter();
    const route = useRoute();

    const store = useStore();
    const activeSetting = ref();
    const vuePhoneInputComp = ref(null);

    const inputGroups = ref([
      {
        value: $t("index.unique"),
        id: "unique",
        description: $t("index.auth_uniqueB"),
      },
      {
        description: $t("index.auth_doubleB"),
        value: $t("index.double"),
        id: "double",
      },
    ]);
    const countryCode = ref("");
    function countryChanged(country: any) {
      countryCode.value = country.dialCode;
      console.log(countryCode.value);
    }
    const state = reactive({
      Loading: false,
      showCase: "",
      activeSetting,
      isLoading: false,
      validatorNumber: "",
      phoneNumberIsValid: false,
      firstName: "",
      emailVal: "",
      lastName: "",
      showModal: false,
      accountInfo: computed((): any => store.state.meInfo),
      accountValidator: computed(
        (): AccountValidator => store.getters.accountValidator,
      ),
      validatorName: computed(
        (): string =>
          state.accountValidator.firstname +
          " " +
          state.accountValidator.lastname,
      ),
      picked: "",
      activeItemIndex: computed((): number => {
        return state.picked === "unique" ? 0 : 1;
      }),
      isUniqueValidatorType: computed((): boolean => {
        let result;
        if (state.accountInfo && state.accountValidator)
          result = state.accountValidator.id === state.accountInfo.id;
        else result = false;

        return result;
      }),
      showPhoneNumberErrorMessage: false,
      phoneNumberErrorMessage: "",
      dataAvailable: computed(
        (): boolean => state.accountInfo && state.accountValidator,
      ),
      showConfirmationModal: false,
      adddedListenerToVueTelInput: false,
      secondValidatorInvitation: computed(
        (): { status: string; label: string } => {
          return {
            status: state.accountValidator.confirmationStatus ?? "PENDING",
            label:
              state.accountValidator.confirmationStatus === "VALIDATED"
                ? $t("index.validatorValidated")
                : $t("index.validatorWaiting"),
          };
        },
      ),
      askConfirmationToResendNotification: false,
      invitationSent: false,
      invitationSentNotificationText: computed((): string => {
        return $t("index.doubleValidatorRequestMessage").replace(
          "#phoneNumber",
          state.accountValidator.phone ?? state.validatorNumber,
        );
      }),
      showValidatorSettings: false,

      invitationResendLoader: false,
    });
    function formatPhone(rawNumber: any) {
      if (rawNumber) {
        const phoneNumberLength = rawNumber.length;
        let cute = rawNumber.substr(3, phoneNumberLength);
        cute = cute.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, " ");
        return `(${rawNumber.substr(0, 3)}) ${cute} `;
      }
      return "";
    }
    const goodKyc = ref(false);

    function whatCase(data: any) {
      console.log(data);

      (state.showValidatorSettings = !state.showValidatorSettings),
        (state.showCase = data);
    }
    function goBack() {
      router.push({ name: "noSetting" });
    }
    watch(
      () => state.isUniqueValidatorType,
      (val) => {
        state.picked = val ? "unique" : "double";
      },
      {
        immediate: true,
      },
    );

    watch(
      () => vuePhoneInputComp.value,
      (val) => {
        if (state.adddedListenerToVueTelInput) return;
        (vuePhoneInputComp.value as any).$el
          .querySelector("input")
          .addEventListener("keydown", function (event: any) {
            const letter = event.key;
            if (/[^0-9]/.exec(letter) && letter !== "Backspace")
              event.preventDefault();
          });
        state.adddedListenerToVueTelInput = true;
      },
    );

    function isRequired(value: any) {
      return value ? true : "Ce champ est requis";
    }

    function redirectToAddPage(data: any) {
      console.log(data);

      state.picked = data;
      if (data == 0) {
        router.push({ name: "addValidator", params: { id: 1 } });
      }
    }
    function setPicked(data: any) {
      state.picked = data;
      if (data === "unique" && !state.isUniqueValidatorType) {
        // onUniquePick();
        // router.push({ name: "addValidator", params: { id: 1 } });
      }
    }

    function showModal() {
      state.showValidatorSettings = false;
      state.showModal = true;
    }

    function showValidatorSettings() {
      state.showValidatorSettings = !state.showValidatorSettings;
    }
    function joinSettingRoom(settingId: string) {
      if (PushUpIo?.isSocketConnected) {
        PushUpIo?.joinRoom(settingId);
      } else {
        PushUpIo?.on("connect", () => {
          PushUpIo?.joinRoom(settingId);
        });
      }

      PushUpIo.on(WorkflowWsEvents.SETTING_CONFIRMED, async (data: any) => {
        // void store.dispatch(ActionTypes.GET_ACCOUNT_VALIDATOR);
        await store.dispatch("ME");
      });
    }
    const info = ref(false);

    onMounted(async () => {
      info.value = true;
      activeSetting.value = route.params.id;
      console.log(activeSetting.value);

      // if (!store.state.account) {
      //   await store.dispatch(ActionTypes.GET_BUSSINESS_ACCOUNT);
      // }
      if (state.accountInfo.ownerSettings.length > 0) {
        const settingRoomToJoin = state.accountInfo.ownerSettings[0].id;
        joinSettingRoom(settingRoomToJoin);
      }
    });

    const showInfoDanger = ref(false);
    async function verifyKYC() {
      console.log("verifyKYC");

      state.showValidatorSettings = false;
      if (!state.phoneNumberIsValid) {
        state.phoneNumberErrorMessage = "Invalid phone number";
        state.showPhoneNumberErrorMessage = true;
        return;
      }
      state.showPhoneNumberErrorMessage = false;
      state.Loading = true;
      try {
        const response = await store.dispatch("VERIFY_KYC", {
          phone: countryCode.value + state.validatorNumber,
          firstname: state.firstName,
          email: state.emailVal,
          lastname: state.lastName,
        });
        info.value = false;

        state.Loading = false;
        console.log(response, "eee");
        if (response.status === 201) {
          goodKyc.value = true;
        } else {
          showInfoDanger.value = true;
        }

        showInfoDanger.value = false;
      } catch (error) {
        showInfoDanger.value = true;
        info.value = false;

        goodKyc.value = false;

        state.Loading = false;
      }
    }
    async function onSubmit() {
      state.isLoading = true;
      let payload = {
        id: state.accountInfo.id,
        body: {
          firstname: state.firstName,
          lastname: state.lastName,
          email: state.emailVal,
          phone: countryCode.value + state.validatorNumber,
        },
      };
      console.log(payload);

      try {
        const result = await store.dispatch("ADD_VALIDATOR", payload);
        emit("done", result.data);
        await store.dispatch("ME");
        state.isLoading = false;
      } catch (error: any) {
        state.isLoading = false;

        console.log(error.response.data.statusCode, "ee");
        if (error.response.data.statusCode == 422) {
          showInfoDanger.value = true;
        }
      }
    }

    function onPhoneNumberChange(number: any) {
      // state.validatorNumber = number;
      state.phoneNumberIsValid = number.valid;
    }

    function cancelUniqueConfirmationRequest() {
      state.showConfirmationModal = false;
      state.picked = "double";
    }
    function createDispatching() {
      router.push({ name: "create" });
    }
    function completedUniqueConfirmationRequest(result: ApiCallResult): void {
      state.showConfirmationModal = false;
      state.picked = "unique";
      const notif: any = {
        note: result.success
          ? $t("index.changesSaved")
          : $t("index.errorOccured"),
      };
      if (result.success) notif.success = true;
      else notif.error = true;

      showNotif(notif);
    }

    async function onUniquePick() {
      state.showConfirmationModal = true;
      return;
    }

    async function resendInvitationLink() {
      state.showValidatorSettings = false;
      state.invitationResendLoader = true;
      try {
        const result = await store.dispatch(ActionTypes.RESEND_INVITATION, {
          id: state.accountInfo.id,
          validator: state.accountInfo.validations[0].id,
        });
        state.invitationResendLoader = false;
        state.askConfirmationToResendNotification = false;
        state.invitationSent = true;
        showNotif({
          success: true,
          note: $t("index.successResentLink"),
        });
      } catch (error) {
        state.invitationResendLoader = false;
        state.askConfirmationToResendNotification = false;
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    }
    const change = ref("");
    function changeValidator() {
      state.showModal = true;
      change.value = "change";
      console.log(change.value);
    }
    const getStatus = (key: "SUCCESS" | "PENDING") => {
      return {
        SUCCESS: $t("index.accept"),
        PENDING: $t("index.waiting"),
      }[key];
    };
    return {
      goodKyc,
      getStatus,
      state,
      inputGroups,
      setPicked,
      showModal,
      // addValidator,
      isRequired,
      onSubmit,
      onPhoneNumberChange,
      onUniquePick,
      dropdownOptions,
      cancelUniqueConfirmationRequest,
      completedUniqueConfirmationRequest,
      telInputOptions,
      vuePhoneInputComp,
      showValidatorSettings,
      resendInvitationLink,
      createDispatching,
      redirectToAddPage,
      activeSetting,
      whatCase,
      goBack,
      countryChanged,
      formatPhone,
      changeValidator,
      showInfoDanger,
      verifyKYC,
      info,
    };
  },
};
