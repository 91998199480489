<template>
  <div class="relative">
    <div>
      <div
        @click="toggle()"
        class="cursor-pointer select-none flex relative space-x-6 items-center"
      >
        <span>
          <calendarShape />
        </span>
        <span class="md:text-sm text-xs text-left">Filtre par date</span>
        <span class="inline-flex flex-shrink-0">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.71209 6.52459C4.0782 6.15847 4.6718 6.15847 5.03791 6.52459L10 11.4867L14.9621 6.52459C15.3282 6.15847 15.9218 6.15847 16.2879 6.52459C16.654 6.8907 16.654 7.4843 16.2879 7.85041L10.6629 13.4754C10.2968 13.8415 9.7032 13.8415 9.33709 13.4754L3.71209 7.85041C3.34597 7.4843 3.34597 6.8907 3.71209 6.52459Z"
              fill="#C6D6EC"
            />
          </svg>
        </span>
      </div>
      <div
        v-if="show"
        class="z-1 absolute xs:right-0-0 bg-white p-4 mt-[7px] datepicker-custom-css"
      >
        <DatePicker
          v-model="date"
          :attributes="calendarAttributes"
          title-position="left"
          is-expanded
          :locale="{ id: $i18n.locale, masks: { weekdays: 'W' } }"
          transition="fade"
          :mode="mode"
        >
          <template #header-left-button>
            <calendar-left-icon />
          </template>
          <template #header-right-button>
            <calendar-right-icon />
          </template>
        </DatePicker>

        <div class="w-full !bg-E8EFFB h-[1px] mt-[10.5px]"></div>

        <!-- <Datepicker v-model="date" inline autoApply :enableTimePicker="false">
          <template #calendar-header="{ index, day }">
            <div :class="index === 5 || index === 6 ? 'red-color' : ''">
              {{ day }}
            </div>
          </template>
        </Datepicker> -->

        <primary-button
          class="w-full mt-4 !bg-E8EFFB"
          :label="$t('index.dateFilterDisableText')"
          @click="dateFilterDisable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
// import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import PrimaryButton from "./primaryButton.vue";
import calendarShape from "@/components/svg/calendar.vue";
import { DatePicker } from "v-calendar";
import CalendarLeftIcon from "../svg/calendarLeftIcon.vue";
import CalendarRightIcon from "../svg/calendarRightIcon.vue";

const calendarAttributes = [
  {
    highlight: {
      color: "#F11C33",
      fillMode: "solid",
    },
  },
];

export default {
  name: "Demo",
  components: {
    // Datepicker,
    PrimaryButton,
    calendarShape,
    DatePicker,
    CalendarLeftIcon,
    CalendarRightIcon,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const show = ref(false);
    const date = ref(new Date());
    const toggle = () => {
      show.value = !show.value;
    };
    const dateFilterDisable = () => {
      emit("dateFilterDisable");

      show.value = !show.value;
    };

    watch(
      () => date.value,
      (val) => {
        console.log(val);
        datePicked(val);
      },
    );

    const datePicked = (date) => {
      console.log(date);
      emit("dateSelected", date);
      show.value = false;
    };

    return {
      date,
      datePicked,
      toggle,
      show,
      calendarAttributes,
      dateFilterDisable,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.primary-button-label) {
  color: #566c80;
}

:deep(.vc-arrow.is-right) {
  margin-left: 11px;
}

:deep(.vc-container) {
  z-index: 1;
  border: 0;
  border-color: transparent;
  box-shadow: none;
}
:deep(.vc-header) {
  padding-top: 5px;
  text-transform: capitalize;
}
:deep(.vc-weeks),
:deep(.vc-weekday) {
  font-family: "Source sans pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #131516;
}
:deep(.vc-day.is-not-in-month *) {
  color: #a7afb2;
  opacity: 1;
}
:deep(.vc-highlights .vc-highlight) {
  background: #f11c33 !important;
}
:deep(.vc-weekday) {
  color: #a5bce0;
  font-size: 14px;
  line-height: 17px;
  /* border-bottom: solid 1px #a7afb2; */
}
* {
  --vdp-hover-bg-color: red;
  --vdp-selected-color: black;
  --vdp-selected-bg-color: rgba(46, 50, 56, 0.05);
}

.datepicker-custom-css {
  background: #ffffff;

  box-shadow: 0px 4px 6px rgba(153, 167, 179, 0.2);
  border-radius: 4px;
}
</style>
