<template>
  <div>
      <div 
    class=" theParent relative rounded-full bg-primaryColor flex justify-center items-center h-10 w-10 cursor-pointer" @click="goToAppro()"
  >
    <span
      ><svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="white" />
      </svg>
    </span>
  </div>
 
   <div class=" card absolute mt-2   hideCard ">
      <span class=" text-white text-xs">
          {{ $t('index.doAppro')}}  
      </span>
  </div>
  </div>
</template>

<script>
import {useRouter} from 'vue-router';
export default {
    setup() {
        const router = useRouter();
        function goToAppro (){
            router.push({
                name: 'appro' 
            });
        }
        return {
            goToAppro,
        };
    },
};
</script>

<style>
.card{
    background: rgba(55, 61, 63, 0.8);
border-radius: 4px;
padding: 4px 6px;

}
.hideCard{
    display: none;
}

.theParent:hover + .hideCard{
    display: block;
   
}
</style>
