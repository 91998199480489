<template>
  <div class="w-full flex justify-between items-center py-4">
    <!-- {{ me.permissions.includes("RUN_DISPATCHING") }} jj -->
    <div>
      <span class="font-semibold text-sm md:text-xl text-primaryColor">
        {{ $t(label) }}
      </span>
      <!-- ( {{ getTotalPushs }}) -->
    </div>
    <slot name="button" v-if="$slots.button"></slot>
    <div v-else>
      <primary-button
        @click="gotoCreatevue()"
        :label="$t('index.new')"
        v-if="
          me.permissions ? me.permissions.includes('RUN_DISPATCHING') : true
        "
      >
        <template #icon>
          <span>
            <add />
          </span>
        </template>
      </primary-button>

      <!-- <CreateDispathing v-if="me.permissions.includes('RUN_DISPATCHING')" /> -->

      <button
        @click="show = !show"
        v-else
        ref="cardBlack"
        class="relative theParent bg-[#E0E0E0] px-2 py-3 items-center rounded font-semibold text-[#999898] flex gap-4 justify-center text-sm md:text-lg md:w-[232px] w-[170px]"
      >
        <span>
          <addGray />
        </span>
        {{ $t("index.new") }}
      </button>
      <div
        v-if="show"
        class="absolute p-3 bg-[#333333] rounded-md right-[20px] top-[155px] max-w-[260px]"
      >
        <span
          @click="showModal = true"
          class="text-white cursor-pointer"
          v-html="$t('index.canRun')"
        >
        </span>
      </div>
    </div>
    <modal :closeIcon="false" v-if="showModal">
      <template #title>
        <div class="flex justify-center items-center">
          <svg
            width="94"
            height="94"
            viewBox="0 0 94 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M82.4459 73.2676L51.156 15.1602C48.9382 11.0404 43.0301 11.0404 40.8105 15.1602L9.52243 73.2676C9.04089 74.162 8.79946 75.166 8.82172 76.1815C8.84398 77.197 9.12917 78.1894 9.64944 79.0619C10.1697 79.9343 10.9073 80.6569 11.7902 81.1592C12.6731 81.6615 13.6711 81.9263 14.6869 81.9277H77.2722C78.2887 81.9279 79.2879 81.6642 80.1721 81.1626C81.0563 80.6609 81.7951 79.9385 82.3165 79.0658C82.8379 78.1931 83.1238 77.2001 83.1465 76.1838C83.1692 75.1675 82.9278 74.1627 82.4459 73.2676ZM45.9841 72.9316C45.2579 72.9316 44.548 72.7163 43.9442 72.3128C43.3403 71.9093 42.8697 71.3359 42.5918 70.6649C42.3139 69.994 42.2411 69.2557 42.3828 68.5434C42.5245 67.8311 42.8742 67.1769 43.3877 66.6633C43.9013 66.1498 44.5555 65.8001 45.2678 65.6584C45.9801 65.5168 46.7184 65.5895 47.3893 65.8674C48.0603 66.1453 48.6337 66.6159 49.0372 67.2198C49.4407 67.8236 49.656 68.5335 49.656 69.2598C49.656 69.742 49.561 70.2194 49.3765 70.6649C49.192 71.1104 48.9215 71.5152 48.5806 71.8562C48.2396 72.1971 47.8348 72.4676 47.3893 72.6521C46.9438 72.8367 46.4663 72.9316 45.9841 72.9316ZM49.9718 36.0018L48.918 58.4002C48.918 59.1793 48.6085 59.9264 48.0576 60.4773C47.5067 61.0282 46.7595 61.3377 45.9805 61.3377C45.2014 61.3377 44.4542 61.0282 43.9033 60.4773C43.3525 59.9264 43.043 59.1793 43.043 58.4002L41.9891 36.0109C41.9655 35.4759 42.0497 34.9416 42.2369 34.4399C42.4242 33.9381 42.7105 33.4792 43.0788 33.0905C43.4472 32.7017 43.89 32.3911 44.381 32.1772C44.8719 31.9632 45.4009 31.8503 45.9364 31.8452H45.975C46.5141 31.8449 47.0478 31.9539 47.5436 32.1655C48.0395 32.3771 48.4874 32.687 48.8602 33.0765C49.2331 33.466 49.5231 33.927 49.7129 34.4316C49.9027 34.9363 49.9883 35.4741 49.9645 36.0128L49.9718 36.0018Z"
              fill="#F11C33"
            />
          </svg>
        </div>

        <div class="flex justify-center items-center w-full">
          <span class="font-bold text-xl">
            {{ $t("index.imposible") }}
          </span>
        </div>
      </template>

      <div class="text-center">
        <span>
          {{ $t("index.explication") }}
        </span>
      </div>
      <primary-button
        @click="showModal = false"
        :label="$t('index.okay')"
        class="w-full mt-6"
      >
      </primary-button>
    </modal>
  </div>
</template>

<script>
import CreateDispathing from "../global/dispatchingBTn.vue";
import { useRouter } from "vue-router";
import PrimaryButton from "./primaryButton.vue";
import add from "@/components/svg/add.vue";
import addGray from "@/components/svg/addGray.vue";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import modal from "../global/modal.vue";
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  components: {
    PrimaryButton,
    add,
    addGray,
    modal,
    CreateDispathing,
  },
  setup(_, { emit }) {
    const cardBlack = ref(null);
    const router = useRouter();
    const store = useStore();
    const show = ref(false);
    const showModal = ref(false);
    const me = computed(() => {
      return store.getters["getMeInfo"];
    });
    const gotoCreatevue = () => {
      router.push({ name: "create" });
    };
    onMounted(() => {
      document.addEventListener("click", (event) => {
        if (cardBlack.value && !cardBlack.value.contains(event.target)) {
          show.value = false;
        }
      });
    });
    return {
      gotoCreatevue,
      me,
      cardBlack,
      show,
      showModal,
    };
  },
};
</script>

<style scoped>
.hideCard {
  display: none;
}
.theParent:hover + .hideCard:hover + {
  display: block;
}
</style>
