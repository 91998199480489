<template>
  <div class="w-full flex justify-center relative h-full">
    <div class="blackCard">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
  setup(_, { emit }) {
    const closeCard = () => {
      emit("closed");
    };
    const deleteElmt = () => {
      emit("deleted");
    };
    return {
      closeCard,
      deleteElmt,
    };
  },
};
</script>

<style>
.blackCard {
  display: flex;
  gap: 20px;
  font-size: 12px;
  align-content: center;
  align-items: center;
  background-color: black;
  position: fixed;
  padding: 16px 40px;
  color: white;
  height: 56px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  bottom: 5%;
  z-index: 1;
}
</style>
