<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_4569_86968)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18ZM18 32.7031C26.1203 32.7031 32.7031 26.1203 32.7031 18C32.7031 9.8797 26.1203 3.2969 18 3.2969C9.8797 3.2969 3.2969 9.8797 3.2969 18C3.2969 26.1203 9.8797 32.7031 18 32.7031Z" fill="white"/>
</g>
<path d="M32.7031 18C32.7031 26.1203 26.1203 32.7031 18 32.7031C9.87968 32.7031 3.29688 26.1203 3.29688 18C3.29688 9.87968 9.87968 3.29688 18 3.29688C26.1203 3.29688 32.7031 9.87968 32.7031 18Z" fill="#F9DC4A"/>
<path d="M9.43604 20.7317L12.4699 16.7254C12.6408 16.4644 12.8641 16.2418 13.1256 16.0716C13.2581 15.9887 13.4128 15.9481 13.569 15.9554C13.7252 15.9627 13.8755 16.0175 13.9998 16.1124C14.2258 16.3217 14.2406 16.668 14.2406 16.981L14.2313 18.7943C14.2313 18.8388 14.2424 18.8962 14.2869 18.8999C14.3313 18.9036 14.3387 18.8758 14.3554 18.8536L15.989 16.681C16.12 16.4819 16.2879 16.3096 16.4836 16.1735C16.4687 16.3188 16.4993 16.4651 16.5709 16.5923C16.6425 16.7196 16.7518 16.8215 16.8837 16.8842C17.0156 16.9469 17.1636 16.9673 17.3075 16.9425C17.4514 16.9177 17.5842 16.849 17.6875 16.7458C17.7023 17.3978 17.7078 18.0423 17.7078 18.7054C17.7078 19.0332 17.7078 19.3592 17.7078 19.6871C17.7078 19.8501 17.7078 20.013 17.7078 20.176C17.7078 20.2575 17.7078 20.339 17.7078 20.4224C17.7125 20.4697 17.7014 20.5172 17.6764 20.5576C17.5374 20.7428 17.2855 20.7428 17.0781 20.7243C16.9749 20.7251 16.8737 20.6955 16.7873 20.6391C16.7389 20.5839 16.7029 20.519 16.6818 20.4487C16.6607 20.3783 16.655 20.3043 16.6651 20.2316V17.5274C16.6651 17.47 16.654 17.3959 16.5984 17.3811C16.5428 17.3663 16.5039 17.4144 16.4724 17.457L14.4425 20.1557C14.3071 20.379 14.1155 20.5629 13.8868 20.6891C13.6479 20.7928 13.3145 20.7298 13.2108 20.4891C13.1761 20.3956 13.1616 20.2959 13.1682 20.1964L13.1385 17.5663C13.1385 17.4959 13.1015 17.3959 13.033 17.4163C13.0086 17.4258 12.9883 17.4435 12.9755 17.4663L10.84 20.3742C10.775 20.4864 10.6844 20.5816 10.5756 20.6521C10.4668 20.7226 10.3429 20.7664 10.2139 20.7798C9.95397 20.7777 9.69429 20.7616 9.43604 20.7317Z" fill="#D93834"/>
<path d="M10.6639 14.4621H14.2182C14.349 14.4422 14.4828 14.4649 14.5997 14.5269C14.6842 14.574 14.7507 14.6476 14.7892 14.7363C14.8277 14.825 14.836 14.9239 14.8127 15.0177C14.7917 15.1284 14.7305 15.2274 14.6409 15.2957C14.5514 15.3641 14.4398 15.397 14.3275 15.3882C13.757 15.3882 13.1884 15.3882 12.6179 15.3882C12.2475 15.3882 11.8641 15.3882 11.4862 15.3882C11.4265 15.3827 11.3664 15.3951 11.3136 15.4238C11.2609 15.4525 11.2178 15.4962 11.1899 15.5493C10.1021 17.2126 9.01359 18.8746 7.92451 20.5354C7.89064 20.6027 7.83703 20.6581 7.77084 20.6942C7.70465 20.7303 7.62903 20.7453 7.55408 20.7372C7.28551 20.7261 7.0151 20.7372 6.74653 20.7372C6.69467 20.7372 6.61873 20.7372 6.59465 20.7169C6.54464 20.6558 6.62058 20.6076 6.65207 20.5613C7.17809 19.7957 7.70596 19.032 8.23568 18.2702L10.0879 15.6012C10.1267 15.5456 10.2008 15.49 10.1693 15.416C10.1379 15.3419 10.0453 15.3808 9.98413 15.3808C8.98396 15.3808 7.98317 15.3808 6.98176 15.3808C6.8774 15.3905 6.7729 15.3639 6.68585 15.3055C6.59881 15.2471 6.53455 15.1606 6.5039 15.0603C6.47281 14.9732 6.46966 14.8786 6.49489 14.7896C6.52012 14.7007 6.57248 14.6218 6.64466 14.564C6.76008 14.485 6.90053 14.4514 7.03917 14.4695L10.6639 14.4621Z" fill="#D93834"/>
<path d="M25.2928 19.3946H26.8709C26.924 19.4001 26.9772 19.3863 27.0209 19.3557C27.053 19.3167 27.0695 19.2672 27.0672 19.2168C27.0854 18.9698 27.0513 18.7218 26.9672 18.4889C26.8835 18.2529 26.7313 18.0472 26.5301 17.8981C26.3898 17.8022 26.2305 17.7377 26.063 17.709C25.8955 17.6802 25.7238 17.6878 25.5596 17.7314C25.2292 17.8222 24.9453 18.0346 24.765 18.3259C24.597 18.6116 24.5087 18.9372 24.5094 19.2687C24.4866 19.5783 24.5507 19.8881 24.6946 20.1633C24.8052 20.3395 24.9533 20.4892 25.1285 20.6016C25.3036 20.714 25.5014 20.7863 25.7077 20.8134C25.9848 20.8508 26.2668 20.8241 26.5319 20.7356C26.5987 20.7138 26.6642 20.6885 26.7283 20.6596C26.7727 20.6411 26.8727 20.6133 26.9005 20.5726C26.9283 20.5318 26.9005 20.4392 26.8839 20.3966C26.8684 20.3267 26.846 20.2585 26.8172 20.1929C26.8061 20.1609 26.7845 20.1335 26.7561 20.1151C26.7305 20.1077 26.7035 20.1066 26.6774 20.1117C26.6513 20.1169 26.6269 20.1282 26.606 20.1447C26.4635 20.2259 26.306 20.2775 26.143 20.2963C25.9801 20.315 25.815 20.3007 25.6577 20.254C25.5011 20.203 25.3633 20.1065 25.2617 19.9769C25.1601 19.8473 25.0994 19.6904 25.0873 19.5261C25.0847 19.4951 25.0918 19.464 25.1076 19.4372C25.1252 19.4205 25.1463 19.408 25.1694 19.4007C25.1925 19.3933 25.217 19.3912 25.241 19.3946H25.2928ZM25.1539 18.7501C25.1968 18.6057 25.2838 18.4784 25.4027 18.386C25.5217 18.2937 25.6665 18.2409 25.817 18.2352C25.9603 18.2221 26.1035 18.2615 26.2199 18.3459C26.3364 18.4304 26.4183 18.5542 26.4505 18.6945C26.4912 18.8408 26.4505 18.8908 26.2949 18.8797C26.1393 18.8686 25.9726 18.8797 25.8115 18.8797H25.2799C25.191 18.8834 25.1317 18.8649 25.1539 18.7501Z" fill="#D93834"/>
<path d="M21.3033 19.0258C21.2928 18.792 21.2232 18.5646 21.101 18.3649C20.9788 18.1652 20.808 17.9998 20.6045 17.884C20.401 17.7682 20.1716 17.7058 19.9375 17.7028C19.7034 17.6997 19.4723 17.756 19.2659 17.8664C18.6362 18.1887 18.3176 19.0407 18.5491 19.7797C18.7973 20.5687 19.4567 20.9614 20.2161 20.7761C20.881 20.6169 21.2996 20.0353 21.2977 19.2555C21.3033 19.1777 21.307 19.1018 21.3033 19.0258ZM20.6328 19.7834C20.5684 19.9415 20.4573 20.0762 20.3144 20.1696C20.1714 20.2629 20.0033 20.3104 19.8327 20.3057C19.6483 20.2824 19.4764 20.1999 19.3428 20.0707C19.2093 19.9415 19.1212 19.7724 19.0918 19.5889C19.0132 19.2888 19.0482 18.9702 19.19 18.6943C19.2515 18.5563 19.3527 18.4396 19.4806 18.3592C19.6086 18.2787 19.7575 18.2381 19.9086 18.2424C20.0589 18.2385 20.2069 18.2796 20.3336 18.3604C20.4604 18.4413 20.5601 18.5582 20.6198 18.6961C20.7126 18.8702 20.7573 19.0658 20.7495 19.2629C20.7521 19.4432 20.7121 19.6215 20.6328 19.7834Z" fill="#D93834"/>
<path d="M28.441 19.7798C28.691 19.1297 28.9244 18.5481 29.1319 17.9572C29.2022 17.7591 29.3171 17.7461 29.4819 17.7572C29.5486 17.7572 29.7171 17.7572 29.7134 17.8183C29.6591 18.07 29.5873 18.3175 29.4986 18.5592C29.1578 19.3538 28.8725 20.1724 28.5021 20.9559C28.313 21.4154 27.9493 21.7811 27.4908 21.9728C27.3445 22.0302 27.2908 21.9987 27.2389 21.8616C27.1278 21.5671 27.1222 21.5708 27.4056 21.4171C27.6789 21.2565 27.8987 21.0188 28.0372 20.7337C28.0731 20.6758 28.0938 20.6098 28.0974 20.5418C28.101 20.4738 28.0873 20.406 28.0576 20.3447C27.7335 19.5649 27.4241 18.7685 27.1019 17.9813C27.0315 17.8091 27.0426 17.7461 27.2537 17.7535C27.6353 17.7665 27.6242 17.7535 27.7705 18.1128C27.9798 18.6388 28.1984 19.176 28.441 19.7798Z" fill="#D93834"/>
<path d="M24.1388 19.6611C24.1388 19.9537 24.1388 20.2445 24.1388 20.5353C24.1448 20.5836 24.1385 20.6327 24.1203 20.6779C24.113 20.6943 24.1023 20.7089 24.0889 20.7207C24.0755 20.7325 24.0596 20.7413 24.0425 20.7465C24.0129 20.7465 23.7239 20.7576 23.7017 20.7465C23.6672 20.7424 23.6352 20.7267 23.6109 20.702C23.5864 20.6727 23.5715 20.6364 23.5683 20.5983C23.5656 20.5631 23.5656 20.5278 23.5683 20.4927C23.585 19.9722 23.5683 19.4499 23.5683 18.9295C23.5683 18.485 23.3479 18.2312 22.9793 18.2275C22.8868 18.2275 22.7952 18.246 22.7099 18.2819C22.6246 18.3179 22.5474 18.3706 22.4829 18.4369C22.4183 18.5032 22.3676 18.5818 22.3339 18.668C22.3002 18.7541 22.2842 18.8462 22.2866 18.9387C22.2767 19.4685 22.2767 19.9976 22.2866 20.526C22.2866 20.6909 22.2533 20.7613 22.0718 20.752C21.6606 20.7353 21.7273 20.7909 21.7217 20.4149C21.7087 19.587 21.7551 18.7591 21.6865 17.9312C21.6865 17.8719 21.6865 17.8052 21.7513 17.7793C21.7871 17.7664 21.8245 17.7589 21.8625 17.757L21.9773 17.7404C22.0314 17.7265 22.0881 17.7265 22.1422 17.7404C22.1737 17.7556 22.2009 17.7785 22.2213 17.807C22.2417 17.8355 22.2546 17.8686 22.2588 17.9034C22.2792 17.983 22.3644 17.9034 22.4107 17.8774C22.651 17.7353 22.9325 17.6791 23.209 17.7182C23.4628 17.7519 23.696 17.8758 23.866 18.0673C24.0361 18.2587 24.1316 18.5049 24.1351 18.7609C24.1518 19.0591 24.1351 19.3592 24.1351 19.6574L24.1388 19.6611Z" fill="#D93834"/>
<path d="M17.1004 14.1511C17.5289 14.1455 17.95 14.2628 18.314 14.4889C18.678 14.7151 18.9696 15.0406 19.1545 15.4272C19.1673 15.4624 19.172 15.4999 19.1684 15.5371C19.1648 15.5744 19.1528 15.6103 19.1334 15.6423C19.1141 15.6743 19.0878 15.7015 19.0565 15.722C19.0252 15.7425 18.9897 15.7557 18.9526 15.7606C18.8582 15.7606 18.8582 15.6698 18.8322 15.6161C18.6522 15.2257 18.3441 14.9085 17.9591 14.7171C17.574 14.5258 17.1351 14.4718 16.7152 14.5641C16.563 14.5902 16.4168 14.6435 16.2836 14.7215C16.128 14.8197 16.0984 14.7215 16.0317 14.6141C15.9651 14.5067 16.0003 14.4289 16.1243 14.3696C16.4292 14.2244 16.7628 14.1497 17.1004 14.1511Z" fill="#D93935"/>
<path d="M17.1005 14.9825C17.3911 14.9765 17.6765 15.06 17.918 15.2217C18.1596 15.3834 18.3455 15.6154 18.4507 15.8864C18.4679 15.9759 18.4497 16.0685 18.4 16.1449C18.3502 16.2212 18.2728 16.2753 18.184 16.2957C18.1118 16.2957 18.1247 16.2402 18.1155 16.1994C18.0825 16.0445 18.0137 15.8995 17.9146 15.776C17.8156 15.6525 17.689 15.5538 17.545 15.4879C17.401 15.4221 17.2436 15.3908 17.0854 15.3966C16.9272 15.4025 16.7725 15.4452 16.6337 15.5215C16.493 15.5975 16.4763 15.4974 16.4485 15.4178C16.4207 15.3381 16.2781 15.2418 16.4485 15.1529C16.6455 15.0361 16.8715 14.977 17.1005 14.9825Z" fill="#D93935"/>
<path d="M16.8335 16.1179C17.0932 16.1424 17.3391 16.2465 17.5373 16.4161C17.6244 16.4939 17.6559 16.5754 17.5503 16.6625C17.4787 16.7333 17.3884 16.7822 17.29 16.8035C17.1916 16.8249 17.0891 16.8177 16.9946 16.7829C16.8949 16.7536 16.8063 16.6951 16.7402 16.6148C16.6741 16.5346 16.6337 16.4363 16.6242 16.3328C16.6131 16.1791 16.6557 16.0865 16.8335 16.1179Z" fill="#DA3B37"/>
<defs>
<filter id="filter0_d_4569_86968" x="0.703098" y="0.703098" width="34.5938" height="34.5938" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="0.648451"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4569_86968"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4569_86968" result="shape"/>
</filter>
</defs>
</svg>


</template>