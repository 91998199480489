
import Warning from "@/components/svg/warning.vue";

import dragFile from "@/components/global/dragFile.vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import customInput from "@/components/global/customInput.vue";
import whiteButton from "@/components/global/redBorderButton.vue";
import Balance from "@/components/svg/firstIcon.vue";
import back from "@/components/svg/back.vue";
import { ref, toRef, inject, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ActionTypes, CreateCampaignPayload } from "@/store/pushup/store.types";
import { ApiCallResult } from "@/api";
import { PushupSocket, WorkflowWsEvents } from "@/socket";
import { StoreState } from "@/store/pushup/state";
import { Field, Form } from "vee-validate";
import modal from "@/components/global/modal.vue";

export default {
  props: {
    campaignId: {
      type: String,
      required: true,
    },
    dispatchingFileId: {
      type: String,
      required: false,
    },
  },
  components: {
    back,
    modal,
    Balance,
    customInput,
    whiteButton,
    dragFile,
    PrimaryButton,
    Warning,
    Field,
    Form,
  },

  setup(props: any, { emit }: any) {
    const store: Record<string, any> & { state: StoreState } = useStore();
    const $t: any = inject("$t");
    // const addSocketListener: any = inject('$addSocketListener')
    const PushUpIo: any = inject("$PushUpIo");
    const showNotif: any = inject("$showNotif");

    const acceptedFileFormats = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    const campagnId = toRef(props, "id");

    const state = reactive({
      isLoading: false,
      file: null as any,
      fileName: "",
      showMissingFileError: false,
    });

    function onUpload(e: any) {
      state.file = e;
      state.fileName = state.file.name;
    }

    const errorFile = ref("");
    const download = async () => {
      console.log("vv");

      await store.dispatch(ActionTypes.TEMPLATE_FILE);
    };

    async function updateDispatching() {
      if (!state.file) {
        state.showMissingFileError = true;
        return;
      }
      state.showMissingFileError = false;
      state.isLoading = true;

      try {
        let formData = new FormData();

        formData.append("file", state.file);
        formData.append("campaignId", props.campaignId);
        formData.append("dispatchingFileId", props.dispatchingFileId);

        const uploadResult = await store.dispatch(
          ActionTypes.UPLOAD_FILE,
          formData,
        );
        console.log(formData);
        if (uploadResult.data) {
          PushUpIo.joinRoom(uploadResult.data.filename);
        }
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
        state.isLoading = false;
      }
    }
    const account = computed(() => {
      return store.getters["getAmount"];
    });

    function onCloseRequest() {
      emit("closeModal");
    }

    return {
      state,
      onUpload,
      errorFile,
      download,
      updateDispatching,
      account,
      acceptedFileFormats,
      onCloseRequest,
    };
  },
};
