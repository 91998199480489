<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.25 15.375L12 8.625L18.75 15.375"
      stroke="#808080"
      stroke-width="2.25"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>
