<template>
  <div
    class="w-[15.625rem] bg-white border border-btnBorder rounded px-4 py-2 flex flex-shrink flex-nowrap items-center space-x-2"
  >
    <span class="inline-flex flex-whrink-0">
      <search />
    </span>
    <input
      v-typing="{
        finish: (event) => {
          $emit('typing:finished', event.target.value);
        },
        run: (event) => {
          $emit('typing:running', event.target.value);
        },
      }"
      @input="$emit('search', $event.target.value)"
      :placeholder="placeholder"
      class="flex-shrink w-full text-xs text-left font-semibold appearance-none border-0 outline-none"
      type="text"
    />
  </div>
</template>

<script>
import Search from "@/components/svg/search.vue";
import { inject } from "vue";
export default {
  components: {
    Search,
  },
  props: {
    placeholder: {
      type: String,
      default: "Rechercher ",
    },
  },
  setup(_, { emit }) {
    const $t = inject("$t");
    const search = (data) => {
      console.log(data);
      emit("search", data);
    };
    return {
      search,
    };
  },
};
</script>
<style>
.text {
  font-size: 12px;
}
</style>
