<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.96967 6.17475C3.26256 5.88185 3.73744 5.88185 4.03033 6.17475L8 10.1444L11.9697 6.17475C12.2626 5.88185 12.7374 5.88185 13.0303 6.17475C13.3232 6.46764 13.3232 6.94252 13.0303 7.23541L8.53033 11.7354C8.23744 12.0283 7.76256 12.0283 7.46967 11.7354L2.96967 7.23541C2.67678 6.94252 2.67678 6.46764 2.96967 6.17475Z"
      fill="white"
    />
  </svg>
</template>
