import { onMounted, ref } from "vue";
import { TUseResponsiveArg } from "../interface";

const limit = 860;

export function useResponsive(cb: TUseResponsiveArg) {
  const prevSize = ref(window.innerWidth);

  onMounted(() => {
    window.addEventListener("resize", () => {
      if (
        (prevSize.value > limit && window.innerWidth <= limit) ||
        (prevSize.value < limit && window.innerWidth >= limit)
      ) {
        prevSize.value = window.innerWidth;
        console.log("resize", window.innerWidth);

        cb();
      }
    });
  });
}
