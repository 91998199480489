<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.875 6.50006H8.625C8.55596 6.50006 8.5 6.55603 8.5 6.62506V7.87506C8.5 7.9441 8.55596 8.00006 8.625 8.00006H9.875C9.94404 8.00006 10 7.9441 10 7.87506V6.62506C10 6.55603 9.94404 6.50006 9.875 6.50006Z"
      fill="black"
    />
    <path
      d="M12.375 6.50006H11.125C11.056 6.50006 11 6.55603 11 6.62506V7.87506C11 7.9441 11.056 8.00006 11.125 8.00006H12.375C12.444 8.00006 12.5 7.9441 12.5 7.87506V6.62506C12.5 6.55603 12.444 6.50006 12.375 6.50006Z"
      fill="black"
    />
    <path
      d="M9.875 9.00006H8.625C8.55596 9.00006 8.5 9.05603 8.5 9.12506V10.3751C8.5 10.4441 8.55596 10.5001 8.625 10.5001H9.875C9.94404 10.5001 10 10.4441 10 10.3751V9.12506C10 9.05603 9.94404 9.00006 9.875 9.00006Z"
      fill="black"
    />
    <path
      d="M12.375 9.00006H11.125C11.056 9.00006 11 9.05603 11 9.12506V10.3751C11 10.4441 11.056 10.5001 11.125 10.5001H12.375C12.444 10.5001 12.5 10.4441 12.5 10.3751V9.12506C12.5 9.05603 12.444 9.00006 12.375 9.00006Z"
      fill="black"
    />
    <path
      d="M4.875 9.00006H3.625C3.55596 9.00006 3.5 9.05603 3.5 9.12506V10.3751C3.5 10.4441 3.55596 10.5001 3.625 10.5001H4.875C4.94404 10.5001 5 10.4441 5 10.3751V9.12506C5 9.05603 4.94404 9.00006 4.875 9.00006Z"
      fill="black"
    />
    <path
      d="M7.375 9.00006H6.125C6.05596 9.00006 6 9.05603 6 9.12506V10.3751C6 10.4441 6.05596 10.5001 6.125 10.5001H7.375C7.44404 10.5001 7.5 10.4441 7.5 10.3751V9.12506C7.5 9.05603 7.44404 9.00006 7.375 9.00006Z"
      fill="black"
    />
    <path
      d="M4.875 11.5001H3.625C3.55596 11.5001 3.5 11.556 3.5 11.6251V12.8751C3.5 12.9441 3.55596 13.0001 3.625 13.0001H4.875C4.94404 13.0001 5 12.9441 5 12.8751V11.6251C5 11.556 4.94404 11.5001 4.875 11.5001Z"
      fill="black"
    />
    <path
      d="M7.375 11.5001H6.125C6.05596 11.5001 6 11.556 6 11.6251V12.8751C6 12.9441 6.05596 13.0001 6.125 13.0001H7.375C7.44404 13.0001 7.5 12.9441 7.5 12.8751V11.6251C7.5 11.556 7.44404 11.5001 7.375 11.5001Z"
      fill="black"
    />
    <path
      d="M9.875 11.5001H8.625C8.55596 11.5001 8.5 11.556 8.5 11.6251V12.8751C8.5 12.9441 8.55596 13.0001 8.625 13.0001H9.875C9.94404 13.0001 10 12.9441 10 12.8751V11.6251C10 11.556 9.94404 11.5001 9.875 11.5001Z"
      fill="black"
    />
    <path
      d="M14 2.00006H12.5V1.00006H11.25V2.00006H4.75V1.00006H3.5V2.00006H2C1.73478 2.00006 1.48043 2.10542 1.29289 2.29295C1.10536 2.48049 1 2.73484 1 3.00006V14.0001C1 14.2653 1.10536 14.5196 1.29289 14.7072C1.48043 14.8947 1.73478 15.0001 2 15.0001H14C14.2652 15.0001 14.5196 14.8947 14.7071 14.7072C14.8946 14.5196 15 14.2653 15 14.0001V3.00006C15 2.73484 14.8946 2.48049 14.7071 2.29295C14.5196 2.10542 14.2652 2.00006 14 2.00006ZM13.625 13.6251H2.375V5.50006H13.625V13.6251Z"
      fill="black"
    />
  </svg>
</template>
