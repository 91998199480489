<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 14H14C14.2652 14 14.5195 13.8946 14.707 13.7071C14.8945 13.5195 14.9998 13.2652 14.9998 13C14.9998 12.7348 14.8945 12.4805 14.707 12.2929C14.5195 12.1054 14.2652 12 14 12H10C9.73481 12 9.48049 12.1054 9.29299 12.2929C9.10549 12.4805 9.00015 12.7348 9.00015 13C9.00015 13.2652 9.10549 13.5195 9.29299 13.7071C9.48049 13.8946 9.73481 14 10 14ZM19 3H5C4.20435 3 3.44129 3.31607 2.87868 3.87868C2.31607 4.44129 2 5.20435 2 6V9C2 9.26522 2.10536 9.51957 2.29289 9.70711C2.48043 9.89464 2.73478 10 3 10H4V18C4 18.7956 4.31607 19.5587 4.87868 20.1213C5.44129 20.6839 6.20435 21 7 21H17C17.7956 21 18.5587 20.6839 19.1213 20.1213C19.6839 19.5587 20 18.7956 20 18V10H21C21.2652 10 21.5196 9.89464 21.7071 9.70711C21.8946 9.51957 22 9.26522 22 9V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7956 3 19 3ZM18 18C18 18.2652 17.8946 18.5196 17.7071 18.7071C17.5196 18.8946 17.2652 19 17 19H7C6.73478 19 6.48043 18.8946 6.29289 18.7071C6.10536 18.5196 6 18.2652 6 18V10H18V18ZM20 8H4V6C4 5.73478 4.10536 5.48043 4.29289 5.29289C4.48043 5.10536 4.73478 5 5 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V8Z"
      fill="#2D7BF7"
    />
  </svg>
</template>
