/* eslint-disable */
import { GetterTree } from "vuex";
import { State } from "./state";

export type Getters = any;

export interface ExistenceCheckResult {
  exist: boolean;
  index?: number;
}

export const getters: GetterTree<State, State> & Getters = {
  getAccountInfo: (state: State) => {
    return state.account;
  },
  getMode: (state: State) => {
    return state.mode;
  },
  getLoading: (state: State) => {
    return state.globalLoading;
  },

  getAllCampaign: (state: State) => {
    return state.allCampaign;
  },
  getFunding: (state: State) => {
    return state.funding;
  },
  getTotal: (state: State) => {
    return state.total;
  },
  getTotalAppro: (state: State) => {
    return state.totalAppro;
  },
  getReference: (state: State) => {
    return state.reference;
  },
  getBusinessId: (state: State) => {
    return state.businessId;
  },
  getAccountNumber: (state: State) => {
    return state.accountNumber;
  },
  getMeInfo: (state: State) => {
    return state.meInfo;
  },
  getPlans: (state: State) => {
    return state.plans;
  },
  getUserPlan: (state: State) => {
    return state.userPlan;
  },
  getContacts: (state: State) => {
    return state.contacts;
  },
  getBalance: (state: State) => {
    return state.balance;
  },

  getAmount: (state: State) => {
    return state.amount;
  },
  getAllAppro: (state: State) => {
    return state.allAppro;
  },
  getAllTransaction: (state: State) => {
    return state.allTransaction;
  },

  getCampaign: (state: State) => {
    return state.campaigns;
  },
  accountValidator: (state: State) => {
    return state.accountValidator;
  },
  getCampaignTransactions: (state: State) => {
    return state.campaignTransactions;
  },
  getTotalTransaction: (state: State) => {
    return state.totalTransaction;
  },
  getForgotusers: (state: State) => {
    return state.usersForgot;
  },

  getRevocateUser: (state: State) => {
    return state.revocateUser;
  },
  checkTransactionExistence:
    (state: State) =>
    (transactionId: string): ExistenceCheckResult => {
      const transactIndex = state.campaignTransactions.findIndex(
        (tr) => tr.id === transactionId,
      );

      return {
        exist: transactIndex !== -1,
        index: transactIndex === -1 ? undefined : transactIndex,
      };
    },
};
