<template>
  <div class="">
    <!-- { name: 'paySlip', libelle: 'Fiche de paie' }, -->
    <tabs
      :tabs="[
        { name: 'global', libelle: $t('index.global') },

        { name: 'contact', libelle: $t('index.subscriptionContact') },
      ]"
    >
      <template #global>
        <global />
      </template>
      <template #contact>
        <contact />
      </template>
      <template #paySlip>
        <paySlip />
      </template>
    </tabs>
  </div>
</template>

<script>
import global from "./global.vue";
import contact from "./contact.vue";
import paySlip from "./payslipGlobal.vue";
import tabs from "@/components/global/tld-side-tabs.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {
    tabs,
    global,
    contact,
    paySlip,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const UserInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    // if (!UserInfo.value.permissions.includes("HAS_ACCESS")) {
    //   router.push("/removeAccess");
    // }
  },
};
</script>

<style></style>
