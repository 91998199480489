<template>
  <div class="select-none w-max circle">
    <div
      class="rounded-full flex flex-shrink-0 justify-center items-center truncate circle status-chip"
      :class="{
        'bg-lightGreen  border border-hightGreen':
          status === 'COMPLETED' ||
          status === 'ACTIVE' ||
          status === 'active' ||
          status === 'VALIDATED' ||
          status === 'SUCCESS',
        'bg-[#DCDEFB]  border border-[#656ADC]': status === 'INVITED',
        'bg-[#F5F6F6]  border border-[#B2B2B2]': status === 'EXPIRED',
        'bg-lightLinkBlue  border border-blueDark': status === 'CREATED',
        'bg-lightOrange border border-hightOrange':
          status === 'PENDING' ||
          status === 'APPROVED' ||
          status === 'PROCESSING' ||
          status === 'processed' ||
          status === 'Submitted' ||
          status === 'REVOCATING' ||
          status === 'PROCESSING',
        'bg-287D3C':
          status === 'Active' || status === 'enabled' || status === 'Verified',
        'bg-lightRed  border border-primaryColor': status === 'FAILED',
        'bg-[#F9F6FF]  border border-[#954CF2]': status === 'FINISHED',
      }"
    >
      <span v-if="status === 'FINISHED'">
        <svg
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00001 1.83331C4.33334 1.83331 1.33334 4.83331 1.33334 8.49998C1.33334 12.1666 4.33334 15.1666 8.00001 15.1666C11.6667 15.1666 14.6667 12.1666 14.6667 8.49998C14.6667 4.83331 11.6667 1.83331 8.00001 1.83331ZM5.80001 8.83331C5.60001 9.36665 5.06668 9.63331 4.53335 9.43331C4.00001 9.23331 3.73334 8.69998 3.93334 8.16665C4.13334 7.63331 4.66668 7.36665 5.20001 7.56665C5.66668 7.69998 6.00001 8.29998 5.80001 8.83331ZM8.93334 8.83331C8.73334 9.36665 8.20001 9.63331 7.66668 9.43331C7.13334 9.23331 6.86668 8.69998 7.06668 8.16665C7.26668 7.63331 7.80001 7.36665 8.33334 7.56665C8.86668 7.69998 9.13334 8.29998 8.93334 8.83331ZM12.1333 8.83331C11.9333 9.36665 11.4 9.63331 10.8667 9.43331C10.3333 9.23331 10.0667 8.69998 10.2667 8.16665C10.4667 7.63331 11 7.36665 11.5333 7.56665C12 7.69998 12.2667 8.29998 12.1333 8.83331Z"
            fill="#954CF2"
          />
        </svg>
      </span>
      <span class="placeholder-item" v-if="status === 'INVITED'">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99967 1.33301C4.33301 1.33301 1.33301 4.33301 1.33301 7.99967C1.33301 11.6663 4.33301 14.6663 7.99967 14.6663C11.6663 14.6663 14.6663 11.6663 14.6663 7.99967C14.6663 4.33301 11.6663 1.33301 7.99967 1.33301ZM5.79967 8.33301C5.59968 8.86634 5.06634 9.13301 4.53301 8.93301C3.99968 8.73301 3.73301 8.19967 3.93301 7.66634C4.13301 7.13301 4.66634 6.86634 5.19968 7.06634C5.66634 7.19968 5.99968 7.79967 5.79967 8.33301ZM8.93301 8.33301C8.73301 8.86634 8.19967 9.13301 7.66634 8.93301C7.13301 8.73301 6.86634 8.19967 7.06634 7.66634C7.26634 7.13301 7.79967 6.86634 8.33301 7.06634C8.86634 7.19968 9.13301 7.79967 8.93301 8.33301ZM12.133 8.33301C11.933 8.86634 11.3997 9.13301 10.8663 8.93301C10.333 8.73301 10.0663 8.19967 10.2663 7.66634C10.4663 7.13301 10.9997 6.86634 11.533 7.06634C11.9997 7.19968 12.2663 7.79967 12.133 8.33301Z"
            fill="#656ADC"
          />
        </svg>
      </span>
      <span v-if="status === 'EXPIRED'">
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00001 0.833313C3.33334 0.833313 0.333344 3.83331 0.333344 7.49998C0.333344 11.1666 3.33334 14.1666 7.00001 14.1666C10.6667 14.1666 13.6667 11.1666 13.6667 7.49998C13.6667 3.83331 10.6667 0.833313 7.00001 0.833313ZM9.80001 9.29998C10.5333 10.0333 9.40001 11.1 8.66668 10.3666C8.06668 9.76665 7.53334 9.16665 6.93334 8.63331L5.20001 10.3666C4.46668 11.1 3.40001 9.96665 4.06668 9.23331C4.66668 8.63331 5.26668 8.03331 5.86668 7.49998L4.13334 5.76665C3.40001 5.03331 4.53334 3.96665 5.20001 4.69998C5.80001 5.29998 6.40001 5.89998 6.93334 6.43331L8.66668 4.69998C9.40001 3.96665 10.4667 5.09998 9.80001 5.83331C9.26668 6.36665 8.66668 6.89998 8.06668 7.49998L9.80001 9.29998Z"
            fill="#B2B2B2"
          />
        </svg>
      </span>
      <span class="w-4 placeholder-item" v-if="status === 'CREATED'">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00006 0.499977C3.416 0.499977 0.500061 3.41591 0.500061 6.99998C0.500061 10.584 3.416 13.5 7.00006 13.5C10.5841 13.5 13.5001 10.584 13.5001 6.99998C13.5001 3.41591 10.5841 0.499977 7.00006 0.499977ZM7.00006 10.4972C6.87645 10.4972 6.75561 10.4605 6.65283 10.3918C6.55005 10.3232 6.46994 10.2255 6.42264 10.1113C6.37533 9.99714 6.36296 9.87147 6.38707 9.75023C6.41119 9.629 6.47071 9.51763 6.55812 9.43022C6.64553 9.34282 6.75689 9.28329 6.87813 9.25917C6.99937 9.23506 7.12503 9.24744 7.23924 9.29474C7.35344 9.34205 7.45105 9.42215 7.51973 9.52493C7.58841 9.62772 7.62506 9.74855 7.62506 9.87217C7.62506 10.0379 7.55921 10.1969 7.442 10.3141C7.32479 10.4313 7.16582 10.4972 7.00006 10.4972ZM7.67881 4.21123L7.49944 8.02373C7.49944 8.15634 7.44676 8.28351 7.35299 8.37728C7.25922 8.47105 7.13204 8.52373 6.99944 8.52373C6.86683 8.52373 6.73965 8.47105 6.64588 8.37728C6.55211 8.28351 6.49944 8.15634 6.49944 8.02373L6.32006 4.2131V4.21154C6.31613 4.11995 6.33077 4.02852 6.36311 3.94274C6.39545 3.85697 6.44481 3.77862 6.50823 3.71243C6.57164 3.64623 6.6478 3.59356 6.73211 3.55757C6.81642 3.52158 6.90714 3.50303 6.99881 3.50303C7.09048 3.50303 7.1812 3.52158 7.26551 3.55757C7.34982 3.59356 7.42598 3.64623 7.4894 3.71243C7.55281 3.77862 7.60217 3.85697 7.63451 3.94274C7.66685 4.02852 7.68149 4.11995 7.67756 4.21154L7.67881 4.21123Z"
            fill="#0965F6"
          />
        </svg>
      </span>
      <span
        class="w-4 placeholder-item"
        v-if="
          status === 'APPROVED' ||
          status === 'PENDING' ||
          status === 'PROCESSING' ||
          status === 'Submitted' ||
          status === 'REVOCATING'
        "
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99992 0.333313C3.33325 0.333313 0.333252 3.33331 0.333252 6.99998C0.333252 10.6666 3.33325 13.6666 6.99992 13.6666C10.6666 13.6666 13.6666 10.6666 13.6666 6.99998C13.6666 3.33331 10.6666 0.333313 6.99992 0.333313ZM4.79992 7.33331C4.59992 7.86665 4.06659 8.13331 3.53325 7.93331C2.99992 7.73331 2.73325 7.19998 2.93325 6.66665C3.13325 6.13331 3.66659 5.86665 4.19992 6.06665C4.66659 6.19998 4.99992 6.79998 4.79992 7.33331ZM7.93325 7.33331C7.73325 7.86665 7.19992 8.13331 6.66659 7.93331C6.13325 7.73331 5.86658 7.19998 6.06658 6.66665C6.26658 6.13331 6.79992 5.86665 7.33325 6.06665C7.86659 6.19998 8.13325 6.79998 7.93325 7.33331ZM11.1333 7.33331C10.9333 7.86665 10.3999 8.13331 9.86659 7.93331C9.33325 7.73331 9.06659 7.19998 9.26659 6.66665C9.46659 6.13331 9.99992 5.86665 10.5333 6.06665C10.9999 6.19998 11.2666 6.79998 11.1333 7.33331Z"
            fill="#F2C94C"
          />
        </svg>
      </span>
      <span
        class=""
        v-if="
          status === 'COMPLETED' ||
          status === 'active' ||
          status === 'ACTIVE' ||
          status === 'SUCCESS' ||
          status === 'VALIDATED'
        "
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00004 0.333336C3.33337 0.333336 0.333374 3.33334 0.333374 7C0.333374 10.6667 3.33337 13.6667 7.00004 13.6667C10.6667 13.6667 13.6667 10.6667 13.6667 7C13.6667 3.33334 10.6667 0.333336 7.00004 0.333336ZM10.8667 5.26667C9.33337 6.8 7.80004 8.33334 6.20004 9.86667C5.86671 10.2 5.40004 10.2 5.13337 9.86667C4.46671 9.2 3.86671 8.6 3.20004 8C2.46671 7.26667 3.60004 6.2 4.33337 6.86667C4.80004 7.33334 5.26671 7.8 5.66671 8.2C7.00004 6.86667 8.40004 5.53334 9.73337 4.2C10.4667 3.46667 11.5334 4.53334 10.8667 5.26667Z"
            fill="#27AE60"
          />
        </svg>
      </span>
      <span v-if="status === 'FAILED'">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00004 0.333313C3.33337 0.333313 0.333374 3.33331 0.333374 6.99998C0.333374 10.6666 3.33337 13.6666 7.00004 13.6666C10.6667 13.6666 13.6667 10.6666 13.6667 6.99998C13.6667 3.33331 10.6667 0.333313 7.00004 0.333313ZM9.80004 8.79998C10.5334 9.53331 9.40004 10.6 8.66671 9.86665C8.06671 9.26665 7.53337 8.66665 6.93337 8.13331L5.20004 9.86665C4.46671 10.6 3.40004 9.46665 4.06671 8.73331C4.66671 8.13331 5.26671 7.53331 5.86671 6.99998L4.13337 5.26665C3.40004 4.53331 4.53337 3.46665 5.20004 4.19998C5.80004 4.79998 6.40004 5.39998 6.93337 5.93331L8.66671 4.19998C9.40004 3.46665 10.4667 4.59998 9.80004 5.33331C9.26671 5.86665 8.66671 6.39998 8.06671 6.99998L9.80004 8.79998Z"
            fill="#EB5757"
          />
        </svg>
      </span>
      <span
        class="w-[5px] h-[5px] rounded-[50%]"
        :class="{
          'bg-dark-green':
            status === 'COMPLETED' ||
            status === 'active' ||
            status === 'ACTIVE' ||
            status === 'Validated' ||
            status === 'SUCCESS',
          'bg-dark-blue':
            status === 'CREATED' ||
            status === 'INVITED' ||
            status === 'PROCESSING' ||
            status === 'REVOCATING' ||
            status === 'Updated',
          'bg-white':
            status === 'Active' ||
            status === 'enabled' ||
            status === 'disabled' ||
            status === 'Inactive' ||
            status === 'Verified',
          'bg-[#954CF2]': status === 'FINISHED',
          'bg-dark-red': status === 'FAILED',
        }"
      />
      <span
        class="leading-[0.938rem]"
        :class="{
          'text-hightGreen':
            status === 'COMPLETED' ||
            status === 'Validated' ||
            status === 'active' ||
            status === 'ACTIVE' ||
            status === 'VALIDATED' ||
            status === 'SUCCESS',
          'text-blueDark': status === 'CREATED',
          'text-[#656ADC]': status === 'INVITED',
          'text-[#954CF2]': status === 'FINISHED',
          'text-[#B2B2B2]': status === 'EXPIRED',
          'text-hightOrange':
            status === 'REVOCATING' ||
            status === 'REVOCATING' ||
            status === 'PENDING' ||
            status === 'APPROVED' ||
            status === 'PROCESSING' ||
            status === 'processed' ||
            status === 'Updated' ||
            status === 'Submitted',
          'text-red-DangerPrimary':
            status === 'FAILED' ||
            status === 'enabled' ||
            status === 'disabled' ||
            status === 'Inactive' ||
            status === 'Verified',
          'text-dark-red': status === 'FAILED' || status === 'FAILED',
        }"
      >
        {{ value }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["status", "value"],
  setup(props) {
    return {};
  },
};
</script>

<style style="scss" scoped>
.circle {
  width: fit-content;
}

.border-statusOrange {
  border: 1px solid #f2c94c;
}

.placeholder-item {
  width: 14px;
  height: 14px;
}

.status-chip {
  padding: 6px;
  font-weight: 600;
  font-size: 12px;
}
</style>
