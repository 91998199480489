<template>
  <div data-d2d-item="baseinput" class="flex flex-col items-baseline relative">
    <!--input-->
    <div
      class="bg-white border w-full border-gray rounded-lg py-3 px-4 flex flex-shrink flex-nowrap items-center space-x-2"
      :class="{ error: errorMessage, success: hasSuccess }"
    >
      <!---->
      <input
        :placeholder="placeholder"
        class="border-0 outline-none appearance-none flex-shrink w-full bg-transparent"
        type="text"
        ref="input_base"
        :name="name"
        :id="name"
        :value="inputValue"
        @input="on_phone_input($event)"
      />
    </div>
    <!--select option-->

    <!-- errors messages -->

    <div
      v-if="hasError"
      class="rounded-lg w-full bg-lightRed flex flex-row space-x-1 py-1.5 px-2 mt-2 items-center select-none"
    >
      <span class="inline-flex flex-shrink-0"> </span>
      <span class="text-xs text-primaryColor font-semibold">{{
        errorMessage
      }}</span>
    </div>

    <div
      v-if="hasSuccess"
      class="rounded-lg w-full bg-primary-light flex flex-row space-x-1 py-1.5 px-2 mt-2 items-center select-none"
    >
      <span class="inline-flex flexshrink-0"> </span>
      <span class="text-xs text-primary font-medium">{{ successMessage }}</span>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
import {
  computed,
  toRef,
  ref,
  Ref,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
import { useField } from "vee-validate";
import Down from "../svg/downBlack.vue";

export default {
  components: {
    Down,
  },

  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    hasSuccess: {
      default: false,
      type: Boolean,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      required: true,
      default: true,
    },
    defaultCountry: {
      type: String,
      default: "CI",
    },
    arrow: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    const hasError = computed(() => {
      return errorMessage.value !== undefined;
    });

    const open_select = ref(false);
    const default_selected = ref([]);
    const defaultCountry = toRef(props, "defaultCountry");
    const base_phone_arrow = ref(null);
    const phone = ref("");
    const input_base = ref(null);

    const allowed_countries = ref([
      ["Ivory Coast (Côte d’Ivoire)", "ci", "225"],
      ["Benin (Bénin)", "bj", "229"],
    ]);

    const formatPhone = (n) => {
      n = String(n)
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{2})+(?!\d))/g, " ");
      return `${n}`;
    };

    const toggleSelect = () => {
      open_select.value = !open_select.value;
    };

    const findCountry = (cntry) => {
      return allowed_countries.value.find((it) => {
        return it.includes(String(cntry).toLowerCase());
      });
    };

    const emitPhone = () => {
      if (phone.value)
        context.emit(
          "phone",
          `${default_selected.value[2]}${phone.value.replace(/\s/g, "")}`,
        );
      else context.emit("phone", "");
    };

    const choose = (country) => {
      default_selected.value = country;
      open_select.value = false;
      context.emit("country", default_selected.value[1]);
      emitPhone();
    };

    const on_phone_input = (event) => {
      event.target.value = phone.value = formatPhone(event.target.value);
      handleChange(event);
      emitPhone();
    };

    onMounted(() => {
      default_selected.value = findCountry(
        String(inputValue.value).slice(0, 3) ||
          String(defaultCountry.value).toLowerCase() ||
          "ci",
      );
      inputValue.value = phone.value = inputValue.value
        ? formatPhone(inputValue.value.slice(-8))
        : "";
      context.emit("country", default_selected.value[1]);

      document.addEventListener("click", (event) => {
        if (
          base_phone_arrow.value &&
          !base_phone_arrow.value.contains(event.target)
        ) {
          open_select.value = false;
        }
      });
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      allowed_countries,
      hasError,
      toggleSelect,
      open_select,
      choose,
      base_phone_arrow,
      default_selected,
      formatPhone,
      phone,
      input_base,
      on_phone_input,
    };
  },
};
</script>

<style scoped lang="scss">
::placeholder {
  font-weight: bold;
  color: gray;
}

.error {
  background: #feefef;
  border: 1px solid #da1414;
}

.success {
  background: #edf9f0;
  border: 1px solid #287d3c;
}

.error input {
  -webkit-box-shadow: 0 0 0px 1000px #feefef inset !important;
}

.success input {
  -webkit-box-shadow: 0 0 0px 1000px #edf9f0 inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

div [data-d2d-item="base-phone-select-group"] {
  max-height: 150px;
  overflow-y: scroll;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.15));
}
</style>
