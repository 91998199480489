<template>
  <div
    data-pushup-item="baseinput"
    class="preference flex flex-col items-baseline relative"
  >
    <label class="baseinput-label text-base text-black mb-2" for="cheese">
      {{ label }}
    </label>
    <!-- <span v-if="required" class="text-left text-primaryColor">*</span> -->
    <input
      :placeholder="placeholder"
      :class="{ error: hasError, success: hasSuccess }"
      class="border w-full border-btnBorder rounded outline-none py-2 px-4"
      :type="type"
      name="cheese"
      :value="value"
      @input="$emit('input', $event)"
    />
    <div
      v-show="hasError"
      class="rounded-lg w-full bg-danger-light flex flex-row space-x-1 py-1.5 px-2 mt-2 items-center select-none"
    >
      <warning />

      <span class="">{{ errorMessage }}</span>
    </div>

    <div
      v-show="hasSuccess"
      class="rounded-lg w-full bg-primary-light flex flex-row space-x-1 py-1.5 px-2 mt-2 items-center select-none"
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.66667 0C2.98667 0 0 2.98667 0 6.66667C0 10.3467 2.98667 13.3333 6.66667 13.3333C10.3467 13.3333 13.3333 10.3467 13.3333 6.66667C13.3333 2.98667 10.3467 0 6.66667 0ZM6.66667 7.33333C6.3 7.33333 6 7.03333 6 6.66667V4C6 3.63333 6.3 3.33333 6.66667 3.33333C7.03333 3.33333 7.33333 3.63333 7.33333 4V6.66667C7.33333 7.03333 7.03333 7.33333 6.66667 7.33333ZM6 8.66667V10H7.33333V8.66667H6Z"
          fill="#287D3C"
        />
      </svg>

      <span class="text-xs text-primary font-medium">{{ successMessage }}</span>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
import warning from "../svg/warning.vue";
export default {
  components: { warning },
  methods: {
    sendValue($event) {
      this.$emit("input", $event.target.value);
    },
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: "Email",
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    hasSuccess: {
      default: false,
      type: Boolean,
    },
    successMessage: {
      type: String,
      default: "Votre mot de passe est correcte",
    },
    errorMessage: {
      type: String,
      default: "L’email spécifié n’a pas été trouvé",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      default: "text",
      type: String,
    },
  },
};
</script>

<style scoped>
::placeholder {
  font-weight: bold;
  color: gray;
}

.error {
  background: #feefef;
  border: 1px solid #da1414;
  box-shadow: 0 0 0 30px #feefef inset !important;
  -webkit-box-shadow: 0 0 0 30px #feefef inset !important;
}

.success {
  background: #edf9f0;
  border: 1px solid #287d3c;
}

input:-webkit-autofill.error,
/* input:-webkit-autofill:hover,
input:-webkit-autofill:focus,  */
input:-webkit-autofill:active.error {
  box-shadow: 0 0 0 30px #feefef inset !important;
  -webkit-box-shadow: 0 0 0 30px #feefef inset !important;
}
</style>
