<template>
  <div class="">
    <div class="flex justify-center items-center p-6 bg-white">
      <logo />
    </div>
    <div
      class="bg-[#ECF7FE] justify-start items-center flex flex-col w-full h-screen"
    >
      <remove class="mt-[116px]" />
      <div class="mt-[45px] max-w-[500px] text-center">
        <span class="text-2xl" v-html="$t('index.callUs')"> </span>
      </div>
    </div>
    <footer />
  </div>
</template>

<script>
import Logo from "@/components/svg/logo.vue";
import remove from "@/components/svg/remove.vue";
import footer from "./footer.vue";
export default {
  components: { footer, Logo, remove },
};
</script>

<style></style>
