<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0367 41.9333V11.752H27.749V43.504L22.2288 50.8642L18.459 48.0368L23.0367 41.9333ZM32.4613 47.9919V22.523H37.1736V49.5627L31.6535 56.9229L27.8836 54.0955L32.4613 47.9919Z"
      fill="#4661B9"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.5396 46.6626L50.4433 38.3624L53.6454 35.6787L50.3418 33.1211L40.5396 25.5322V34.0458L42.9171 35.8865L40.5396 37.8791V46.6626Z"
      fill="#F11C33"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.8604 45.9888L9.95656 37.6885L6.75447 35.0049L10.0581 32.4473L19.8604 24.8584V33.372L17.4828 35.2127L19.8604 37.2053V45.9888Z"
      fill="#F11C33"
    />
    <path d="M25.0562 3L20.4824 12.088H30.3032L25.0562 3Z" fill="#F11C33" />
    <path
      d="M34.481 13.7715L29.9072 22.8595H39.728L34.481 13.7715Z"
      fill="#F11C33"
    />
  </svg>
</template>
