<template>
  <modal
    :title="type == 'UNIQUE' ? $t('index.goToUnique') : $t('index.goToDouble')"
    @close="$emit('closeModal')"
  >
    <div class="border-b border-[#CCCCCC] py-4">
      <div class="flex items-center space-x-5">
        <span>
          <userBlue v-if="type == 'UNIQUE'" />
          <userYellow v-if="type == 'DOUBLE'" />
        </span>
        <div>
          <span class="block font-bold text-xl">
            {{ type == "UNIQUE" ? $t("index.simple") : $t("index.double") }}
          </span>
          <span class="block">
            {{
              type == "UNIQUE"
                ? $t("index.auth_uniqueB")
                : $t("index.auth_doubleB")
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="py-4">
      <div v-if="data.length">
        <div v-if="selectedValidator != 'NONE'">
          <span class="font-bold" v-if="type == 'UNIQUE'">
            {{ $t("index.howYouWhant") }}
          </span>
        </div>
      </div>
      <div v-if="data.length">
        <div class="" v-if="type == 'DOUBLE'">
          <span class="font-bold">{{
            $t("index.keep", {
              name: data[0].lastname + " " + data[0].firstname,
            })
          }}</span>
          <div class="py-2 flex flex-col gap-3">
            <div class="flex items-center gap-2" @click="selectedElm(data[0])">
              <div
                class="h-[22px] w-[22px] border-2 p-[4px] shrink-0 cursor-pointer border-[#B2B2B2] rounded-full"
                :class="{
                  'border-[#0965F6] border-[6px]  ': selectedValidator != 'NO',
                }"
              ></div>
              <span>Oui</span>
            </div>
            <div
              class="flex items-center gap-2"
              @click="selectedValidator = 'NO'"
            >
              <div
                class="h-[22px] w-[22px] border-2 p-[4px] shrink-0 cursor-pointer border-[#B2B2B2] rounded-full"
                :class="{
                  'border-[#0965F6]  border-[6px] ': selectedValidator == 'NO',
                }"
              ></div>
              <span>Non</span>
            </div>
          </div>
        </div>
        <info status="warning" class="mb-4" v-if="selectedValidator == 'NO'">
          <template #title>
            <span class="font-bold">
              “{{
                data[0].lastname +
                " " +
                data[0].firstname +
                "" +
                " ” " +
                $t("index.must")
              }}
            </span>
          </template>
        </info>
      </div>
      <div
        class="mt-2 flex flex-col space-y-4"
        v-if="type == 'UNIQUE' && data.length"
      >
        <div class="">
          <div class="" v-for="(validator, index) in data" :key="index">
            <div @click="getInfoUser(index)">
              <div
                class="flex space-x-2 items-center cursor-pointer mb-4"
                @click="selectedElm(validator)"
              >
                <div
                  class="h-[22px] w-[22px] border-2 p-[4px] shrink-0 cursor-pointer border-[#B2B2B2] rounded-full"
                  :class="{
                    'border-[#0965F6] border-[6px]  ':
                      validator.id == selectedValidator.id,
                  }"
                ></div>
                <span>{{ validator.lastname ?? "" }}</span>
                <span>{{ validator.firstname ?? "" }}</span>
              </div>
            </div>
          </div>
          <div class="mb-2">
            <div
              class="flex space-x-2 items-center cursor-pointer"
              @click="selectedValidator = 'NONE'"
            >
              <div
                class="h-[22px] w-[22px] border-2 p-[4px] shrink-0 cursor-pointer border-[#B2B2B2] rounded-full"
                :class="{
                  'border-[#0965F6] border-[6px]  ':
                    selectedValidator == 'NONE',
                }"
              ></div>
              <span>
                {{ $t("index.none") }}
              </span>
            </div>
          </div>
          <info status="warning" class="mb-4">
            <template #title>
              <div class=" " v-if="data.length">
                <div v-if="selectedValidator !== 'NONE'">
                  <span class="font-bold" v-if="theIndex == 0"
                    >“{{
                      data.length > 1
                        ? data[1].lastname +
                          " " +
                          data[1].firstname +
                          " " +
                          $t("index.must")
                        : " "
                    }}
                    “
                  </span>
                  <span class="font-bold" v-else-if="theIndex == 1"
                    >“{{
                      data.length > 1
                        ? data[0].lastname +
                          " " +
                          data[0].firstname +
                          " " +
                          $t("index.must")
                        : ""
                    }}
                  </span>
                </div>
                <span v-if="selectedValidator == 'NONE'" class="font-bold">
                  {{
                    type == "UNIQUE"
                      ? $t("index.allVaidator", {
                          nameOne:
                            data.length > 0
                              ? data[0].lastname + " " + data[0].firstname
                              : "",
                          nameTwo:
                            data.length > 1
                              ? data[1].lastname + " " + data[1].firstname
                              : " ",
                        })
                      : $t("index.theVaidator")
                  }}
                </span>
              </div>
            </template>
          </info>
        </div>
      </div>

      <div>
        <span class="font-semibold">
          {{
            type == "UNIQUE"
              ? $t("index.enterPasswordSimple")
              : $t("index.enterPasswordDouble")
          }}
        </span>

        <div>
          <input
            type="password"
            v-model="password"
            :class="{
              'border-3 border-primaryColor': hasError,
              'border-btnBorder': !hasError,
            }"
            class="border outline-none rounded p-2 w-full mt-2"
          />
          <div>
            <!-- <span class="font-bold" v-if="hasError">
              {{ $t("index.passwordError") }}</span
            > -->
          </div>
          <primaryButton
            class="w-full mt-6"
            :label="$t('index.validation')"
            :loading="loader"
            @click="verifyPassword"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import info from "../global/info.vue";
import userBlue from "../svg/userColor.vue";
import userYellow from "../svg/doubleIcon.vue";
import modal from "../global/modal.vue";
import primaryButton from "../global/primaryButton.vue";
import { onMounted, computed, inject } from "@vue/runtime-core";
import { ref, toRef } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    data: {
      type: Array,
      default: [],
    },
    ischecked: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    info,
    userYellow,
    primaryButton,
    modal,
    userBlue,
  },
  setup(props, { emit }) {
    const showNotif = inject("$showNotif");
    const $t = inject("$t");
    const selectedValidator = ref({});
    const none = ref({});
    const data = toRef(props, "data");
    const loader = ref(false);
    const password = ref("");
    const store = useStore();
    const hasError = ref(false);
    const type = toRef(props, "type");
    const userInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    function selectedElm(data) {
      selectedValidator.value = data;
    }
    async function verifyPassword() {
      loader.value = true;
      console.log("eee");
      if (password.value == "") {
        hasError.value = true;
        loader.value = false;
        return;
      }
      hasError.value = false;

      try {
        const verify = await store.dispatch("VERIFY_PASSWORD", {
          email: userInfo.value.email,
          password: password.value,
        });
      } catch (error) {
        loader.value = false;
        password.value = "";
        if (error.response.status === 403) {
          showNotif({
            error: true,
            note: $t("index.passwordError"),
          });
        }
      }
      try {
        const payload = {
          id: userInfo.value.id,
          type: type.value,
          body: {
            keep: selectedValidator.value ? [selectedValidator.value.id] : [],
          },
        };
        await store.dispatch("CHANGE_SETTINGS", payload);
        await store.dispatch("GET_REVOC_VALIDATORS", { id: userInfo.value.id });
        loader.value = false;
        emit("changeMode", {
          type: type.value,
          validator: selectedValidator.value,
        });
      } catch (error) {
        console.log(error);
      }
    }
    const theIndex = ref("");
    function getInfoUser(data) {
      theIndex.value = data;
      if (theIndex.value == 0) {
      }
      console.log(theIndex.value);
    }

    onMounted(() => {
      selectedValidator.value = data.value[0];
    });
    return {
      theIndex,
      getInfoUser,
      selectedElm,
      selectedValidator,
      loader,
      password,
      verifyPassword,
      hasError,
    };
  },
};
</script>

<style></style>
