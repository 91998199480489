<template>
  <div
    style="background: white"
    class="card select-none grid grid-cols-1 gap-4 bg-white md:flex flex-wrap justify-between border-FFFFFF items-center px-4 py-2 rounded-md shadow-md"
  >
    <div class="grid grid-cols-1 md:flex flex-wrap relative items-center">
      <div
        class="md:grid grid-cols-2 items-center md:border-0 border hidden border-btnBorder rounded"
      >
        <div v-if="showStatusFilter">
          <selected :data="status" @change="chooseElm">
            <template #icon>
              <filtre />
            </template>
          </selected>
        </div>
        <div v-if="showDatePicker" class="mr-4">
          <calendar
            @dateSelected="dateSelected"
            @dateFilterDisable="dateFilterDisable"
            mode="range"
          />
        </div>
      </div>
      <div>
        <search class="w-full" @typing:finished="search" />
      </div>
    </div>
    <slot name="download"></slot>
  </div>
</template>

<script>
import filtre from "@/components/svg/filter.vue";
import down from "@/components/svg/downBlack.vue";
import Selected from "@/components/global/selected.vue";
import { ref, inject } from "vue";
import Calendar from "./calendar.vue";
import Search from "./search.vue";
import DownloadReport from "./downloadReport.vue";
export default {
  components: { down, filtre, Selected, Calendar, Search, DownloadReport },
  props: {
    showDatePicker: {
      type: Boolean,
      default: true,
    },
    showStatusFilter: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const $t = inject("$t");
    const dateSelected = (data) => {
      emit("choice", data);
    };
    const chooseElm = (data) => {
      emit("chooseElm", data.value);
    };
    const search = (data) => {
      emit("search", data);
    };
    const dateFilterDisable = () => {
      emit("dateFilterDisable");
    };

    const status = ref([
      { label: $t("index.pending"), value: "CREATED" },
      { label: $t("index.completed"), value: "SUCCESS" },
      { label: $t("index.failed"), value: "FAILED" },
      { label: $t("index.all"), value: "" },
    ]);
    return {
      status,
      dateSelected,
      chooseElm,
      search,
      dateFilterDisable,
    };
  },
};
</script>

<style lang="css">
.card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1200px) {
  div[data-d2d-item="table-filter"] > div {
    margin: 5px;
  }
}
</style>
