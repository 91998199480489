<template>
  <div>
    <div class="md:px-24 p-5 md:pt-10 md:bg-turquoise h-screen">
      <div>
        <div class="md:grid grid-cols-2 text-[17px]">
          <men class="md:flex hidden" />
          <div class="flex flex-col justify-center">
            <div
              class="shadow rounded bg-white whiteCard md:ml-20 md:py-8 md:px-12 px-3 py-5"
            >
              <div v-if="!isValid">
                <div class="mb-4 md:pb-4 pb-8 border-b border-grey">
                  <div class="md:flex justify-center">
                    <span
                      class="text-black font-semibold text-2xl"
                      style="text-align: center"
                    >
                      Bonjour John !
                    </span>
                  </div>
                  <div class="text-black text-xl mt-2">
                    <span
                      >Vous avez une nouvelle demande de validation de versement
                      de masse de Open SI.</span
                    >
                  </div>
                </div>
                <div class="flex flex-col">
                  <div class="md:justify-center mb-3">
                    <span class="font-semibold text-xl"
                      >Détails de l'opération
                    </span>
                  </div>

                  <div class="flex flex-col space-y-6">
                    <div class="flex space-x-2">
                      <div
                        class="w-12 h-12 rounded-full bg-lightRed flex items-center justify-center"
                      >
                        <span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.40414 6.02955C3.40442 7.47667 4.58249 8.65418 6.02907 8.6542L3.40414 6.02955ZM3.40414 6.02955C3.40499 4.5818 4.5827 3.40428 6.02909 3.40428M3.40414 6.02955L6.02909 3.40428M6.02909 3.40428H6.02929M6.02909 3.40428H6.02929M6.02929 3.40428C6.0293 3.40428 6.02931 3.40428 6.02931 3.40428M6.02929 3.40428H6.02931M6.02931 3.40428C7.47595 3.4045 8.65404 4.58209 8.65426 6.02901L6.02931 3.40428ZM15.2816 23.8554H15.2341C14.4909 23.8435 13.7993 23.5521 13.2804 23.0319L13.2802 23.0318L0.962434 10.7155L0.962329 10.7154C0.460168 10.2143 0.169433 9.54099 0.144454 8.81862L0.144231 2.96611C0.145751 1.41206 1.41107 0.147569 2.96431 0.147352L2.96436 0.147352L8.79467 0.144232C9.53902 0.168594 10.2133 0.460226 10.7149 0.964356L10.7152 0.964609L23.0324 13.2809L23.0325 13.2809C23.5625 13.8103 23.8551 14.5199 23.8558 15.2802C23.856 16.0406 23.5644 16.7506 23.0342 17.2813C23.0342 17.2813 23.0342 17.2813 23.0341 17.2813L17.2814 23.033L17.2814 23.033C16.7514 23.5634 16.0415 23.8554 15.2816 23.8554ZM10.015 1.66445C9.70518 1.354 9.29498 1.16916 8.85301 1.13858L8.85018 1.13471L8.77701 1.13475L2.96497 1.13832C2.96494 1.13832 2.96491 1.13832 2.96488 1.13832C1.95636 1.13837 1.13574 1.95872 1.13481 2.96736V2.96749V8.80029H1.13472L1.13489 8.8052C1.15018 9.25371 1.33781 9.69113 1.66303 10.0156L13.9812 22.3328C14.3251 22.6774 14.7875 22.8664 15.282 22.8664C15.7756 22.8664 16.238 22.6776 16.5818 22.3338L22.3346 16.5821L22.3347 16.582C22.6778 16.2381 22.8663 15.7758 22.8663 15.2817V15.2816C22.866 14.7874 22.6768 14.3251 22.3332 13.9817L10.015 1.66445ZM6.17359 4.40068L6.17377 3.89937L6.02954 3.89932L5.88531 3.89927L5.88513 4.40061C5.05118 4.4739 4.39497 5.17669 4.39449 6.02961L4.39449 6.02973C4.39474 6.9308 5.12828 7.66395 6.02931 7.66395C6.93088 7.66395 7.66412 6.93022 7.66437 6.02905V6.02897C7.66414 5.17645 7.00753 4.47433 6.17359 4.40068ZM8.65426 6.02903C8.65425 7.4764 7.47663 8.65397 6.02909 8.6542L8.65426 6.02903Z"
                              fill="#EB5757"
                              stroke="#EB5757"
                              stroke-width="0.288462"
                            />
                          </svg>
                        </span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-greyScale">Motif</span>
                        <span class="font-semibold">Salaire de Mars 2021</span>
                      </div>
                    </div>

                    <div class="flex space-x-2 items-center">
                      <div
                        class="w-12 h-12 rounded-full bg-lightGreen flex items-center justify-center"
                      >
                        <span>
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.8969 9.24042C21.7384 11.4401 20.1067 13.1347 18.3272 13.1347C16.5478 13.1347 14.9133 11.4407 14.7575 9.24042C14.5952 6.95202 16.1838 5.34619 18.3272 5.34619C20.4707 5.34619 22.0592 6.99367 21.8969 9.24042Z"
                              stroke="#27AE60"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M18.3273 16.5962C14.8025 16.5962 11.4129 18.347 10.5637 21.7566C10.4512 22.2077 10.7341 22.6539 11.1976 22.6539H25.4575C25.9211 22.6539 26.2023 22.2077 26.0914 21.7566C25.2423 18.2923 21.8527 16.5962 18.3273 16.5962Z"
                              stroke="#27AE60"
                              stroke-width="1.15385"
                              stroke-miterlimit="10"
                            />
                            <path
                              d="M10.9716 10.2107C10.8451 11.9675 9.52644 13.351 8.10505 13.351C6.68365 13.351 5.36286 11.968 5.23846 10.2107C5.10919 8.38316 6.39267 7.07697 8.10505 7.07697C9.81743 7.07697 11.1009 8.41669 10.9716 10.2107Z"
                              stroke="#27AE60"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.2964 16.7044C10.3201 16.2571 9.24485 16.0851 8.10525 16.0851C5.29275 16.0851 2.58301 17.4832 1.90422 20.2065C1.81498 20.5667 2.04106 20.9231 2.41102 20.9231H8.48385"
                              stroke="#27AE60"
                              stroke-width="1.15385"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-greyScale"
                          >Nombre de bénéficiaires</span
                        >
                        <span class="font-semibold">64</span>
                      </div>
                    </div>
                    <div class="flex space-x-2">
                      <div
                        class="w-12 h-12 rounded-full bg-lightLinkBlue flex items-center justify-center"
                      >
                        <span>
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.75083 18.327L25.2508 18.327C25.7288 18.327 26.1162 17.9395 26.1162 17.4616V5.3462C26.1162 4.86826 25.7288 4.48081 25.2508 4.48081L2.75083 4.48081C2.27289 4.48081 1.88544 4.86826 1.88544 5.3462V17.4616C1.88544 17.9395 2.27289 18.327 2.75083 18.327Z"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.61621 20.9231H24.3854"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.34668 23.5192H22.6544"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14.0008 15.7308C16.3904 15.7308 18.3277 13.7936 18.3277 11.4039C18.3277 9.01419 16.3904 7.07697 14.0008 7.07697C11.6111 7.07697 9.67383 9.01419 9.67383 11.4039C9.67383 13.7936 11.6111 15.7308 14.0008 15.7308Z"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M26.116 8.8077C24.9684 8.8077 23.8678 8.35183 23.0564 7.54037C22.2449 6.72891 21.7891 5.62835 21.7891 4.48077"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.88477 8.8077C2.45299 8.8077 3.01564 8.69578 3.54061 8.47833C4.06557 8.26088 4.54257 7.94216 4.94436 7.54037C5.34615 7.13858 5.66487 6.66158 5.88232 6.13662C6.09977 5.61165 6.21169 5.04899 6.21169 4.48077"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M26.116 14C24.9684 14 23.8678 14.4559 23.0564 15.2673C22.2449 16.0788 21.7891 17.1794 21.7891 18.3269"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1.88477 14C2.45299 14 3.01564 14.1119 3.54061 14.3294C4.06557 14.5468 4.54257 14.8655 4.94436 15.2673C5.34615 15.6691 5.66487 16.1461 5.88232 16.6711C6.09977 17.196 6.21169 17.7587 6.21169 18.3269"
                              stroke="#588FF0"
                              stroke-width="1.15385"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div class="flex flex-col">
                        <span class="text-greyScale">Montant total</span>
                        <span class="font-semibold">14 800 000 F cfa</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-8 mb-2">
                  <span class="font-semibold">Saisir le code PIN</span>
                </div>
                <div class="flex justify-between items-center space-x-6">
                  <input
                    v-model="code"
                    placeholder="Code PIN"
                    @input="inputChange"
                    class="py-1.5 px-2 rounded border-2 focus:outline-none focus:shadow-outline"
                    :class="[
                      isInvalid
                        ? 'border border-primaryColor'
                        : 'border-btnBorder',
                    ]"
                  />

                  <div
                    @click="verifyCode"
                    class="py-2 px-4 rounded bg-primaryColor text-white w-full text-center cursor-pointer"
                  >
                    Valider
                  </div>
                </div>

                <div class="mt-2 flex space-x-1" v-if="isInvalid">
                  <span>
                    <svg
                      width="19"
                      height="17"
                      viewBox="0 0 19 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18.0524 13.2372H18.0526L10.9243 1.01107H10.9241C10.5594 0.385048 9.88952 0 9.16503 0C8.44054 0 7.77065 0.385048 7.40599 1.01107H7.40582L0.295008 13.2071L0.29425 13.2084L0.277509 13.2371H0.27768C-0.0899569 13.8672 -0.0927167 14.6457 0.270444 15.2784C0.633604 15.911 1.30727 16.3012 2.03674 16.3015H16.2933C17.0228 16.3012 17.6964 15.911 18.0595 15.2784C18.4227 14.6458 18.42 13.8673 18.0524 13.2372ZM12.3151 12.9995C12.158 13.1567 11.9449 13.2449 11.7227 13.2449C11.5005 13.2449 11.2874 13.1567 11.1303 12.9995L9.16478 11.033L7.19925 12.9995C6.98813 13.2142 6.67807 13.2991 6.38704 13.2219C6.09602 13.1447 5.86878 12.9173 5.79178 12.6262C5.71479 12.3352 5.79988 12.0251 6.01467 11.8142L7.98015 9.84787L6.08455 7.95134C5.7609 7.62354 5.76252 7.09596 6.08819 6.77015C6.41385 6.44435 6.94143 6.4425 7.26937 6.766L9.16493 8.66254L11.0605 6.766C11.2715 6.55105 11.5817 6.46594 11.8729 6.54307C12.1642 6.62021 12.3915 6.84773 12.4685 7.13898C12.5455 7.43023 12.4602 7.74039 12.2452 7.95134L10.3496 9.84787L12.3151 11.8141C12.6421 12.1415 12.6421 12.672 12.3151 12.9995Z"
                        fill="#EB5757"
                      />
                    </svg>
                  </span>

                  <div class="text-greyScale">Code erroné</div>
                </div>

                <div class="mt-8">
                  <span>Code oublié ?</span>
                  <span class="underline text-lightBlue cursor-pointer">
                    Réinitialiser mon code</span
                  >
                </div>
              </div>

              <div
                v-else
                class="flex flex-col items-center justify-center space-y-4"
              >
                <div
                  class="w-20 h-20 bg-[#3FD578] rounded-full items-center justify-center flex"
                >
                  <svg
                    width="50"
                    height="38"
                    viewBox="0 0 50 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.5 16.5L18.5 31.5L47 3"
                      stroke="white"
                      stroke-width="8"
                    />
                  </svg>
                </div>
                <div class="font-semibold text-xl">Félicitations !</div>
                <div>La validation a été effectuée avec succès</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Men from "@/components/svg/men.vue";

import { onMounted, ref } from "vue";
export default {
  components: {
    Men,
  },
  setup() {
    let isValid = ref(false);
    let isInvalid = ref(false);

    let code = ref();

    const verifyCode = () => {
      console.log("sssssssssss", code.value);
      if (code.value == "2000") isValid.value = true;
      else isInvalid.value = true;
    };

    const inputChange = (value) => {
      if (!/[0-9]/.exec(value.data)) {
        code.value = code.value.slice(0, code.value.length - 1);
      }
      isInvalid.value = false;
    };

    onMounted(() => {});
    return {
      isValid,
      code,
      verifyCode,
      isInvalid,
      inputChange,
    };
  },
};
</script>

<style></style>
