import { createApp } from 'vue';
import Notification from './components/global/notification.vue';

let instance: any;

const Body = document.body;
const event = new Event('pushupnotify');

function showNotif(props: any) {
  instance = createApp(Notification, props);
  Body.dispatchEvent(event);
}

function insertIntoDom() {
  const parent = document.querySelector('.pushup--notification-parent');
  if (parent) parent.remove();

  const div = document.createElement('div');
  div.className = 'pushup--notification-parent';
  instance.mount(div);
  document.body.appendChild(div);
}

Body.addEventListener('pushupnotify', () => {
  insertIntoDom();
});

export { showNotif };
