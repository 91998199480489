<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.4446 50.7632C41.6354 46.0691 48.4444 34.8716 59.3363 34.8716C70.2282 34.8716 77.0127 46.0324 75.2279 50.7632C72.3674 57.9389 46.2318 57.9389 43.4446 50.7632Z"
      fill="#F6DB6F"
    />
    <path
      d="M4.73446 60.2549C2.14563 53.5769 11.8439 37.7007 27.2887 37.7007C42.7334 37.7007 52.4611 53.6063 49.8429 60.2549C45.9204 70.4828 8.65693 70.4828 4.73446 60.2549Z"
      fill="#B7D8F5"
    />
    <path
      d="M42.9017 65.7303L45.1081 63.9456C47.5502 61.9083 49.5144 59.3589 50.8616 56.4781C52.2089 53.5972 52.9061 50.4553 52.904 47.275V44.9216L42.9017 39.9204L32.8994 44.9216V47.275C32.9033 50.4592 33.6082 53.6035 34.964 56.4846C36.3198 59.3657 38.2933 61.913 40.7444 63.9456L42.9507 65.7793"
      fill="#F7A9A8"
    />
    <path
      d="M27.2894 40.3403C24.2886 40.3423 21.3546 39.4542 18.8586 37.7885C16.3626 36.1227 14.4167 33.7541 13.267 30.9823C12.1173 28.2105 11.8154 25.16 12.3997 22.2166C12.9839 19.2733 14.428 16.5693 16.5492 14.4467C18.6703 12.3241 21.3734 10.8784 24.3164 10.2922C27.2594 9.70608 30.3101 10.0059 33.0826 11.1538C35.8552 12.3017 38.225 14.2461 39.8924 16.7411C41.5597 19.236 42.4497 22.1694 42.4497 25.1702C42.4471 29.191 40.8493 33.0466 38.0071 35.8908C35.1648 38.7349 31.3102 40.3351 27.2894 40.3403ZM27.2894 11.971C24.676 11.9691 22.1209 12.7424 19.9472 14.1931C17.7735 15.6437 16.0789 17.7066 15.078 20.1206C14.077 22.5346 13.8146 25.1913 14.3239 27.7545C14.8333 30.3177 16.0915 32.6722 17.9394 34.5201C19.7873 36.368 22.1418 37.6262 24.705 38.1356C27.2682 38.6449 29.9249 38.3825 32.3389 37.3816C34.7529 36.3806 36.8158 34.686 38.2664 32.5123C39.7171 30.3386 40.4904 27.7835 40.4885 25.1702C40.4833 21.6711 39.091 18.3169 36.6168 15.8427C34.1426 13.3685 30.7884 11.9762 27.2894 11.971Z"
      fill="#24315A"
    />
    <path
      d="M27.3583 68.8457C17.0128 68.8457 6.05925 66.3648 3.82344 60.5791C1.76414 55.2544 6.40247 46.1052 13.7571 40.9668C13.9706 40.8343 14.2268 40.7888 14.4728 40.8397C14.7189 40.8906 14.936 41.0339 15.0794 41.2402C15.2228 41.4466 15.2816 41.7 15.2435 41.9484C15.2055 42.1968 15.0736 42.421 14.875 42.575C7.69688 47.5565 4.08821 55.7937 5.6572 59.8241C8.01068 65.8843 24.5929 68.2083 36.7624 66.0902C37.0225 66.046 37.2895 66.1069 37.5046 66.2595C37.7198 66.4122 37.8655 66.644 37.9097 66.9041C37.9539 67.1642 37.893 67.4312 37.7404 67.6463C37.5878 67.8615 37.3559 68.0072 37.0958 68.0514C33.878 68.5888 30.6207 68.8545 27.3583 68.8457Z"
      fill="#24315A"
    />
    <path
      d="M42.9009 66.7076C42.6718 66.708 42.4498 66.6282 42.2733 66.4821L40.0375 64.6385C37.4915 62.5179 35.4441 59.8624 34.0407 56.8607C32.6374 53.8591 31.9126 50.5852 31.918 47.2717V44.9183C31.9185 44.7372 31.9692 44.5598 32.0644 44.4057C32.1596 44.2517 32.2956 44.127 32.4573 44.0455L42.4596 39.0444C42.5965 38.9754 42.7477 38.9395 42.9009 38.9395C43.0542 38.9395 43.2053 38.9754 43.3422 39.0444L53.3445 44.0455C53.5062 44.127 53.6422 44.2517 53.7374 44.4057C53.8326 44.5598 53.8833 44.7372 53.8838 44.9183V47.2717C53.8918 51.7185 52.5852 56.0684 50.1281 59.7746C49.983 59.9871 49.7603 60.134 49.5079 60.1838C49.2555 60.2336 48.9936 60.1821 48.7788 60.0406C48.564 59.8991 48.4133 59.6789 48.3594 59.4273C48.3055 59.1758 48.3525 58.9131 48.4904 58.6959C50.7328 55.3081 51.9266 51.3344 51.9226 47.2717V45.487L42.9009 40.9762L33.8792 45.487V47.2717C33.8748 50.307 34.5394 53.3059 35.8258 56.0551C37.1123 58.8042 38.9889 61.236 41.3221 63.1774L42.9009 64.4914L44.4797 63.1774C44.9014 62.8243 45.3231 62.4419 45.7251 62.0497C45.9111 61.8676 46.1617 61.7669 46.4219 61.7696C46.6822 61.7724 46.9306 61.8784 47.1127 62.0644C47.2947 62.2503 47.3955 62.501 47.3927 62.7612C47.3899 63.0214 47.2839 63.2699 47.098 63.4519C46.6567 63.8834 46.2056 64.2953 45.7349 64.6777L43.5285 66.5213C43.3468 66.6534 43.1253 66.7192 42.9009 66.7076Z"
      fill="#24315A"
    />
    <path
      d="M42.2063 55.2246C42.0772 55.2253 41.9493 55.2006 41.8298 55.1518C41.7104 55.103 41.6017 55.0311 41.5101 54.9402L39.5488 52.979C39.3662 52.7952 39.2637 52.5467 39.2637 52.2876C39.2637 52.0286 39.3662 51.78 39.5488 51.5963C39.64 51.5044 39.7484 51.4314 39.8679 51.3816C39.9874 51.3319 40.1156 51.3062 40.2451 51.3062C40.3745 51.3062 40.5027 51.3319 40.6222 51.3816C40.7417 51.4314 40.8501 51.5044 40.9413 51.5963L42.2455 52.9005L46.6387 48.5073C46.7299 48.4154 46.8383 48.3425 46.9578 48.2927C47.0773 48.2429 47.2055 48.2173 47.3349 48.2173C47.4644 48.2173 47.5926 48.2429 47.7121 48.2927C47.8316 48.3425 47.94 48.4154 48.0312 48.5073C48.2138 48.6911 48.3163 48.9396 48.3163 49.1987C48.3163 49.4578 48.2138 49.7063 48.0312 49.89L42.9418 54.9794C42.8423 55.0681 42.7257 55.1356 42.5993 55.1778C42.4728 55.2199 42.3391 55.2359 42.2063 55.2246Z"
      fill="#24315A"
    />
    <path
      d="M11.5989 52.51C11.3989 52.5095 11.2039 52.4479 11.0399 52.3335C10.9339 52.2592 10.8436 52.1646 10.7743 52.0552C10.705 51.9458 10.6581 51.8237 10.6362 51.6961C10.6143 51.5684 10.6179 51.4377 10.6468 51.3115C10.6757 51.1853 10.7293 51.066 10.8046 50.9606C11.4877 50.0115 12.2312 49.1075 13.0306 48.2541C13.1153 48.1497 13.2205 48.0638 13.3398 48.0017C13.4591 47.9397 13.5898 47.9029 13.7239 47.8935C13.858 47.8841 13.9927 47.9024 14.1194 47.9473C14.2461 47.9921 14.3623 48.0626 14.4607 48.1542C14.559 48.2458 14.6375 48.3567 14.6912 48.48C14.7449 48.6032 14.7727 48.7362 14.7729 48.8706C14.773 49.0051 14.7456 49.1381 14.6921 49.2615C14.6387 49.3848 14.5605 49.4959 14.4623 49.5877C13.7346 50.3785 13.0567 51.2136 12.4324 52.0883C12.3395 52.2224 12.2146 52.3311 12.0691 52.4048C11.9236 52.4784 11.7619 52.5146 11.5989 52.51Z"
      fill="#24315A"
    />
    <path
      d="M9.54217 56.098C9.39713 56.097 9.25377 56.067 9.1205 56.0098C8.99851 55.9545 8.88915 55.8747 8.79916 55.7755C8.70917 55.6763 8.64047 55.5597 8.59728 55.4329C8.5541 55.3061 8.53735 55.1718 8.54807 55.0382C8.55879 54.9047 8.59675 54.7748 8.65961 54.6565C8.7969 54.3525 8.9538 54.0485 9.1205 53.7446C9.17556 53.6219 9.25525 53.5119 9.35462 53.4213C9.45399 53.3308 9.57091 53.2616 9.69814 53.2182C9.82536 53.1747 9.96016 53.1579 10.0942 53.1687C10.2282 53.1796 10.3585 53.2178 10.4771 53.2812C10.5957 53.3445 10.7 53.4315 10.7835 53.5369C10.867 53.6422 10.928 53.7636 10.9626 53.8935C10.9973 54.0234 11.0048 54.1591 10.9848 54.292C10.9648 54.425 10.9177 54.5524 10.8464 54.6663C10.6993 54.9409 10.562 55.2253 10.4345 55.4999C10.3601 55.6756 10.2361 55.8258 10.0775 55.932C9.91904 56.0383 9.73298 56.096 9.54217 56.098Z"
      fill="#24315A"
    />
    <path
      d="M59.3004 37.3225C57.0914 37.308 54.936 36.6397 53.1063 35.4018C51.2766 34.164 49.8544 32.4121 49.019 30.367C48.1837 28.322 47.9726 26.0753 48.4124 23.9104C48.8523 21.7456 49.9233 19.7594 51.4905 18.2024C53.0576 16.6455 55.0507 15.5874 57.2185 15.1618C59.3862 14.7361 61.6314 14.9618 63.6709 15.8105C65.7105 16.6592 67.4531 18.0929 68.6789 19.9307C69.9048 21.7685 70.559 23.9281 70.559 26.1372C70.5574 27.6118 70.2646 29.0716 69.6973 30.4327C69.1301 31.7938 68.2995 33.0295 67.2534 34.0688C66.2073 35.1081 64.9663 35.9305 63.6015 36.4889C62.2366 37.0472 60.775 37.3305 59.3004 37.3225ZM59.3004 17.3968C57.5749 17.4113 55.8923 17.9362 54.4646 18.9053C53.037 19.8745 51.9281 21.2446 51.2778 22.8429C50.6275 24.4412 50.4648 26.1963 50.8103 27.8869C51.1558 29.5775 51.994 31.128 53.2192 32.343C54.4445 33.5581 56.0019 34.3833 57.6954 34.7146C59.3888 35.046 61.1425 34.8687 62.7353 34.205C64.3281 33.5414 65.6889 32.4211 66.6461 30.9854C67.6033 29.5497 68.1141 27.8627 68.1142 26.1372C68.1126 24.9836 67.883 23.8418 67.4386 22.7773C66.9942 21.7128 66.3438 20.7466 65.5247 19.9343C64.7056 19.1221 63.734 18.4797 62.6658 18.0443C61.5977 17.6088 60.4539 17.3887 59.3004 17.3968Z"
      fill="#24315A"
    />
    <path
      d="M47.2459 41.5927C47.0083 41.592 46.7762 41.522 46.5778 41.3915C46.3794 41.2609 46.2233 41.0753 46.1286 40.8575C46.034 40.6396 46.0048 40.3989 46.0447 40.1648C46.0847 39.9306 46.192 39.7132 46.3535 39.5391C47.2955 38.5168 48.3447 37.5988 49.4829 36.8008C49.7504 36.616 50.0803 36.545 50.4001 36.6035C50.7199 36.6619 51.0034 36.845 51.1882 37.1125C51.373 37.38 51.444 37.7099 51.3855 38.0297C51.3271 38.3495 51.144 38.633 50.8765 38.8178C49.8806 39.5068 48.9624 40.302 48.1382 41.1893C48.0253 41.3145 47.8878 41.4149 47.7342 41.4843C47.5806 41.5537 47.4144 41.5906 47.2459 41.5927Z"
      fill="#24315A"
    />
    <path
      d="M59.3853 57.3743C58.1628 57.3743 57.0504 57.3743 55.9013 57.2398C55.7406 57.2275 55.5839 57.1835 55.4403 57.1103C55.2966 57.0372 55.1689 56.9364 55.0643 56.8137C54.9598 56.691 54.8806 56.5488 54.8312 56.3954C54.7818 56.2419 54.7633 56.0802 54.7767 55.9196C54.789 55.7589 54.833 55.6022 54.9062 55.4585C54.9793 55.3149 55.0801 55.1871 55.2028 55.0826C55.3255 54.9781 55.4677 54.8988 55.6211 54.8495C55.7746 54.8001 55.9363 54.7816 56.0969 54.795C64.6539 55.4673 72.8198 53.4014 74.0178 50.3208C75.0202 47.7171 72.502 42.1305 67.7223 38.83C67.5891 38.7383 67.4754 38.621 67.3879 38.4851C67.3003 38.3492 67.2406 38.1972 67.2122 38.0381C67.1838 37.8789 67.1872 37.7157 67.2223 37.5578C67.2574 37.4 67.3234 37.2507 67.4166 37.1186C67.5998 36.8511 67.8818 36.6674 68.2004 36.6078C68.5191 36.5482 68.8483 36.6176 69.1158 36.8007C74.4701 40.468 77.8318 47.2403 76.2915 51.1888C74.6535 55.4184 67.0621 57.3743 59.3853 57.3743Z"
      fill="#24315A"
    />
  </svg>
</template>
