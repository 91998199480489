<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.64407 5.10169L2.01695 0.355932C1.54237 -0.118644 0.830509 -0.118644 0.355932 0.355932C-0.118644 0.830508 -0.118644 1.54237 0.355932 2.01695L4.15254 5.9322L0.355932 9.72881C-0.118644 10.2034 -0.118644 10.9153 0.355932 11.3898C0.59322 11.6271 0.830508 11.7458 1.18644 11.7458C1.42373 11.7458 1.77966 11.6271 2.01695 11.3898L6.64407 6.64407C7.11864 6.28814 7.11864 5.45763 6.64407 5.10169Z"
      fill="#A5BCE0"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarRightIcon",
};
</script>

<style></style>
