<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5999 2.40002H11.9999C8.65934 2.40002 5.71982 4.10834 4.00022 6.69698V2.40002H2.3999V12C2.3999 15.3406 4.1087 18.2806 6.69758 19.9999H2.3999V21.6H11.9999C15.3405 21.6 18.2805 19.8915 19.9998 17.3023V21.6H21.5999V12C21.5999 8.65946 19.8913 5.71994 17.3034 4.00034H21.5999V2.40002ZM19.9998 12C19.9998 16.4179 16.4178 19.9999 11.9999 19.9999C7.5815 19.9999 3.99998 16.4179 3.99998 12C3.99998 7.58162 7.58174 4.0001 11.9999 4.0001C16.4178 4.0001 19.9998 7.58162 19.9998 12Z"
      fill="#4661B9"
    />
    <path
      d="M12.0002 5.59998C8.46522 5.59998 5.6001 8.4651 5.6001 12.0001C5.6001 15.5345 8.46522 18.4001 12.0002 18.4001C15.5347 18.4001 18.4003 15.5345 18.4003 12.0001C18.4003 8.46534 15.5347 5.59998 12.0002 5.59998ZM12.0002 16.8001C9.34914 16.8001 7.20018 14.6509 7.20018 12.0001C7.20018 9.34902 9.34914 7.20006 12.0002 7.20006C14.651 7.20006 16.8002 9.34902 16.8002 12.0001C16.8002 14.6509 14.651 16.8001 12.0002 16.8001Z"
      fill="#F11C33"
    />
    <path
      d="M12.0002 8.80005C10.2326 8.80005 8.80029 10.2326 8.80029 12C8.80029 13.7671 10.2331 15.1999 12.0002 15.1999C13.7673 15.1999 15.2001 13.7671 15.2001 12C15.2001 10.2324 13.7673 8.80005 12.0002 8.80005ZM12.0002 13.6C11.1165 13.6 10.4001 12.8836 10.4001 12C10.4001 11.1163 11.1165 10.3999 12.0002 10.3999C12.8839 10.3999 13.6003 11.1163 13.6003 12C13.6003 12.8836 12.8839 13.6 12.0002 13.6Z"
      fill="#4661B9"
    />
  </svg>
</template>
