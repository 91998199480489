<template>
  <svg
    width="43"
    height="31"
    viewBox="0 0 43 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="cursor-pointer"
  >
    <g clip-path="url(#clip0_1_2)">
      <path
        d="M2 12C2 5.37258 7.37258 0 14 0H30C36.6274 0 42 5.37258 42 12C42 18.6274 36.6274 24 30 24H14C7.37258 24 2 18.6274 2 12Z"
        fill="#F11C33"
      ></path>
      <g filter="url(#filter0_dd_1_2)">
        <path
          d="M39 12C39 7.02944 34.9706 3 30 3C25.0294 3 21 7.02944 21 12C21 16.9706 25.0294 21 30 21C34.9706 21 39 16.9706 39 12Z"
          fill="white"
        ></path>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_1_2"
        x="15"
        y="1"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        ></feColorMatrix>
        <feOffset></feOffset>
        <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        ></feColorMatrix>
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1_2"
        ></feBlend>
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        ></feColorMatrix>
        <feOffset dy="4"></feOffset>
        <feGaussianBlur stdDeviation="3"></feGaussianBlur>
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        ></feColorMatrix>
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1_2"
          result="effect2_dropShadow_1_2"
        ></feBlend>
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1_2"
          result="shape"
        ></feBlend>
      </filter>
      <clipPath id="clip0_1_2">
        <rect width="43" height="31" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>
