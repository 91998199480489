
import Sheet from "@/components/svg/sheet.vue";
import file from "@/components/svg/file.vue";
import { reactive, ref } from "@vue/reactivity";
import { computed, inject, PropType } from "@vue/runtime-core";
export default {
  components: {
    Sheet,
    file,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    acceptedFileFormats: {
      type: Object as PropType<any>,
      required: false,
    },
  },
  setup(props: any, { emit }: { emit: any }) {
    const $t: any = inject("$t");
    const file = ref();

    const state = reactive({
      fileList: [],
      error: null as null | string,
      selectedFile: File,
      acceptedFileType: computed((): string =>
        props.acceptedFileFormats.join(", "),
      ),
    });

    function onChange() {
      const theFile: File = file.value.files["0"];
      if (!props.acceptedFileFormats.includes(theFile.type)) {
        state.error = $t("index.badFileTypeError");
        return;
      }
      state.error = null;
      emit("upload", file.value.files["0"]);
    }
    function dragover(event: any) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-lightGreen");
      }
    }

    function dragleave(event: any) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-lightGreen");
    }

    function dragend(event: any) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-lightGreen");
    }

    function drop(event: any) {
      event.preventDefault();
      file.value.files = event.dataTransfer.files;

      onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    }

    return {
      state,
      file,
      dragover,
      dragleave,
      dragend,
      drop,
      onChange,
    };
  },
  // data() {
  //   return {
  //     filelist: [],
  //   };
  // },
  // methods: {

  // },
};
