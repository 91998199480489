<template>
  <div class="theScroll">
    <!-- {{ selectedBank }} jj -->
    <Head label="index.appros">
      <template #button>
        <primaryButton
          :label="$t('index.addAppro')"
          class="mt-4 w-full md:w-fit"
          @click="showApproModal"
        >
          <template #icon>
            <span>
              <add />
            </span>
          </template>
        </primaryButton>
      </template>
    </Head>
    <!-- <div v-if="!searching && allAppro.length == 0">
      <div class="customEmpty">
        <empty />
        <span class="font-bold"> {{ $t("index.noData") }} </span>
      </div>
    </div> -->

    <div>
      <div class="pt-4 bg-bgprimary bg-white">
        <filterCard
          @choice="choiceDate"
          @chooseElm="chooseElm"
          @search="search"
          @dateFilterDisable="dateFilterDisable"
        >
          <template #download>
            <button
              class="bg-[#4661B9] py-2 px-4 flex space-x-3 text-sm font-semibold rounded text-white"
              @click="downloadSupply"
            >
              <span
                ><svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99994 7.38299C10.2782 7.13807 10.638 7.00441 11.0101 7.00917C11.4012 7.01418 11.7749 7.17179 12.0515 7.44839M9.99994 7.38299L12.0515 7.44839M9.99994 7.38299V6H12.2493C12.4483 6.00033 12.639 6.07951 12.7797 6.22022C12.9204 6.36087 12.9996 6.55153 12.9999 6.75043C12.9999 6.75064 12.9999 6.75086 12.9999 6.75107V13.2489C12.9999 13.2491 12.9999 13.2494 12.9999 13.2496C12.9996 13.4485 12.9204 13.6391 12.7797 13.7798C12.6391 13.9204 12.4484 13.9996 12.2495 14C12.2493 14 12.2491 14 12.2489 14H4.75101C4.7508 14 4.75058 14 4.75037 14C4.55147 13.9996 4.36081 13.9204 4.22016 13.7798C4.07945 13.6391 4.00027 13.4483 3.99994 13.2494V6.75063C4.00027 6.55166 4.07945 6.36092 4.22016 6.22022C4.36086 6.07951 4.5516 6.00033 4.75057 6H6.99994V7.38299C6.72164 7.13807 6.36183 7.00441 5.98979 7.00917C5.59864 7.01418 5.22493 7.17179 4.94833 7.44839L5.63263 8.13269L4.94833 7.44839C4.67173 7.72499 4.51412 8.0987 4.50911 8.48985L4.50911 8.48985C4.50411 8.88099 4.65209 9.25862 4.92152 9.54221L4.93034 9.55149L4.93939 9.56054L7.43939 12.0605L7.43963 12.0608C7.7209 12.3419 8.10228 12.4998 8.49994 12.4998C8.89759 12.4998 9.27898 12.3419 9.56025 12.0608L9.56048 12.0605L12.0605 9.56054L12.0695 9.55149L12.0784 9.54221C12.3478 9.25862 12.4958 8.88099 12.4908 8.48985C12.4858 8.0987 12.3281 7.72499 12.0515 7.44839M9.99994 7.38299L11.3444 8.1555L12.0515 7.44839"
                    fill="white"
                    stroke="white"
                    stroke-width="2"
                  />
                  <mask id="path-2-inside-1_6644_63571" fill="white">
                    <path
                      d="M8.99994 1.5C8.99994 1.36739 8.94726 1.24021 8.85349 1.14645C8.75972 1.05268 8.63255 1 8.49994 1C8.36733 1 8.24015 1.05268 8.14639 1.14645C8.05262 1.24021 7.99994 1.36739 7.99994 1.5V5H8.99994V1.5Z"
                    />
                  </mask>
                  <path
                    d="M8.99994 1.5C8.99994 1.36739 8.94726 1.24021 8.85349 1.14645C8.75972 1.05268 8.63255 1 8.49994 1C8.36733 1 8.24015 1.05268 8.14639 1.14645C8.05262 1.24021 7.99994 1.36739 7.99994 1.5V5H8.99994V1.5Z"
                    fill="black"
                  />
                  <path
                    d="M8.49994 1V-1V1ZM7.99994 5H5.99994V7H7.99994V5ZM8.99994 5V7H10.9999V5H8.99994ZM10.9999 1.5C10.9999 0.836963 10.7365 0.201077 10.2677 -0.267767L7.43928 2.56066C7.15797 2.27935 6.99994 1.89782 6.99994 1.5H10.9999ZM10.2677 -0.267767C9.79886 -0.736609 9.16298 -1 8.49994 -1V3C8.10212 3 7.72058 2.84197 7.43928 2.56066L10.2677 -0.267767ZM8.49994 -1C7.8369 -1 7.20101 -0.736608 6.73217 -0.267767L9.5606 2.56066C9.2793 2.84196 8.89776 3 8.49994 3V-1ZM6.73217 -0.267767C6.26333 0.201074 5.99994 0.836958 5.99994 1.5H9.99994C9.99994 1.89783 9.8419 2.27936 9.5606 2.56066L6.73217 -0.267767ZM5.99994 1.5V5H9.99994V1.5H5.99994ZM7.99994 7H8.99994V3H7.99994V7ZM10.9999 5V1.5H6.99994V5H10.9999Z"
                    fill="white"
                    mask="url(#path-2-inside-1_6644_63571)"
                  />
                </svg>
              </span>
              <span>{{ $t("index.download") }}</span>
            </button>
          </template>
        </filterCard>
      </div>
      <div class="py-2">
        <data-table
          :columns="columns"
          :data="allAppro"
          :count="false"
          :perPage="perPage"
          :loading="loader"
          :totalData="totalAppro"
          @onMouseleave="mouseleave"
          @onMouseover="mouseover"
          @paginate="paginatorPage"
          @paginateChoose="paginateChoice"
        >
          <template #mobile>
            <div class="w-full md:hidden">
              <div>
                <div v-for="(row, index) in allAppro" :key="index">
                  <div
                    class="p-2 border-b grid grid-cols-6 items-center border-[rgba(0,0,0,0.15)]"
                  >
                    <div class="col-span-3">
                      <div class="flex space-x-2 items-center">
                        <div class="flex">
                          <moov v-if="row.commonName == 'moov-benin'" />
                          <mtn v-if="row.commonName == 'mtn-benin'" />
                          <ecobank v-if="row.source.name == 'Ecobank'" />
                          <uba v-if="row.source.name == 'UBA'" />
                          <mastercard v-if="row.method == 'CARD'" />
                          <visa v-if="row.method == 't-money'" />
                        </div>
                        <div>
                          <span class="block text-sm font-semibold uppercase">
                            {{
                              row.phoneNumber
                                ? formatPhone(row.phoneNumber)
                                : row.source.name
                            }}
                          </span>
                          <span class="block text-sm">
                            {{ formatPrice(row.amount, false) }} F CFA
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-start col-span-2">
                      <status
                        :status="row.status"
                        :value="formatStatus(row.status)"
                      />
                    </div>
                    <div class="flex justify-end">
                      <top
                        v-if="display && showDetail.id == row.id"
                        @click="toggleDisplay"
                      />
                      <Down @click="clickMe(row)" v-else />
                    </div>
                  </div>
                  <div
                    class="p-2 text-sm border-b border-[rgba(0,0,0,0.15)]"
                    v-if="showDetail.id == row.id && display"
                  >
                    <div class="flex justify-between">
                      <span> {{ $t("index.montantAppro") }} : </span>
                      <span>
                        {{ row.amount }}
                      </span>
                    </div>
                    <div class="flex justify-between">
                      <span> {{ $t("index.referenceAppro") }} : </span>
                      <span> {{ row.reference }} </span>
                    </div>
                    <div
                      class="flex justify-between"
                      v-if="row.method === 'BANK'"
                    >
                      <span> {{ $t("index.kkiapayAccount") }} : </span>
                      <span class="truncate">{{
                        row.source.accountNumber
                      }}</span>
                    </div>
                    <div class="flex justify-between">
                      <span>Date :</span>
                      <span> {{ formatDate(row.createdAt) }} </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #account="{ row }">
            {{ row.source.accountNumber }}
          </template>
          <template #initiatorName="{ row }">
            <span class="block text-sm">
              {{ row.name ? row.name : "---" }}</span
            >
          </template>
          <!-- <template #customEmpty>
            <div class="customEmpty w-full">
              <empty class="mb-4" />
              <span class="font-bold"> {{ $t("index.noData") }} </span>
              <primaryButton
                :label="$t('index.addAppro')"
                class="mt-4"
                @click="showApproModal"
              />
            </div>
          </template> -->

          <template #card>
            <table-tld-side
              @selected="changeMethodPayment"
              :activeTabIndex="activePaymentMethodIndex"
              :tabs="[
                {
                  name: 'all',
                  libelle: $t('index.libOne'),
                },
                {
                  name: 'bank',
                  libelle: $t('index.libTwo'),
                },
              ]"
            >
            </table-tld-side>
          </template>
          <template #method="{ row }">
            <div class="flex space-x-2 items-center">
              <div class="flex">
                <moov v-if="row.commonName == 'moov-benin'" />
                <mtn v-if="row.commonName == 'mtn-benin'" />
                <ecobank v-if="row.source.name == 'Ecobank'" />
                <uba v-if="row.source.name == 'UBA'" />
                <mastercard v-if="row.method == 'CARD'" />
                <visa v-if="row.method == 't-money'" />
              </div>
              <div>
                <span class="block font-semibold text-sm">
                  {{ row.phoneNumber ? formatPhone(row.phoneNumber) : "" }}
                </span>
                <span class="block text-sm">
                  {{ formatMoyen(row.method) }}
                </span>
              </div>
            </div>
          </template>

          <template #amount="{ row }">
            <span> {{ formatPrice(row.amount) }} </span>
          </template>
          <template #date="{ row }">
            <span> {{ formatDate(row.createdAt) }} </span>
          </template>
          <template #status="{ row }">
            <status :status="row.status" :value="formatStatus(row.status)" />
          </template>
          <!-- <template #details="{ row }" >
            <button
              :class="{ show: hoverElm == row.id }"
              @click="goToDetails(row)"
              class="border truncate border-blueDark flex space-x-2 items-center p-2 rounded"
            >
              <eyesBlue class="cursor-pointer" />
              <span class="text-blueDark text-xs font-normal">{{
                $t("index.details")
              }}</span>
            </button> -->
          <template #details="{ row }">
            <button
              class="border truncate border-blueDark flex space-x-2 items-center p-2 rounded"
              :class="{ show: hoverElm == row.id }"
            >
              <span><eyesBlue class="cursor-pointer" /></span>
              <span class="text-blueDark text-xs font-normal">{{
                $t("index.details")
              }}</span>
            </button>
          </template>
        </data-table>
        <!-- {{ api_key }} -->
      </div>
    </div>

    <modal @close="closeModal" :title="$t('index.addAppro')" v-if="approModal">
      <Form @submit="onFormSubmit()">
        <Switch
          :elements="[
            { key: 'momo', label: $t('index.byMomo') },
            { key: 'bank', label: $t('index.byBank') },
          ]"
          @change="methodPayment = $event.key"
          :default="methodPayment"
        />

        <div class="mt-4" v-if="methodPayment == 'bank'">
          <span class="font-semibold text-default">
            {{ $t("index.bankName") }}
          </span>
          <select-tld class="mt-2" :data="bank" @changed="onBankSelected" />
        </div>

        <div class="mt-4">
          <span class="font-semibold text-default">
            {{ $t("index.amount") }}
          </span>
          <Field
            :class="{
              'border-2 border-primaryColor': errors.amount != null,
              'border-btnBorder': !errors.amount,
            }"
            class="border w-full rounded outline-none py-2 px-4 mt-2"
            v-model="amount"
            name="amount"
            autocomplete="off"
            @input="onAmountInput"
          />
          <div v-if="errors.amount" class="mt-2">
            <div class="flex">
              <warning /> <span>{{ errors.amount }}</span>
            </div>
          </div>
        </div>
        <primaryButton
          class="w-full mt-[24px]"
          type="submit"
          :label="$t('index.validation')"
          :loading="isLoading"
        />
      </Form>
    </modal>
    <modal
      @close="closeNextModal"
      :back="true"
      :closeIcon="false"
      :title="$t('index.how')"
      v-if="nextModal"
    >
      <info :reference="reference" :accountNumber="accountNumber" />
      <primary-button
        class="w-full"
        @click="closeNextModal"
        :label="$t('index.okay')"
      />
    </modal>
  </div>
</template>

<script>
import Warning from "@/components/svg/warning.vue";
import eyesBlue from "@/components/svg/eyesBlue.vue";

import { ref, onMounted, inject, watch, computed } from "vue";
import TableTldSide from "@/components/tables/table-tld-side.vue";
import moov from "@/components/svg/moov.vue";
import uba from "@/components/svg/uba.vue";
import ecobank from "@/components/svg/ecobank.vue";
import mtn from "@/components/svg/mtn.vue";
import editSvg from "@/components/svg/edit.vue";
import deleteSvg from "@/components/svg/delete.vue";
import visa from "@/components/svg/visa.vue";
import mastercard from "@/components/svg/mastercard.vue";
import Status from "@/components/global/status.vue";
import filterCard from "@/components/global/filterCard.vue";
import primaryButton from "@/components/global/primaryButton.vue";
import add from "@/components/svg/add.vue";
import empty from "@/components/global/empty.vue";
import Modal from "@/components/global/modal.vue";
import Switch from "@/components/global/switch.vue";
import selectTld from "@/components/global/select-tld.vue";
import customInput from "@/components/global/customInput.vue";
import info from "./info.vue";
import DataTable from "@/components/tables/DataTable";
import { useStore } from "vuex";
import { Field, Form } from "vee-validate";
import { $locale } from "@/main";
import Head from "@/components/global/head.vue";
import Down from "@/components/svg/downBlack.vue";
import top from "@/components/svg/top.vue";
const amountFieldsRules = {
  required: true,
  min_value: "",
};

import {
  openKkiapayWidget,
  addSuccessListener,
} from "@kkiapay-org/pushup-widget";
import { BACKEND } from "@/api";
import store from "@/store";
import {
  ActionTypes,
  MAXIMUM_AMOUNT_TO_PROVIDE,
  MINIMUM_AMOUNT_TO_PROVIDE,
} from "@/store/pushup/store.types";
import { WorkflowWsEvents } from "@/socket";
import { useRouter } from "vue-router";
export default {
  components: {
    ecobank,
    top,
    Head,
    uba,
    add,
    Warning,
    Form,
    Field,
    editSvg,
    deleteSvg,
    info,
    selectTld,
    customInput,
    empty,
    eyesBlue,
    primaryButton,
    filterCard,
    visa,
    mtn,
    mastercard,
    moov,
    TableTldSide,
    Status,
    Modal,
    Switch,
    DataTable,
    Down,
  },

  setup() {
    const methodPayment = ref("momo");
    const amount = ref();
    const approModal = ref(false);
    const store = useStore();
    const showNotif = inject("$showNotif");
    const $t = inject("$t");
    const router = useRouter();
    const PushUpIo = inject("$PushUpIo");

    const columns = computed(() => {
      if (activePaymentMethodIndex.value == 0) {
        return {
          method:
            activePaymentMethodIndex.value == 0
              ? $t("index.moyen")
              : $t("index.moyenBanque"),
          // initiatorName: $t("index.initiator_label"),
          amount: $t("index.montantAppro"),
          date: $t("index.paymentDate"),
          // confirmDate:$t("index.confirmDate"),
          reference: $t("index.referenceAppro"),
          status: "Statut",

          // details: "",
        };
      } else {
        return {
          method:
            activePaymentMethodIndex.value == 0
              ? $t("index.moyen")
              : $t("index.moyenBanque"),
          amount: $t("index.montantAppro"),
          // configureDate:$t("index.configureDate"),
          // tranfertDate:$t("index.tranfertDate"),
          date: $t("index.paymentDate"),
          // account: $t("index.kkiapayAccount"),

          reference: $t("index.referenceAppro"),
          status: "Statut",
          // details: "",
        };
      }
    });
    const hoverElm = ref("");
    const mouseover = (data) => {
      hoverElm.value = data.id;
    };
    const mouseleave = (data) => {
      hoverElm.value = "";
    };

    const errors = ref({});
    const activePaymentMethodIndex = ref(0);

    const nextModal = ref(false);
    const nextStep = () => {
      approModal.value = false;
      nextModal.value = true;
    };
    const closeModal = () => {
      approModal.value = false;
      methodPayment.value = "momo";
      errors.value.amount = null;
      console.log(errors.value, "errors");
    };
    const closeNextModal = () => {
      changeMethodPayment("bank");
      nextModal.value = false;
    };
    const showApproModal = () => {
      approModal.value = true;
    };
    const formatMoyen = (key) => {
      const obj = {
        MOBILE_MONEY: "Mobile Money",
        // BANK: "Virement bancaire",
        CARD: "Carte bancaire",
      };

      return obj[key];
    };
    const showDetail = ref({});
    const display = ref(false);
    const formatDate = (data) => {
      const date = new Date(data);
      return date
        .toLocaleString($locale, {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", $t("index.at"));
    };
    function toggleDisplay() {
      display.value = !display.value;
    }
    function clickMe(data) {
      showDetail.value = data;
      display.value = true;
    }
    const formatStatus = (key) => {
      const obj = {
        CREATED: $t("index.waitingValidate"),
        PENDING: $t("index.waitingValidate"),
        SUCCESS: $t("index.completed"),
        FAILED: $t("index.failed"),
      };

      return obj[key];
    };

    const formatPrice = (n, currency = true) => {
      const curr = currency ? " " + "F cfa" : "";
      n = String(n)
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return `${n}${curr}`;
    };
    const table = ref([]);

    const successMessage = async () => {
      errors.value.amount = null;
      await getFundings(paginate.value);
      showNotif({
        success: true,
        note: $t("index.goodAppro"),
      });
    };

    const amountNumberInput = ref(null);
    let amountNumberVal = 0;

    const reference = computed(() => {
      return store.getters["getReference"];
    });

    watch(!approModal, () => {
      errors.value.amount = null;
    });
    watch(
      () => amount.value,
      (val) => {
        if (!val) {
          errors.value.amount = $t("index.invalidValue");
          return;
        }

        const intVal = parseInt(val.replace(" ", ""));
        console.log(amount.value, "ff");
        if (amount.value < MINIMUM_AMOUNT_TO_PROVIDE) {
          errors.value.amount = $t("index.invalidValue");
        } else {
          // amountNumberVal = intVal;
          errors.value.amount = null;
        }
      },
    );

    let adddedListenerToNumberInput = false;

    watch(
      () => amountNumberInput.value,
      (val) => {
        if (adddedListenerToNumberInput) return;
        amountNumberInput.value.$el.addEventListener(
          "keydown",
          function (event) {
            const letter = event.key;
            if (letter === "e" || letter === "E") event.preventDefault();
          },
        );
        adddedListenerToNumberInput = true;
      },
    );

    const isLoading = ref(false);
    const gotoCreatevue = () => {
      router.push({ name: "create" });
    };
    async function onFormSubmit() {
      if (amount.value < MINIMUM_AMOUNT_TO_PROVIDE) {
        errors.value.amount = $t("index.invalidValue");
        return;
      }
      if (methodPayment.value === "momo") {
        const data = {
          id: store.state.businessId,
          body: {
            method: "MOBILE_MONEY",
            amount: Number(amount.value),
          },
        };
        errors.value.amount = "";
        console.log(data);
        await store.dispatch(ActionTypes.INIT, data);
        approModal.value = false;

        openKkiapayWidget({
          amount: parseInt(amount.value.replace(" ", "")),
          key: api_key.value,
          partnerId: reference.value,
          theme: "primary",
          sandbox: process.env.VUE_APP_KKIAPAY_ENV === "live" ? false : true,
          paymentMethods: ["momo"],
        });
        changeMethodPayment();
        errors.value.amount = null;
      }
      if (methodPayment.value === "bank") {
        console.log("bank", selectedBank.value);
        const data = {
          id: store.state.businessId,
          body: {
            method: "BANK",
            supportedFundingSourceId: selectedBank.value.value,
            amount: Number(amount.value),
          },
        };
        try {
          await store.dispatch(ActionTypes.INIT, data);
          approModal.value = false;
          nextModal.value = true;
          console.log(data);
          // await getFundings(paginate.value);
        } catch (error) {
          showNotif({
            error: true,
            note: $t("index.errorMessage"),
          });
          approModal.value = false;
        }
        errors.value.amount = null;
      }

      isLoading.value = false;

      amount.value = "";
      amountNumberVal = 0;
    }
    const accountNumber = computed(() => {
      return store.getters["getAccountNumber"];
    });
    const funding = computed(() => {
      return store.getters["getFunding"];
    });
    console.log(funding.value);
    const bank = computed(() =>
      Array(funding.value.find((d) => d.label !== "UBA")),
    );
    const perPage = ref(10);
    const loader = ref(false);
    const paginator = ref({});
    const allAppro = computed(() => {
      return store.getters["getAllAppro"];
    });
    const selectedBank = ref({});

    const paginate = ref({
      limit: perPage.value,
      page: paginator.value.page,
    });
    const totalAppro = computed(() => {
      return store.getters["getTotalAppro"];
    });
    const searched = ref({});
    const statusFilter = ref({});
    const typeFilter = ref({ type: ["MOBILE_MONEY", "CARD"] });

    const changeMethodPayment = async (event) => {
      const obj = { type: ["MOBILE_MONEY", "CARD"] };
      activePaymentMethodIndex.value = 0;

      if (event == "bank") {
        activePaymentMethodIndex.value = 1;
        obj.type = "BANK";
      }
      console.log("typefilyter", obj);

      typeFilter.value = obj;
      await getFundings(paginate.value);
    };
    const selectedDate = ref();
    const searching = ref(false);
    const getFundings = async (add) => {
      console.log("getFunding", "entrance");
      loader.value = true;
      await store.dispatch("APPRO_TRANSACTION", {
        params: {
          ...add,
          ...searched.value,
          ...statusFilter.value,
          ...typeFilter.value,
          date: selectedDate.value,
        },
        id: store.state.businessId,
      });
      loader.value = false;
    };

    function onBankSelected(data) {
      console.log("data:", data);
      selectedBank.value = data;
    }
    async function dateFilterDisable() {
      selectedDate.value = undefined;
      await getFundings(paginate.value);
    }

    function formatPhone(rawNumber) {
      if (rawNumber) {
        const phoneNumberLength = rawNumber.length;
        return `(${rawNumber.substr(0, 3)})${rawNumber.substr(
          3,
          phoneNumberLength,
        )}`;
      }
      return "";
    }

    async function selected(data) {
      console.log("ff");
      await getFundings(paginate.value);
      searching.value = false;
    }
    async function paginateChoice(data) {
      perPage.value = data;
      // await getFundings(paginate.value);
    }
    async function paginateChoose(data) {
      perPage.value = data;
      const cos = {
        limit: perPage.value,
        page: paginator.value.page,
      };
      console.log(cos);
      // await getFundings(cos);
    }
    async function paginatorPage(data) {
      paginate.value = {
        limit: perPage.value,
        page: data.page,
      };
      console.log(data, paginate.value, "paginator");
      await getFundings(paginate.value);
    }

    async function choiceDate(data) {
      console.log("---------", typeof data, data);
      selectedDate.value = data.toISOString();
      await getFundings(paginate.value);
      searching.value = false;
    }

    async function chooseElm(data) {
      console.log(data);
      if (data == "all") statusFilter.value = {};
      else statusFilter.value = { status: data };
      await getFundings(paginate.value);
      searching.value = false;
    }

    async function search(data) {
      if (!data.replace(/\s/g, "").length) searched.value = {};
      else searched.value = { query: data };

      await getFundings(paginate.value);
      searching.value = false;
    }

    function onAmountInput(event) {
      event.preventDefault();

      // amount.value = amount.value
      //   .replace(/\D/g, "")
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      // console.log(amount.value, "zzz");
    }
    function goToDetails(row) {
      console.log(row);
      router.push(`/detail/${row.id}`);
    }
    watch(perPage, async (val) => {
      perPage.value = val;
      paginate.value = {
        limit: perPage.value,
        page: paginator.value.page,
      };
      await getFundings(paginate.value);
    });

    watch(paginator, async (val) => {
      paginator.value = {
        limit: perPage.value,
        page: val.page,
      };

      searching.value = false;
    });
    watch(methodPayment, () => {
      errors.value.amount = null;
    });

    const me = computed(() => {
      return store.getters["getMeInfo"];
    });
    async function downloadSupply() {
      await store.dispatch("DOWNLOAD_SUPPLY_TRANSACTION", me.value.id);
    }
    const api_key = ref("");
    onMounted(async () => {
      // if (!me.value.permissions.includes("HAS_ACCESS")) {
      //   router.push("/removeAccess");
      // }
      methodPayment.value = "momo";
      api_key.value = me.value.publicApiKey;
      addSuccessListener(successMessage);
      await store.dispatch(ActionTypes.SUPPORTED_FUNDING_SOURCE);
      await getFundings(paginate.value);
      searching.value = true;

      selectedBank.value = funding.value[1];

      const accountInfo = store.getters.getMeInfo;
      console.log("accountInfo", accountInfo.id);
      if (accountInfo) {
        console.log("++++++accountInfo:", accountInfo);

        if (PushUpIo?.isSocketConnected) {
          PushUpIo?.joinRoom(accountInfo.id);
        } else {
          PushUpIo?.on("connect", () => {
            PushUpIo?.joinRoom(accountInfo.id);
          });
        }

        PushUpIo.on(WorkflowWsEvents.PAYEMENT_STATUS_CHANGED, async (data) => {
          await getFundings(paginate.value);
        });
      }
    });

    return {
      columns,
      table,
      formatMoyen,
      funding,
      formatStatus,
      methodPayment,
      nextStep,
      nextModal,
      approModal,
      showApproModal,
      closeNextModal,
      closeModal,
      amount,
      mouseover,
      bank,
      mouseleave,
      hoverElm,
      amountFieldsRules,
      onFormSubmit,
      errors,
      onBankSelected,
      selected,
      formatDate,
      perPage,
      paginatorPage,
      paginateChoose,
      loader,
      choiceDate,
      chooseElm,
      search,
      allAppro,
      toggleDisplay,
      amountNumberInput,
      adddedListenerToNumberInput,
      changeMethodPayment,
      totalAppro,
      reference,
      goToDetails,
      accountNumber,
      formatPhone,
      gotoCreatevue,
      formatPrice,
      onAmountInput,
      activePaymentMethodIndex,
      isLoading,
      searching,
      dateFilterDisable,
      api_key,
      clickMe,
      display,
      showDetail,
      paginateChoice,
      downloadSupply,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-empty-case) {
  margin-top: 0;
  margin-bottom: 0;
}

.hide {
  visibility: hidden;
}
.show {
  visibility: visible;
}

.customEmpty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background-color: white;
  height: 420px;
  text-align: center;
}
// .theScroll {
//   padding-bottom: 20% !important;
// }
// @media screen and (max-width: 800px) {
//   .theScroll {
//     padding-bottom: 100% !important;
//   }
// }
</style>
