<template>
  <section
    class="fixed top-0 left-0 right-0 bottom-0 z-[999] bg-white md:bg-[#000000] md:bg-opacity-50"
    style="display: flex; align-items: center; justify-content: center"
  >
    <div
      class="fixed top-0 md:relative bg-white rounded md:rounded-2xl md:w-[1096px] md:m-4 py-4 overflow-y-scroll"
      style="overflow-y: scroll"
    >
      <div class="flex justify-end items-center px-4">
        <span class="cursor-pointer" @click="$emit('close')">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.21967 6.21973C6.51256 5.92684 6.98744 5.92684 7.28033 6.21973L17.7803 16.7197C18.0732 17.0126 18.0732 17.4875 17.7803 17.7804C17.4874 18.0733 17.0126 18.0733 16.7197 17.7804L6.21967 7.28039C5.92678 6.9875 5.92678 6.51262 6.21967 6.21973Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7803 6.21973C18.0732 6.51262 18.0732 6.9875 17.7803 7.28039L7.28033 17.7804C6.98744 18.0733 6.51256 18.0733 6.21967 17.7804C5.92678 17.4875 5.92678 17.0126 6.21967 16.7197L16.7197 6.21973C17.0126 5.92684 17.4874 5.92684 17.7803 6.21973Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
      <div class="flex flex-col justify-center p-4 md:p-0">
        <div class="head mt-[37px]">
          <div class="flex justify-center text-center">
            <!-- {{ type }}hhh -->

            <span class="font-bold md:text-3xl text-base text-black">
              {{
                type == "transactions"
                  ? $t("index.choice")
                  : "Activez un forfait “Essentiel” pour obtenir plusieurs de fiche paie"
              }}
            </span>
          </div>
          <div class="flex justify-center text-center">
            <span class="md:text-xl text-sm text-primaryColor">
              {{
                type == "transactions"
                  ? $t("index.merci")
                  : "Ou sélectionnez l’un des autres forfaits recommandés"
              }}
            </span>
          </div>
        </div>
        <div class="mt-[20px]">
          <div >
            <div v-if="type == 'transactions'" class="gridResponsive gap-[24px] md:p-[24px]">
              <card
                :ischecked="true ? data.id == selectedElm.id : false"
                @click="clickMe(data)"
                v-for="data in plans"
                :day="30"
                :key="data"
                :price="data.price"
                :total="data.endCount"
                :type="formatTag(data.tag)"
              >
                <template #icon>
                  <div v-if="data.tag === 'STANDARD'">
                    <standart class="md:flex hidden" />
                    <standartMobile class="md:hidden" />
                  </div>
                  <div v-if="data.tag === 'ELITE'">
                    <elite class="md:flex hidden" />
                    <eliteMobile class="md:hidden" />
                  </div>
                  <div v-if="data.tag == 'ESENTIAL'">
                    <essentiel class="md:flex hidden" />
                    <essentielMobile class="md:hidden" />
                  </div>
                  <div v-if="data.tag === 'PREMIUM'">
                    <premium class="md:flex hidden" />
                    <premiumMobile class="md:hidden" />
                  </div>
                </template>
              </card>
            </div>

            <card
              v-else
              :ischecked="true ? data.id == selectedElm.id : false"
              @click="clickMe(data)"
              v-for="data in paySilpPlans"
              :day="30"
              :key="data"
              :price="data.price"
              :total="data.endCount"
              :type="formatTag(data.tag)"
            >
              <template #icon>
                <div v-if="data.tag === 'STANDARD'">
                  <standart class="md:flex hidden" />
                  <standartMobile class="md:hidden" />
                </div>
                <div v-if="data.tag === 'ELITE'">
                  <elite class="md:flex hidden" />
                  <eliteMobile class="md:hidden" />
                </div>
                <div v-if="data.tag == 'ESENTIAL'">
                  <essentiel class="md:flex hidden" />
                  <essentielMobile class="md:hidden" />
                </div>
                <div v-if="data.tag === 'PREMIUM'">
                  <premium class="md:flex hidden" />
                  <premiumMobile class="md:hidden" />
                </div>
              </template>
            </card>
          </div>
        </div>
        <div
          style="box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15)"
          class="fixed bottom-0 md:relative flex justify-between md:px-10 md:p-[24px] w-full items-center bg-white p-6 left-0"
        >
          <group class="md:flex hidden" />
          <groupMobile class="md:hidden" />
          <button
            class="bg-primaryColor text-white font-semibold h-fit p-3 md:px-6 md:py-3 rounded"
            @click="pay()"
          >
            {{ $t("index.continu") }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import card from "@/components/global/pricingCard.vue";
import {
  openKkiapayWidget,
  addSuccessListener,
  removeKkiapayListener,
} from "@kkiapay-org/pushup-widget";
import standart from "@/components/svg/standart.vue";
import standartMobile from "@/components/svg/standartMobile.vue";
import elite from "@/components/svg/elite.vue";
import eliteMobile from "@/components/svg/eliteMobile.vue";
import { ref, inject } from "vue";
import premium from "@/components/svg/premium.vue";
import premiumMobile from "@/components/svg/premiumMobile.vue";
import essentiel from "@/components/svg/essentiel.vue";
import essentielMobile from "@/components/svg/essentielMobile.vue";
import { onMounted, computed, toRef } from "@vue/runtime-core";
import group from "@/components/svg/group.vue";
import groupMobile from "@/components/svg/groupMobile.vue";
import { useStore } from "vuex";
import { WorkflowWsEvents } from "@/socket";

export default {
  components: {
    card,
    standart,
    groupMobile,
    group,
    premium,
    elite,
    essentiel,
    essentielMobile,
    premiumMobile,
    eliteMobile,
    standartMobile,
  },
  props: {
    plans: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = useStore();
    const showNotif = inject("$showNotif");
    const PushUpIo = inject("$PushUpIo");
    const $t = inject("$t");
    const typeChoose = toRef(props, "type");
    const selectedElm = ref({});
    const api_key = ref("");
    const plans = toRef(props, "plans");
    const me = computed(() => {
      return store.getters["getMeInfo"];
    });
    //get plans from backend
    const paySilpPlans = ref([
      {
        id: "a528bc41-da75-4c44-8a46-41af40e99baa",
        createdAt: "2022-05-04T14:23:58.669Z",
        updatedAt: "2022-05-04T14:23:58.669Z",
        enabled: true,
        tag: "STANDARD",
        frequency: "MONTH",
        price: 7500,
        currency: "XOF",
        startCount: 1,
        endCount: 100,
      },
      {
        id: "732462ad-1dde-4d9b-8f92-035bdec0a8c1",
        createdAt: "2022-05-04T14:27:41.161Z",
        updatedAt: "2022-05-04T14:27:41.161Z",
        enabled: true,
        tag: "ESENTIAL",
        frequency: "MONTH",
        price: 27500,
        currency: "XOF",
        startCount: 101,
        endCount: 500,
      },

      {
        id: "b35f5087-208b-48ce-82db-27a7f9c80d6d",
        createdAt: "2022-05-04T14:45:40.242Z",
        updatedAt: "2022-05-04T14:45:40.242Z",
        enabled: true,
        tag: "ELITE",
        frequency: "MONTH",
        price: 125000,
        currency: "XOF",
        startCount: 2001,
        endCount: 5000,
      },
    ]);
    const subscriptionId = ref("");
    async function pay() {
      console.log(selectedElm.value.price, "ffff");
      context.emit("close");
      var data = {
        plan: selectedElm.value.id,
        business: store.state.businessId,
      };
      console.log(data);
      try {
        const res = await store.dispatch("CREATE_PLAN", data);
        console.log(res.data.publicKey, "res");
        console.log(res.data, "res");
        subscriptionId.value = res.data.id;
        openKkiapayWidget({
          amount: selectedElm.value.price,
          sandbox: process.env.VUE_APP_KKIAPAY_ENV === "live" ? false : true,
          key: res.data.publicKey,
          theme: "primary",
          paymentMethods: ["momo"],
        });
      } catch (error) {
        console.log(error.response);
        if (error.response.status == 406) {
          showNotif({
            error: true,
            note: $t("index.alreadyPlan"),
          });
        }
      }
    }
    function clickMe(data) {
      selectedElm.value = data;
    }
    function formatTag(key) {
      const obj = {
        ELITE: "Elite",
        STANDARD: "Standard",
        ESENTIAL: "Essentiel",
        PREMIUM: "Premium",
      };

      return obj[key];
    }
    async function successMessage(res) {
      console.log(res);
      const data = {
        subscriptionId: subscriptionId.value,
        businessId: store.state.businessId,
      };
      console.log(data, "subscriptionId");
      await store.dispatch("WEBHOOK", {
        stateData: {
          businessId: store.state.businessId,
          subscriptionId: subscriptionId.value,
        },
        isPaymentSucces: true,
        transactionId: res.transactionId,
      });
      await store.dispatch("ME");

      // await store.dispatch("GET_BALANCE", store.state.businessId);
      showNotif({
        success: true,
        note: $t("index.goodPlan"),
      });
      context.emit("reload");
    }
    onMounted(async () => {
      addSuccessListener(successMessage);
      setTimeout(() => {
        selectedElm.value = plans.value[0];
      }, 1000);
      console.log(selectedElm.value, "selectedElm");
      api_key.value = me.value.publicApiKey;
      PushUpIo.on(WorkflowWsEvents.SUBSCRIPTION_PAYMENT, async (data) => {
        console.log(data, "data-----------------------------------------?");
      });
    });

    return {
      paySilpPlans,
      selectedElm,
      clickMe,
      pay,

      formatTag,
      typeChoose,
    };
  },
};
</script>

<style>
.gridResponsive::-webkit-scrollbar {
  display: none;
}
.gridResponsive {
  display: flex;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  justify-content: space-between;
  overflow-x: scroll;
}
@media screen and (max-width: 800px) {
  .gridResponsive {
    display: flex;
    flex-direction: column;
    padding-bottom: 40%;
    height: 500px;
    overflow: scroll;
  }
}
</style>
