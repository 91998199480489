<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.36235" cy="9.40923" r="9.36235" fill="white" />
    <g clip-path="url(#clip0_6807_67463)">
      <path
        d="M15.9498 6.04868C15.5123 6.24473 15.0448 6.37309 14.5567 6.43611C15.0587 6.1327 15.4409 5.65658 15.6205 5.08244C15.1531 5.36484 14.6373 5.56322 14.0869 5.67525C13.6448 5.1968 13.0116 4.90039 12.3231 4.90039C10.9852 4.90039 9.9076 6.002 9.9076 7.35101C9.9076 7.54472 9.92372 7.73143 9.96286 7.90881C7.95264 7.81079 6.17499 6.83288 4.9799 5.34617C4.77036 5.71259 4.64832 6.1327 4.64832 6.58548C4.64832 7.43503 5.07892 8.18655 5.72136 8.62066C5.33221 8.61366 4.95227 8.49929 4.6299 8.31958C4.6299 8.32658 4.6299 8.33592 4.6299 8.34759C4.6299 9.53789 5.46807 10.5275 6.56644 10.7539C6.37071 10.8075 6.15426 10.8355 5.93321 10.8355C5.77893 10.8355 5.62235 10.8262 5.47498 10.7935C5.78814 11.7644 6.67697 12.4763 7.73389 12.4996C6.91184 13.1508 5.86643 13.5452 4.73812 13.5452C4.5401 13.5452 4.34897 13.5359 4.16016 13.5126C5.23089 14.2127 6.49966 14.6118 7.86744 14.6118C12.3162 14.6118 14.7478 10.8776 14.7478 7.64041C14.7478 7.53305 14.7432 7.42802 14.7386 7.323C15.2175 6.97758 15.6205 6.5458 15.9498 6.04868Z"
        fill="#222F5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_6807_67463">
        <rect
          width="11.7896"
          height="9.70911"
          fill="white"
          transform="translate(4.16113 4.90234)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
