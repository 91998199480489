<template>
  <div>
    <h1 class="text-primaryColor font-semibold text-medium">
      {{ $t("index.settingTitle") }}
    </h1>
    <passwordResset />
    <div class="mt-6">
      <span class="text-base font-semibold">
        {{ $t("index.activeMode") }}
      </span>

      <div
        class="p-6 border rounded-md border-[#CCCCCC] mt-4"
        v-if="UserInfo.ownerSettings.length"
      >
        <div class="border-[#CCCCCC] border-b pb-4 md:flex justify-between">
          <div class="flex items-center space-x-5">
            <user
              v-if="
                UserInfo.ownerSettings.length &&
                UserInfo.ownerSettings[0].type == 'UNIQUE'
              "
            />
            <userYellow v-else />
            <div class="max-w-[350px]">
              <span class="block text-[#201F1E] font-bold text-xl">
                {{
                  UserInfo.ownerSettings.length &&
                  UserInfo.ownerSettings[0].type == "UNIQUE"
                    ? $t("index.unique")
                    : $t("index.double")
                }}
              </span>

              <span class="block">
                {{
                  UserInfo.ownerSettings[0].type == "UNIQUE"
                    ? $t("index.auth_double")
                    : $t("index.auth_doubleB")
                }}
              </span>
            </div>
          </div>
          <button
            @click="chooseType('UNIQUE')"
            v-if="
              UserInfo.ownerSettings.length &&
              UserInfo.ownerSettings[0].type == 'DOUBLE'
            "
            class="border-[#CCCCCC] border rounded py-3 px-6 h-fit cursor-pointer hover:bg-[#f6f4f4]"
          >
            <span class="font-semibold text-base truncate">
              {{ $t("index.goToUnique") }}
            </span>
          </button>
          <button
            @click="chooseType('DOUBLE')"
            v-else
            class="border-[#CCCCCC] border rounded py-3 px-6 h-fit cursor-pointer hover:bg-[#f6f4f4]"
          >
            <span class="font-semibold text-base truncate">
              {{ $t("index.goToDouble") }}
            </span>
          </button>
        </div>
        <div class="py-4">
          <span class="font-semibold text-base">{{
            UserInfo.ownerSettings[0].type == "UNIQUE"
              ? "Validateur"
              : "Validateurs"
          }}</span>
        </div>

        <div
          class="grid grid-cols-1 gap-6"
          v-if="
            UserInfo.ownerSettings.length &&
            UserInfo.ownerSettings[0].type == 'UNIQUE'
          "
        >
          <div
            class="border-[#CCCCCC] border rounded-md theShadow p-4 items-center flex justify-between"
          >
            <div class="flex space-x-4 items-center">
              <user class="w-[45px]" />
              <div>
                <div v-if="UserInfo.validations[0]">
                  <span class="block font-bold">
                    {{ UserInfo.validations[0].lastname ?? " " }}
                    {{ UserInfo.validations[0].firstname ?? "" }}
                  </span>
                  <span class="block text-sm">
                    {{ formatPhone(UserInfo.validations[0].phone) }}
                  </span>
                </div>
                <span class="font-bold" v-else> Validateur </span>
              </div>

              <div v-if="revocateUser.length"></div>
            </div>
            <div class="flex relative" v-if="UserInfo.validations[0]">
              <status
                :status="UserInfo.validations[0].status"
                :value="getStatus(UserInfo.validations[0].status)"
              />
              <ThreeDot
                class="cursor-pointer"
                @click="showUniqueValidator = !showUniqueValidator"
              />
              <div
                v-if="showUniqueValidator"
                class="absolute validator flex-shrink-0 top-10 w-max flex flex-col items-baseline m-0"
              >
                <!-- v-if="UserInfo.validations[0].status != 'SUCCESS'" -->
                <button
                  v-if="UserInfo.validations[0].status != 'SUCCESS'"
                  @click="resendLink(UserInfo.validations[0])"
                  class="p-2 hover:bg-[#F5F6F6] font-semibold w-full"
                >
                  {{ $t("index.resendInvitation") }}
                </button>
                <button
                  @click="changeValidator(UserInfo.validations[0])"
                  class="p-2 hover:bg-[#F5F6F6] font-semibold w-full"
                >
                  {{ $t("index.changeValidator") }}
                </button>
              </div>
            </div>
            <div v-else>
              <primaryButton
                v-if="!revocateUser.length"
                :label="$t('index.addValidator')"
                @click="showModal = true"
                class="w-fit h-fit"
              />
              <button class="bg-[#F5F6F6] text-[#B2B2B2] p-3 rounded" v-else>
                <span class="font-semibold">
                  {{ $t("index.addValidator") }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-1 gap-6"
          v-if="UserInfo.ownerSettings[0].type == 'DOUBLE'"
        >
          <div
            class="border-[#CCCCCC] border rounded-md theShadow p-4 items-center flex justify-between"
          >
            <div class="flex space-x-4 items-center">
              <user class="w-[45px]" />
              <div v-if="UserInfo.validations[0]">
                <span class="block font-bold">
                  {{ UserInfo.validations[0].lastname }}
                  {{ UserInfo.validations[0].firstname }}
                </span>
                <span class="block text-sm">
                  {{ formatPhone(UserInfo.validations[0].phone) }}</span
                >
              </div>
              <div v-else>
                <span class="font-bold">Validateur N° 1</span>
              </div>
            </div>
            <div class="flex relative" v-if="UserInfo.validations[0]">
              <status
                :status="UserInfo.validations[0].status"
                :value="getStatus(UserInfo.validations[0].status)"
              />

              <ThreeDot
                class="cursor-pointer"
                @click="showFirstValidator = !showFirstValidator"
              />
              <div
                v-if="showFirstValidator"
                class="absolute validator z-40 flex-shrink-0 top-10 w-max flex flex-col items-baseline m-0"
              >
                <button
                  v-if="UserInfo.validations[0].status != 'SUCCESS'"
                  @click="resendLink(UserInfo.validations[0])"
                  class="p-2 hover:bg-[#F5F6F6] font-semibold w-full"
                >
                  {{ $t("index.resendInvitation") }}
                </button>
                <button
                  @click="changeValidator(UserInfo.validations[0])"
                  class="p-2 hover:bg-[#F5F6F6] font-semibold w-full"
                >
                  {{ $t("index.changeValidator") }}
                </button>
              </div>
            </div>
            <div v-else>
              <primaryButton
                v-if="!revocateUser.length"
                :label="$t('index.addFistValidator')"
                @click="showModal = true"
                class="w-fit h-fit"
              />
              <button class="bg-[#F5F6F6] text-[#B2B2B2] p-3 rounded" v-else>
                <span class="font-semibold">
                  {{ $t("index.addFistValidator") }}
                </span>
              </button>
            </div>
          </div>
          <div
            class="border-[#CCCCCC] border rounded-md theShadow p-4 items-center flex justify-between"
          >
            <div class="flex space-x-4 items-center">
              <yellowUser class="w-[45px]" />
              <div v-if="UserInfo.validations[1]">
                <span class="block font-bold">
                  {{ UserInfo.validations[1].lastname }}
                  {{ UserInfo.validations[1].firstname }}
                </span>
                <span class="block text-sm">
                  {{ formatPhone(UserInfo.validations[1].phone) }}</span
                >
              </div>
              <div v-else>
                <span class="font-semibold">Validateur N° 2</span>
              </div>
            </div>
            <div class="flex relative" v-if="UserInfo.validations[1]">
              <status
                :status="UserInfo.validations[1].status"
                :value="getStatus(UserInfo.validations[1].status)"
              />
              <ThreeDot
                class="cursor-pointer"
                @click="showSecondValidator = !showSecondValidator"
              />
              <div
                v-if="showSecondValidator"
                class="absolute validator flex-shrink-0 top-10 w-max flex flex-col items-baseline m-0"
              >
                <button
                  v-if="UserInfo.validations[1].status != 'SUCCESS'"
                  @click="resendLink(UserInfo.validations[1])"
                  class="p-2 hover:bg-[#F5F6F6] font-semibold w-full"
                >
                  {{ $t("index.resendInvitation") }}
                </button>
                <button
                  @click="changeValidator(UserInfo.validations[1])"
                  class="p-2 hover:bg-[#F5F6F6] font-semibold w-full"
                >
                  {{ $t("index.changeValidator") }}
                </button>
              </div>
            </div>
            <div v-else>
              <primaryButton
                v-if="!revocateUser.length"
                :label="$t('index.addSecondValidator')"
                @click="showModal = true"
                class="w-fit h-fit"
              />

              <button class="bg-[#F5F6F6] text-[#B2B2B2] p-3 rounded" v-else>
                <span class="font-semibold">
                  {{ $t("index.addSecondValidator") }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="py-4">
          <revocValidator @closeSuccessCard="showBtn = true" />
        </div>
      </div>
      <div v-else></div>
    </div>

    <succesValidation
      v-if="showSuccessValidation"
      @close="showSuccessValidation = false"
      :validator="theValidator"
      typeLabel="REVOC"
    />
    <succesValidation
      v-if="showAddSuccessValidation"
      @close="showAddSuccessValidation = false"
      :validator="theValidator"
    />
    <chooseModal
      :type="type"
      v-if="showTypeModal"
      :data="UserInfo.validations"
      @closeModal="showTypeModal = false"
      @changeMode="changeMode"
    >
    </chooseModal>

    <addValidator
      :validator="theValidator"
      @done="onSubmit"
      v-if="showModal"
      @close="showModal = false"
    />

    <resendInvitation
      :validator="theValidator"
      v-if="resend"
      @close="resend = false"
    />
    <changeValidator
      :validator="theValidator"
      v-if="confirm"
      @closeModal="confirm = false"
      @closeMe="closeChange"
    />
    <changeValidator
      v-if="confirmChangeWhenPending"
      @closeModal="confirmChangeWhenPending = false"
      @closeMe="closeChangeWhenPending"
    />
  </div>
</template>

<script>
import passwordResset from "../components/modals/passwordResset.vue";
import succesValidation from "../components/modals/successModal.vue";
import { WorkflowWsEvents } from "@/socket";
import resendInvitation from "../components/modals/resendInvitation.vue";
import chooseModal from "../components/modals/settingChooseModal.vue";
import user from "../components/svg/userColor.vue";
import yellowUser from "../components/svg/yellowUser.vue";
import revoc from "../components/svg/revoc.vue";
import status from "@/components/global/status.vue";
import ThreeDot from "@/components/svg/threeDot.vue";
import { ref, computed, onMounted, inject, watch } from "vue";
import { useStore } from "vuex";
import addValidator from "../components/modals/addValidatorModal.vue";
import changeValidator from "../components/modals/changeValidator.vue";
import primaryButton from "../components/global/primaryButton.vue";
import revocValidator from "../components/modals/revocValidator.vue";
import { showNotif } from "@/notif";
import userYellow from "../components/svg/doubleIcon.vue";
export default {
  components: {
    revocValidator,
    changeValidator,
    passwordResset,
    resendInvitation,
    yellowUser,
    revoc,
    userYellow,
    chooseModal,
    user,
    primaryButton,
    status,
    ThreeDot,
    addValidator,
    succesValidation,
  },
  setup() {
    const $t = inject("$t");
    const type = ref("");
    const store = useStore();
    const selectedValidator = ref({});
    const UserInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    const PushUpIo = inject("$PushUpIo");
    const showSuccessValidation = ref(false);
    const resend = ref(false);
    const showUniqueValidator = ref(false);
    const showModal = ref(false);
    const data = ref([
      {
        id: 1,
        name: "Toto",
      },

      { id: 2, name: "lala" },
    ]);
    const confirmChangeWhenPending = ref(false);
    const showSecondValidator = ref(false);
    const showFirstValidator = ref(false);
    const showTypeModal = ref(false);
    watch(showModal, (val) => {
      if (val == true) {
        console.log(val);
        showUniqueValidator.value = false;
      }
    });
    async function closeChange() {
      confirm.value = false;
      await store.dispatch("GET_REVOC_VALIDATORS", { id: UserInfo.value.id });
      showSuccessValidation.value = true;
    }
    watch(showModal, (val) => {
      if (val == true) {
        console.log(val);
        showSecondValidator.value = false;
        showFirstValidator.value = false;
      }
    });
    watch(resend, (val) => {
      if (val == true) {
        showSecondValidator.value = false;
        showFirstValidator.value = false;
      }
    });
    async function chooseType(data) {
      showTypeModal.value = true;
      type.value = data;
      console.log(type);
    }
    const showBtn = ref(false);
    const resetValidator = ref(false);
    async function changeMode(data) {
      if (data.validator == "NONE" || data.validator == "NO") {
        console.log(data, "data");
        showSuccessValidation.value = true;
      } else {
        showSuccessValidation.value = false;
      }
      showTypeModal.value = false;
      await store.dispatch("ME");
    }
    const doubleModal = ref(false);
    async function addDoubleValidator() {
      doubleModal.value = true;
    }
    function formatPhone(rawNumber) {
      if (rawNumber) {
        const phoneNumberLength = rawNumber.length;
        let cute = rawNumber.substr(3, phoneNumberLength);
        cute = cute.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, " ");
        return `(${rawNumber.substr(0, 3)}) ${cute} `;
      }
      return "";
    }
    const getStatus = (key) => {
      return {
        SUCCESS: $t("index.accept"),
        PENDING: $t("index.waiting"),
      }[key];
    };
    const theValidator = ref({});
    function resendLink(data) {
      resend.value = true;
      theValidator.value = data;
    }
    // const settingType = computed(() => {
    //   return store.getters["getMeInfo"].ownerSettings[0].type;
    // });
    const confirm = ref(false);
    const theValidatorChange = ref({ id: "" });
    async function closeChangeWhenPending() {
      try {
        console.log(confirmChangeWhenPending.value, "confirmChangeWhenPending");
        await store.dispatch("CHANGE_VALIDATOR", {
          validatorId: theValidatorChange.value.id,
          id: UserInfo.value.id,
        });
        await store.dispatch("ME");
        confirmChangeWhenPending.value = false;
        showNotif({
          success: true,
          note: $t("index.revocSuccess"),
        });
      } catch (error) {
        confirmChangeWhenPending.value = false;
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    }
    async function changeValidator(data) {
      // showModal.value = true;
      if (data.status !== "SUCCESS") {
        theValidatorChange.value = data;
        confirmChangeWhenPending.value = true;

        confirm.value = false;

        resetValidator.value = false;
      } else {
        confirm.value = true;
        theValidator.value = data;

        resetValidator.value = true;
      }
    }
    const add = ref({});
    async function onSubmitGoToDoubleValidator(data) {
      try {
        let payload = {
          id: data.id,
          body: {
            firstname: data.body.firstname,
            lastname: data.body.lastname,
            phone: data.body.phone,
          },
        };
        console.log(payload, "cc");
        const result = await store.dispatch("DOUBLE_VALIDATION", payload);
        console.log(result.data.id);

        joinSettingRoom(result.data.id);
        showNotif({
          success: true,
          note: $t("index.userInvite"),
        });
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
      await store.dispatch("ME");
      doubleModal.value = false;
    }
    const showAddSuccessValidation = ref(false);
    async function onSubmit(data) {
      theValidator.value = data;
      add.value = data;
      console.log(data, "cvvvvvvvvvvvv");
      showModal.value = false;
      showAddSuccessValidation.value = true;
    }
    watch(
      showSuccessValidation,
      (val) => {
        if (val == false) {
          theValidator.value = {};
        }
      },
      { immediate: true },
    );

    function joinSettingRoom(settingId) {
      if (PushUpIo?.isSocketConnected) {
        PushUpIo?.joinRoom(settingId);
      } else {
        PushUpIo?.on("connect", () => {
          PushUpIo?.joinRoom(settingId);
        });
      }

      PushUpIo.on(WorkflowWsEvents.SETTING_CONFIRMED, async (data) => {
        // void store.dispatch(ActionTypes.GET_ACCOUNT_VALIDATOR);
        await store.dispatch("ME");
      });
    }
    const revocateUser = computed(() => {
      return store.getters["getRevocateUser"];
    });
    onMounted(async () => {
      if (!UserInfo.value.ownerSettings.length) {
        router.push({ name: "noSetting" });
      }
      await store.dispatch("GET_REVOC_VALIDATORS", { id: UserInfo.value.id });
      type.value = UserInfo.value.ownerSettings[0].type;

      selectedValidator.value = data.value[0];
    });
    return {
      revocateUser,
      closeChange,
      getStatus,
      data,
      type,
      chooseType,
      UserInfo,
      showTypeModal,
      selectedValidator,
      formatPhone,
      onSubmit,
      showModal,
      showUniqueValidator,
      showSecondValidator,
      showFirstValidator,
      resendLink,
      theValidator,
      resend,
      changeValidator,
      changeMode,
      showSuccessValidation,
      addDoubleValidator,
      doubleModal,
      onSubmitGoToDoubleValidator,
      showAddSuccessValidation,
      confirm,
      showBtn,
      closeChangeWhenPending,
      confirmChangeWhenPending,
    };
  },
};
</script>

<style>
.theShadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.validator {
  left: -11px;
  background: #ffffff;
  border: 1px solid rgba(204, 204, 204, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
</style>
