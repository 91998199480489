<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.95342 38.1609C5.19371 33.6216 11.786 22.8301 22.2843 22.8301C32.7825 22.8301 39.3948 33.6416 37.6151 38.1609C34.9488 45.1131 9.61965 45.1131 6.95342 38.1609Z"
      fill="#F6DB6F"
    />
    <path
      d="M22.3044 23.9507C27.6276 23.9507 31.9429 19.6354 31.9429 14.3123C31.9429 8.9891 27.6276 4.67383 22.3044 4.67383C16.9813 4.67383 12.666 8.9891 12.666 14.3123C12.666 19.6354 16.9813 23.9507 22.3044 23.9507Z"
      fill="white"
    />
    <path
      d="M32.8952 42.5478C32.7394 42.5481 32.5885 42.4938 32.4686 42.3945L30.9488 41.1413C29.2182 39.6999 27.8265 37.8949 26.8726 35.8546C25.9187 33.8143 25.4261 31.5889 25.4297 29.3366V27.7369C25.4297 27.5601 25.4999 27.3905 25.6249 27.2655C25.7499 27.1405 25.9195 27.0703 26.0963 27.0703C26.273 27.0703 26.4426 27.1405 26.5676 27.2655C26.6926 27.3905 26.7628 27.5601 26.7628 27.7369V29.3366C26.7598 31.3998 27.2116 33.4382 28.086 35.3069C28.9604 37.1756 30.236 38.8286 31.822 40.1482L33.3218 41.3946C33.3892 41.4506 33.445 41.5193 33.4859 41.5969C33.5268 41.6744 33.552 41.7593 33.5601 41.8466C33.5681 41.9339 33.5589 42.0219 33.5328 42.1056C33.5068 42.1894 33.4645 42.2671 33.4084 42.3345C33.3432 42.4049 33.2637 42.4604 33.1751 42.4972C33.0865 42.534 32.991 42.5513 32.8952 42.5478Z"
      fill="#24315A"
    />
    <path
      d="M32.8965 41.8817L34.3963 40.6686C36.0563 39.2837 37.3914 37.5509 38.3072 35.5927C39.2229 33.6344 39.6969 31.4988 39.6954 29.3371V27.7373L32.8965 24.3379L26.0977 27.7373V29.3371C26.1003 31.5015 26.5794 33.6387 27.501 35.5971C28.4226 37.5555 29.7641 39.287 31.4301 40.6686L32.9299 41.915"
      fill="#F7A9A8"
    />
    <path
      d="M22.2832 24.6233C20.2434 24.6246 18.2491 24.021 16.5525 22.8887C14.8559 21.7565 13.5332 20.1465 12.7517 18.2624C11.9702 16.3783 11.765 14.3047 12.1622 12.304C12.5593 10.3033 13.5409 8.46535 14.9827 7.02257C16.4245 5.5798 18.2619 4.59705 20.2623 4.19863C22.2628 3.80021 24.3365 4.00403 26.2211 4.78429C28.1056 5.56456 29.7165 6.88623 30.8499 8.58211C31.9832 10.278 32.5881 12.2719 32.5881 14.3117C32.5864 17.0448 31.5003 19.6656 29.5683 21.5988C27.6364 23.532 25.0163 24.6198 22.2832 24.6233ZM22.2832 5.33978C20.5068 5.33847 18.77 5.86409 17.2924 6.85016C15.8149 7.83623 14.6631 9.23842 13.9827 10.8793C13.3023 12.5202 13.1239 14.326 13.4702 16.0683C13.8164 17.8106 14.6716 19.411 15.9277 20.6671C17.1838 21.9232 18.7842 22.7784 20.5265 23.1247C22.2688 23.4709 24.0746 23.2925 25.7155 22.6121C27.3564 21.9317 28.7586 20.7799 29.7446 19.3024C30.7307 17.8248 31.2563 16.088 31.255 14.3117C31.2515 11.9332 30.3051 9.65327 28.6233 7.97148C26.9415 6.28969 24.6616 5.34331 22.2832 5.33978Z"
      fill="#24315A"
    />
    <path
      d="M22.3308 43.9997C15.2986 43.9997 7.85312 42.3133 6.33336 38.3806C4.93359 34.7612 8.08641 28.5422 13.0856 25.0494C13.2307 24.9594 13.4048 24.9284 13.5721 24.963C13.7393 24.9976 13.8869 25.0951 13.9844 25.2353C14.0819 25.3756 14.1218 25.5479 14.096 25.7167C14.0701 25.8855 13.9805 26.038 13.8455 26.1426C8.96627 29.5287 6.51333 35.1278 7.57983 37.8673C9.17957 41.9867 20.4511 43.5664 28.723 42.1266C28.8998 42.0966 29.0813 42.138 29.2276 42.2417C29.3738 42.3455 29.4729 42.5031 29.5029 42.6799C29.533 42.8567 29.4916 43.0381 29.3878 43.1844C29.2841 43.3307 29.1265 43.4297 28.9497 43.4598C26.7624 43.825 24.5483 44.0056 22.3308 43.9997Z"
      fill="#24315A"
    />
    <path
      d="M32.8952 42.5468C32.7394 42.5471 32.5885 42.4928 32.4686 42.3935L30.9488 41.1403C29.2182 39.6989 27.8265 37.8938 26.8726 35.8536C25.9187 33.8133 25.4261 31.5879 25.4297 29.3356V27.7359C25.4301 27.6128 25.4645 27.4922 25.5292 27.3875C25.5939 27.2828 25.6864 27.198 25.7963 27.1426L32.5952 23.7432C32.6882 23.6963 32.791 23.6719 32.8952 23.6719C32.9993 23.6719 33.1021 23.6963 33.1951 23.7432L39.994 27.1426C40.1039 27.198 40.1964 27.2828 40.2611 27.3875C40.3258 27.4922 40.3602 27.6128 40.3606 27.7359V29.3356C40.366 32.3582 39.4778 35.3149 37.8077 37.8342C37.7091 37.9786 37.5577 38.0785 37.3861 38.1123C37.2146 38.1462 37.0366 38.1112 36.8905 38.015C36.7445 37.9188 36.6421 37.7691 36.6054 37.5981C36.5688 37.4271 36.6008 37.2486 36.6945 37.101C38.2187 34.7982 39.0302 32.0971 39.0275 29.3356V28.1225L32.8952 25.0563L26.7628 28.1225V29.3356C26.7598 31.3988 27.2116 33.4372 28.086 35.3059C28.9604 37.1746 30.236 38.8276 31.822 40.1472L32.8952 41.0404L33.9683 40.1472C34.2549 39.9072 34.5416 39.6472 34.8148 39.3806C34.9412 39.2569 35.1116 39.1884 35.2885 39.1903C35.4654 39.1922 35.6343 39.2642 35.758 39.3906C35.8818 39.517 35.9502 39.6874 35.9484 39.8643C35.9465 40.0412 35.8744 40.2101 35.748 40.3338C35.4481 40.6271 35.1415 40.907 34.8215 41.167L33.3218 42.4201C33.1983 42.51 33.0477 42.5547 32.8952 42.5468Z"
      fill="#24315A"
    />
    <path
      d="M32.4221 34.7416C32.3344 34.7421 32.2474 34.7253 32.1662 34.6921C32.085 34.659 32.0111 34.6101 31.9488 34.5483L30.6157 33.2152C30.4916 33.0903 30.4219 32.9214 30.4219 32.7453C30.4219 32.5692 30.4916 32.4002 30.6157 32.2753C30.6777 32.2129 30.7514 32.1633 30.8326 32.1294C30.9138 32.0956 31.001 32.0782 31.089 32.0782C31.177 32.0782 31.2641 32.0956 31.3453 32.1294C31.4265 32.1633 31.5003 32.2129 31.5622 32.2753L32.4487 33.1619L35.4349 30.1757C35.4969 30.1132 35.5706 30.0636 35.6518 30.0298C35.7331 29.9959 35.8202 29.9785 35.9082 29.9785C35.9962 29.9785 36.0833 29.9959 36.1645 30.0298C36.2457 30.0636 36.3195 30.1132 36.3814 30.1757C36.5056 30.3006 36.5753 30.4695 36.5753 30.6456C36.5753 30.8217 36.5056 30.9906 36.3814 31.1155L32.922 34.575C32.8544 34.6353 32.7752 34.6811 32.6892 34.7098C32.6033 34.7384 32.5124 34.7493 32.4221 34.7416Z"
      fill="#24315A"
    />
    <path
      d="M11.6191 32.8955C11.4831 32.8951 11.3506 32.8533 11.2391 32.7755C11.167 32.725 11.1057 32.6607 11.0586 32.5863C11.0114 32.5119 10.9795 32.429 10.9647 32.3422C10.9498 32.2555 10.9522 32.1666 10.9719 32.0808C10.9915 31.995 11.028 31.9139 11.0791 31.8423C11.5434 31.1972 12.0489 30.5827 12.5922 30.0026C12.6498 29.9316 12.7213 29.8732 12.8024 29.8311C12.8835 29.7889 12.9724 29.7638 13.0635 29.7575C13.1547 29.7511 13.2462 29.7636 13.3323 29.794C13.4185 29.8245 13.4974 29.8724 13.5643 29.9347C13.6312 29.997 13.6845 30.0723 13.721 30.1561C13.7575 30.2399 13.7764 30.3303 13.7765 30.4217C13.7766 30.513 13.7579 30.6035 13.7216 30.6873C13.6853 30.7712 13.6321 30.8467 13.5654 30.9091C13.0708 31.4466 12.61 32.0143 12.1856 32.6088C12.1225 32.7 12.0376 32.7739 11.9387 32.8239C11.8397 32.874 11.7299 32.8986 11.6191 32.8955Z"
      fill="#24315A"
    />
    <path
      d="M10.2189 35.3351C10.1203 35.3344 10.0228 35.314 9.93226 35.2751C9.84933 35.2375 9.775 35.1833 9.71383 35.1159C9.65266 35.0484 9.60596 34.9692 9.57661 34.883C9.54725 34.7968 9.53587 34.7055 9.54315 34.6147C9.55044 34.524 9.57624 34.4357 9.61897 34.3553C9.71229 34.1486 9.81894 33.942 9.93226 33.7354C9.96968 33.652 10.0238 33.5772 10.0914 33.5157C10.1589 33.4541 10.2384 33.4071 10.3249 33.3776C10.4114 33.348 10.503 33.3366 10.5941 33.344C10.6852 33.3513 10.7738 33.3773 10.8544 33.4204C10.935 33.4635 11.0059 33.5226 11.0627 33.5942C11.1194 33.6658 11.1609 33.7483 11.1844 33.8366C11.208 33.9249 11.2131 34.0171 11.1995 34.1075C11.1859 34.1979 11.1539 34.2845 11.1054 34.3619C11.0054 34.5486 10.9121 34.7419 10.8254 34.9285C10.7749 35.048 10.6905 35.1501 10.5828 35.2223C10.475 35.2945 10.3486 35.3337 10.2189 35.3351Z"
      fill="#24315A"
    />
  </svg>
</template>
