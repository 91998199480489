<template>
  <svg
    width="43"
    height="31"
    viewBox="0 0 43 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 12C3 5.37258 8.37258 0 15 0H31C37.6274 0 43 5.37258 43 12C43 18.6274 37.6274 24 31 24H15C8.37258 24 3 18.6274 3 12Z"
      fill="#2E3238"
      fill-opacity="0.09"
    />
    <g filter="url(#filter0_dd_7192_18394)">
      <rect x="6" y="3" width="18" height="18" rx="9" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_dd_7192_18394"
        x="0"
        y="1"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7192_18394"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_7192_18394"
          result="effect2_dropShadow_7192_18394"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_7192_18394"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>
