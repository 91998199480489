<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.1836" cy="9.40923" r="9.36235" fill="white" />
    <path
      d="M13.1093 9.74755H11.6694V14.6114H9.48776V9.74755H8.4502V8.03818H9.48776V6.93202C9.48776 6.141 9.89528 4.90234 11.6888 4.90234L13.3047 4.90858V6.56781H12.1323C11.9399 6.56781 11.6695 6.65641 11.6695 7.03375V8.03977H13.2999L13.1093 9.74755Z"
      fill="#222F5A"
    />
  </svg>
</template>
