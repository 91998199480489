<template>
  <svg
    width="310"
    height="309"
    viewBox="0 0 310 309"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M278.89 256.347C284.197 255.851 289.237 253.793 293.375 250.433C297.513 247.074 300.563 242.563 302.139 237.472C303.716 232.38 303.748 226.936 302.232 221.826C300.717 216.716 297.721 212.17 293.623 208.761L226.484 152.74C220.971 148.352 213.961 146.294 206.951 147.008C199.942 147.721 193.49 151.149 188.975 156.558C184.459 161.967 182.24 168.927 182.79 175.952C183.34 182.976 186.617 189.506 191.919 194.146L259.065 250.205C261.798 252.491 264.958 254.211 268.361 255.265C271.764 256.32 275.343 256.688 278.89 256.347Z"
      fill="#9ABDF3"
    />
    <path
      d="M134.698 94.5098H179.398V156.291H134.698V94.5098Z"
      fill="#D39990"
    />
    <path
      d="M179.398 125.354L134.692 94.5098H179.398V125.354Z"
      fill="#020202"
    />
    <path
      d="M203.051 22.3047L162.573 17.2844C162.477 17.2725 162.38 17.2992 162.304 17.3586C162.227 17.4181 162.178 17.5054 162.166 17.6014L157.281 56.9876C157.27 57.0404 157.272 57.095 157.285 57.1474C157.298 57.1997 157.322 57.2485 157.356 57.2903C157.39 57.332 157.433 57.3657 157.482 57.3888C157.531 57.412 157.584 57.424 157.638 57.4241L198.116 62.4444C200.243 62.6901 202.381 62.0838 204.062 60.7578C205.744 59.4319 206.832 57.4942 207.089 55.3683L210.069 31.3392C210.333 29.211 209.743 27.065 208.427 25.3715C207.111 23.6779 205.178 22.5751 203.051 22.3047Z"
      fill="#020202"
    />
    <path
      d="M158.746 119.892C162.764 120.391 166.84 120.092 170.743 119.015C174.645 117.938 178.297 116.102 181.49 113.613C184.684 111.124 187.355 108.031 189.352 104.509C191.35 100.988 192.634 97.1075 193.132 93.0897L197.458 58.4199C198.025 53.8373 196.75 49.2168 193.912 45.5744C191.074 41.9319 186.905 39.5657 182.323 38.9961L158.839 36.0854C154.256 35.5175 149.636 36.7929 145.993 39.6311C142.351 42.4693 139.985 46.6378 139.415 51.2202L134.675 88.9923C134.226 92.6019 134.493 96.2645 135.459 99.771C136.426 103.278 138.074 106.559 140.31 109.429C142.545 112.298 145.324 114.699 148.487 116.495C151.65 118.29 155.136 119.444 158.746 119.892Z"
      fill="#D39990"
    />
    <path
      d="M158.34 93.156C157.33 93.1626 156.323 93.2599 155.33 93.4465C155.13 93.4825 154.951 93.5969 154.835 93.7643C154.719 93.9318 154.673 94.1387 154.709 94.3395C154.745 94.5403 154.86 94.7185 155.027 94.835C155.195 94.9514 155.402 94.9966 155.602 94.9606C161.084 93.9718 164.736 95.764 167.443 100.77C167.491 100.859 167.556 100.937 167.634 101.001C167.713 101.065 167.803 101.112 167.899 101.141C167.996 101.17 168.097 101.18 168.198 101.17C168.298 101.16 168.396 101.13 168.485 101.082C168.573 101.034 168.652 100.969 168.716 100.891C168.78 100.813 168.827 100.723 168.856 100.626C168.885 100.529 168.895 100.428 168.885 100.327C168.874 100.227 168.845 100.129 168.797 100.041C166.288 95.3932 162.92 93.156 158.34 93.156Z"
      fill="#231F20"
    />
    <path
      d="M160.445 77.0387C158.939 77.0387 157.719 75.8185 157.719 74.3133C157.719 72.8081 158.939 71.5879 160.445 71.5879C161.95 71.5879 163.17 72.8081 163.17 74.3133C163.17 75.8185 161.95 77.0387 160.445 77.0387Z"
      fill="#231F20"
    />
    <path
      d="M184.175 79.7652C182.67 79.7652 181.45 78.545 181.45 77.0398C181.45 75.5346 182.67 74.3145 184.175 74.3145C185.68 74.3145 186.9 75.5346 186.9 77.0398C186.9 78.545 185.68 79.7652 184.175 79.7652Z"
      fill="#231F20"
    />
    <path
      d="M175.649 93.9363C175.798 93.9345 175.943 93.8917 176.069 93.8127L183.288 89.1468C183.436 89.0498 183.547 88.9051 183.602 88.7366C183.657 88.568 183.653 88.3857 183.59 88.2198L176.842 70.8293C176.762 70.6466 176.615 70.5017 176.431 70.4249C176.248 70.3481 176.041 70.3453 175.855 70.4171C175.669 70.4889 175.519 70.6297 175.434 70.8102C175.35 70.9906 175.338 71.1967 175.402 71.3855L181.928 88.1889L175.235 92.5149C175.095 92.6051 174.989 92.7381 174.93 92.8939C174.872 93.0497 174.866 93.2202 174.913 93.3799C174.959 93.5395 175.056 93.6799 175.189 93.7802C175.321 93.8805 175.483 93.9352 175.649 93.9363Z"
      fill="#231F20"
    />
    <path
      d="M192.785 65.0506C192.926 65.0495 193.065 65.0111 193.186 64.9393C193.361 64.8319 193.486 64.6601 193.534 64.4611C193.583 64.2622 193.551 64.0521 193.446 63.8764C191.777 61.1324 189.157 59.9397 185.665 60.3476C185.467 60.3724 185.286 60.4732 185.16 60.6288C185.035 60.7844 184.974 60.9827 184.992 61.1819C185.001 61.2834 185.03 61.3821 185.079 61.4718C185.127 61.5616 185.193 61.6407 185.273 61.7042C185.353 61.7678 185.444 61.8145 185.543 61.8415C185.641 61.8686 185.744 61.8755 185.845 61.8617C188.718 61.528 190.789 62.4426 192.124 64.6612C192.191 64.7773 192.288 64.8741 192.403 64.9422C192.519 65.0104 192.65 65.0477 192.785 65.0506Z"
      fill="#231F20"
    />
    <path
      d="M155.154 59.5616C155.305 59.5638 155.454 59.5207 155.581 59.438C159.159 57.0649 162.379 56.9166 165.339 58.9807C165.417 59.0635 165.513 59.1279 165.62 59.1692C165.726 59.2105 165.84 59.2278 165.954 59.2197C166.068 59.2117 166.178 59.1785 166.278 59.1226C166.377 59.0667 166.463 58.9894 166.529 58.8964C166.595 58.8033 166.64 58.6968 166.66 58.5845C166.68 58.4721 166.675 58.3568 166.645 58.2466C166.615 58.1365 166.561 58.0344 166.487 57.9476C166.413 57.8608 166.32 57.7915 166.216 57.7447C162.706 55.316 158.837 55.4643 154.728 58.1835C154.588 58.2739 154.482 58.4071 154.425 58.5631C154.368 58.719 154.363 58.8893 154.412 59.0482C154.46 59.2072 154.558 59.3462 154.692 59.4444C154.826 59.5425 154.988 59.5945 155.154 59.5925V59.5616Z"
      fill="#231F20"
    />
    <path
      d="M173.665 54.0619C163.928 54.0619 156.034 46.168 156.034 36.4304C156.034 26.6927 163.928 18.7988 173.665 18.7988C183.403 18.7988 191.297 26.6927 191.297 36.4304C191.297 46.168 183.403 54.0619 173.665 54.0619Z"
      fill="#020202"
    />
    <path
      d="M171.493 53.9264L158.985 52.3752C155.551 51.9507 152.088 52.9075 149.359 55.0352C146.63 57.163 144.857 60.2874 144.431 63.7217L142.021 82.923L128.759 81.2791L126.441 48.5251C126.13 44.1484 126.793 39.7571 128.381 35.6669C129.969 31.5767 132.444 27.8891 135.627 24.8691C138.81 21.8491 142.622 19.5717 146.79 18.2006C150.958 16.8295 155.378 16.3987 159.733 16.9391L175.801 18.9291L171.493 53.9264Z"
      fill="#020202"
    />
    <path
      d="M134.749 96.1003C129.994 96.1003 126.14 92.246 126.14 87.4916C126.14 82.7371 129.994 78.8828 134.749 78.8828C139.503 78.8828 143.357 82.7371 143.357 87.4916C143.357 92.246 139.503 96.1003 134.749 96.1003Z"
      fill="#D39990"
    />
    <path
      d="M137.381 91.6297C137.551 91.6322 137.717 91.5754 137.851 91.469C137.931 91.4073 137.998 91.3303 138.049 91.2423C138.099 91.1544 138.132 91.0573 138.144 90.9567C138.157 90.8561 138.15 90.754 138.123 90.6563C138.095 90.5586 138.049 90.4673 137.987 90.3875L132.734 83.6452C132.671 83.564 132.594 83.4959 132.506 83.4447C132.417 83.3934 132.32 83.3601 132.218 83.3467C132.117 83.3332 132.014 83.3398 131.915 83.3661C131.817 83.3924 131.724 83.4379 131.643 83.4999C131.562 83.562 131.494 83.6395 131.442 83.7279C131.391 83.8163 131.358 83.914 131.344 84.0153C131.331 84.1165 131.337 84.2195 131.364 84.3182C131.39 84.417 131.435 84.5096 131.498 84.5907L136.751 91.3331C136.825 91.4284 136.921 91.505 137.03 91.5565C137.14 91.608 137.26 91.6331 137.381 91.6297Z"
      fill="#231F20"
    />
    <path
      d="M61.231 177.488V292.109L236.125 292.288V177.556C236.123 167.84 232.265 158.522 225.396 151.649C218.528 144.776 209.212 140.911 199.496 140.903L179.362 140.866V131.237L161.267 143.783C160.022 144.556 158.586 144.967 157.12 144.967C155.654 144.967 154.218 144.556 152.973 143.783L134.662 131.219V140.841L97.9217 140.81C93.1033 140.806 88.3313 141.752 83.8788 143.594C79.4263 145.435 75.3804 148.137 71.9728 151.543C68.5651 154.95 65.8624 158.995 64.0192 163.446C62.176 167.898 61.2285 172.67 61.231 177.488Z"
      fill="#9ABDF3"
    />
    <path
      d="M162.525 142.924L179.391 160.123L166.376 156.273L162.525 142.924Z"
      fill="#231F20"
    />
    <path
      d="M162.526 142.925L179.391 160.124L189.014 140.867L179.391 131.238L162.526 142.925Z"
      fill="#EFEFF0"
    />
    <path
      d="M151.551 142.924L134.692 160.123L147.701 156.273L151.551 142.924Z"
      fill="#231F20"
    />
    <path
      d="M151.551 142.925L134.692 160.124L125.063 140.867L134.692 131.238L151.551 142.925Z"
      fill="#EFEFF0"
    />
    <path
      d="M157.133 145.738C158.765 145.766 160.368 145.305 161.737 144.415L179.838 131.876C180.005 131.758 180.118 131.579 180.154 131.378C180.19 131.177 180.145 130.969 180.03 130.801C179.973 130.717 179.9 130.646 179.815 130.591C179.73 130.536 179.636 130.498 179.536 130.48C179.437 130.461 179.335 130.463 179.236 130.484C179.137 130.505 179.044 130.546 178.96 130.603L160.859 143.149C159.742 143.828 158.459 144.187 157.151 144.187C155.843 144.187 154.561 143.828 153.443 143.149L135.138 130.585C134.969 130.482 134.767 130.448 134.574 130.49C134.381 130.531 134.211 130.645 134.099 130.808C133.988 130.972 133.943 131.171 133.974 131.366C134.005 131.562 134.11 131.737 134.267 131.858L152.572 144.422C153.928 145.302 155.516 145.76 157.133 145.738Z"
      fill="#231F20"
    />
    <path
      d="M33.6444 263.886C37.5006 263.888 41.3122 263.061 44.8211 261.462C48.3301 259.862 51.4544 257.528 53.9828 254.616L110.839 189.454C115.463 184.053 117.767 177.044 117.251 169.952C116.734 162.86 113.439 156.259 108.081 151.584C102.723 146.91 95.7357 144.54 88.6394 144.99C81.543 145.44 74.9114 148.673 70.1867 153.987L13.3307 219.149C9.91809 223.05 7.70155 227.852 6.94626 232.98C6.19097 238.107 6.92892 243.344 9.07182 248.063C11.2147 252.783 14.6719 256.785 19.0296 259.591C23.3874 262.397 28.4613 263.888 33.6444 263.886Z"
      fill="#9ABDF3"
    />
    <path
      d="M135.571 178.04L143.926 175.982L146.738 179.041C148.078 180.502 149.895 181.437 151.862 181.679C153.829 181.922 155.818 181.455 157.473 180.364C157.694 180.219 157.881 180.027 158.02 179.802C158.159 179.578 158.248 179.325 158.28 179.063C158.312 178.8 158.286 178.534 158.204 178.283C158.122 178.031 157.987 177.801 157.807 177.607L149.402 168.566C148.598 167.704 147.593 167.055 146.476 166.679C145.36 166.302 144.167 166.21 143.006 166.409L123.471 169.777L135.571 178.04Z"
      fill="#D39990"
    />
    <path
      d="M88.2049 174.38L104.081 156.582L135.568 178.039L129.333 187.192L109.075 210.737L90.269 222.275L74.5532 196.641L81.7406 192.111L82.4327 186.944C83.0583 182.274 85.0688 177.898 88.2049 174.38Z"
      fill="#D39990"
    />
    <path
      d="M112.358 191.53L126.621 185.758C127.474 185.413 128.204 184.822 128.719 184.061C129.234 183.299 129.511 182.401 129.514 181.482V169.696C129.516 168.973 129.376 168.256 129.101 167.587C128.826 166.918 128.422 166.31 127.911 165.797C127.401 165.285 126.795 164.878 126.127 164.6C125.459 164.322 124.743 164.179 124.02 164.178H120.281V178.355L113.656 181.043C111.889 181.759 110.478 183.147 109.734 184.903C108.99 186.659 108.974 188.638 109.688 190.406C109.896 190.906 110.293 191.305 110.793 191.515C111.292 191.726 111.854 191.731 112.358 191.53Z"
      fill="#D39990"
    />
    <path
      d="M111.581 192.228C111.915 192.226 112.247 192.161 112.557 192.037L126.821 186.259C127.775 185.876 128.592 185.217 129.168 184.365C129.745 183.514 130.052 182.509 130.053 181.481V169.696C130.054 169.625 130.04 169.554 130.014 169.489C129.987 169.423 129.947 169.363 129.897 169.312C129.847 169.261 129.788 169.221 129.722 169.194C129.657 169.166 129.586 169.152 129.515 169.152C129.371 169.154 129.234 169.212 129.132 169.313C129.031 169.415 128.973 169.552 128.971 169.696V181.475C128.971 182.287 128.727 183.081 128.273 183.754C127.818 184.427 127.172 184.948 126.419 185.251L112.155 191.03C111.783 191.179 111.367 191.174 110.998 191.018C110.629 190.862 110.336 190.566 110.184 190.195C109.856 189.387 109.69 188.521 109.696 187.648C109.703 186.776 109.881 185.913 110.222 185.109C110.562 184.306 111.058 183.577 111.68 182.965C112.303 182.353 113.039 181.87 113.849 181.543C113.982 181.49 114.089 181.385 114.145 181.253C114.202 181.121 114.204 180.972 114.152 180.839C114.097 180.705 113.991 180.598 113.858 180.542C113.725 180.485 113.575 180.483 113.441 180.536C111.541 181.308 110.025 182.802 109.226 184.691C108.426 186.579 108.409 188.708 109.177 190.609C109.304 190.926 109.493 191.214 109.733 191.457C109.972 191.7 110.258 191.893 110.573 192.025C110.892 192.161 111.234 192.23 111.581 192.228Z"
      fill="#231F20"
    />
    <path
      d="M104.781 185.275L118.538 180.455C119.469 180.127 120.272 179.51 120.828 178.694C121.384 177.878 121.664 176.905 121.628 175.918L121.214 164.794C121.187 164.072 121.019 163.361 120.718 162.704C120.417 162.046 119.99 161.454 119.46 160.962C118.93 160.469 118.309 160.086 117.631 159.834C116.953 159.582 116.232 159.466 115.51 159.492L111.771 159.628L112.259 172.828L106.579 174.825C105.689 175.137 104.868 175.622 104.165 176.252C103.462 176.882 102.889 177.644 102.481 178.495C102.072 179.347 101.835 180.27 101.783 181.212C101.732 182.155 101.866 183.099 102.179 183.989C102.266 184.245 102.402 184.48 102.579 184.683C102.757 184.886 102.972 185.052 103.214 185.171C103.456 185.291 103.718 185.361 103.987 185.379C104.256 185.397 104.526 185.361 104.781 185.275Z"
      fill="#D39990"
    />
    <path
      d="M104.101 185.937C104.393 185.937 104.684 185.887 104.96 185.789L118.698 180.968C119.74 180.605 120.639 179.916 121.261 179.004C121.883 178.092 122.195 177.004 122.152 175.901L121.738 164.777C121.738 164.704 121.724 164.633 121.695 164.567C121.666 164.5 121.624 164.441 121.572 164.391C121.519 164.342 121.456 164.304 121.388 164.28C121.32 164.256 121.248 164.246 121.176 164.251C121.033 164.258 120.898 164.32 120.801 164.426C120.703 164.531 120.652 164.67 120.657 164.814L121.065 175.938C121.099 176.809 120.853 177.668 120.362 178.389C119.871 179.11 119.162 179.655 118.339 179.942L104.601 184.763C104.223 184.895 103.807 184.872 103.445 184.698C103.084 184.524 102.806 184.214 102.673 183.836C102.383 183.012 102.259 182.14 102.307 181.269C102.354 180.397 102.573 179.544 102.951 178.757C103.328 177.97 103.857 177.265 104.507 176.683C105.157 176.101 105.916 175.652 106.739 175.363C106.81 175.342 106.875 175.306 106.931 175.259C106.987 175.212 107.034 175.154 107.067 175.089C107.1 175.024 107.12 174.952 107.125 174.879C107.13 174.806 107.12 174.732 107.095 174.663C107.071 174.594 107.033 174.53 106.984 174.476C106.934 174.422 106.875 174.378 106.808 174.348C106.741 174.317 106.669 174.301 106.595 174.299C106.522 174.297 106.449 174.31 106.381 174.337C105.422 174.673 104.539 175.194 103.782 175.871C103.026 176.548 102.41 177.368 101.97 178.284C101.53 179.199 101.275 180.193 101.22 181.207C101.165 182.221 101.31 183.236 101.647 184.194C101.758 184.518 101.933 184.816 102.161 185.07C102.389 185.325 102.666 185.532 102.976 185.677C103.326 185.848 103.711 185.936 104.101 185.937Z"
      fill="#231F20"
    />
    <path
      d="M97.5702 177.508L109.93 175.265C111.069 175.056 112.089 174.429 112.788 173.505C113.487 172.582 113.815 171.43 113.706 170.277L112.736 160.062C112.592 158.612 111.88 157.278 110.756 156.352C109.631 155.425 108.186 154.982 106.735 155.118C105.749 155.213 104.84 155.695 104.208 156.458C103.576 157.221 103.271 158.204 103.361 159.191L104.09 166.953L100.963 167.521C100.034 167.69 99.1475 168.04 98.354 168.552C97.5604 169.064 96.8755 169.727 96.3383 170.503C95.8011 171.28 95.4222 172.155 95.2232 173.078C95.0242 174.001 95.009 174.954 95.1785 175.883C95.2834 176.414 95.5931 176.882 96.0408 177.186C96.4884 177.49 97.0379 177.606 97.5702 177.508Z"
      fill="#D39990"
    />
    <path
      d="M97.1918 178.089C97.3513 178.086 97.5104 178.072 97.6677 178.045L110.028 175.796C111.303 175.571 112.446 174.874 113.229 173.842C114.011 172.811 114.376 171.522 114.249 170.234L113.291 160.012C113.271 159.873 113.199 159.748 113.088 159.661C112.978 159.575 112.839 159.534 112.7 159.547C112.56 159.561 112.432 159.628 112.34 159.734C112.249 159.84 112.202 159.977 112.209 160.117L113.167 170.339C113.267 171.356 112.979 172.373 112.362 173.188C111.744 174.002 110.842 174.554 109.836 174.733L97.4761 176.982C97.2807 177.019 97.0797 177.017 96.8854 176.974C96.6911 176.932 96.5073 176.85 96.3452 176.735C96.1834 176.622 96.0456 176.478 95.9396 176.311C95.8335 176.145 95.7614 175.959 95.7272 175.765C95.4119 174.03 95.7986 172.241 96.8022 170.791C97.8058 169.341 99.3442 168.349 101.079 168.034C101.218 168.004 101.339 167.922 101.418 167.804C101.497 167.686 101.526 167.542 101.5 167.403C101.475 167.264 101.395 167.14 101.28 167.058C101.164 166.976 101.021 166.943 100.881 166.965C99.8822 167.146 98.9286 167.523 98.0751 168.073C97.2216 168.623 96.4848 169.336 95.9069 170.171C95.329 171.006 94.9213 171.947 94.707 172.94C94.4927 173.932 94.476 174.957 94.658 175.956C94.7675 176.554 95.0825 177.095 95.5485 177.486C96.0146 177.876 96.6024 178.092 97.2104 178.095L97.1918 178.089Z"
      fill="#231F20"
    />
    <path
      d="M126.256 190.764L126.114 199.712C126.101 200.641 126.329 201.557 126.775 202.371C127.221 203.186 127.871 203.871 128.66 204.36L132.208 206.56C133.934 207.628 135.286 209.206 136.078 211.076C136.869 212.945 137.061 215.015 136.626 216.998C136.527 217.455 136.326 217.885 136.04 218.255C135.753 218.625 135.387 218.927 134.969 219.137C134.551 219.348 134.091 219.463 133.623 219.473C133.155 219.484 132.69 219.39 132.263 219.198L116.887 212.276L109.076 210.737L126.256 190.764Z"
      fill="#D39990"
    />
    <path
      d="M15.2462 256.805C19.6123 261.078 25.2992 263.744 31.377 264.366C37.4547 264.988 43.5636 263.529 48.7048 260.228L102.959 225.002L73.777 179.629L19.5228 214.855C16.5434 216.773 13.9711 219.259 11.9529 222.171C9.93465 225.083 8.51006 228.365 7.76052 231.828C7.01098 235.291 6.95116 238.867 7.58452 242.354C8.21787 245.84 9.53197 249.167 11.4517 252.145C12.5412 253.833 13.8138 255.396 15.2462 256.805Z"
      fill="#9ABDF3"
    />
    <path
      d="M61.2318 252.879C61.3809 252.879 61.5269 252.837 61.6521 252.756L104.047 225.23L74.043 178.559L27.2418 208.939C27.149 208.991 27.0677 209.061 27.003 209.145C26.9383 209.229 26.8917 209.326 26.866 209.429C26.8403 209.532 26.8361 209.639 26.8537 209.743C26.8712 209.848 26.9102 209.948 26.968 210.037C27.0259 210.126 27.1015 210.202 27.1901 210.26C27.2787 210.319 27.3784 210.358 27.4829 210.377C27.5875 210.395 27.6946 210.391 27.7978 210.366C27.9009 210.341 27.9978 210.295 28.0823 210.231L73.5857 180.697L101.933 224.773L60.824 251.464C60.7387 251.519 60.6651 251.591 60.6075 251.674C60.5498 251.758 60.5092 251.852 60.488 251.951C60.4667 252.051 60.4653 252.153 60.4837 252.253C60.5021 252.353 60.54 252.448 60.5953 252.533C60.6654 252.638 60.76 252.724 60.8709 252.785C60.9818 252.845 61.1056 252.878 61.2318 252.879Z"
      fill="#231F20"
    />
    <path
      d="M187.745 223.141L160.556 223.516C157.771 223.555 155.483 221.328 155.444 218.544L154.593 156.842C154.555 154.057 156.781 151.769 159.566 151.73L186.756 151.355C189.54 151.317 191.829 153.543 191.868 156.328L192.718 218.03C192.757 220.814 190.53 223.103 187.745 223.141Z"
      fill="#2C2829"
    />
    <path
      d="M181.603 155.498L186.633 155.431C187.398 155.42 188.026 156.032 188.036 156.796L188.16 166.028C188.171 166.793 187.559 167.421 186.795 167.431L181.765 167.499C181 167.509 180.372 166.898 180.362 166.133L180.238 156.901C180.227 156.137 180.839 155.509 181.603 155.498Z"
      fill="#211D1E"
    />
    <path
      d="M149.549 192.316C149.625 191.665 149.83 191.035 150.152 190.464C150.474 189.893 150.906 189.391 151.424 188.989C151.941 188.586 152.534 188.291 153.167 188.119C153.8 187.948 154.461 187.904 155.111 187.99L160.055 188.608L189.719 192.786L208.846 201.036L183.508 234.563C183.508 234.563 158.862 228.896 155.834 228.216C152.805 227.536 151.168 225.392 151.508 222.654C151.589 222.005 151.797 221.378 152.12 220.809C152.444 220.24 152.876 219.74 153.392 219.339C153.909 218.937 154.5 218.641 155.13 218.467C155.761 218.294 156.42 218.247 157.07 218.328L155.216 218.099C154.566 218.018 153.939 217.81 153.371 217.487C152.802 217.163 152.302 216.731 151.901 216.214C151.499 215.698 151.203 215.107 151.03 214.476C150.856 213.845 150.809 213.187 150.89 212.537C150.971 211.888 151.179 211.261 151.502 210.692C151.826 210.124 152.258 209.624 152.775 209.222C153.291 208.821 153.882 208.525 154.513 208.351C155.144 208.178 155.802 208.13 156.452 208.211L154.598 207.983C153.948 207.902 153.321 207.693 152.753 207.37C152.184 207.046 151.684 206.614 151.283 206.098C150.881 205.581 150.585 204.991 150.412 204.36C150.238 203.729 150.191 203.07 150.272 202.421C150.353 201.771 150.561 201.145 150.884 200.576C151.208 200.007 151.64 199.507 152.157 199.106C152.673 198.704 153.264 198.408 153.895 198.235C154.526 198.061 155.184 198.014 155.834 198.095L153.98 197.866C153.322 197.798 152.685 197.599 152.105 197.282C151.525 196.965 151.014 196.536 150.601 196.02C150.189 195.503 149.884 194.91 149.703 194.274C149.522 193.638 149.47 192.973 149.549 192.316Z"
      fill="#D39990"
    />
    <path
      d="M208.846 201.036L203.685 180.833L200.917 165.779C200.857 165.454 200.723 165.148 200.523 164.885C200.324 164.622 200.066 164.41 199.769 164.265C199.473 164.12 199.147 164.047 198.817 164.051C198.487 164.055 198.163 164.137 197.87 164.289C196.116 165.209 194.649 166.593 193.628 168.29C192.607 169.987 192.071 171.931 192.079 173.912V187.415L189.7 192.767L208.846 201.036Z"
      fill="#D39990"
    />
    <path
      d="M208.846 201.035L247.396 208.964L231.848 245.358L183.489 234.562L208.846 201.035Z"
      fill="#D39990"
    />
    <path
      d="M301.312 239.723C302.955 235.705 303.605 231.35 303.206 227.028C302.808 222.705 301.372 218.543 299.022 214.893C296.672 211.244 293.476 208.215 289.706 206.064C285.935 203.913 281.702 202.703 277.364 202.537L211.603 199.67L209.533 253.578L275.312 256.47C280.84 256.68 286.299 255.185 290.949 252.19C295.599 249.194 299.217 244.842 301.312 239.723Z"
      fill="#9ABDF3"
    />
    <path
      d="M243.095 255.635C243.295 255.636 243.487 255.56 243.63 255.421C243.774 255.282 243.857 255.093 243.862 254.893C243.868 254.69 243.795 254.493 243.657 254.344C243.52 254.195 243.329 254.106 243.126 254.096L210.372 252.86L212.381 200.491L272.419 203.117C272.624 203.126 272.824 203.053 272.976 202.915C273.127 202.776 273.217 202.583 273.226 202.379C273.235 202.174 273.162 201.974 273.024 201.822C272.885 201.671 272.692 201.581 272.487 201.572L210.885 198.865L208.765 254.318L243.083 255.635H243.095Z"
      fill="#231F20"
    />
    <path
      d="M258.259 65.2168C280.52 65.2168 298.631 83.3278 298.631 105.589C298.631 127.849 280.52 145.961 258.259 145.961C235.998 145.961 217.887 127.849 217.887 105.589C217.887 83.3278 235.998 65.2168 258.259 65.2168ZM241.445 118.011L245.837 122.403L258.259 109.981L270.681 122.403L275.073 118.011L262.651 105.589L275.073 93.1665L270.681 88.7742L258.259 101.196L245.837 88.7742L241.445 93.1665L253.867 105.589L241.445 118.011Z"
      fill="#F599A3"
    />
  </svg>
</template>
