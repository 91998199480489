<template>
  <div class="py-4 theScroll">
    <div class="bg-white rounded p-6">
      <div style="box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15)" class="pb-4">
        <span class="text-2xl font-semibold block">
          {{ $t("index.gestion") }}
        </span>
        <span class="text-[#4D4C4] text-base">
          {{ $t("index.gestionDesc") }}
        </span>
      </div>
      <div
        class="flex items-center justify-between py-4"
        style="box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15)"
        v-for="(row, index) in contacts"
        :key="index"
      >
        <div class="flex space-x-2 items-center">
          <span>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5625 3.12488H3.4375C2.85753 3.1255 2.30149 3.35617 1.89139 3.76627C1.48129 4.17637 1.25062 4.73241 1.25 5.31238V14.6874C1.25062 15.2673 1.48129 15.8234 1.89139 16.2335C2.30149 16.6436 2.85753 16.8743 3.4375 16.8749H16.5625C17.1425 16.8743 17.6985 16.6436 18.1086 16.2335C18.5187 15.8234 18.7494 15.2673 18.75 14.6874V5.31238C18.7494 4.73241 18.5187 4.17637 18.1086 3.76627C17.6985 3.35617 17.1425 3.1255 16.5625 3.12488ZM16.0086 6.74324L10.3836 11.1182C10.2739 11.2035 10.1389 11.2498 10 11.2498C9.86107 11.2498 9.72609 11.2035 9.61641 11.1182L3.99141 6.74324C3.92532 6.69333 3.86981 6.63078 3.8281 6.55924C3.78639 6.48769 3.75932 6.40857 3.74846 6.32646C3.73759 6.24436 3.74315 6.16092 3.76482 6.08099C3.78648 6.00106 3.82381 5.92623 3.87465 5.86085C3.92548 5.79547 3.9888 5.74084 4.06093 5.70015C4.13306 5.65945 4.21255 5.63349 4.2948 5.62379C4.37704 5.61408 4.4604 5.62082 4.54002 5.6436C4.61964 5.66639 4.69394 5.70477 4.75859 5.75652L10 9.83308L15.2414 5.75652C15.3725 5.65754 15.5372 5.61413 15.7 5.63568C15.8629 5.65722 16.0107 5.74199 16.1115 5.87164C16.2123 6.0013 16.258 6.16543 16.2387 6.32853C16.2195 6.49164 16.1368 6.64061 16.0086 6.74324Z"
                fill="#808080"
              />
            </svg>
          </span>
          <span class="font-semibold">{{ row }}</span>
        </div>
        <span class="cursor-pointer" @click="deleteContact(row)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7803 6.21967C18.0732 6.51256 18.0732 6.98744 17.7803 7.28033L7.28033 17.7803C6.98744 18.0732 6.51256 18.0732 6.21967 17.7803C5.92678 17.4874 5.92678 17.0126 6.21967 16.7197L16.7197 6.21967C17.0126 5.92678 17.4874 5.92678 17.7803 6.21967Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
      <div class="mt-10">
        <span class="font-semibold">
          {{ $t("index.gestionAdd") }}
        </span>
        <div class="mt-4">
          <span class="text-lg font-semibold">Email</span>
          <div class="flex gap-6 flex-col md:flex-row items-center md:w-3/4">
            <input
              type="text"
              class="border w-full border-[#C6D6EC] rounded p-3 outline-none"
              v-model="email"
            />
            <button
              v-if="!validEMail"
              class="bg-[#F5F6F6] text-[#B2B2B2] w-full md:w-fit py-4 px-8 shrink-0 rounded"
              disabled
            >
              {{ $t("index.add") }}
            </button>
            <button
              v-else
              @click="addEmail"
              class="bg-[#F11C33] text-white w-full md:w-fit py-4 px-8 shrink-0 rounded"
            >
              {{ $t("index.add") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, onMounted, computed } from "@vue/runtime-core";
import { inject } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const email = ref("");
    const store = useStore();
    const showNotif = inject("$showNotif");
    const $t = inject("$t");
    const validEMail = ref(false);
    const contacts = computed(() => {
      return store.state.contacts;
    });
    watch(email, () => {
      // verify if email is an valid and strong email
      if (
        email.value &&
        email.value.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        console.log(email.value);
        validEMail.value = true;
      } else {
        validEMail.value = false;
      }
    });
    async function addEmail() {
      const data = {
        id: store.state.businessId,
        email: email.value,
      };
      await store.dispatch("CREATE_CONTACT", data);
      email.value = "";
      showNotif({
        success: true,
        note: $t("index.addContact"),
      });
      await store.dispatch("GET_CONTACTS", store.state.businessId);
    }
    async function deleteContact(val) {
      const data = {
        id: store.state.businessId,
        email: val,
      };
      console.log(data);
      await store.dispatch("DELETE_CONTACT", data);
      await store.dispatch("GET_CONTACTS", store.state.businessId);

      showNotif({
        success: true,
        note: $t("index.goodDelete"),
      });
    }
    onMounted(async () => {
      await store.dispatch("GET_CONTACTS", store.state.businessId);
    });
    return {
      email,
      validEMail,
      addEmail,
      contacts,
      deleteContact,
    };
  },
};
</script>

<style>
/* .theScroll {
  padding-bottom: 20% !important;
}
@media screen and (max-width: 800px) {
  .theScroll {
    padding-bottom: 100% !important;
  }
} */
</style>
