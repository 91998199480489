
import { ref, toRefs, toRef, onMounted, inject, watch } from "vue";
import reportIcon from "@/components/svg/report.vue";
import {
  ActionTypes,
  DownloadFilterType,
  TransactionStatus,
} from "@/store/pushup/store.types";
import { TypeOf } from "yup";
import store from "@/store";
import { useStore } from "vuex";
import { ApiCallResult } from "@/api";
import { wait } from "@/utils";

export default {
  components: {
    reportIcon,
  },
  props: {
    dispatchingId: {
      type: String,
      required: true,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: "PENDING",
    },
    // data: {
    //   type: Array,
    //   default: () => [],
    //   required: true,
    // },
    // textClass: {
    //   type: String,
    // },
    // defaultKey: {
    //   type: String,
    //   default: "admin",
    // },
    // addedClass: {
    //   type: String,
    //   default: "py-2 px-4",
    // },
  },
  // data() {
  //   return {
  //     show: false,
  //     actualSelection: "",
  //   };
  // },

  setup(props: any, { emit }: { emit: any }) {
    const store = useStore();
    const $t: any = inject("$t");
    const showNotif: any = inject("$showNotif");
    const status = toRef(props, "status");
    const downloadableTypes: DownloadFilterType[] = [
      {
        type: "ALL",
        label: $t("index.completRepport"),
      },
      {
        type: TransactionStatus.SUCCESS,
        label: $t("index.succesRepport"),
      },
      {
        type: TransactionStatus.PENDING,
        label: $t("index.pendingRepport"),
      },
      {
        type: TransactionStatus.FAILED,
        label: $t("index.failedRepport"),
      },
    ];

    const show = ref(false);
    const isLoading = ref(false);

    // const properties = toRefs(props);

    // const actualSelection = ref(properties.data.value[0]);
    const goToRed = ref(false);
    watch(status, () => {
      console.log(status.value, " status");

      if (status.value === "COMPLETED") {
        goToRed.value = true;
      }
    });
    function toggle() {
      show.value = !show.value;
    }

    const custom_select_button = ref();
    // const custom_select_options = ref(null);

    async function choose(item: DownloadFilterType) {
      // isLoading.value = true;
      try {
        const result: ApiCallResult = await store.dispatch(
          ActionTypes.GET_DOWNLOAD_URL,
          item.type,
        );
        showNotif({
          success: true,
          note: $t("index.emptyReport"),
        });
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorOccured"),
        });
      }
      // isLoading.value = false;
      show.value = false;
    }

    onMounted(() => {
      // const found = properties.data.value.find(
      //   (d) => d.value === properties.defaultKey.value,
      // );
      // if (found) choose(found);

      document.addEventListener("click", (event) => {
        if (
          custom_select_button.value &&
          !custom_select_button.value.contains(event.target)
        ) {
          show.value = false;
        }
      });
    });

    return {
      show,
      toggle,
      status: props.status,
      downloadableTypes,
      choose,
      isLoading,
      goToRed,
    };
  },
  // methods: {
  //   toggle() {
  //     this.show = !this.show;
  //   },
  //   choose(item) {
  //     this.actualSelection = item;
  //     this.$emit("change", item);
  //     this.show = false;
  //   },
  // },
};
