<template>
  <div>
    <div class="table-container overflow-auto">
      <!--table--->
      <table
        data-pushup-item="datatable"
        class="select-none w-full border-collapse"
      >
        <thead class="bg-white select-none text-left hidden md:flex">
          <tr class="border-b border-[rgba(0,0,0,0.15)]">
            <!--headers-->
            <!--checkbox-->
            <!-- <th class="h-[48px] px-3" v-if="checkable">
              <label class="checkbox-container select-none">
                <input
                  type="checkbox"
                  :id="'all'"
                  ref="checkAll"
                  @click="checkAllRow($event)"
                />
                <span class="checkmark checkmark-all"></span>
              </label>
            </th> -->
            <th class="px-3 py-3.5">N°</th>
            <th
              class="truncate px-3 py-3.5 text-left text-334952 font-semibold text-sm leading-4 cursor-pointer"
              v-for="(col, index) in columns"
              :key="index"
            >
              {{ col }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <slot name="mobile" />
          <tr
            class="border-b border-[rgba(0,0,0,0.15)] hover:bg-FDF7E8 cursor-pointer"
            :class="{ 'checked-row': isCheckedRow(row.id) }"
            v-for="(row, index) in data"
            :key="index"
            @mouseover="onMouseOver(row)"
            @mouseleave="onMouseLeave(row)"
          >
            <!--checkbox-->

            <!-- <td
              class="flex justify-center items-center h-[47px] w-full"
              v-if="checkable"
            >
              <label class="checkbox-container select-none">
                <input
                  type="checkbox"
                  :id="row.id"
                  @click="checkRow(row.id)"
                  data-pushup-item="datatable-checkbox"
                  :checked="isCheckedRow(row.id)"
                />
                <span class="checkmark"></span>
              </label>
            </td> -->
            <td class="px-3 hidden md:flex">
              {{ index + 1 }}
            </td>
            <td
              class="h-[47px] px-3"
              v-for="(col, key) in columns"
              :key="key"
              :data-pushup-table-hoveraction="key === colAction"
            >
              <slot v-if="colSlots.includes(key)" :name="key" :row="row" />
              <template v-else> {{ row[key] }} </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- empty table -->
    <div
      class="table-empty-case w-full flex flex-col items-center justify-center md:mt-28 md:mb-28 mb-1O mt-10"
    >
      <template v-if="!$slots.customEmpty">
        <div class="mb-10">
          <empty />
        </div>
        <span
          class="block font-semibold text-black text-center text-xl leading-relaxed tracking-tight mb-1"
        >
          {{ $t("index.empty") }}
        </span>
        <span
          class="block text-center text-394452 font-medium text-xs leading-5"
        >
          {{ $t("index.empty") }}
        </span>
      </template>
      <slot v-else name="customEmpty"></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, toRef } from "vue";
import Card from "./Card.vue";
import Paginator from "./Paginator.vue";
import Spinner from "./Spinner.vue";
import Empty from "@/components/global/empty.vue";
export default defineComponent({
  components: {
    Card,
    Paginator,
    Spinner,
    Empty,
  },
  computed: {
    colSlots() {
      return Object.keys(this.$slots).filter((slot) => {
        return Object.keys(this.columns).includes(slot);
      });
    },
  },

  props: {
    colAction: {
      title: String,
    },
    showPaginator: {
      type: Boolean,
      default: true,
    },
    card: {
      type: Boolean,
      default: true,
    },
    data: {
      type: [Array, Object],
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: [Array, Object],
      default: () => [],
    },
    checkable: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    totalData: {
      type: Number,
      default: 0,
    },
    selectedRows: {
      type: Array,
      default: [],
    },
  },
  setup(props, context) {
    const size = toRef(props, "totalData");
    const pagination = ref(null);

    const checkAll = ref(null);
    const checkBoxElement = ref(
      "table[data-pushup-item=datatable] input[data-pushup-item=datatable-checkbox]",
    );
    // const rowsChecked = ref([]);

    const onMouseOver = (row) => {
      context.emit("onMouseover", row);
    };

    const onMouseLeave = (row) => {
      context.emit("onMouseleave", row);
    };

    const updateDataValues = (props) => {
      context.emit("paginate", props);
    };

    // watch(rowsChecked, () => {
    //   const checkboxs = Array.from(
    //     document.querySelectorAll(checkBoxElement.value),
    //   );
    //   checkAll.value.checked = rowsChecked.value.length === checkboxs.length;
    //   context.emit("rowSelected", Array.from(rowsChecked.value));
    // });

    const checkRow = (rowId) => {
      context.emit("rowSelected", {
        rowId,
        operationType: isCheckedRow(rowId) ? "REMOVE" : "ADD",
      });
      // const id = $event.target.getAttribute("id");
      // rowsChecked.value = rowsChecked.value.filter((item) => item !== id);
      // if ($event.target.checked) rowsChecked.value.push(id);
    };

    const checkAllRow = () => {
      console.log(props.data, "-------------props.data------------------");
      context.emit(
        "checkAllRow",
        props.selectedRows.length === props.data.length
          ? "UNCHECK_ALL"
          : "CHECK_ALL",
      );
      // rowsChecked.value = [];
      // const checkboxs = document.querySelectorAll(checkBoxElement.value);
      // Array.from(checkboxs).forEach((row) => {
      //   row.checked = $event.target.checked;
      //   if (row.checked) rowsChecked.value.push(row.getAttribute("id"));
      // });
    };
    const paginatorChange = (data) => {
      console.log(data);
      context.emit("paginateChoose", data);
    };

    function isCheckedRow(rowId) {
      return props.selectedRows.includes(rowId);
    }

    return {
      onMouseOver,
      onMouseLeave,
      size,
      updateDataValues,
      checkRow,
      checkAllRow,
      checkAll,
      pagination,
      paginatorChange,
      isCheckedRow,
    };
  },
});
</script>

<style lang="scss" scoped>
// table scoped scss
table[data-pushup-item="datatable"] tr td {
  /* padding: 14px 14px; */
}

table[data-pushup-item="datatable"]
  tr
  td[data-pushup-table-hoveraction="true"] {
  opacity: 0;
}

table[data-pushup-item="datatable"] tr:hover {
  background: #fff9e2;
  cursor: pointer;
}

table[data-pushup-item="datatable"]
  tr:hover
  td[data-pushup-table-hoveraction="true"] {
  opacity: 1;
}

table[data-pushup-item="datatable"] tr.checked-row {
  background: #e6effe;
}

/* Customize the label (the container) */
.checkbox-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.checkmark {
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #bcc4ce;
  box-sizing: border-box;
  border-radius: 2px;
  /* background: white;
  border: 1px solid rgba(4, 59, 209, 0.5);
  border-radius: 2px;
  transform: scale(0.8); */
}

.checkbox-container input:checked ~ .checkmark {
  background: #0075c9;
  border: none;
}

.checkmark::after {
  position: absolute;
  display: none;
  content: "";
}

.checkbox-container input:checked ~ .checkmark::after {
  display: block;
}

.checkbox-container .checkmark::after {
  left: 4px;
  bottom: 2px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox-container .checkmark-all::after {
  /* top: 2px;
  left: 10px; */
  top: -2px;
  width: 2px;
  height: 20px;
  background: white;
  transform: rotate(89deg);
}
</style>
