<template>
  <div>
    <h1 class="text-primaryColor font-semibold text-medium mb-6">
      {{ $t("index.notifications") }}
    </h1>
    <div class="flex justify-between">
      <div class="flex space-x-2 items-center">
        <notif />
        <span class="font-bold">{{ $t("index.versementCreationNotif") }}</span>
      </div>
      <div class="flex">
        <toggleRed
          @click="clickMe('START')"
          class="cursor-pointer"
          v-if="UserInfo.ownerSettings[0].sendReportOnDispatchingCreate"
        />

        <toggleWhite @click="clickMe('START')" class="cursor-pointer" v-else />
      </div>
    </div>
    <div class="flex justify-between mt-7">
      <div class="flex space-x-2 items-center">
        <notif />
        <span class="font-bold">{{ $t("index.versementEndingNotif") }}</span>
      </div>
      <div class="flex">
        <toggleRed
          @click="clickMe('END')"
          class="cursor-pointer"
          v-if="UserInfo.ownerSettings[0].sendReportOnDispatchingEnd"
        />

        <toggleWhite @click="clickMe('END')" class="cursor-pointer" v-else />
      </div>
    </div>
    <modal
      :title="$t('index.settingsUpdateConfirmationTitle')"
      v-if="showModal"
      @close="showModal = false"
    >
      <div>
        <span>
          {{
            whatNotif == "START"
              ? $t("index.dispatchingCreationNotificationSettings")
              : $t("index.dispatchingEndNotificationSettings")
          }}
        </span>
      </div>
      <div class="flex justify-between gap-4 mt-4">
        <whiteButton
          class="w-full"
          :label="$t('index.cancel')"
          @click="showModal = false"
        />

        <primaryButton
          class="w-full"
          :label="$t('index.yesValidate')"
          @click="submit"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import primaryButton from "../components/global/primaryButton.vue";
import whiteButton from "../components/global/redBorderButton.vue";
import { ref, computed, inject } from "vue";
import modal from "../components/global/modal.vue";
import toggleWhite from "../components/svg/toggleWhite.vue";
import toggleRed from "../components/svg/toggleRed.vue";
import notif from "../components/svg/notif.vue";
import { useStore } from "vuex";
export default {
  components: {
    whiteButton,
    primaryButton,
    modal,
    notif,
    toggleRed,
    toggleWhite,
  },
  setup() {
    const showModal = ref(false);
    const whatNotif = ref("");
    const store = useStore();
    const showNotif = inject("$showNotif");
    const $t = inject("$t");

    const UserInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    function clickMe(data) {
      showModal.value = true;
      whatNotif.value = data;
    }
    async function submit() {
      const payload = {
        id: UserInfo.value.id,
        body: {
          sendReportOnDispatchingCreate: undefined,
          sendReportOnDispatchingEnd: undefined,
        },
      };
      if (whatNotif.value == "START") {
        payload.body.sendReportOnDispatchingCreate =
          !UserInfo.value.ownerSettings[0].sendReportOnDispatchingCreate;
      }
      if (whatNotif.value == "END") {
        payload.body.sendReportOnDispatchingEnd =
          !UserInfo.value.ownerSettings[0].sendReportOnDispatchingEnd;
      }
      console.log(payload);
      try {
        await store.dispatch("SET_NOTIFICATIONS_SETTINGS", payload);
        await store.dispatch("ME");

        showNotif({
          note: $t("index.changesSaved"),
          success: true,
        });
        showModal.value = false;
      } catch (error) {
        showNotif({
          note: $t("index.errorMessage"),
          error: true,
        });
      }
    }
    return {
      whatNotif,
      showModal,
      clickMe,
      UserInfo,
      submit,
    };
  },
};
</script>

<style></style>
