<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5516 7.94839C11.8282 8.22499 11.9858 8.5987 11.9908 8.98985C11.9958 9.38099 11.8478 9.75862 11.5784 10.0422L11.5695 10.0515L11.5605 10.0605L9.0605 12.5605L9.06027 12.5608C8.77899 12.8419 8.39761 12.9998 7.99995 12.9998C7.6023 12.9998 7.22092 12.8419 6.93964 12.5608L6.93941 12.5605L4.43941 10.0605L4.43036 10.0515L4.42154 10.0422C4.15211 9.75861 4.00412 9.38099 4.00913 8.98985L4.00913 8.98985C4.01414 8.5987 4.17174 8.22499 4.44835 7.94839C4.72495 7.67179 5.09866 7.51418 5.4898 7.50917C5.86185 7.50441 6.22166 7.63807 6.49995 7.88299V6.5H4.25059C4.05161 6.50033 3.86088 6.57951 3.72017 6.72022C3.57947 6.86092 3.50028 7.05166 3.49995 7.25063V13.7494C3.50028 13.9483 3.57947 14.1391 3.72017 14.2798C3.86083 14.4204 4.05149 14.4996 4.25039 14.5C4.2506 14.5 4.25081 14.5 4.25102 14.5H11.7489C11.7491 14.5 11.7493 14.5 11.7495 14.5C11.9484 14.4996 12.1391 14.4204 12.2797 14.2798C12.4204 14.1391 12.4996 13.9485 12.5 13.7496C12.5 13.7494 12.5 13.7491 12.5 13.7489V7.25107C12.5 7.25086 12.5 7.25064 12.5 7.25043C12.4996 7.05153 12.4204 6.86087 12.2797 6.72022C12.139 6.57951 11.9483 6.50033 11.7493 6.5H9.49995V7.88299C9.77825 7.63807 10.1381 7.50441 10.5101 7.50917C10.9013 7.51418 11.275 7.67179 11.5516 7.94839ZM11.5516 7.94839L10.8445 8.6555L11.5516 7.94839Z"
      fill="#F11C33"
      stroke="#F11C33"
      stroke-width="2"
    />
    <mask id="path-2-inside-1_6087_21870" fill="white">
      <path
        d="M8.49996 2C8.49996 1.86739 8.44728 1.74021 8.35351 1.64645C8.25974 1.55268 8.13256 1.5 7.99995 1.5C7.86735 1.5 7.74017 1.55268 7.6464 1.64645C7.55263 1.74021 7.49995 1.86739 7.49995 2V5.5H8.49996V2Z"
      />
    </mask>
    <path
      d="M8.49996 2C8.49996 1.86739 8.44728 1.74021 8.35351 1.64645C8.25974 1.55268 8.13256 1.5 7.99995 1.5C7.86735 1.5 7.74017 1.55268 7.6464 1.64645C7.55263 1.74021 7.49995 1.86739 7.49995 2V5.5H8.49996V2Z"
      fill="#F11C33"
    />
    <path
      d="M7.99995 1.5V-0.5V1.5ZM7.49995 5.5H5.49995V7.5H7.49995V5.5ZM8.49996 5.5V7.5H10.5V5.5H8.49996ZM10.5 2C10.5 1.33696 10.2366 0.701076 9.76772 0.232232L6.9393 3.06066C6.65799 2.77935 6.49996 2.39782 6.49996 2H10.5ZM9.76772 0.232232C9.29888 -0.23661 8.66299 -0.5 7.99995 -0.5V3.5C7.60213 3.5 7.2206 3.34197 6.9393 3.06066L9.76772 0.232232ZM7.99995 -0.5C7.33691 -0.5 6.70103 -0.236608 6.23219 0.232232L9.06061 3.06066C8.77931 3.34197 8.39778 3.5 7.99995 3.5V-0.5ZM6.23219 0.232232C5.76335 0.701072 5.49995 1.33696 5.49995 2H9.49995C9.49995 2.39783 9.34192 2.77936 9.06061 3.06066L6.23219 0.232232ZM5.49995 2V5.5H9.49995V2H5.49995ZM7.49995 7.5H8.49996V3.5H7.49995V7.5ZM10.5 5.5V2H6.49996V5.5H10.5Z"
      fill="#F11C33"
      mask="url(#path-2-inside-1_6087_21870)"
    />
  </svg>
</template>
