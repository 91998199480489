import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M9.99997 18.7499C10.6078 18.7493 11.2023 18.5717 11.7107 18.2387C12.2192 17.9057 12.6197 17.4318 12.8633 16.8749H7.13669C7.38022 17.4318 7.7807 17.9057 8.28919 18.2387C8.79768 18.5717 9.39216 18.7493 9.99997 18.7499Z",
      fill: $setup.fillColor
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M15.625 11.2499V8.88549C15.625 6.13275 14.5563 3.7347 11.875 3.12494L11.5625 1.24994H8.4375L8.125 3.12494C5.43438 3.7347 4.375 6.12338 4.375 8.88549V11.2499L2.5 13.7499V15.6249H17.5V13.7499L15.625 11.2499Z",
      fill: $setup.fillColor
    }, null, 8, _hoisted_3)
  ]))
}