<template>
  <div class="relative">
    <div class="flex flex-col items-end">
      <!-- Button Generate statement-->
      <whiteBtn @click="toggle()" :label="$t('index.generator')"> </whiteBtn>
      <!-- -->
      <div v-if="isOpen" class="mt-14 right-0 z-10 absolute">
        <dropdown @apply="toggle" />
      </div>
    </div>
  </div>
</template>

<script>
import whiteBtn from "@/components/global/redBorderButton.vue";
import Dropdown from "@/components/global/dropdownStatement.vue";
import store from "@/store";

export default {
  components: {
    whiteBtn,
    Dropdown,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    generateStatement() {
      toggle();
    },
  },
};
</script>

<style lang="scss" scoped></style>
