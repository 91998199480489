<template>
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_4135_84060)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M34 18C34 26.8366 26.8366 34 18 34C9.16344 34 2 26.8366 2 18C2 9.16344 9.16344 2 18 2C26.8366 2 34 9.16344 34 18ZM18 32.7031C26.1203 32.7031 32.7031 26.1203 32.7031 18C32.7031 9.8797 26.1203 3.2969 18 3.2969C9.8797 3.2969 3.2969 9.8797 3.2969 18C3.2969 26.1203 9.8797 32.7031 18 32.7031Z" fill="white"/>
</g>
<path d="M32.8002 18C32.8002 26.1738 26.174 32.8 18.0002 32.8C9.82638 32.8 3.2002 26.1738 3.2002 18C3.2002 9.82614 9.82638 3.19995 18.0002 3.19995C26.174 3.19995 32.8002 9.82614 32.8002 18Z" fill="#FECE01"/>
<path d="M8.385 15.4844C8.385 15.4844 12.8782 7.65724 13.0011 7.60412C13.124 7.551 15.0551 8.07802 16.2476 8.99042L18.6692 7.49893C18.6692 7.49893 18.9504 7.48122 19.0733 7.58642C19.1962 7.69161 20.0909 8.90293 20.4075 9.46432L25.3393 11.1318C25.3393 11.1318 25.7257 11.588 25.7257 12.115C25.7257 12.6421 20.0743 27.7528 20.0743 27.7528C20.0743 27.7528 19.1619 28.1569 18.9161 27.9986C18.6702 27.8403 12.4574 25.8228 12.2471 25.4718C12.0367 25.1208 11.72 24.6469 12.1064 23.8751C12.4929 23.1033 14.1604 18.1185 14.1604 18.1185C14.1604 18.1185 11.0534 16.6093 8.57977 15.9427C8.57873 15.9406 8.21002 15.7896 8.385 15.4844Z" fill="#FDFEFA"/>
<path d="M20.5344 23.0844C20.1969 22.073 19.6522 21.17 18.8804 20.4618C18.1159 19.7598 17.2733 19.1265 16.3984 18.5672C14.5601 17.3923 12.5489 16.5695 10.4981 15.8415C10.0773 15.6925 9.65545 15.5436 9.19092 15.379C9.57525 14.7031 9.94083 14.0459 10.32 13.3959C11.2074 11.8742 12.1083 10.3598 12.9863 8.83293C13.1655 8.52255 13.3092 8.37777 13.706 8.52984C14.9236 8.99332 15.9964 9.68803 17.015 10.4806C18.871 11.9263 19.9345 13.875 20.5146 16.1071C20.6812 16.7476 20.7427 17.4215 20.7906 18.0839C20.8531 18.9369 20.8937 19.7952 20.8677 20.6492C20.8437 21.4554 20.7198 22.2584 20.6406 23.0625C20.6052 23.0698 20.5698 23.0771 20.5344 23.0844ZM14.7486 10.5265C13.1457 10.4359 11.7583 11.8076 11.7521 13.4574C11.7458 15.0989 13.104 16.4872 14.8371 16.432C16.2661 16.3862 17.7024 15.178 17.7097 13.4761C17.716 11.9107 16.4036 10.4504 14.7486 10.5265Z" fill="#016185"/>
<path d="M14.9691 18.5122C15.6825 18.9726 16.372 19.3913 17.0344 19.8496C18.2103 20.6609 19.2206 21.6327 19.7112 23.0138C19.8706 23.4616 19.906 23.9637 19.9351 24.4448C19.9508 24.6938 19.8414 24.9031 19.4696 24.7636C17.8021 24.1397 16.1241 23.5408 14.4483 22.9377C13.5161 22.6024 13.4765 22.5399 13.8119 21.616C14.1764 20.6161 14.5545 19.6235 14.9691 18.5122Z" fill="#DF001B"/>
<path d="M20.4077 9.46399C21.8107 10.0139 23.3418 10.5951 24.7739 11.0607C25.4311 11.2742 25.554 11.8522 25.3644 12.3761C24.402 15.0435 23.4334 17.7088 22.4616 20.3731C21.6347 22.6406 20.8014 24.9059 19.9682 27.1702C19.7526 27.7566 19.3818 27.9483 18.7965 27.7379C16.7998 27.0223 14.8188 26.261 12.8097 25.5829C12.0243 25.3173 11.9358 24.7611 12.1514 24.1383C12.4889 23.1624 12.8586 22.1979 13.2117 21.2282C13.5773 20.2242 13.9397 19.2191 14.3084 18.2005C14.1585 18.1369 14.0314 18.0838 13.8741 18.0171C13.6366 18.665 13.4033 19.3014 13.1711 19.9367C12.643 21.3813 12.0993 22.8208 11.5942 24.2747C11.3244 25.0517 11.6619 25.6642 12.4451 25.9516C13.2159 26.2349 13.9866 26.5193 14.7584 26.7984C16.0832 27.2775 17.406 27.766 18.7381 28.2243C19.5047 28.4878 20.1255 28.1785 20.39 27.4587C21.291 25.0038 22.1971 22.552 23.0928 20.095C24.024 17.5432 24.9489 14.9904 25.8633 12.4334C25.9467 12.2012 25.981 11.9293 25.9539 11.6856C25.8873 11.0867 25.4675 10.7951 24.9353 10.6055C23.824 10.2108 22.72 9.79624 21.6107 9.39316C21.0754 9.19839 20.5369 9.01092 19.9994 8.82031L20.4077 9.46399Z" fill="black"/>
<path d="M21.9841 19.6194C22.3997 16.4562 22.1862 13.3857 20.8301 10.3923C21.6873 10.7038 22.5059 11.0006 23.3235 11.2974C23.612 11.4026 23.8995 11.511 24.1891 11.613C24.6609 11.7807 24.7494 11.9817 24.5755 12.4588C23.7766 14.6554 22.9809 16.853 22.1851 19.0507C22.1174 19.2403 22.0508 19.4298 21.9841 19.6194Z" fill="black"/>
<path d="M21.3672 16.4666C20.6673 13.5471 19.333 11.1255 16.8281 9.41738C17.4572 9.03722 18.0363 8.68726 18.6508 8.31543C20.5214 10.7037 21.3818 13.4044 21.3672 16.4666Z" fill="#016185"/>
<path d="M16.2731 26.0095C15.7981 26.0168 15.4398 25.6751 15.4346 25.2075C15.4294 24.7763 15.7908 24.3972 16.2241 24.3784C16.6095 24.3617 17.0553 24.7825 17.0678 25.1752C17.0803 25.5856 16.6824 26.0032 16.2731 26.0095Z" fill="black"/>
<path d="M17.417 26.1271C17.5232 25.8323 17.6243 25.549 17.7284 25.2605C18.2273 25.4365 18.7116 25.6292 19.2084 25.7761C19.549 25.8761 19.6355 26.0708 19.5397 26.3791C19.4417 26.6937 19.2126 26.7989 18.8845 26.6791C18.3877 26.4989 17.894 26.3073 17.417 26.1271Z" fill="black"/>
<path d="M15.0434 24.273C14.9403 24.5688 14.8413 24.8521 14.7372 25.1521C14.1998 24.9615 13.654 24.7876 13.127 24.5688C12.9562 24.498 12.7697 24.3647 12.9197 24.0793C13.0488 23.8325 13.0968 23.5637 13.4811 23.7127C13.9914 23.9116 14.5112 24.0835 15.0434 24.273Z" fill="black"/>
<path d="M13.4316 14.9743C12.9515 14.5014 13.6222 13.8849 13.6222 13.8849L13.3774 13.637C12.5203 14.3556 12.8338 15.218 13.6139 15.5982C14.2482 15.9075 14.7231 15.167 14.7585 14.9618L14.5075 14.7358C14.5075 14.7358 14.1065 15.7013 13.466 15.0139L13.4316 14.9743Z" fill="#016185"/>
<path d="M13.6631 13.222L15.039 14.5354L15.2744 14.2375L14.6994 13.673L15.0442 13.1647L14.7921 12.8981L14.4068 13.4782L14.1089 13.1554L14.5307 12.5648L14.2786 12.2971L13.6631 13.222Z" fill="#016185"/>
<path d="M15.7275 13.4712L14.6641 11.76L14.9297 11.283L16.569 12.1797L16.3732 12.488L15.916 12.2662L15.6171 12.6849L15.9389 13.1473L15.7275 13.4712Z" fill="#016185"/>
<path d="M15.4499 12.4473L15.001 11.7588L15.7071 12.089L15.4499 12.4473Z" fill="#FDFEFA"/>
<defs>
<filter id="filter0_d_4135_84060" x="0.378872" y="0.378872" width="35.2423" height="35.2423" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="0.810564"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4135_84060"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4135_84060" result="shape"/>
</filter>
</defs>
</svg>

</template>