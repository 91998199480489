<template>
  <svg
    width="88"
    height="87"
    viewBox="0 0 88 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="44" cy="43.5" r="43.5" fill="#3FD578" />
    <path d="M22.5 42.5L37.5 57.5L66 29" stroke="white" stroke-width="8" />
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
};
</script>

<style></style>
