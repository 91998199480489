<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const me = computed(() => {
      return store.getters["getMeInfo"];
    });
    onMounted(() => {
      // if (!me.value.permissions.includes("HAS_ACCESS")) {
      //   router.push("/removeAccess");
      // }
    });
  },
};
</script>

<style></style>
