<template>
  <div>
    <!-- <div
      class="h-screen flex flex-col items-center justify-center"
      v-if="loader"
    >
      <span class="loader"></span>
    </div> -->
    <div class="w-full">
      <Topbar />
      <div class="flex p-0">
        <Sidebar class="w-full hidden md:flex" />
        <div
          class="flex-grow-0 md:p-6 p-4 overflow-y-scroll w-full bg-bgprimary h-screen"
        >
          <div class=" ">
            <router-view />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../views/footer.vue";
import Sidebar from "@/components/layouts/sideBar.vue";
import Topbar from "@/components/layouts/topBar.vue";

import { onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  components: {
    Footer,
    Sidebar,
    Topbar,
  },
  setup() {
    const store = useStore();
    const loader = ref(false);

    return {
      loader,
    };
  },
};
</script>

<style>
.loader {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #de3500 #0000 #de3500 #0000;
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.loader:before,
.loader:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #de3500;
  transform: translate(-10px, 19px) rotate(-35deg);
}
.loader:after {
  border-color: #de3500 #0000 #0000 #0000;
  transform: translate(32px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
