<template>
  <div>
    <error-card
      @changeFile="toggleFileModal"
      @cancel="cancelRun"
      :cancelBtn="false"
      :show="
        currentCampagn.globalErrorsReports &&
        (currentCampagn.globalErrorsReports['CT-DTG-05'] ||
          currentCampagn.globalErrorsReports['CT-DTG-12'])
      "
      class="mt-4"
      v-if="notEmptyObject(reports)"
      :errors="reports"
    >
      <template #custom>
        <primaryButton
          v-if="
            currentCampagn.globalErrorsReports &&
            currentCampagn.globalErrorsReports['CT-DTG-05']
          "
          :label="$t('index.doAppro')"
          class="mt-4 w-full md:w-fit bg-purple"
          @click="gotoAppro"
        >
          <template #icon>
            <span>
              <add />
            </span>
          </template>
        </primaryButton>
        <primaryButton
          v-if="currentCampagn.globalErrorsReports['CT-DTG-12']"
          :label="$t('index.buyPlan')"
          class="mt-4 w-full md:w-fit bg-purple"
          @click="gotoPlan"
        >
          <template #icon>
            <span>
              <add />
            </span>
          </template>
        </primaryButton>
      </template>
    </error-card>
  </div>
</template>

<script>
import { ref, toRef, inject, computed, onMounted } from "vue";
import { useStore } from "vuex";
import errorCard from "../global/errorCard.vue";
import { formatErrors, wait } from "@/utils/index";
import primaryButton from "../global/primaryButton.vue";
import { useRouter } from "vue-router";
export default {
  props: {
    errors: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  components: {
    primaryButton,
    errorCard,
  },
  setup(props) {
    const store = useStore();
    const $t = inject("$t");
    const router = useRouter();
    const reports = ref({});
    const errors = toRef(props, "errors");
    const notEmptyObject = (someObject) => {
      return Object.keys(someObject).length > 0;
    };
    const currentCampagn = computed(() => {
      return store.getters["getCampaign"] ?? "";
    });
    function gotoPlan() {
      router.push({ name: "subscription" });
    }
    onMounted(async () => {
      reports.value = formatErrors(errors.value, $t);
      console.log(reports.value);
    });
    return {
      notEmptyObject,
      reports,
      gotoPlan,
      currentCampagn,
    };
  },
};
</script>

<style></style>
