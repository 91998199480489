<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      d="M19.5516 15.4484C19.8282 15.725 19.9858 16.0987 19.9908 16.4899C19.9958 16.881 19.8478 17.2586 19.5784 17.5422L19.5696 17.5515L19.5605 17.5605L17.0605 20.0605L17.0603 20.0608C16.779 20.3419 16.3976 20.4998 16 20.4998C15.6023 20.4998 15.2209 20.3419 14.9397 20.0608L14.9394 20.0605L12.4394 17.5605L12.4304 17.5515L12.4216 17.5422C12.1521 17.2586 12.0041 16.881 12.0091 16.4898L12.0091 16.4898C12.0142 16.0987 12.1718 15.725 12.4484 15.4484C12.725 15.1718 13.0987 15.0142 13.4898 15.0092C13.8619 15.0044 14.2217 15.1381 14.5 15.383V14H12.2506C12.0516 14.0003 11.8609 14.0795 11.7202 14.2202C11.5795 14.3609 11.5003 14.5517 11.5 14.7506V21.2494C11.5003 21.4483 11.5795 21.6391 11.7202 21.7798C11.8608 21.9204 12.0515 21.9996 12.2504 22C12.2506 22 12.2508 22 12.251 22H19.7489C19.7491 22 19.7493 22 19.7495 22C19.9484 21.9996 20.1391 21.9204 20.2798 21.7798C20.4204 21.6391 20.4996 21.4485 20.5 21.2496C20.5 21.2494 20.5 21.2491 20.5 21.2489V14.7511C20.5 14.7509 20.5 14.7506 20.5 14.7504C20.4996 14.5515 20.4204 14.3609 20.2798 14.2202C20.139 14.0795 19.9483 14.0003 19.7493 14H17.5V15.383C17.7783 15.1381 18.1381 15.0044 18.5101 15.0092C18.9013 15.0142 19.275 15.1718 19.5516 15.4484ZM19.5516 15.4484L18.8445 16.1555L19.5516 15.4484Z"
      fill="#F11C33"
      stroke="#F11C33"
      stroke-width="2"
    />
    <mask id="path-4-inside-1_6087_31344" fill="white">
      <path
        d="M16.5 9.5C16.5 9.36739 16.4473 9.24021 16.3535 9.14645C16.2598 9.05268 16.1326 9 16 9C15.8674 9 15.7402 9.05268 15.6464 9.14645C15.5526 9.24021 15.5 9.36739 15.5 9.5V13H16.5V9.5Z"
      />
    </mask>
    <path
      d="M16.5 9.5C16.5 9.36739 16.4473 9.24021 16.3535 9.14645C16.2598 9.05268 16.1326 9 16 9C15.8674 9 15.7402 9.05268 15.6464 9.14645C15.5526 9.24021 15.5 9.36739 15.5 9.5V13H16.5V9.5Z"
      fill="#F11C33"
    />
    <path
      d="M16 9V7V9ZM15.5 13H13.5V15H15.5V13ZM16.5 13V15H18.5V13H16.5ZM18.5 9.5C18.5 8.83696 18.2366 8.20108 17.7677 7.73223L14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5H18.5ZM17.7677 7.73223C17.2989 7.26339 16.663 7 16 7V11C15.6021 11 15.2206 10.842 14.9393 10.5607L17.7677 7.73223ZM16 7C15.3369 7 14.701 7.26339 14.2322 7.73223L17.0606 10.5607C16.7793 10.842 16.3978 11 16 11V7ZM14.2322 7.73223C13.7634 8.20107 13.5 8.83696 13.5 9.5H17.5C17.5 9.89783 17.3419 10.2794 17.0606 10.5607L14.2322 7.73223ZM13.5 9.5V13H17.5V9.5H13.5ZM15.5 15H16.5V11H15.5V15ZM18.5 13V9.5H14.5V13H18.5Z"
      fill="#F11C33"
      mask="url(#path-4-inside-1_6087_31344)"
    />
    <path
      d="M4 1H28V-1H4V1ZM31 4V28H33V4H31ZM28 31H4V33H28V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM31 28C31 29.6569 29.6569 31 28 31V33C30.7614 33 33 30.7614 33 28H31ZM28 1C29.6569 1 31 2.34315 31 4H33C33 1.23858 30.7614 -1 28 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F11C33"
    />
  </svg>
</template>
