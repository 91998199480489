<template>
  <div class="flex flex-col">
    <div class="border-b border-greyLith flex py-4 space-x-4 items-center">
      <div>
        <span v-if="status == 'Info'">
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="64" height="64" rx="32" fill="#DCDEFB" />
            <path
              d="M45.0085 45.0079H18.1791C17.732 45.0079 17.3662 44.6421 17.3662 44.195V40.5366C17.3662 40.0894 17.732 39.7236 18.1791 39.7236H45.0085C45.4557 39.7236 45.8215 40.0894 45.8215 40.5366V44.195C45.8215 44.6419 45.4557 45.0079 45.0085 45.0079ZM18.9922 43.3817H44.1955V41.3492H18.9922V43.3817Z"
              fill="#8E92F3"
            />
            <path
              d="M47.0409 48.6666H16.1464C15.6993 48.6666 15.3335 48.3008 15.3335 47.8537V44.1953C15.3335 43.7481 15.6993 43.3823 16.1464 43.3823H47.0409C47.488 43.3823 47.8538 43.7481 47.8538 44.1953V47.8537C47.8538 48.3008 47.488 48.6666 47.0409 48.6666ZM16.9594 47.0407H46.2278V45.0082H16.9594V47.0407Z"
              fill="#8E92F3"
            />
            <path
              d="M21.0244 38.5039C20.5772 38.5039 20.2114 38.1381 20.2114 37.6909V26.3088C20.2114 25.8616 20.5772 25.4958 21.0244 25.4958C21.4715 25.4958 21.8373 25.8616 21.8373 26.3088V37.6909C21.8376 38.1381 21.4715 38.5039 21.0244 38.5039Z"
              fill="#8E92F3"
            />
            <path
              d="M26.309 38.5039C25.8619 38.5039 25.4961 38.1381 25.4961 37.6909V26.3088C25.4961 25.8616 25.8619 25.4958 26.309 25.4958C26.7562 25.4958 27.122 25.8616 27.122 26.3088V37.6909C27.122 38.1381 26.7562 38.5039 26.309 38.5039Z"
              fill="#8E92F3"
            />
            <path
              d="M31.5932 38.5039C31.146 38.5039 30.7803 38.1381 30.7803 37.6909V26.3088C30.7803 25.8616 31.1461 25.4958 31.5932 25.4958C32.0404 25.4958 32.4062 25.8616 32.4062 26.3088V37.6909C32.4062 38.1381 32.0404 38.5039 31.5932 38.5039Z"
              fill="#8E92F3"
            />
            <path
              d="M36.8784 38.5039C36.4312 38.5039 36.0654 38.1381 36.0654 37.6909V26.3088C36.0654 25.8616 36.4312 25.4958 36.8784 25.4958C37.3255 25.4958 37.6913 25.8616 37.6913 26.3088V37.6909C37.6913 38.1381 37.3255 38.5039 36.8784 38.5039Z"
              fill="#8E92F3"
            />
            <path
              d="M42.1628 38.5039C41.7157 38.5039 41.3499 38.1381 41.3499 37.6909L41.3496 26.3088C41.3496 25.8616 41.7154 25.4958 42.1625 25.4958C42.6097 25.4958 42.9755 25.8616 42.9755 26.3088V37.6909C42.9758 38.1381 42.61 38.5039 42.1628 38.5039H42.1628Z"
              fill="#8E92F3"
            />
            <path
              d="M45.4148 27.1218H17.7724C17.3252 27.1218 16.9595 26.756 16.9595 26.3088V24.5201C16.9595 24.2355 17.122 23.9916 17.3661 23.8291L31.1866 15.4551C31.4304 15.2926 31.7557 15.2926 32.0404 15.4551L45.8616 23.8291C46.1054 23.9916 46.2682 24.2357 46.2682 24.5201V26.3088C46.2276 26.756 45.8616 27.1218 45.4144 27.1218H45.4148ZM18.5854 25.4959H44.6018V24.9674L31.5936 17.0812L18.5854 24.9674V25.4959Z"
              fill="#8E92F3"
            />
          </svg>
        </span>
        <span v-if="status == 'warning'">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.5301 20.5036C21.0701 20.5036 22.0301 18.8336 21.2601 17.5036L13.7301 4.49359C12.9601 3.16359 11.0401 3.16359 10.2701 4.49359L2.74012 17.5036C1.97012 18.8336 2.93012 20.5036 4.47012 20.5036H19.5301ZM12.0001 13.5036C11.4501 13.5036 11.0001 13.0536 11.0001 12.5036V10.5036C11.0001 9.95359 11.4501 9.50359 12.0001 9.50359C12.5501 9.50359 13.0001 9.95359 13.0001 10.5036V12.5036C13.0001 13.0536 12.5501 13.5036 12.0001 13.5036ZM11.0001 15.5036V17.5036H13.0001V15.5036H11.0001Z"
              fill="#CC6517"
            />
          </svg>
        </span>
        <span v-if="status == 'danger'">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM11 15V17H13V15H11Z"
              fill="#DA1414"
            />
          </svg>
        </span>
      </div>
      <div>
        <span class="font-semibold text-default text-greyScale">
          {{ $t("index.kkiapayBankAccount") }}
        </span>
        <br />
        <span class="font-bold text-mobiletitle">
          {{ formatPrice(accountNumber) }}
        </span>
      </div>
    </div>
    <div class="border-b border-greyLith flex py-4 space-x-4 items-center">
      <div>
        <span>
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="64" height="64" rx="32" fill="#FAE6CB" />
            <path
              d="M47.7407 15.3335H35.0556C34.8241 15.3335 34.5925 15.4262 34.4075 15.6113L15.6113 34.3612C15.2409 34.7316 15.2409 35.287 15.6113 35.6574L28.2964 48.3889C28.4815 48.5739 28.713 48.6666 28.9445 48.6666C29.1761 48.6666 29.4076 48.5739 29.5927 48.3889L48.3426 29.639C48.5276 29.4539 48.6203 29.2224 48.6203 28.9908V16.2594C48.6668 15.7501 48.2499 15.3333 47.7406 15.3333L47.7407 15.3335ZM46.8149 28.5742L28.9909 46.4445L17.5558 35.0094L35.4261 17.1854H46.8149V28.5742ZM43.4354 20.5651C42.7411 19.8708 41.7687 19.5003 40.7963 19.5003C39.824 19.5003 38.8981 19.8708 38.1573 20.5651C37.463 21.2594 37.0929 22.2317 37.0929 23.2041C37.0929 24.1765 37.4633 25.1023 38.1576 25.8431C38.8519 26.5375 39.7782 26.9079 40.7967 26.9079C41.769 26.9079 42.6949 26.5375 43.4357 25.8431C44.13 25.1488 44.5004 24.2226 44.5004 23.2041C44.5001 22.1856 44.1297 21.2594 43.4354 20.5651ZM42.0926 24.5004C41.3983 25.1947 40.1482 25.1947 39.4536 24.5004C39.1297 24.1762 38.9446 23.7134 38.9446 23.2041C38.9446 22.6948 39.1297 22.2317 39.5001 21.9078C39.8705 21.5374 40.3333 21.3524 40.7964 21.3524C41.3057 21.3524 41.7688 21.5374 42.0927 21.9078C42.4169 22.2782 42.6481 22.741 42.6481 23.2041C42.6485 23.6669 42.4631 24.1762 42.0927 24.5004H42.0926Z"
              fill="#F69F27"
            />
          </svg>
        </span>
      </div>
      <div>
        <span class="font-semibold text-default text-greyScale">
          {{ $t("index.reference") }}
        </span>
        <br />
        <span class="font-bold text-mobiletitle"> {{ reference }} </span>
      </div>
    </div>
    <div class="flex items-start py-3 md:p-6 gap-4">
      <tuto class="cursor-pointer" />
      <div>
        <span class="font-bold text-sm md:text-base">
          {{ $t("index.howPut") }}
        </span>
      </div>
    </div>
    <div class="flex items-center space-x-2 mb-6">
      <div>
        <span>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0.625C4.83063 0.625 0.625 4.83062 0.625 10C0.625 15.1694 4.83063 19.375 10 19.375C15.1694 19.375 19.375 15.1694 19.375 10C19.375 4.83062 15.1694 0.625 10 0.625ZM10 4.46875C10.241 4.46875 10.4767 4.54023 10.6771 4.67415C10.8775 4.80806 11.0337 4.99841 11.126 5.2211C11.2182 5.4438 11.2424 5.68885 11.1953 5.92527C11.1483 6.16168 11.0322 6.37884 10.8618 6.54929C10.6913 6.71973 10.4742 6.83581 10.2378 6.88283C10.0014 6.92986 9.7563 6.90572 9.5336 6.81348C9.31091 6.72123 9.12056 6.56502 8.98665 6.3646C8.85273 6.16418 8.78125 5.92855 8.78125 5.6875C8.78125 5.36427 8.90965 5.05427 9.13821 4.82571C9.36677 4.59715 9.67677 4.46875 10 4.46875ZM13 15.0625H7.375V13.5625H9.4375V9.4375H7.9375V7.9375H10.9375V13.5625H13V15.0625Z"
              fill="#4D4C4C"
            />
          </svg>
        </span>
      </div>

      <div>
        <span class="info-text" v-html="$t('index.info')"> </span>
      </div>
    </div>
  </div>
</template>

<script>
import tuto from "@/components/svg/video.vue";

export default {
  props: {
    status: {
      type: String,
      default: "Info",
    },
    reference: {
      type: Number,
      default: 0,
    },
    accountNumber: {
      type: Number,
      default: 0,
    },
  },
  components: {
    tuto,
  },
  setup() {
    const formatPrice = (n, currency = false) => {
      const curr = currency ? " " + "F cfa" : "";
      n = String(n).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return `${n}${curr}`;
    };
    return {
      formatPrice,
    };
  },
};
</script>

<style>
.info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #4d4c4c;
}
</style>
