<template>
  <svg
    width="35"
    height="40"
    viewBox="0 0 35 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.953423 34.1609C-0.80629 29.6216 5.78597 18.8301 16.2843 18.8301C26.7825 18.8301 33.3948 29.6416 31.6151 34.1609C28.9488 41.1131 3.61965 41.1131 0.953423 34.1609Z"
      fill="#B6D8F5"
    />
    <path
      d="M16.3044 19.9507C21.6276 19.9507 25.9429 15.6354 25.9429 10.3123C25.9429 4.9891 21.6276 0.673828 16.3044 0.673828C10.9813 0.673828 6.66602 4.9891 6.66602 10.3123C6.66602 15.6354 10.9813 19.9507 16.3044 19.9507Z"
      fill="white"
    />
    <path
      d="M26.8952 38.5478C26.7394 38.5481 26.5885 38.4938 26.4686 38.3945L24.9488 37.1413C23.2182 35.6999 21.8265 33.8949 20.8726 31.8546C19.9187 29.8143 19.4261 27.5889 19.4297 25.3366V23.7369C19.4297 23.5601 19.4999 23.3905 19.6249 23.2655C19.7499 23.1405 19.9195 23.0703 20.0963 23.0703C20.273 23.0703 20.4426 23.1405 20.5676 23.2655C20.6926 23.3905 20.7628 23.5601 20.7628 23.7369V25.3366C20.7598 27.3998 21.2116 29.4382 22.086 31.3069C22.9604 33.1756 24.236 34.8286 25.822 36.1482L27.3218 37.3946C27.3892 37.4506 27.445 37.5193 27.4859 37.5969C27.5268 37.6744 27.552 37.7593 27.5601 37.8466C27.5681 37.9339 27.5589 38.0219 27.5328 38.1056C27.5068 38.1894 27.4645 38.2671 27.4084 38.3345C27.3432 38.4049 27.2637 38.4604 27.1751 38.4972C27.0865 38.534 26.991 38.5513 26.8952 38.5478Z"
      fill="#24315A"
    />
    <path
      d="M26.8965 37.8817L28.3963 36.6686C30.0563 35.2837 31.3914 33.5509 32.3072 31.5927C33.2229 29.6344 33.6969 27.4988 33.6954 25.3371V23.7373L26.8965 20.3379L20.0977 23.7373V25.3371C20.1003 27.5015 20.5794 29.6387 21.501 31.5971C22.4226 33.5555 23.7641 35.287 25.4301 36.6686L26.9299 37.915"
      fill="#F7A9A8"
    />
    <path
      d="M16.2832 20.6233C14.2434 20.6246 12.2491 20.021 10.5525 18.8887C8.85588 17.7565 7.53318 16.1465 6.75169 14.2624C5.9702 12.3783 5.76505 10.3047 6.16217 8.30404C6.5593 6.30334 7.54086 4.46535 8.9827 3.02257C10.4245 1.5798 12.2619 0.597046 14.2623 0.198628C16.2628 -0.199789 18.3365 0.00402583 20.2211 0.784294C22.1056 1.56456 23.7165 2.88623 24.8499 4.58211C25.9832 6.278 26.5881 8.27192 26.5881 10.3117C26.5864 13.0448 25.5003 15.6656 23.5683 17.5988C21.6364 19.532 19.0163 20.6198 16.2832 20.6233ZM16.2832 1.33978C14.5068 1.33847 12.77 1.86409 11.2924 2.85016C9.81492 3.83623 8.66308 5.23842 7.98269 6.8793C7.3023 8.52019 7.12394 10.326 7.47016 12.0683C7.81639 13.8106 8.67165 15.411 9.92772 16.6671C11.1838 17.9232 12.7842 18.7784 14.5265 19.1247C16.2688 19.4709 18.0746 19.2925 19.7155 18.6121C21.3564 17.9317 22.7586 16.7799 23.7446 15.3024C24.7307 13.8248 25.2563 12.088 25.255 10.3117C25.2515 7.93325 24.3051 5.65327 22.6233 3.97148C20.9415 2.28969 18.6616 1.34331 16.2832 1.33978Z"
      fill="#24315A"
    />
    <path
      d="M16.3308 39.9997C9.29857 39.9997 1.85312 38.3133 0.333363 34.3806C-1.06641 30.7612 2.08641 24.5422 7.0856 21.0494C7.2307 20.9594 7.40485 20.9284 7.57209 20.963C7.73934 20.9976 7.88693 21.0951 7.98441 21.2353C8.08189 21.3756 8.12182 21.5479 8.09597 21.7167C8.07012 21.8855 7.98045 22.038 7.84547 22.1426C2.96627 25.5287 0.513334 31.1278 1.57983 33.8673C3.17957 37.9867 14.4511 39.5664 22.723 38.1266C22.8998 38.0966 23.0813 38.138 23.2276 38.2417C23.3738 38.3455 23.4729 38.5031 23.5029 38.6799C23.533 38.8567 23.4916 39.0381 23.3878 39.1844C23.2841 39.3307 23.1265 39.4297 22.9497 39.4598C20.7624 39.825 18.5483 40.0056 16.3308 39.9997Z"
      fill="#24315A"
    />
    <path
      d="M26.8952 38.5468C26.7394 38.5471 26.5885 38.4928 26.4686 38.3935L24.9488 37.1403C23.2182 35.6989 21.8265 33.8938 20.8726 31.8536C19.9187 29.8133 19.4261 27.5879 19.4297 25.3356V23.7359C19.4301 23.6128 19.4645 23.4922 19.5292 23.3875C19.5939 23.2828 19.6864 23.198 19.7963 23.1426L26.5952 19.7432C26.6882 19.6963 26.791 19.6719 26.8952 19.6719C26.9993 19.6719 27.1021 19.6963 27.1951 19.7432L33.994 23.1426C34.1039 23.198 34.1964 23.2828 34.2611 23.3875C34.3258 23.4922 34.3602 23.6128 34.3606 23.7359V25.3356C34.366 28.3582 33.4778 31.3149 31.8077 33.8342C31.7091 33.9786 31.5577 34.0785 31.3861 34.1123C31.2146 34.1462 31.0366 34.1112 30.8905 34.015C30.7445 33.9188 30.6421 33.7691 30.6054 33.5981C30.5688 33.4271 30.6008 33.2486 30.6945 33.101C32.2187 30.7982 33.0302 28.0971 33.0275 25.3356V24.1225L26.8952 21.0563L20.7628 24.1225V25.3356C20.7598 27.3988 21.2116 29.4372 22.086 31.3059C22.9604 33.1746 24.236 34.8276 25.822 36.1472L26.8952 37.0404L27.9683 36.1472C28.2549 35.9072 28.5416 35.6472 28.8148 35.3806C28.9412 35.2569 29.1116 35.1884 29.2885 35.1903C29.4654 35.1922 29.6343 35.2642 29.758 35.3906C29.8818 35.517 29.9502 35.6874 29.9484 35.8643C29.9465 36.0412 29.8744 36.2101 29.748 36.3338C29.4481 36.6271 29.1415 36.907 28.8215 37.167L27.3218 38.4201C27.1983 38.51 27.0477 38.5547 26.8952 38.5468Z"
      fill="#24315A"
    />
    <path
      d="M26.4221 30.7416C26.3344 30.7421 26.2474 30.7253 26.1662 30.6921C26.085 30.659 26.0111 30.6101 25.9488 30.5483L24.6157 29.2152C24.4916 29.0903 24.4219 28.9214 24.4219 28.7453C24.4219 28.5692 24.4916 28.4002 24.6157 28.2753C24.6777 28.2129 24.7514 28.1633 24.8326 28.1294C24.9138 28.0956 25.001 28.0782 25.089 28.0782C25.177 28.0782 25.2641 28.0956 25.3453 28.1294C25.4265 28.1633 25.5003 28.2129 25.5622 28.2753L26.4487 29.1619L29.4349 26.1757C29.4969 26.1132 29.5706 26.0636 29.6518 26.0298C29.7331 25.9959 29.8202 25.9785 29.9082 25.9785C29.9962 25.9785 30.0833 25.9959 30.1645 26.0298C30.2457 26.0636 30.3195 26.1132 30.3814 26.1757C30.5056 26.3006 30.5753 26.4695 30.5753 26.6456C30.5753 26.8217 30.5056 26.9906 30.3814 27.1155L26.922 30.575C26.8544 30.6353 26.7752 30.6811 26.6892 30.7098C26.6033 30.7384 26.5124 30.7493 26.4221 30.7416Z"
      fill="#24315A"
    />
    <path
      d="M5.61906 28.8955C5.48314 28.8951 5.35057 28.8533 5.23912 28.7755C5.16703 28.725 5.10566 28.6607 5.05855 28.5863C5.01145 28.5119 4.97954 28.429 4.96466 28.3422C4.94979 28.2555 4.95225 28.1666 4.9719 28.0808C4.99155 27.995 5.028 27.9139 5.07915 27.8423C5.54344 27.1972 6.04885 26.5827 6.59223 26.0026C6.64979 25.9316 6.72133 25.8732 6.8024 25.8311C6.88347 25.7889 6.97235 25.7638 7.06351 25.7575C7.15467 25.7511 7.24617 25.7636 7.33232 25.794C7.41847 25.8245 7.49743 25.8724 7.56429 25.9347C7.63116 25.997 7.68451 26.0723 7.72102 26.1561C7.75753 26.2399 7.77642 26.3303 7.77653 26.4217C7.77663 26.513 7.75794 26.6035 7.72162 26.6873C7.6853 26.7712 7.63213 26.8467 7.56541 26.9091C7.07078 27.4466 6.60997 28.0143 6.18563 28.6088C6.12248 28.7 6.0376 28.7739 5.93867 28.8239C5.83974 28.874 5.72988 28.8986 5.61906 28.8955Z"
      fill="#24315A"
    />
    <path
      d="M4.21887 31.3351C4.12029 31.3344 4.02284 31.314 3.93226 31.2751C3.84933 31.2375 3.775 31.1833 3.71383 31.1159C3.65266 31.0484 3.60596 30.9692 3.57661 30.883C3.54725 30.7968 3.53587 30.7055 3.54315 30.6147C3.55044 30.524 3.57624 30.4357 3.61897 30.3553C3.71229 30.1486 3.81894 29.942 3.93226 29.7354C3.96968 29.652 4.02385 29.5772 4.09139 29.5157C4.15894 29.4541 4.23842 29.4071 4.32489 29.3776C4.41137 29.348 4.503 29.3366 4.59408 29.344C4.68517 29.3513 4.77377 29.3773 4.85438 29.4204C4.93498 29.4635 5.00588 29.5226 5.06265 29.5942C5.11943 29.6658 5.16087 29.7483 5.18442 29.8366C5.20796 29.9249 5.21309 30.0171 5.1995 30.1075C5.1859 30.1979 5.15387 30.2845 5.1054 30.3619C5.00541 30.5486 4.9121 30.7419 4.82544 30.9285C4.77486 31.048 4.69053 31.1501 4.58279 31.2223C4.47505 31.2945 4.34858 31.3337 4.21887 31.3351Z"
      fill="#24315A"
    />
  </svg>
</template>
