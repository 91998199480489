<template>
  <div
    data-pushup-item="baseinput"
    class="flex flex-col items-baseline relative"
  >
    <label
      class="baseinput-label text-base text-black font-semibold mb-2"
      :for="name"
    >
      {{ label }}
      <span v-if="required" class="text-left text-primaryColor">*</span>
    </label>
    <!--input-->
    <div
      class="bg-white border w-full border-btnBorder rounded flex flex-shrink flex-nowrap items-center space-x-2"
      :class="{ error: hasError, success: hasSuccess }"
    >
      <template v-if="arrow">
        <span v-if="$slots.arrow" class="inline-flex flex-shrink-0">
          <slot name="arrow" />
        </span>
        <span v-else class="inline-flex flex-shrink-0">
          <Down />
        </span>
      </template>
      <div class="h-full felx justify-center top-0 bottom-0">
        <span
          class="cursor-pointer px-2 select-none inline-flex flex-whrink-0 font-semibold text-black text-left text-sm leading-[1.225rem]"
          @click="toggleSelect()"
          ref="base_phone_arrow"
        >
          {{ `+${default_selected[2]}` }}
        </span>
      </div>
      <!---->
      <input
        :placeholder="placeholder"
        class="py-2 px-4 border-0 outline-none appearance-none flex-shrink w-full bg-transparent"
        type="text"
        ref="input_base"
        :name="name"
        :id="name"
        :value="phone"
        @input="on_phone_input($event)"
      />
    </div>
    <!--select option-->
    <div
      ref="select-options"
      class="w-full rounded border border-DADEE3 bg-white absolute mt-[0.281rem] z-[1]"
      v-if="open_select"
      data-pushup-item="base-phone-select-group"
    >
      <div
        class="w-full py-2 px-4 cursor-pointer text-left hover:bg-[rgba(217,242,236,.5)]"
        @click="choose(country)"
        v-for="(country, index) in allowed_countries"
        :key="index"
      >
        <span class="font-semibold text-xs text-394452">
          {{ country[0] }}
        </span>
      </div>
    </div>

    <!-- errors messages -->

    <div
      v-if="hasError"
      class="rounded-lg w-full bg-lightRed flex flex-row space-x-1 py-1.5 px-2 mt-2 items-center select-none"
    >
      <span class="inline-flex flex-shrink-0"> </span>
      <span class="text-primaryColor font-semibold">{{ errorMessage }}</span>
    </div>

    <div
      v-if="hasSuccess"
      class="rounded-lg w-full bg-primary-light flex flex-row space-x-1 py-1.5 px-2 mt-2 items-center select-none"
    >
      <span class="inline-flex flexshrink-0"> </span>
      <span class="text-xs text-primary font-medium">{{ successMessage }}</span>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
import {
  computed,
  toRef,
  ref,
  Ref,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
import Down from "../svg/downBlack.vue";

import { inject } from "vue";

export default {
  components: {
    Down,
  },

  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    hasSuccess: {
      default: false,
      type: Boolean,
    },
    successMessage: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
    },
    defaultCountry: {
      type: String,
      default: "BJ",
    },
    arrow: {
      type: Boolean,
    },
  },

  setup(props, context) {
    const open_select = ref(false);
    const default_selected = ref([]);
    const defaultCountry = toRef(props, "defaultCountry");
    const base_phone_arrow = ref(null);
    const phone = ref("");
    const input_base = ref(null);

    const allowed_countries = ref([
      ["Ivory Coast (Côte d’Ivoire)", "ci", "225"],
      ["Benin (Bénin)", "bj", "229"],
    ]);

    const formatPhone = (n) => {
      n = String(n)
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{2})+(?!\d))/g, " ");
      return `${n}`;
    };

    const toggleSelect = () => {
      open_select.value = !open_select.value;
    };

    const findCountry = (cntry) => {
      return allowed_countries.value.find((it) => {
        return it.includes(String(defaultCountry.value).toLowerCase());
      });
    };

    const emitPhone = () => {
      if (phone.value)
        context.emit(
          "phone",
          `${default_selected.value[2]}${phone.value.replace(/\s/g, "")}`,
        );
      else context.emit("phone", "");
    };

    const choose = (country) => {
      default_selected.value = country;
      open_select.value = false;
      context.emit("country", default_selected.value[1]);
      emitPhone();
    };

    const on_phone_input = (event) => {
      event.target.value = phone.value = formatPhone(event.target.value);
      emitPhone();
    };

    onMounted(() => {
      default_selected.value = findCountry(
        String(defaultCountry.value).toLowerCase(),
      );
      if (!default_selected.value) default_selected.value = findCountry("ci");
      context.emit("country", default_selected.value[1]);

      document.addEventListener("click", (event) => {
        if (
          base_phone_arrow.value &&
          !base_phone_arrow.value.contains(event.target)
        ) {
          open_select.value = false;
        }
      });
    });

    return {
      allowed_countries,
      toggleSelect,
      open_select,
      choose,
      base_phone_arrow,
      default_selected,
      formatPhone,
      phone,
      input_base,
      on_phone_input,
    };
  },
};
</script>

<style scoped lang="scss">
::placeholder {
  font-weight: bold;
  color: gray;
}

.error {
  background: #feefef;
  border: 1px solid #da1414;
  //-webkit-box-shadow: 0 0 0 30px #feefef inset !important;
}

.success {
  background: #edf9f0;
  border: 1px solid #287d3c;
}

.error input {
  -webkit-box-shadow: 0 0 0px 1000px #feefef inset !important;
}

.success input {
  -webkit-box-shadow: 0 0 0px 1000px #edf9f0 inset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

div [data-pushup-item="base-phone-select-group"] {
  max-height: 150px;
  overflow-y: scroll;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.15));
}
</style>
