<!-- tiny and simple tabs made with tailwindcss -->
<!--
tabs property is required

inactiveTab & activeTab are optional, set to change appearance

just all;

-->

<template>
  <div class="tld-tabs bg-white">
    <div class="w-full">
      <!-- tabs button -->
      <ul
        class="tld-tabs-buttons m-0 list-none select-none flex flex-wrap flex-row"
      >
        <li
          v-for="tab in tabs"
          :key="tab.name"
          class="tld-tab-button cursor-pointer px-4 py-4 md:px-10 text-center"
          :class="dynamicClass(tab.name)"
          @click="toggleTabs(tab.name)"
        >
          <span class="md:text-sm font-semibold text-xs">
            {{ tab.libelle }}
          </span>
        </li>
      </ul>
      <!-- tabs area -->
      <div
        class="bg-bgprimary relative tld-tabs-tab-content tld-tabs-tab-space w-full"
      >
        <slot :name="openTab" :item="openTab"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tailwind-tabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },

    inactiveTab: {
      type: String,
      default: "text-09101D bg-white",
    },

    activeTab: {
      type: String,
      default:
        "text-primaryColor font-bold bg-D9F2EC border-b-4 border-primaryColor",
    },
  },
  computed: {},
  data() {
    return {
      openTab: this.tabs[0].name,
    };
  },
  methods: {
    toggleTabs(tab) {
      this.openTab = tab;
      this.$emit("tldTabs:current", this.openTab);
    },

    dynamicClass(tabname) {
      if (tabname === this.openTab) return this.activeTab;
      return this.inactiveTab;
    },
  },
};
</script>
<style scoped></style>
