<template>
  <div
    class="pushup-loader relative w-full p-1 bg-transparent overflow-hidden border-transparent"
  >
    <div
      class="transform-gpu absolute pushup-linear-loader block h-full w-1/4 bg-primaryColor rounded"
    />
  </div>
</template>

<style lang="scss">
@keyframes run {
  0% {
    left: 0;
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  }
  100% {
    left: 100%;
    transform: translateX(100%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.pushup-linear-loader {
  animation: run 1.2s cubic-bezier(0, 0.74, 0.58, 1) infinite;
}
</style>
