<template>
  <div data-pushup-item="custom-select-table-filter min-w-[15.625rem]" class="select-none relative">
    <div
      
      class="
       
       py-3 rounded
        px-4
        py-

        bg-white
        flex
        items-center
        space-x-4
        cursor-pointer
        flex-shrink
      "
      :class="addedClass"
      @click="toggle()"
    >
      <!--custom icon-->
      <div>
          <span v-if="$slots.icon" class="inline-flex flex-shrink-0">
          <slot name="icon" />
        </span>
        </div>
      <div class="inline-flex items-center flex-row space-x-1 flex-shrink-0">
        
        <span
          class=" text-left text-black md:text-sm text-xs"
          :class="textClass"
        >
          {{ actualSelection ? actualSelection.label : $t('index.filter') }}
        </span>
      </div>
      <!-- custom arrow needed ? -->
      <span v-if="$slots.arrow" class="inline-flex flex-shrink-0">
        <slot name="arrow" />
      </span>
      <span v-else class="inline-flex flex-shrink-0">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.71209 6.52459C4.0782 6.15847 4.6718 6.15847 5.03791 6.52459L10 11.4867L14.9621 6.52459C15.3282 6.15847 15.9218 6.15847 16.2879 6.52459C16.654 6.8907 16.654 7.4843 16.2879 7.85041L10.6629 13.4754C10.2968 13.8415 9.7032 13.8415 9.33709 13.4754L3.71209 7.85041C3.34597 7.4843 3.34597 6.8907 3.71209 6.52459Z"
            fill="#C6D6EC"
          />
        </svg>
      </span>
    </div>

    <div 
      ref="custom_select_options"
      class="
       
        rounded
        border border-btnBorder
        bg-white
        absolute
        w-full
          min-h-40
        z-1
      "
      style="z-index: 1;"
      v-if="show && data.length > 0"
      data-pushup-item="status-content-option-item"
    >
      <div
        class="
          w-full
          py-2
          px-4
          cursor-pointer
          text-left
          hover:bg-authblue 
        "
        @click="choose(status)"
        v-for="(status, index) in data"
        :key="index"
      >
        <span class="font-semibold text-xs text-black">
          {{ status.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, onMounted } from "vue";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    textClass: {
      type: String,
    },
    defaultKey: {
      type: String,
      default: "admin",
    },
    addedClass: {
      type: String,
      default: "py-2 px-4",
    },
  },
  data(){
    return {
      show:false,
      actualSelection:''
    }
  },
  

  components: {},

  setup(props, { emit }) {
    const show = ref(false);

    const properties = toRefs(props);

    const actualSelection = ref(properties.data.value[0]);

    const toggle = () => {
      show.value = !show.value;
    };

    const custom_select_options = ref(null);

    const choose = (item) => {
      this.actualSelection = item;
      emit("change", item);
      this.show = false;
    };

    onMounted(() => {
      const found = properties.data.value.find(
        (d) => d.value === properties.defaultKey.value
      );
      if (found) choose(found);

      document.addEventListener("click", (event) => {
        if (
          custom_select_options.value &&
          !custom_select_options.value.contains(event.target)
        ) {
          show.value = false;
        }
      });
    });

    
  },
  methods:{
      toggle () {
      this.show = !this.show;
    },
    choose(item){
      this.actualSelection = item;
      this.$emit("change", item);
      this.show = false;
    }
  },
};
</script>

<style lang="css" scoped>
div [data-pushup-item="status-content-option-item"] {
  max-height: 150px;
  overflow-y: auto;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.15));
}
</style>
