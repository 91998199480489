<template>
  <div>
    <div
      class="w-screen h-screen flex items-center justify-center"
      v-if="loading"
    >
      <Logo class="animate-pulse" />
    </div>
    <div v-else>
      <router-view />
    </div>
    <!-- <Footer /> -->
  </div>
</template>
<script>
import Logo from "@/components/svg/logo.vue";
import "v-calendar/dist/style.css";
import nextPageVue from "./components/svg/nextPage.vue";
import defaultLayout from "./components/layouts/default.vue";
import Footer from "./views/footer.vue";
import { computed, onMounted, ref } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  components: { defaultLayout, Footer, Logo },
  name: "App",
  setup() {
    const store = useStore();
    const loading = computed(() => store.getters["getLoading"]);

    onMounted(() => {
      console.log(loading.value);
    });
    return {
      loading,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blueCard {
  display: flex !important;
  justify-content: space-between !important;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.linkCard {
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  gap: 24px;
  align-items: center;
}
@media screen and (max-width: 800px) {
  .footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
  .linkCard {
    display: grid;
    gap: 16px;
  }
}
</style>
