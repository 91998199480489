
import { inject, PropType, reactive } from "@vue/runtime-core";
import primaryButton from "../global/primaryButton.vue";
import whiteButton from "../global/redBorderButton.vue";
import { useStore } from "vuex";
import { ActionTypes, NotificationType } from "@/store/pushup/store.types";
export default {
  components: { primaryButton, whiteButton },
  name: "SettingsConfirmation",
  setup(props: any, context: any) {
    const $t: any = inject("$t");

    const store = useStore();

    const state = reactive({
      isLoading: false,
    });

    function onCancel() {
      context.emit("canceled");
    }

    async function onConfirm() {
      state.isLoading = true;
      const result = await store.dispatch(
        ActionTypes.SET_VALIDATION_SETTINGS,
        undefined,
      );
      state.isLoading = false;

      context.emit("completed", result);
    }

    return {
      state,
      onCancel,
      onConfirm,
    };
  },
};
