import { string } from "yup";

export interface Campaign {
  id: string;
  name: string;
  reason: string;
  status: "CREATED";
  businessId: string;
  createdAt: Date;
  updatedAt: Date;
  enabled: boolean;
  campaignFile?: CampaignFile;
  dispatching?: Record<string, any>[];
}

export interface CampaignFile {
  id: string;
  filename: string;
  originalname: string;
  mimetype: string;
  size: number;
  url: string;
}

export interface AccountValidator {
  id: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  externalId: string;
  confirmationStatus?: "VALIDATED" | "PENDING";
}

export type CampaignTransaction = Record<string, any> & {
  status: "SUCCESS" | "PENDING" | "PROCESSING" | "FAILED";
  id: string;
  name: string;
  phone: string;
  identityCardId: string;
  amount: number;
};
export interface StoreState {
  currentCampaign: any;
  account: any;
  allCampaign: any;
  amount: any;
  campaigns: any;
  reportData: any;
  campaignsById: any;
  accountValidator: AccountValidator | null;
  businessId: string;
  campaignTransactions: CampaignTransaction[];
  allAppro: any;
  funding: any;
  totalTransaction: any;
  total: any;
  totalAppro: any;
  reference: any;
  meInfo: any;
  accountNumber: any;
  plans: any;
  userPlan: any;
  balance: any;
  contacts: any;
  usersForgot: any;
  revocateUser: any;
  allTransaction: any;
  mode: String;
  globalLoading: Boolean;
}

export const state: StoreState = {
  currentCampaign: {},
  campaigns: {},
  account: {},
  amount: null,
  allCampaign: {
    campaigns: [],
    stats: {
      balance: 0,
      beneficiaries: 0,
      amount: 0,
      totalCount: 0,
      lastSupplyAmount: 0,
      lastSupplyDate: "",
      lastDispatchingDate: 0,
    },
  },
  funding: [],
  reportData: {},
  campaignsById: {},
  accountValidator: null,
  businessId: "",
  meInfo: {},
  allAppro: {},
  campaignTransactions: [],
  totalTransaction: null,
  total: null,
  totalAppro: null,
  reference: null,
  accountNumber: null,
  plans: [],
  userPlan: [],
  balance: {},
  contacts: [],
  usersForgot: [],
  revocateUser: [],
  allTransaction: [],
  mode: "",
  globalLoading: false,
};

export type State = typeof state;
