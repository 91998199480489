
import Warning from "@/components/svg/warning.vue";
import Plus from "@/components/global/plusButton.vue";
import dragFile from "@/components/global/dragFile.vue";
import PrimaryButton from "@/components/global/primaryButton.vue";
import customInput from "@/components/global/customInput.vue";
import whiteButton from "@/components/global/redBorderButton.vue";
import Balance from "@/components/svg/firstIcon.vue";
import back from "@/components/svg/back.vue";
import {
  ref,
  inject,
  reactive,
  onMounted,
  watch,
  computed,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ActionTypes, CreateCampaignPayload } from "@/store/pushup/store.types";
import { ApiCallResult } from "@/api";
import { PushupSocket, WorkflowWsEvents } from "@/socket";
import { StoreState } from "@/store/pushup/state";
import { Field, Form } from "vee-validate";

export default {
  components: {
    back,
    Plus,
    Balance,
    customInput,
    whiteButton,
    dragFile,
    PrimaryButton,
    Warning,
    Field,
    Form,
  },

  setup() {
    const dateRef = ref();
    function dateChange() {}
    function clickdate() {
      console.log(dateRef.value, "clickdate");
    }
    const store: Record<string, any> & { state: StoreState } = useStore();
    const $t: any = inject("$t");
    // const addSocketListener: any = inject('$addSocketListener')
    const PushUpIo: any = inject("$PushUpIo");
    const showNotif: any = inject("$showNotif");

    const acceptedFileFormats = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    const state = reactive({
      isLoading: false,
      name: "",
      reason: "",
      date: "",
      time: "",
      file: null as any,
      fileName: "",
      showMissingFileError: false,
    });

    const router = useRouter();
    const formatPrice = (amount: any, delimiter = " ", separator = ",") => {
      const roundedValue = String(Math.round(amount * 100) / 100);

      const putDelimiter = (value: any) => {
        const result = [];

        const reversedValue = value.split("").reverse();

        for (let i = 1; i < reversedValue.length + 1; i++) {
          result.push(reversedValue[i - 1]);

          if (i % 3 === 0 && i !== reversedValue.length) {
            result.push(delimiter);
          }
        }

        return result.reverse().join("");
      };
      if (roundedValue.indexOf(separator) !== -1) {
        const [intPart, decPart] = roundedValue.split(separator);

        return [putDelimiter(intPart), ".", decPart].join("");
      }
      return putDelimiter(roundedValue);
    };

    // onMounted(() => {

    // });

    watch(
      () => store.state.reportData,
      (val) => {
        console.log("----------value", val);
        alert("reportdata value changed");
      },
    );

    // const onUpload = (e) => {
    //   file.value = e;
    //   fileName.value = file.value.name;
    // };

    function onUpload(e: any) {
      state.file = e;
      state.fileName = state.file.name;
    }

    const errorFile = ref("");
    const download = async () => {
      console.log("vv");

      await store.dispatch("TEMPLATE_FILE");
    };

    async function createDispatching() {
      console.log(state.date);

      if (!state.file) {
        state.showMissingFileError = true;
        return;
      }
      state.showMissingFileError = false;
      state.isLoading = true;
      const payload = {
        name: state.name,
        reason: state.reason,
        businessId: store.state.businessId, //TODO: Create a business account and use his ID instead of this hardcoded one
      };

      try {
        const campagnCreationResult: ApiCallResult = await store.dispatch(
          ActionTypes.CREATE_CAMPAIGN,
          payload,
        );

        if (campagnCreationResult.success && campagnCreationResult.data) {
          console.log(campagnCreationResult);

          const campaignId = campagnCreationResult.data.id;
          let formData = new FormData();

          formData.append("file", state.file);
          formData.append("campaignId", campaignId);
          formData.append("mapping[name]", "0");
          formData.append("mapping[gender]", "1");
          formData.append("mapping[state]", "2");
          formData.append("mapping[commune]", "3");
          formData.append("mapping[district]", "4");
          formData.append("mapping[town]", "5");
          formData.append("mapping[phone]", "6");
          formData.append("mapping[amount]", "7");
          formData.append("mapping[identityPaperId]", "8");

          const uploadResult = await store.dispatch(
            ActionTypes.UPLOAD_FILE,
            formData,
          );

          // Join socket room with this filename
          if (uploadResult.data) {
            PushUpIo.joinRoom(uploadResult.data.filename);
          }

          PushUpIo.on(WorkflowWsEvents.REPORTS, async (data: any) => {
            const id = data.dispatchingFileId;

            state.isLoading = false;
            const payload = {
              id: id,
            };
            const response = await store.dispatch(
              ActionTypes.GET_DISPACTHING_INFO,
              payload,
            );
            console.log("responseEEEE", response);
            store.commit("DISPATCHING", response);
            router.push({
              name: "preview",
              params: {
                campaignId: id,
              },
            });
            console.log("bye bye");
          });
          // if (uploadResult) {
          // const dispatchings = await waitForDispatchingExistence(campaignId);
          // console.log("dispatchings:", dispatchings);
          // initSocketConnection(uploadResult.data.filename);
          // state.isLoading = false;
          // showNotif({
          //   success: true,
          //   note: "Campagne créée avec succès",
          // });
          // setTimeout(() => {
          //   router.push(`/preview/${campaignId}`);
          // }, 3000);
          // return;
          // }
        } else {
          showNotif({
            error: true,
            note: $t("index.errorMessage"),
          });
          state.isLoading = false;
        }
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
        state.isLoading = false;
      }
    }
    const account = computed(() => {
      return store.getters["getAmount"];
    });
    const amount = ref(0);
    const goBack = () => {
      router.push({
        name: "board",
        // params: { businessId: localStorage.getItem("businessId") },
      });
    };

    function isRequired(value: any) {
      return value ? true : $t("index.fieldError");
    }
    const typeOfDispatching = computed(() => store.getters["getMode"]);

    onMounted(async () => {
      console.log(typeOfDispatching.value, "ff");

      const businessId = localStorage.getItem("businessId");
      if (businessId) {
        store.dispatch(ActionTypes.SAVE_BUSINESS_ID_TO_STORAGE, businessId);
        // store.dispatch(ActionTypes.GET_BUSSINESS_ACCOUNT);
      }
      const payload = {
        id: localStorage.getItem("businessId"),
      };
      // await store.dispatch("GET_ALL_DISPACTHING", payload);

      console.log("account", account.value);
    });

    onUnmounted(() => {
      PushUpIo?.removeEventListener(WorkflowWsEvents.REPORTS);
    });

    return {
      state,
      isRequired,
      onUpload,
      errorFile,
      download,
      goBack,
      createDispatching,
      account,
      formatPrice,
      acceptedFileFormats,
      dateRef,
      clickdate,
      typeOfDispatching,
    };
  },
};
