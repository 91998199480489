<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 8.25006H17.625C18.1223 8.25006 18.5992 8.44761 18.9508 8.79924C19.3025 9.15087 19.5 9.62778 19.5 10.1251V19.8751C19.5 20.3723 19.3025 20.8493 18.9508 21.2009C18.5992 21.5525 18.1223 21.7501 17.625 21.7501H6.375C5.87772 21.7501 5.40081 21.5525 5.04917 21.2009C4.69754 20.8493 4.5 20.3723 4.5 19.8751V10.1251C4.5 9.62778 4.69754 9.15087 5.04917 8.79924C5.40081 8.44761 5.87772 8.25006 6.375 8.25006H8.25"
      stroke="#0965F6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.25 12.7501L12 16.5001L15.75 12.7501"
      stroke="#0965F6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 2.25006V15.7501"
      stroke="#0965F6"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
