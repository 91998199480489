<template>
  <div
    class="bg-white flex flex-col justify-center rounded-md shadow-md  z-[999]   max-w-[11.563rem] absolute right-5 top-16 w-full"
  >
   
    <div class=" flex flex-col py-[26px]  space-y-4 px-6">

    <div class="flex items-center space-x-2 " > 
      <Person/>
      <span class="text-base font-semibold">Profil </span>
    </div>
     <button  @click="logout"  class=" cursor-pointer flex items-center space-x-2">
       <Dec/>
      <span class="text-base font-semibold"> {{$t('index.logout')}} </span>
    </button>
    </div>
  </div>
</template>

<script>
import logout from "@/components/svg/logout.vue";
import settings from "@/components/svg/settings.vue";
import Person from "@/components/svg/personBlack.vue";
import Dec from "@/components/svg/dec.vue";
import { onMounted } from '@vue/runtime-core';
export default {
  components: {
    logout,
    Dec,
    Person,
    settings,
  },
  setup() {
    //logout
    async function logout() {
      localStorage.removeItem("access_token");
      localStorage.removeItem( "id_token");
      localStorage.removeItem("refresh_token",);
      localStorage.removeItem("businessId",);
      localStorage.removeItem("token_type");
      localStorage.removeItem("scope");
      console.log("logout");
      window.location.href = process.env.VUE_APP_LOGIN;
    }
  
    return {
      logout,
    };
  },
};
</script>

<style></style>
