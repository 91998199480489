<template>
  <div
    ref="popup"
    data-pushup-item="notification-modal"
    class="z-[9999] flex-col rounded-md text-white select-none cursor-pointer pt-4 justify-between fixed text-left right-0 top-5 flex flex-nowrap max-w-[373px] border m-[auto]"
    :class="{
      'error-notification border-primaryColor bg-primaryColor ': error,
      'success-notification border-hightGreen  bg-[#27AE60]': success,
    }"
  >
    <div class="px-4 space-x-4 flex items-center">
      <span v-if="success"
        ><svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 0C5.83187 0 0 5.83187 0 13C0 20.1681 5.83187 26 13 26C20.1681 26 26 20.1681 26 13C26 5.83187 20.1681 0 13 0ZM10.625 19.5237L5.5875 13.9256L7.07438 12.5875L10.5744 16.4762L18.875 6.59125L20.4088 7.875L10.625 19.5237Z"
            fill="white"
          />
        </svg>
      </span>
      <span v-if="error">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 0C5.83187 0 0 5.83187 0 13C0 20.1681 5.83187 26 13 26C20.1681 26 26 20.1681 26 13C26 5.83187 20.1681 0 13 0ZM17.7069 16.2931C17.8036 16.3851 17.881 16.4954 17.9345 16.6177C17.9879 16.7401 18.0163 16.8718 18.018 17.0053C18.0197 17.1388 17.9947 17.2712 17.9444 17.3949C17.8941 17.5185 17.8196 17.6308 17.7252 17.7252C17.6308 17.8196 17.5185 17.8941 17.3949 17.9444C17.2712 17.9947 17.1388 18.0197 17.0053 18.018C16.8718 18.0163 16.7401 17.9879 16.6178 17.9345C16.4954 17.881 16.3851 17.8036 16.2931 17.7069L13 14.4144L9.70687 17.7069C9.51781 17.8865 9.26606 17.9852 9.0053 17.9818C8.74454 17.9785 8.4954 17.8734 8.311 17.689C8.1266 17.5046 8.02152 17.2555 8.01819 16.9947C8.01485 16.7339 8.11351 16.4822 8.29313 16.2931L11.5856 13L8.29313 9.70687C8.11351 9.51781 8.01485 9.26606 8.01819 9.0053C8.02152 8.74454 8.1266 8.4954 8.311 8.311C8.4954 8.1266 8.74454 8.02152 9.0053 8.01818C9.26606 8.01485 9.51781 8.1135 9.70687 8.29312L13 11.5856L16.2931 8.29312C16.4822 8.1135 16.7339 8.01485 16.9947 8.01818C17.2555 8.02152 17.5046 8.1266 17.689 8.311C17.8734 8.4954 17.9785 8.74454 17.9818 9.0053C17.9852 9.26606 17.8865 9.51781 17.7069 9.70687L14.4144 13L17.7069 16.2931Z"
            fill="white"
          />
        </svg>
      </span>
      <!-- message -->
      <span class="font-semibold text-base leading-[1.225rem]">
        {{ note }}
      </span>
      <!-- close -->
      <span
        class="self-start cursor-pointer inline-flex hover:opacity-95 active:animate-pulse"
        @click="closeModal()"
      >
        <!-- <Close /> -->
      </span>
    </div>

    <Transition>
      <div
        v-show="show"
        class="py-1 rounded-b-md mt-4"
        :class="{ ' bg-[#F599A3] ': error, ' bg-[#2FD073] ': success }"
      ></div>
    </Transition>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onBeforeMount,
  ref,
  computed,
  toRefs,
  watch,
  toRef,
} from "vue";
import { debounce } from "lodash";
// import Close from '@/components/global/svg/navigation-close.vue';

export default {
  components: {
    //Close,
  },

  props: {
    out: {
      type: Number,
      default: 6000,
    },
    success: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    note: {
      type: String,
      default: "Notification message...",
    },
  },

  setup(props, context) {
    const popup = ref("");
    const time = toRef(props, "out");

    const closeModal = () => {
      popup.value?.classList.add("slideup");
      context.emit("notification:closed");
      debounce(() => {
        popup.value.remove();
      }, 10);
    };
    const show = ref(false);

    const autoClose = debounce(() => {
      closeModal();
    }, time.value);

    onMounted(async () => {
      autoClose();
      setTimeout(() => {
        show.value = true;
      }, 100);
    });

    return {
      popup,
      closeModal,
      autoClose,
      show,
    };
  },
};
</script>

<style lang="scss" scoped>
div[data-pushup-item="notification-modal"] {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

  &.slideup {
    transition: 0.2s ease;
    transition: all 0.2s ease;
    transform: translateY(-150%);
  }
}
.v-enter-active,
.v-leave-active {
  transition: width 6s linear;
}
.v-enter-from {
  width: 100%;
}
.v-enter-to {
  width: 0%;
}
</style>
