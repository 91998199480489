
import { computed } from "@vue/runtime-core";
export default {
  name: "CircleCheckMark",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props: any, _: any) {
    const fillColor = computed((): string =>
      props.isActive ? "#F11C33" : "#808080",
    );

    return {
      fillColor,
    };
  },
};
