<!-- tiny and simple tabs made with tailwindcss -->
<!--
stack widget with left buttons, tabs property is required

inactiveTab & activeTab are optional, set to change appearance

just all;

-->

<template>
  <div class="tld-tabs bg-white">
    <div class="w-full md:flex items-start">
      <!-- tabs button -->
      <ul
        class="flex-shrink-0 tld-tabs-buttons m-0 list-none select-none flex md:flex-col"
      >
        <li
          v-for="tab in tabs"
          :key="tab.name"
          class="flex space-x-4 md:pr-24 items-center tld-tab-button cursor-pointer md:p-4 py-2 px-4 text-left hover:bg-[rgba(217,242,236,.5)]"
          :class="dynamicClass(tab.name)"
          @click="toggleTabs(tab.name)"
        >
          <component
            class="hidden md:flex"
            :is="tab.icon"
            :isActive="openTab === tab.name"
          ></component>
          <!-- <span v-if="$slots.icon" class="inline-flex flex-shrink-0">
            <slot name="icon" />
          </span> -->
          <span class="text-base leading-[1.219rem] font-semibold">
            {{ tab.libelle }}
          </span>
        </li>
      </ul>
      <!-- tabs area -->
      <div
        v-if="openTab !== 'pay_slip'"
        class="tabs-content-area tld-tabs-tab-content tld-tabs-tab-space p-4 relative flex-grow self-stretch border-l border-[rgba(0,0,0,0.15)]"
        style="box-shadow: inset 1px 0px 0px #cccccc !important"
      >
        <slot :name="openTab" :item="openTab"> </slot>
      </div>
      <div v-else class="w-full h-fit" style="background-color: #f5f6f6">
        <slot :name="openTab" :item="openTab"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import CircleCheckmark from "../svg/CircleCheckmark.vue";
import NotificationBell from "../svg/notification-bell.vue";
import PaySlip from "../svg/pay_slip.vue";
export default {
  components: { PaySlip, CircleCheckmark, NotificationBell, CircleCheckmark },
  name: "tailwind-tabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
      required: true,
    },

    inactiveTab: {
      type: String,
      default: "text-09101D bg-white",
    },

    activeTab: {
      type: String,
      default:
        "text-005743 md:bg-lightRed border-b-4 md:border-l-4 md:border-b-0 border-primaryColor",
    },
  },
  computed: {},
  data() {
    return {
      openTab: this.tabs[0].name,
    };
  },
  methods: {
    toggleTabs(tab) {
      this.openTab = tab;
      this.$emit("tldTabs:current", this.openTab);
    },

    dynamicClass(tabname) {
      if (tabname === this.openTab) return this.activeTab;
      return this.inactiveTab;
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 1300px) {
  .tabs-content-area {
    border: none;
  }
}
</style>
