<template>
  <div
    class="cardPricing select-none cursor-pointer border border-[#B2B2B2] md:p-6 md:max-h-[261px] w-full md:w-fit p-4 transition ease-in-out delay-250 shrink-0 overflow-y-auto"
    :class="{
      'bg-[#F5F9FF] border-2  border-[#0965F6]  duration-600': ischecked,
    }"
    style="border-radius: 8px"
  >
    <div class="flex justify-between">
      <div class="flex items-center space-x-4">
        <slot name="icon" />
        <span class="title md:hidden"> {{ type }} </span>
      </div>
      <div
        class="h-[24px] w-[24px] border-2 p-2 shrink-0 border-[#B2B2B2] rounded-full"
        :class="{ 'border-[#0965F6] border-[6px]  ': ischecked }"
      ></div>
    </div>
    <div class="md:mt-[22px] hidden md:flex">
      <span class="title"> {{ type }} </span>
    </div>
    <div class="md:mt-6 mt-4">
      <span class="textBlack"> {{ formatPrice(price) }} F cfa </span>
    </div>
    <div class="md:mt-1">
      <span class="validity">
        {{ $t("index.validity", { day }) }}
      </span>
    </div>
    <div style="margin-top: 4px" class="md:mb-[131px]">
      <span class="transactions">
        {{ total }} {{ $t("index.transaction") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ischecked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      default: "Offre",
    },
    price: {
      type: Number,
      required: true,
      default: 0,
    },
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    color: {
      type: Boolean,
      default: false,
    },
    day: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  setup() {
    function formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return {
      formatPrice,
    };
  },
};
</script>

<style>
.cardPricing::-webkit-scrollbar {
  display: none;
}
.textBlack {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: black;
}
.greyText {
  color: #6d7580;
  font-size: 16px;
  line-height: 20px;
}
.title {
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
}
.buttonRedDouble {
  background-color: red;
  border-radius: 4px;
  padding: 12px 24px;
  border: none;
  color: #ffffff;
  font-weight: 600;
}
.defaultWhiteButton {
  width: 100%;
  border: 1.5px solid #f11c33;
  box-sizing: border-box;
  padding: 12px 24px;
  color: #f11c33;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.01);
  font-size: 16px;
  line-height: 18px;
}
.validity {
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color: #6d7580;
}
.transactions {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #4661b9;
}
</style>
