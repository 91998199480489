<template>
  <div
    class="modal fixed top-0 left-0 right-0 p-[14%] md:p-0 bottom-0 flex md:items-center md:justify-center z-40"
  >
    <div
      class="pushup-modal md:content rounded-md py-[24px] px-[24px] h-fit bg-white md:w-[500px] w-[328px]"
    >
      <slot name="title" v-if="$slots.title"></slot>
      <span class="pushup-modal-header flex justify-between mb-3" v-else>
        <span class="flex space-x-4">
          <span v-if="back" @click="$emit('close')" class="cursor-pointer">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.4375 18.75L4.6875 12L11.4375 5.25"
                stroke="#4D4C4C"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.625 12H19.3125"
                stroke="#4D4C4C"
                stroke-width="2.25"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>

          <span
            class="text-black font-semibold block md:text-xl text-base text-left"
          >
            {{ title }}
          </span>
        </span>
        <div v-if="closeIcon">
          <svg
            @click="$emit('close')"
            class="cursor-pointer"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.21964 6.21967C6.51253 5.92678 6.98741 5.92678 7.2803 6.21967L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0125 18.0732 16.7196 17.7803L6.21964 7.28033C5.92675 6.98744 5.92675 6.51256 6.21964 6.21967Z"
              fill="black"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7803 6.21967C18.0732 6.51256 18.0732 6.98744 17.7803 7.28033L7.2803 17.7803C6.98741 18.0732 6.51253 18.0732 6.21964 17.7803C5.92675 17.4874 5.92675 17.0126 6.21964 16.7197L16.7196 6.21967C17.0125 5.92678 17.4874 5.92678 17.7803 6.21967Z"
              fill="black"
            />
          </svg>
        </div>
      </span>

      <div class="pushup-modal-container" :class="{ 'mt-[20px]': haveGutter }">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    back: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "hello",
    },
    haveGutter: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.modal {
  background: rgba(0, 0, 0, 0.5);
}

.content {
  min-width: 500px;

  /* height: 360px; */
}

.pushup-modal {
  max-height: 90vh;
  overflow-y: auto;
}
</style>
