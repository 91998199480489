<template>
  <svg
    width="90"
    height="35"
    viewBox="0 0 90 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.0773 19.8578L61.9718 20.1261C61.9122 20.3795 61.8227 20.5882 61.6885 20.7522C61.5544 20.9162 61.4053 21.0504 61.2115 21.1249C61.0177 21.2144 60.7792 21.2591 60.481 21.2591C60.1232 21.2591 59.84 21.2144 59.6014 21.11C59.3778 21.0057 59.184 20.8268 59.02 20.5584C58.856 20.305 58.7666 19.9621 58.7666 19.5596C58.7666 19.0229 58.9157 18.6055 59.1989 18.3073C59.4822 18.0092 59.8996 17.875 60.4214 17.875C60.8388 17.875 61.1519 17.9644 61.3904 18.1284C61.6289 18.2924 61.8078 18.5459 61.9122 18.9036L61.0177 19.0975C60.9879 18.9931 60.9581 18.9186 60.9133 18.8738C60.8537 18.7993 60.7941 18.7397 60.7046 18.6949C60.6152 18.6502 60.5257 18.6353 60.4363 18.6353C60.2127 18.6353 60.0338 18.7248 59.9145 18.9186C59.8251 19.0527 59.7803 19.2763 59.7803 19.5596C59.7803 19.9174 59.84 20.1708 59.9443 20.305C60.0487 20.4392 60.2127 20.5137 60.4065 20.5137C60.6003 20.5137 60.7494 20.4541 60.8388 20.3497C60.9581 20.2155 61.0326 20.0665 61.0773 19.8578Z"
      fill="white"
    />
    <path
      d="M62.3301 19.5589C62.3301 19.0222 62.4792 18.6048 62.7773 18.3066C63.0755 18.0085 63.4929 17.8594 64.0296 17.8594C64.5812 17.8594 64.9986 18.0085 65.2968 18.2917C65.5949 18.5899 65.744 18.9924 65.744 19.5291C65.744 19.9167 65.6844 20.2297 65.5502 20.4683C65.416 20.7068 65.2371 20.9006 64.9837 21.0348C64.7452 21.1689 64.4321 21.2435 64.0743 21.2435C63.7016 21.2435 63.4034 21.1838 63.15 21.0646C62.9115 20.9453 62.7177 20.7664 62.5537 20.4981C62.3897 20.2297 62.3301 19.9465 62.3301 19.5589ZM63.3438 19.5589C63.3438 19.8869 63.4034 20.1254 63.5227 20.2745C63.642 20.4235 63.806 20.4981 64.0296 20.4981C64.2532 20.4981 64.4172 20.4235 64.5364 20.2894C64.6557 20.1403 64.7153 19.8869 64.7153 19.5291C64.7153 19.216 64.6557 18.9924 64.5215 18.8582C64.4023 18.7091 64.2234 18.6495 64.0147 18.6495C63.806 18.6495 63.642 18.724 63.5227 18.8731C63.4034 18.9924 63.3438 19.2309 63.3438 19.5589Z"
      fill="white"
    />
    <path
      d="M66.2652 17.9199H67.592L68.0988 19.9176L68.6057 17.9199H69.9325V21.1997H69.0977V18.6951L68.4566 21.1997H67.7112L67.0702 18.6951V21.1997H66.2354V17.9199H66.2652Z"
      fill="white"
    />
    <path
      d="M70.5889 17.9199H72.2735C72.6462 17.9199 72.9145 18.0094 73.0934 18.1883C73.2723 18.3672 73.3617 18.6057 73.3617 18.9337C73.3617 19.2616 73.2574 19.53 73.0636 19.7089C72.8698 19.9027 72.5567 19.9921 72.1542 19.9921H71.6026V21.2146H70.5889V17.9199ZM71.6026 19.3213H71.856C72.0498 19.3213 72.184 19.2914 72.2735 19.2169C72.348 19.1424 72.3927 19.0678 72.3927 18.9635C72.3927 18.8591 72.3629 18.7697 72.2884 18.6951C72.2138 18.6206 72.0946 18.5908 71.9008 18.5908H71.6175L71.6026 19.3213Z"
      fill="white"
    />
    <path
      d="M73.8975 17.9199H74.9112V20.3946H76.4914V21.1997H73.8975V17.9199Z"
      fill="white"
    />
    <path d="M76.999 17.9199H78.0128V21.1997H76.999V17.9199Z" fill="white" />
    <path
      d="M80.7556 20.6474H79.6077L79.4437 21.184H78.415L79.6524 17.9043H80.7556L81.9929 21.184H80.9345L80.7556 20.6474ZM80.5469 19.9467L80.1891 18.769L79.8313 19.9467H80.5469Z"
      fill="white"
    />
    <path
      d="M82.3057 17.9199H83.2449L84.4822 19.7387V17.9199H85.4363V21.1997H84.4822L83.2598 19.3958V21.1997H82.3057V17.9199Z"
      fill="white"
    />
    <path
      d="M85.8838 17.9199H88.9697V18.7249H87.9411V21.1997H86.9273V18.7249H85.8987L85.8838 17.9199Z"
      fill="white"
    />
    <path
      d="M48.8831 22.9121L51.8199 21.9878L50.8658 19.8708C50.1652 20.7504 49.4645 21.8834 48.8831 22.9121ZM47.8842 13.2368L42.1298 0.416016L0 2.80128L11.6729 34.6446L34.1391 27.5633C32.708 25.506 32.1862 23.0611 33.841 21.8685C35.6896 20.5268 38.4773 22.0772 40.2514 24.2687C41.9658 21.4064 46.7811 14.7425 47.8842 13.2368Z"
      fill="#225E63"
    />
    <path
      d="M40.8172 9.681C42.5764 9.681 44.0075 8.33929 44.0075 6.6696C44.0075 4.99992 42.5764 3.6582 40.8172 3.6582C39.0581 3.6582 37.627 4.99992 37.627 6.6696C37.627 8.32438 39.0581 9.681 40.8172 9.681ZM38.2084 10.978H43.4261V24.6187H38.2084V10.978Z"
      fill="#FEFEFE"
    />
    <path
      d="M35.6292 14.9583C35.6739 14.9733 35.7037 14.9583 35.7037 14.9136V11.455C35.7037 11.4103 35.6739 11.3655 35.6292 11.3357C35.6292 11.3357 34.9434 10.8885 32.8563 10.7692C32.752 10.7096 30.9183 10.6947 30.4263 10.7692C22.8233 11.3804 22.54 16.8815 22.54 17.12V18.4617C22.54 18.6257 22.54 24.246 30.4263 24.7379C31.2015 24.7976 32.7072 24.7379 32.8563 24.7379C34.6751 24.7379 35.9274 24.1863 35.9274 24.1863C35.9721 24.1714 36.0019 24.1267 36.0019 24.082V20.847C36.0019 20.8022 35.9721 20.7873 35.9423 20.8172C35.9423 20.8172 35.3758 21.2644 32.901 21.5178C32.2004 21.5924 31.8575 21.5626 31.6041 21.5178C28.0858 20.9215 27.9218 18.3573 27.9218 18.3573C27.9218 18.3126 27.9069 18.2381 27.9069 18.2083V17.2243C27.9069 17.1796 27.9069 17.1051 27.9218 17.0753C27.9218 17.0753 28.1603 14.3173 31.6041 14.0191H32.901C34.4067 14.2129 35.6292 14.9583 35.6292 14.9583Z"
      fill="#FEFEFE"
    />
    <path
      d="M8.0498 24.5736C8.0498 24.6184 8.07962 24.6482 8.12434 24.6482H13.2079C13.2527 24.6482 13.2825 24.6184 13.2825 24.5736V20.6081C13.2825 20.5634 13.3123 20.5336 13.357 20.5336C13.357 20.5336 21.4818 21.115 21.4818 15.6736C21.4818 11.3652 16.3833 10.9031 14.7136 10.9776C14.6838 10.9776 8.12434 10.9776 8.12434 10.9776C8.07962 10.9776 8.0498 11.0074 8.0498 11.0522V24.5736ZM13.193 17.5073V13.9443H14.4453C14.4453 13.9443 16.2492 14.0188 16.3982 15.2562C16.4281 15.3456 16.4281 15.9569 16.3982 15.9867C16.1597 17.4327 14.5944 17.5073 14.5944 17.5073H13.193Z"
      fill="#FEFEFE"
    />
    <path
      d="M40.9668 31.2017C41.3842 31.2017 41.7122 31.2017 42.2489 30.9632C44.0975 29.9941 50.3439 14.7434 56.9332 10.0474C56.9779 10.0175 57.0227 9.97282 57.0525 9.92809C57.0972 9.86846 57.0972 9.80883 57.0972 9.80883C57.0972 9.80883 57.0972 9.49576 56.1282 9.49576C50.2694 9.33178 44.172 21.6308 40.9668 26.4908C40.9221 26.5504 40.7134 26.4908 40.7134 26.4908C40.7134 26.4908 38.5666 23.9564 36.7032 22.9874C36.6584 22.9725 36.4497 22.898 36.2261 22.9129C36.077 22.9129 35.2124 23.0918 34.8099 23.5092C34.3328 24.0161 34.3477 24.2993 34.3477 24.9106C34.3477 24.9553 34.3775 25.164 34.4372 25.2683C34.8993 26.0734 36.9715 28.9357 38.6859 30.5159C38.9393 30.7097 39.3419 31.2017 40.9668 31.2017Z"
      fill="#2BBC5D"
    />
    <path
      d="M58.6621 5.43945H63.537C64.506 5.43945 65.2812 5.57362 65.8626 5.82706C66.459 6.0954 66.9509 6.4681 67.3236 6.94515C67.7112 7.43711 67.9945 8.00361 68.1585 8.64465C68.3374 9.2857 68.4268 9.97146 68.4268 10.7019C68.4268 11.8349 68.2926 12.7145 68.0392 13.3406C67.7858 13.9668 67.428 14.4886 66.9658 14.9209C66.5037 15.3383 66.0117 15.6216 65.4899 15.7706C64.7744 15.9644 64.1184 16.0539 63.537 16.0539H58.6621V5.43945ZM61.9419 7.83963V13.6388H62.7469C63.4326 13.6388 63.9246 13.5643 64.2079 13.4152C64.4911 13.2661 64.7296 12.9978 64.8936 12.6251C65.0576 12.2524 65.1471 11.6262 65.1471 10.7765C65.1471 9.64349 64.9682 8.88318 64.5955 8.46576C64.2228 8.04834 63.6264 7.83963 62.7618 7.83963H61.9419Z"
      fill="white"
    />
    <path
      d="M69.3359 12.551L72.4666 12.3572C72.5411 12.864 72.6753 13.2516 72.884 13.52C73.2269 13.9523 73.704 14.161 74.3301 14.161C74.7922 14.161 75.1649 14.0567 75.4184 13.8331C75.6718 13.6094 75.806 13.356 75.806 13.0728C75.806 12.8044 75.6867 12.551 75.4482 12.3274C75.2097 12.1037 74.6432 11.9099 73.7636 11.7161C72.3175 11.3882 71.3038 10.9558 70.6776 10.4191C70.0515 9.88246 69.7534 9.19669 69.7534 8.36185C69.7534 7.81026 69.9173 7.30339 70.2304 6.81143C70.5435 6.31946 71.0205 5.94677 71.6616 5.66352C72.3026 5.38026 73.1673 5.24609 74.2705 5.24609C75.6271 5.24609 76.6706 5.49953 77.3713 6.0064C78.0869 6.51327 78.5043 7.31829 78.6385 8.42148L75.5376 8.60038C75.4482 8.12332 75.2842 7.78044 75.0159 7.55682C74.7475 7.3332 74.3897 7.22885 73.9276 7.22885C73.54 7.22885 73.2567 7.30339 73.0629 7.46737C72.8691 7.63136 72.7797 7.82516 72.7797 8.06369C72.7797 8.22768 72.8542 8.39166 73.0182 8.52584C73.1673 8.66001 73.54 8.79418 74.1214 8.91344C75.5525 9.22651 76.5812 9.53957 77.2073 9.85264C77.8334 10.1657 78.2807 10.5682 78.5639 11.0304C78.8472 11.4925 78.9814 12.0292 78.9814 12.6106C78.9814 13.2964 78.7876 13.9225 78.4149 14.489C78.0422 15.0704 77.5055 15.5027 76.8346 15.8009C76.1638 16.0991 75.2991 16.2481 74.2705 16.2481C72.4666 16.2481 71.2143 15.9053 70.5137 15.2046C69.8279 14.5039 69.4403 13.6243 69.3359 12.551Z"
      fill="white"
    />
    <path
      d="M79.8164 12.551L82.9471 12.3572C83.0216 12.864 83.1558 13.2516 83.3645 13.52C83.7074 13.9523 84.1844 14.161 84.8106 14.161C85.2727 14.161 85.6454 14.0567 85.8988 13.8331C86.1523 13.6094 86.2864 13.356 86.2864 13.0728C86.2864 12.8044 86.1672 12.551 85.9287 12.3274C85.6901 12.1037 85.1236 11.9099 84.2441 11.7161C82.798 11.3882 81.7843 10.9558 81.1581 10.4191C80.5469 9.88246 80.2338 9.19669 80.2338 8.36185C80.2338 7.81026 80.3978 7.30339 80.7109 6.81143C81.0239 6.31946 81.501 5.94677 82.142 5.66352C82.7831 5.38026 83.6477 5.24609 84.7509 5.24609C86.1075 5.24609 87.1511 5.49953 87.8518 6.0064C88.5673 6.51327 88.9848 7.31829 89.1189 8.42148L86.0181 8.60038C85.9286 8.12332 85.7647 7.78044 85.4963 7.55682C85.228 7.3332 84.8702 7.22885 84.408 7.22885C84.0204 7.22885 83.7372 7.30339 83.5434 7.46737C83.3496 7.63136 83.2601 7.82516 83.2601 8.06369C83.2601 8.22768 83.3347 8.39166 83.4987 8.52584C83.6477 8.66001 84.0204 8.79418 84.6018 8.91344C86.033 9.22651 87.0617 9.53957 87.6878 9.85264C88.3139 10.1657 88.7612 10.5682 89.0444 11.0304C89.3277 11.4925 89.4618 12.0292 89.4618 12.6106C89.4618 13.2964 89.268 13.9225 88.8953 14.489C88.5226 15.0704 87.9859 15.5027 87.3151 15.8009C86.6442 16.0991 85.7796 16.2481 84.7509 16.2481C82.9471 16.2481 81.6948 15.9053 80.9941 15.2046C80.2935 14.5039 79.9059 13.6243 79.8164 12.551Z"
      fill="white"
    />
  </svg>
</template>
