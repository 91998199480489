<template>
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_4573_2521)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M35 19C35 27.8366 27.8366 35 19 35C10.1634 35 3 27.8366 3 19C3 10.1634 10.1634 3 19 3C27.8366 3 35 10.1634 35 19ZM19 33.359C26.9302 33.359 33.359 26.9302 33.359 19C33.359 11.0698 26.9302 4.64103 19 4.64103C11.0698 4.64103 4.64103 11.0698 4.64103 19C4.64103 26.9302 11.0698 33.359 19 33.359Z" fill="white"/>
</g>
<path d="M33.8002 19C33.8002 27.1738 27.174 33.8 19.0002 33.8C10.8264 33.8 4.2002 27.1738 4.2002 19C4.2002 10.8261 10.8264 4.19995 19.0002 4.19995C27.174 4.19995 33.8002 10.8261 33.8002 19Z" fill="#D1362A"/>
<g clip-path="url(#clip0_4573_2521)">
<path d="M25.3835 8.03481C25.2054 8.76338 25.0516 9.49195 24.8897 10.2124C24.7116 10.9977 24.5416 11.791 24.3635 12.5762C24.1611 13.491 23.9426 14.3977 23.7402 15.3043C23.6026 15.9115 23.4811 16.5105 23.3516 17.1177C23.214 17.7248 23.0764 18.332 22.9388 18.9472C22.8497 19.3358 22.7688 19.7324 22.6797 20.1696C22.623 20.0886 22.5907 20.0481 22.5583 19.9996C21.3926 17.7167 20.2188 15.442 19.0773 13.151C18.713 12.4224 18.4054 11.6615 18.1302 10.8924C17.7335 9.7591 18.3811 8.55291 19.474 8.07529C19.903 7.881 20.3645 7.80814 20.834 7.80814C22.283 7.80005 23.7321 7.80814 25.1811 7.80005C25.3835 7.80005 25.4321 7.84053 25.3835 8.03481Z" fill="white"/>
<path d="M19.1503 21.732C18.6564 22.331 18.0007 22.6306 17.2479 22.6468C15.6531 22.6791 14.0503 22.6629 12.4555 22.6629C12.415 22.6629 12.3745 22.6548 12.3179 22.6468C13.2245 18.5425 14.1393 14.4463 15.0622 10.3015C15.1674 10.512 15.2564 10.682 15.3455 10.852C16.4707 13.0539 17.596 15.2558 18.7131 17.4658C19.0855 18.2106 19.4417 18.9634 19.636 19.7729C19.8141 20.5096 19.6198 21.1572 19.1503 21.732Z" fill="white"/>
<path d="M19.7251 26.5083C19.7574 26.484 19.7655 26.4678 19.7817 26.4597C19.8303 26.4355 19.8789 26.4193 19.9274 26.395C20.4455 26.1521 20.7936 25.7717 20.8179 25.1807C20.8341 24.6302 20.5103 24.274 20.0327 24.0717C19.2879 23.7559 18.5108 23.8693 17.7498 23.9988C17.7013 24.0069 17.6365 24.0797 17.6284 24.1283C17.5717 24.3874 17.5232 24.6545 17.4746 24.9217C17.3451 25.6178 17.2236 26.3221 17.086 27.0183C16.9565 27.7145 16.8189 28.4026 16.6813 29.0988C16.6327 29.3578 16.657 29.3983 16.9241 29.4064C17.4989 29.4145 18.0736 29.4469 18.6403 29.4064C19.2717 29.3578 19.8465 29.1474 20.2594 28.6131C20.7774 27.9412 20.656 26.8321 19.7251 26.5083ZM19.1908 28.4107C18.8751 28.6374 18.5108 28.6859 18.187 28.694C17.6527 28.6697 17.6851 28.7669 17.7822 28.2164C17.847 27.8764 17.9117 27.5283 17.9684 27.1883C18.017 26.8888 18.017 26.8726 18.3165 26.8888C18.5432 26.8969 18.7698 26.9131 18.9884 26.9617C19.296 27.0345 19.4903 27.245 19.5308 27.5688C19.5794 27.9007 19.4741 28.2002 19.1908 28.4107ZM19.1989 26.055C18.8832 26.1521 18.5432 26.1602 18.1789 26.2088C18.2841 25.6826 18.3732 25.2212 18.4703 24.7597C18.4784 24.7274 18.527 24.6707 18.5513 24.6707C18.8346 24.6707 19.126 24.6302 19.3932 24.7031C19.8303 24.8164 19.9841 25.3021 19.717 25.6745C19.5875 25.8364 19.3932 25.9983 19.1989 26.055Z" fill="white"/>
<path d="M16.5193 24.1119C16.4303 24.5329 16.3493 24.9538 16.2684 25.3748C16.1631 25.9334 16.0579 26.5 15.9527 27.0586C15.8555 27.5686 15.7179 28.0624 15.4427 28.5076C15.0055 29.2119 14.3498 29.4953 13.5484 29.4872C13.5403 29.5034 13.5403 29.5195 13.5322 29.5357C13.2246 29.4629 12.8927 29.4305 12.6093 29.301C12.0669 29.0581 11.7998 28.5805 11.7998 28.0057C11.7998 27.4957 11.8889 26.9776 11.9698 26.4676C12.0589 25.8929 12.1803 25.3343 12.2855 24.7676C12.3341 24.5167 12.3827 24.2657 12.4393 24.0148C12.4474 23.9743 12.5203 23.9176 12.5608 23.9176C12.7955 23.9096 13.0303 23.9096 13.2731 23.9096C13.3865 23.9096 13.4027 23.9662 13.3784 24.0715C13.2893 24.541 13.2003 25.0105 13.1193 25.48C12.9898 26.1843 12.836 26.8886 12.7469 27.601C12.6498 28.3053 13.095 28.7505 13.775 28.6776C14.1879 28.6372 14.5117 28.4186 14.6655 28.0381C14.8193 27.6738 14.9327 27.2934 15.0136 26.9129C15.1674 26.2167 15.2808 25.5124 15.4184 24.8162C15.4669 24.5734 15.5074 24.3305 15.556 24.0796C15.5803 23.95 15.645 23.9015 15.7827 23.9096C15.9689 23.9176 16.1631 23.9176 16.3493 23.9096C16.495 23.9015 16.5517 23.9581 16.5193 24.1119Z" fill="white"/>
<path d="M25.3028 29.2606C25.2542 28.8558 25.2056 28.4591 25.157 28.0625C25.0761 27.4149 25.0032 26.7672 24.9223 26.1196C24.8413 25.4558 24.7361 24.8001 24.6632 24.1363C24.647 23.9501 24.5742 23.9096 24.4123 23.9177C24.1613 23.9258 23.9104 23.9339 23.6675 23.9177C23.5056 23.9096 23.4166 23.9663 23.3437 24.1201C22.7285 25.3587 22.1051 26.5972 21.4818 27.8439C21.2228 28.3539 20.9637 28.872 20.6885 29.4225C21.0042 29.4225 21.2713 29.4144 21.5385 29.4225C21.6599 29.4225 21.7328 29.382 21.7813 29.2687C21.9675 28.8558 22.1699 28.4429 22.3642 28.0301C22.4209 27.9006 22.5018 27.8439 22.6556 27.8439C23.0766 27.8601 23.4894 27.8601 23.9104 27.8439C24.129 27.8358 24.1937 27.9168 24.2018 28.111C24.2261 28.4834 24.2585 28.8477 24.299 29.2201C24.307 29.2929 24.3718 29.4144 24.4123 29.4144C24.7037 29.4306 24.9951 29.4225 25.3028 29.4225C25.3028 29.3415 25.3109 29.301 25.3028 29.2606ZM22.7851 27.091C23.1413 26.322 23.4975 25.5853 23.8456 24.8406C23.8618 24.8406 23.8699 24.8406 23.878 24.8406C23.9509 25.5853 24.0237 26.3382 24.1047 27.0991H22.7851V27.091Z" fill="white"/>
</g>
<defs>
<filter id="filter0_d_4573_2521" x="0.948718" y="0.948718" width="36.1026" height="36.1026" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="1.02564"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4573_2521"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4573_2521" result="shape"/>
</filter>
<clipPath id="clip0_4573_2521">
<rect width="13.6" height="21.7438" fill="white" transform="translate(11.7998 7.80005)"/>
</clipPath>
</defs>
</svg>

</template>