<template>
  <div
    ref="popup"
    data-pushup-item="notification-modal"
    class="z-[9] border-primaryColor w-full p-3 bg-lightRed text-black select-none cursor-pointer text-left flex flex-row items-center flex-nowrap space-x-3 border rounded-md m-[auto]"
  >
    <span
      ><svg
        width="33"
        height="34"
        viewBox="0 0 33 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 0.75C7.53984 0.75 0.25 8.03984 0.25 17C0.25 25.9602 7.53984 33.25 16.5 33.25C25.4602 33.25 32.75 25.9602 32.75 17C32.75 8.03984 25.4602 0.75 16.5 0.75ZM22.3836 21.1164C22.5046 21.2313 22.6013 21.3693 22.6681 21.5222C22.7349 21.6751 22.7704 21.8398 22.7725 22.0066C22.7747 22.1735 22.7434 22.339 22.6805 22.4936C22.6177 22.6481 22.5245 22.7885 22.4065 22.9065C22.2885 23.0245 22.1481 23.1177 21.9936 23.1805C21.839 23.2434 21.6735 23.2747 21.5066 23.2725C21.3398 23.2704 21.1751 23.2349 21.0222 23.1681C20.8693 23.1013 20.7313 23.0046 20.6164 22.8836L16.5 18.768L12.3836 22.8836C12.1473 23.1081 11.8326 23.2314 11.5066 23.2273C11.1807 23.2231 10.8692 23.0918 10.6387 22.8613C10.4082 22.6308 10.2769 22.3193 10.2727 21.9934C10.2686 21.6674 10.3919 21.3527 10.6164 21.1164L14.732 17L10.6164 12.8836C10.3919 12.6473 10.2686 12.3326 10.2727 12.0066C10.2769 11.6807 10.4082 11.3692 10.6387 11.1387C10.8692 10.9082 11.1807 10.7769 11.5066 10.7727C11.8326 10.7686 12.1473 10.8919 12.3836 11.1164L16.5 15.232L20.6164 11.1164C20.8527 10.8919 21.1674 10.7686 21.4934 10.7727C21.8193 10.7769 22.1308 10.9082 22.3613 11.1387C22.5918 11.3692 22.7231 11.6807 22.7273 12.0066C22.7314 12.3326 22.6081 12.6473 22.3836 12.8836L18.268 17L22.3836 21.1164Z"
          fill="#D80D23"
        />
      </svg>
    </span>
    <!-- message -->
    <slot name="message" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
