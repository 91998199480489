import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  viewBox: "0 0 16 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M7.16667 3.83333H12.1667V5.5H7.16667V3.83333ZM7.16667 7.16667H12.1667V8.83333H7.16667V7.16667ZM7.16667 10.5H12.1667V12.1667H7.16667V10.5ZM3.83333 3.83333H5.5V5.5H3.83333V3.83333ZM3.83333 7.16667H5.5V8.83333H3.83333V7.16667ZM3.83333 10.5H5.5V12.1667H3.83333V10.5ZM14.75 0.5H1.25C0.833333 0.5 0.5 0.833333 0.5 1.25V14.75C0.5 15.0833 0.833333 15.5 1.25 15.5H14.75C15.0833 15.5 15.5 15.0833 15.5 14.75V1.25C15.5 0.833333 15.0833 0.5 14.75 0.5ZM13.8333 13.8333H2.16667V2.16667H13.8333V13.8333Z",
      fill: $setup.fillColor
    }, null, 8, _hoisted_2)
  ]))
}