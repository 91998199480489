<template>
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.82524" cy="9.40923" r="9.36235" fill="white" />
    <g clip-path="url(#clip0_6807_67479)">
      <path
        d="M14.9575 7.23054C14.9575 6.31625 14.285 5.58075 13.454 5.58075C12.3284 5.52813 11.1805 5.50781 10.0073 5.50781H9.64162C8.47132 5.50781 7.32135 5.52813 6.19575 5.58096C5.36679 5.58096 4.69428 6.32052 4.69428 7.23481C4.64349 7.95791 4.62195 8.68122 4.62317 9.40453C4.62114 10.1278 4.64417 10.8518 4.69225 11.5765C4.69225 12.4908 5.36476 13.2324 6.19372 13.2324C7.37621 13.2872 8.58917 13.3116 9.82244 13.3096C11.0578 13.3136 12.2673 13.2879 13.4512 13.2324C14.2822 13.2324 14.9547 12.4908 14.9547 11.5765C15.0034 10.8511 15.0258 10.1278 15.0238 9.40249C15.0284 8.67919 15.0063 7.9552 14.9575 7.23054ZM8.82891 11.3977V7.40527L11.775 9.40046L8.82891 11.3977Z"
        fill="#222F5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_6807_67479">
        <rect
          width="10.4026"
          height="10.4026"
          fill="white"
          transform="translate(4.62305 4.20703)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
