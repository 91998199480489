<template>
  <div>
    <div
      class="md:items-center md:justify-between flex md:flex-col lg:flex-row p-3 w-full"
      :class="{
        success_card: status == 'info',
        warning_card: status == 'warning',
        error_card: status == 'danger',
        good_card: status == 'success',
      }"
      style="overflow-y: auto"
    >
      <div class="flex md:items-start">
        <div class="logo">
          <span class="" v-if="status == 'info'">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM11 9H13V7H11V9Z"
                fill="#2E5AAC"
              />
            </svg>
          </span>
          <span v-if="status == 'warning'">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.5301 20.5036C21.0701 20.5036 22.0301 18.8336 21.2601 17.5036L13.7301 4.49359C12.9601 3.16359 11.0401 3.16359 10.2701 4.49359L2.74012 17.5036C1.97012 18.8336 2.93012 20.5036 4.47012 20.5036H19.5301ZM12.0001 13.5036C11.4501 13.5036 11.0001 13.0536 11.0001 12.5036V10.5036C11.0001 9.95359 11.4501 9.50359 12.0001 9.50359C12.5501 9.50359 13.0001 9.95359 13.0001 10.5036V12.5036C13.0001 13.0536 12.5501 13.5036 12.0001 13.5036ZM11.0001 15.5036V17.5036H13.0001V15.5036H11.0001Z"
                fill="#CC6517"
              />
            </svg>
          </span>
          <span v-if="status == 'danger'">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM11 15V17H13V15H11Z"
                fill="#DA1414"
              />
            </svg>
          </span>
          <span v-if="status == 'success'">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 3.75C11.0398 3.75 3.75 11.0398 3.75 20C3.75 28.9602 11.0398 36.25 20 36.25C28.9602 36.25 36.25 28.9602 36.25 20C36.25 11.0398 28.9602 3.75 20 3.75ZM17.0312 28.1547L10.7344 21.157L12.593 19.4844L16.968 24.3453L27.3438 11.9891L29.2609 13.5938L17.0312 28.1547Z"
                fill="#27AE60"
              />
            </svg>
          </span>
        </div>
        <span v-if="$slots.title">
          <slot name="title" />
        </span>
        <div class="text-base font-semibold" v-else>
          {{ label }}
        </div>
      </div>

      <button class="reset-btn" @click="$emit('close')" v-if="closeButton">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5909 12L18.0441 7.54687C18.2554 7.3359 18.3743 7.04961 18.3745 6.75099C18.3748 6.45237 18.2564 6.16587 18.0455 5.95453C17.8345 5.74319 17.5482 5.62431 17.2496 5.62404C16.951 5.62378 16.6645 5.74215 16.4531 5.95312L12 10.4062L7.54687 5.95312C7.33553 5.74178 7.04888 5.62305 6.75 5.62305C6.45111 5.62305 6.16447 5.74178 5.95312 5.95312C5.74178 6.16447 5.62305 6.45111 5.62305 6.75C5.62305 7.04888 5.74178 7.33553 5.95312 7.54687L10.4062 12L5.95312 16.4531C5.74178 16.6645 5.62305 16.9511 5.62305 17.25C5.62305 17.5489 5.74178 17.8355 5.95312 18.0469C6.16447 18.2582 6.45111 18.3769 6.75 18.3769C7.04888 18.3769 7.33553 18.2582 7.54687 18.0469L12 13.5937L16.4531 18.0469C16.6645 18.2582 16.9511 18.3769 17.25 18.3769C17.5489 18.3769 17.8355 18.2582 18.0469 18.0469C18.2582 17.8355 18.3769 17.5489 18.3769 17.25C18.3769 16.9511 18.2582 16.6645 18.0469 16.4531L13.5909 12Z"
            fill="black"
          />
        </svg>
      </button>
      <div class="flex justify-end">
        <slot name="custom"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: "info",
    },

    label: {
      type: String,
      default: "Note",
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.warning_card {
  overflow-y: auto;
  background: #fcf1e8;
  border: 1px solid #cc6517;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 61px;
  box-sizing: border-box;
  border-radius: 8px;
}
.error_card {
  overflow-y: auto;
  background: #feefef;
  border: 1px solid #da1414;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 61px;
  box-sizing: border-box;
  border-radius: 8px;
}
.good_card {
  overflow-y: auto;
  background: #e6faee;
  border: 1px solid #27ae60;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 61px;
  box-sizing: border-box;
  border-radius: 8px;
}
.success_card {
  /* height: 100%; */

  overflow-y: auto;
  background: #eef2fa;
  border: 1px solid #2e5aac;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 61px;
  box-sizing: border-box;
  border-radius: 8px;
}

.error_list::-webkit-scrollbar {
  display: none;
}

.error_list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

h1 {
  font-family: "SourceSansPro SemiBold";
}

.logo {
  margin-right: 14px;
}

.reset-btn {
  display: flex;

  color: white;
  justify-content: center;
  align-items: center;

  margin-left: auto;
}
</style>
