<template>
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.355933 6.64403L4.98305 11.3898C5.45763 11.8644 6.16949 11.8644 6.64407 11.3898C7.11864 10.9152 7.11864 10.2034 6.64407 9.72878L2.84746 5.81352L6.64407 2.01692C7.11864 1.54234 7.11865 0.830474 6.64407 0.355898C6.40678 0.118609 6.16949 -3.34512e-05 5.81356 -3.34823e-05C5.57627 -3.35031e-05 5.22034 0.118609 4.98305 0.355898L0.355933 5.10166C-0.118644 5.45759 -0.118644 6.2881 0.355933 6.64403Z"
      fill="#A5BCE0"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarLeftIcon",
};
</script>

<style></style>
