<template>
  <modal :title="$t('index.changeValidator')" @close="$emit('closeModal')">
    <info status="warning" v-if="validator.lastname && validator.firstname">
      <template #title>
        <div>
          <span class="font-semibold"
            >“{{ validator.lastname }} {{ validator.firstname }}”
            {{ $t("index.must") }}
          </span>
        </div>
      </template>
    </info>

    <div class="py-4">
      <div class="mt-2 mb-5 flex flex-col space-y-4"></div>
      <div>
        <span class="font-semibold">
          {{ $t("index.toChangeValidator") }}
        </span>

        <div>
          <input
            type="password"
            v-model="password"
            :class="{
              'border-3 border-primaryColor': hasError,
              'border-btnBorder': !hasError,
            }"
            class="border outline-none rounded p-2 w-full mt-2"
          />
          <div>
            <!-- <span class="font-bold" v-if="hasError">
              {{ $t("index.passwordError") }}</span
            > -->
          </div>
          <primaryButton
            class="w-full mt-6"
            :label="$t('index.validation')"
            :loading="loader"
            @click="verifyPassword"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import info from "../global/info.vue";
import userBlue from "../svg/userColor.vue";
import userYellow from "../svg/doubleIcon.vue";
import modal from "../global/modal.vue";
import primaryButton from "../global/primaryButton.vue";
import { onMounted, computed, inject } from "@vue/runtime-core";
import { ref, toRef } from "vue";
import { useStore } from "vuex";
export default {
  props: {
    validator: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Array,
      default: [],
    },
    ischecked: {
      type: Boolean,
    },
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    info,
    userYellow,
    primaryButton,
    modal,
    userBlue,
  },
  setup(props, { emit }) {
    const showNotif = inject("$showNotif");
    const $t = inject("$t");
    const selectedValidator = toRef(props, "validator");
    const none = ref({});
    const data = toRef(props, "data");
    const loader = ref(false);
    const password = ref("");
    const store = useStore();
    const hasError = ref(false);
    const type = toRef(props, "type");
    const userInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    function selectedElm(data) {
      selectedValidator.value = data;
    }
    async function verifyPassword() {
      loader.value = true;
      console.log("eee");
      if (password.value == "") {
        hasError.value = true;
        loader.value = false;
        return;
      }
      hasError.value = false;

      try {
        const verify = await store.dispatch("VERIFY_PASSWORD", {
          email: userInfo.value.email,
          password: password.value,
        });
        // console.log(userInfo.value.id, "cc");
        if (Object.keys(selectedValidator.value).length) {
          await store.dispatch("CHANGE_VALIDATOR", {
            validatorId: selectedValidator.value.id,
            id: userInfo.value.id,
          });
          await store.dispatch("ME");
        }
        loader.value = false;

        emit("closeMe");
      } catch (error) {
        loader.value = false;
        console.log(error);
        password.value = "";
        if (error.response.status === 403) {
          showNotif({
            error: true,
            note: $t("index.passwordError"),
          });
        }
      }
    }

    onMounted(() => {});
    return {
      selectedElm,
      selectedValidator,
      loader,
      password,
      verifyPassword,
      hasError,
    };
  },
};
</script>

<style></style>
