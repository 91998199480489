import { $t } from "@/main";
import { string } from "yup";
import { IReport, IReportPayload, IReportItem } from "./interface";

export async function wait(duration: number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(null), duration);
  });
}

export function formatErrors(reports: IReport | null, $t: any) {
  if (!reports || reports == null) {
    let result = [];
    result.push(` ${$t("index.errorInprocess")}`);
    return result;
  } else {
    return Object.values(reports).map((report: IReportPayload) =>
      formatError(report, $t),
    );
  }
}

function formatError(error: IReportPayload, $t: any): string {
  if (error.code === "CT-DTG-01") {
    let result = "";
    error.reports.forEach((report: IReportItem) => {
      result += `- ${$t("index.line")}  ${report.line} : ${$t(
        "index.incorectIdentity",
      )}  <br />`;
    });
    return result;
  }

  if (error.code === "CT-DTG-02") {
    const isPlural = error.reports.length > 1;
    return `- ${$t("index.line")}${isPlural ? "s" : ""} ${error.reports.join(
      ", ",
    )} : ${$t("index.invalidAccount")} <br />  `;
  }

  if (error.code === "CT-DTG-03") {
    let result = "";
    error.reports.forEach((report: IReportItem) => {
      result += `- ${$t("index.line")}  ${report.line} : ${$t(
        "index.duplicateName",
      )} : ${report.name} <br />`;
    });
    //console.log(result);
    return result;
  }

  if (error.code === "CT-DTG-04") {
    const isPlural = error.reports.length > 1;
    return `- ${$t("index.line")}${isPlural ? "s" : ""} ${error.reports.join(
      ", ",
    )} :  ${$t("index.duplicatePhone")} <br />  `;
  }

  if (error.code === undefined) {
    return `${$t("index.errorInprocess")}`;
  }

  if (error.code == "CT-DTG-05") {
    return `${$t("index.fund")}`;
  }
  if (error.code == "CT-DTG-13") {
    const isPlural = error.reports.length > 1;

    return `- ${$t("index.line")}${isPlural ? "s" : ""} ${error.reports.join(
      ", ",
    )} : ${$t("index.unableTodectectIdentity")} <br />  `;
  }
  if (error.code == "CT-DTG-11") {
    return `${$t("index.emptyFile")}`;
  }
  if (error.code == "CT-DTG-12") {
    return `${$t("index.noPlan")}`;
  }

  if (error.code == "CT-DTG-06") {
    return `- ${$t("index.badHeaders")} <br /> ${$t(
      "index.column",
    )} : ${error.reports.join(", ")}`;
  }
  if (error.code == "CT-DTG-07") {
    return `- ${$t("index.missingHeaders")} <br /> ${$t(
      "index.column",
    )} : ${error.reports.join(", ")}`;
  }

  if (error.code == "CT-DTG-09") {
    let result = "";
    error.reports.forEach((report: IReportItem) => {
      result += `- ${$t("index.line")}   ${report.line} : ${$t(
        "index.invalidAmount",
      )} : ${report.value} F cfa <br />`;
    });
    return result;
  }

  if (error.code === "CT-DTG-10") {
    const isPlural = error.reports.length > 1;
    return `- ${$t("index.line")}${isPlural ? "s" : ""} ${error.reports.join(
      ", ",
    )} : ${$t("index.emptyColumn")} <br />  `;
  }
  if (error.code == "CT-DTG-14") {
    return `${$t("index.noSetting")}`;
  }
  return "";
}
