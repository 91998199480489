import { createI18n } from 'vue-i18n';

const requireLocale = require.context('@/locales', true, /^\.\/.*\.json$/);
const localeFiles = requireLocale.keys();

const messages = localeFiles.reduce((messages, filename) => {
  const [locale, domain] = filename.replace('./', '').replace('.json', '').split('/');

  //@ts-ignore
  if (!messages[locale]) {
    //@ts-ignore
    messages[locale] = {};
  }

  //@ts-ignore
  messages[locale][domain] = requireLocale(filename);

  return messages;
}, {});

export const locales = Object.keys(messages);

const numberFormats = {
  fr: {
    XOF: { style: 'currency', currency: 'XOF', currencyDisplay: 'code' },
    number: { style: 'decimal' },
  },

  en: {
    XOF: { style: 'currency', currency: 'XOF' },
    number: { style: 'decimal' },
  },
};

export const i18n = createI18n({
  locales: locales,
  locale: window.navigator.language.split('-')[0],
  fallbackLocale: 'fr',

  numberFormats,
  messages,
  globalInjection: true,
  legacy: false,
});

export default i18n;
