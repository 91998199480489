<template>
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.5 6H30.5C22.1486 6 14.7998 10.2708 10.5008 16.7424V6H6.5V30C6.5 38.3514 10.772 45.7014 17.2442 49.9998H6.5V54H30.5C38.8514 54 46.2014 49.7286 50.4998 43.2558V54H54.5V30C54.5 21.6486 50.2286 14.2998 43.7588 10.0008H54.5V6ZM50.4998 30C50.4998 41.0448 41.5448 49.9998 30.5 49.9998C19.454 49.9998 10.5002 41.0448 10.5002 30C10.5002 18.954 19.4546 10.0002 30.5 10.0002C41.5448 10.0002 50.4998 18.954 50.4998 30Z"
      fill="#4661B9"
    />
    <path
      d="M30.5002 14C21.6628 14 14.5 21.1628 14.5 30.0002C14.5 38.8364 21.6628 46.0004 30.5002 46.0004C39.3364 46.0004 46.5004 38.8364 46.5004 30.0002C46.5004 21.1634 39.3364 14 30.5002 14ZM30.5002 42.0002C23.8726 42.0002 18.5002 36.6272 18.5002 30.0002C18.5002 23.3726 23.8726 18.0002 30.5002 18.0002C37.1272 18.0002 42.5002 23.3726 42.5002 30.0002C42.5002 36.6272 37.1272 42.0002 30.5002 42.0002Z"
      fill="#F11C33"
    />
    <path
      d="M30.4998 22C26.0808 22 22.5 25.5814 22.5 29.9998C22.5 34.4176 26.082 37.9996 30.4998 37.9996C34.9176 37.9996 38.4996 34.4176 38.4996 29.9998C38.4996 25.5808 34.9176 22 30.4998 22ZM30.4998 34C28.2906 34 26.4996 32.209 26.4996 29.9998C26.4996 27.7906 28.2906 25.9996 30.4998 25.9996C32.709 25.9996 34.5 27.7906 34.5 29.9998C34.5 32.209 32.709 34 30.4998 34Z"
      fill="#4661B9"
    />
  </svg>
</template>