import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/index.vue";
import boardView from "../views/board.vue";
import SubscriptionView from "../views/subscription.vue";
import createView from "../views/create.vue";
import previewView from "../views/preview.vue";
import optView from "../views/otpView.vue";
import ApproView from "../views/appros.vue";
import SettingView from "../views/setting.vue";
import NoSettings from "../views/noSetting.vue";
import TempHome from "../views/TempHome.vue";
import validateDispatching from "../views/validateDispatching.vue";
import addValidator from "../views/addValidatorPage.vue";
import Layout from "@/components/layouts/default.vue";
import removeAccess from "../views/removeAccess.vue";
import api, { BACKEND } from "@/api";
import { $t } from "@/main";
import store from "../store/index";
import { number } from "yup";
import axios from "axios";
import { ROOMS } from "@/sse";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "pushup",
    component: Layout,
    // redirect: { name: "home" },
    children: [
      {
        path: "/pushup",
        name: "home",
        meta: {
          icon: "board",
          sidebar: true,
          title: "index.board",
        },
        component: HomeView,

        children: [
          {
            path: "addValidator/:id",
            name: "addValidator",
            component: addValidator,
            props: (route) => ({ ...route.params }),
            meta: {
              sidebar: false,
            },
          },
          {
            path: "/noSetting",
            name: "noSetting",
            meta: {
              sidebar: false,
            },
            component: NoSettings,
          },
          {
            path: "",
            name: "board",
            component: boardView,
            meta: {
              sidebar: false,
            },
          },
          {
            path: "create",
            name: "create",
            component: createView,
            meta: {
              sidebar: false,
            },
          },
          {
            path: "preview/:campaignId",
            name: "preview",
            component: previewView,

            props: (route) => ({ ...route.params }),
            meta: {
              sidebar: false,
            },
          },
        ],

        // props: (route) => ({ ...route.params }),
      },

      {
        path: "/appro",
        name: "appro",
        meta: {
          icon: "appro",
          sidebar: true,
          title: "index.appros",
        },
        component: ApproView,
      },

      {
        path: "/subscription",
        name: "subscription",
        meta: {
          icon: "subscription",
          sidebar: true,
          title: "index.subscription",
        },
        component: SubscriptionView,
      },
      {
        path: "/settings",
        name: "settings",
        meta: {
          icon: "setting",
          sidebar: true,
          title: "index.setting",
        },
        component: SettingView,
      },

      // {
      //   path: "/validation",
      //   name: "validation",
      //   meta: {
      //     icon: "validation",
      //     sidebar: true,
      //     title: "index.validationTitle",
      //   },
      //   component: createView,
      // },

      {
        path: "preview/:campaignId/validate",
        name: "validateDispatching",
        component: validateDispatching,
        props: (route) => ({ ...route.params }),
        meta: {
          sidebar: false,
        },
      },
    ],
  },
  {
    path: "/removeAccess",
    name: "removeAccess",
    component: removeAccess,
    props: (route) => ({ ...route.params }),
    meta: {
      sidebar: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  localStorage.setItem("loader", "true");
  // if (to.path == "/") {
  //   store.dispatch("LOCAL_LOADING", true);
  // }

  // await wait(3);

  console.log("------>to", to, to.query, to.query.code, from);

  // console.log("beforeEach", to, from);
  if (to.query.code) {
    store.dispatch("LOCAL_LOADING", true);

    const data = new FormData();
    data.append("grant_type", "authorization_code");
    data.append("client_id", process.env.VUE_APP_CLIENT_ID);
    data.append("redirect_uri", process.env.VUE_APP_HYDRA_REDIRECT_URI);
    data.append("code", to.query.code.toString());

    const result = await api(
      {
        method: "POST",
        url: `${process.env.VUE_APP_BASE_URL_HYDRA}/oauth2/token`,
        data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
      { debug: true, module: "none" },
    );

    if (result && result.data) {
      localStorage.setItem("access_token", result.data.access_token);
      localStorage.setItem("refresh_token", result.data.refresh_token);
      localStorage.setItem("token_type", result.data.token_type);
      localStorage.setItem("scope", result.data.scope);
      localStorage.setItem("id_token", result.data.id_token);

      localStorage.removeItem(ROOMS);

      console.log("result-data", result.data);

      const requestApi = await axios.get(
        `${BACKEND.baseUrl}${BACKEND.core}/request-token/${result.data.access_token}`,
      );
      console.log(requestApi.data, "request-api");

      if (requestApi.data.token) {
        localStorage.setItem("pushup_token", requestApi.data.token);
      }

      const response = await store.dispatch("ME");
      console.log(response, "MEeeeeee");
      localStorage.setItem("persmisions", response.permissions);
      if (
        response.permissions &&
        !response.permissions.includes("HAS_ACCESS") &&
        to.path !== "/removeAccess"
      ) {
        next("/removeAccess");
      } else {
        store.dispatch("SAVE_BUSINESS_ID_TO_STORAGE", response.id);
        next("/pushup");
      }
    } else {
      window.location.href = process.env.VUE_APP_LOGIN;
    }
  } else {
    const response = await store.dispatch("ME");
    console.log(
      "-----",
      response.permissions?.includes("HAS_ACCESS"),
      to.path,
      to.path !== "/removeAccesss",
    );
    localStorage.setItem("persmisions", response.permissions);
    if (
      response.permissions &&
      !response.permissions.includes("HAS_ACCESS") &&
      to.path !== "/removeAccess"
    ) {
      next("/removeAccess");
    } else {
      store.dispatch("SAVE_BUSINESS_ID_TO_STORAGE", response.id);

      next();
    }
  }
  localStorage.setItem("loader", "false");
  store.dispatch("LOCAL_LOADING", false);
});

export default router;

function wait(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, seconds * 1000);
  });
}
