<template>
  <div
    class="z-10 footer bottom-0 md:relative flex flex-col justify-start items-center md:justify-center text-sm w-full px-5 md:px-[121px] bg-[#222F5A] py-6 text-white"
  >
    <div class="w-full hidden md:flex flex-col md:flex-row justify-between">
      <div class="flex flex-col space-y-3">
        <span>
          <Kkiapay />
        </span>
        <div class="flex items-center space-x-3">
          <Twiter />
          <Facebook />
          <linkedin />
          <Youtube />
        </div>
        <PCIDSS />
      </div>
      <div>
        <span class="font-bold md:text-white text-lg text-[#A5BCE0]"
          >A propos</span
        >
        <div class="pt-3 flex space-y-3 flex-col items-start">
          <a href="https://kkiapay.me/">Mentions légales</a>
          <a href="https://kkiapay.me/">Conditions d’utilisation</a>
        </div>
      </div>
      <div>
        <a class="font-bold md:text-white text-lg text-[#A5BCE0]"
          >A Propos de KkiaPay</a
        >
        <div class="pt-3 flex space-y-3 flex-col items-start">
          <a href="https://kkiapay.me/">Nos offres</a>
          <a href="https://kkiapay.me/">Créer un compte</a>
        </div>
      </div>
      <div>
        <a class="font-bold md:text-white text-lg text-[#A5BCE0]"
          >Centre d’aide</a
        >
        <div class="pt-3 flex space-y-3 flex-col items-start">
          <a href="https://kkiapay.me/">Poser une question</a>
          <a href="https://kkiapay.me/">Écrivez-nous </a>
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-col md:flex-row space-y-6 justify-between md:hidden"
    >
      <div class="flex flex-col space-y-3">
        <span>
          <Kkiapay />
        </span>
        <div class="flex items-center space-x-3">
          <Twiter />
          <Facebook />
          <linkedin />
          <Youtube />
        </div>
        <PCIDSS />
      </div>
      <div class="">
        <div class="flex justify-between items-center">
          <a class="font-bold md:text-white text-lg text-[#A5BCE0]">A propos</a>
          <span>
            <left
              class="cursor-pointer"
              @click="ToggleAbout"
              v-if="!showAbout"
            />
            <down class="cursor-pointer" v-else @click="ToggleAbout" />
          </span>
        </div>
        <div class="pt-3 flex space-y-3 flex-col items-start" v-if="showAbout">
          <a href="https://kkiapay.me/">Mentions légales</a>
          <a href="https://kkiapay.me/">Conditions d’utilisation</a>
        </div>
      </div>
      <div>
        <div class="flex justify-between items-center">
          <a class="font-bold md:text-white text-lg text-[#A5BCE0]"
            >A Propos de KkiaPay</a
          >
          <span>
            <left
              class="cursor-pointer"
              v-if="!aboutKkiapay"
              @click="ToggleAboutkkiapay"
            />
            <down class="cursor-pointer" v-else @click="ToggleAboutkkiapay" />
          </span>
        </div>
        <div
          class="pt-3 flex space-y-3 flex-col items-start"
          v-if="aboutKkiapay"
        >
          <a href="https://kkiapay.me/">Nos offres</a>
          <a href="https://kkiapay.me/">Créer un compte</a>
        </div>
      </div>
      <div>
        <div class="flex justify-between items-center">
          <span class="font-bold md:text-white text-lg text-[#A5BCE0]"
            >Centre d’aide</span
          >
          <a>
            <left class="cursor-pointer" v-if="!showCgu" @click="ToggleCgu" />
            <down class="cursor-pointer" v-else @click="ToggleCgu" />
          </a>
        </div>
        <div class="pt-3 flex space-y-3 flex-col items-start" v-if="showCgu">
          <a href="https://kkiapay.me/">Poser une question</a>
          <a href="https://kkiapay.me/">Écrivez-nous </a>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-center text-[#A5BCE0] pt-6">
      <span class="md:text-sm text-lg">
        © 2022 KkiaPay. {{ $t("index.reserved") }}
      </span>
    </div>
  </div>
</template>

<script>
import Kkiapay from "../components/svg/kkiapay2.vue";
import Twiter from "../components/svg/twitter.vue";
import Facebook from "../components/svg/facebook.vue";
import linkedin from "../components/svg/linkedin.vue";
import Youtube from "../components/svg/youtube.vue";
import PCIDSS from "../components/svg/pcidss.vue";
import left from "../components/svg/leftWhite.vue";
import down from "../components/svg/downWhite.vue";
import { ref } from "vue";

export default {
  components: {
    Kkiapay,
    Twiter,
    Facebook,
    Youtube,
    linkedin,
    PCIDSS,
    down,
    left,
  },
  setup() {
    const showAbout = ref(false);
    const aboutKkiapay = ref(false);
    const showCgu = ref(false);
    function ToggleAbout() {
      showAbout.value = !showAbout.value;
      aboutKkiapay.value = false;
      showCgu.value = false;
    }
    function ToggleAboutkkiapay() {
      showAbout.value = false;
      aboutKkiapay.value = !aboutKkiapay.value;
      showCgu.value = false;
    }
    function ToggleCgu() {
      showAbout.value = false;
      aboutKkiapay.value = false;
      showCgu.value = !showCgu.value;
    }
    return {
      showAbout,
      showCgu,
      aboutKkiapay,
      ToggleAbout,
      ToggleAboutkkiapay,
      ToggleCgu,
    };
  },
};
</script>

<style scoped>
a {
  cursor: pointer;
}
.footer {
  /* position: fixed; */
  /* bottom: 0 !important;*/
  padding-top: 26px;
  padding-bottom: 26px;
}
.linkCard {
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  gap: 24px;
  align-items: center;
}
.blueCard {
  display: flex !important;
  justify-content: space-between !important;
}
@media screen and (max-width: 800px) {
  .footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
  }
  .linkCard {
    display: grid;
    gap: 16px;
  }
}
</style>
