<template>
  <div>
    <div v-if="revocateUser.length">
      <div class="py-4">
        <span class="font-semibold">Validateur en attente de révocation</span>
      </div>
      <div
        class="border-[#CCCCCC] border mb-4 rounded-md theShadow p-4 items-center flex justify-between"
        v-for="(item, index) in revocateUser"
        :key="index"
      >
        <div class="flex space-x-2 items-center">
          <revoc />
          <user class="" />
          <div>
            <span class="block font-bold">
              {{ item.validator.lastname }}
              {{ item.validator.firstname }}
            </span>
            <span class="block text-sm">
              {{ formatPhone(item.validator.phone) }}</span
            >
          </div>
        </div>
        <div class="flex relative">
          <status :status="item.status" :value="getStatus(item.status)" />
          <threeDot
            class="cursor-pointer"
            @click="(show = item.id) && (showWite = !showWite)"
          />
        </div>
        <div
          class="absolute bg-white p-2 shadow-xl rounded right-0 mt-20"
          v-if="show == item.id && showWite"
        >
          <button @click="resendLink(item.id)">
            {{ $t("index.resendInvitation") }}
          </button>
        </div>
      </div>
    </div>
    <succesCard :closeButton="true" @close="closeSuccessCard" v-if="succesCard">
      <template #text>
        <span class="font-bold">
          {{
            $t("index.successText", {
              name:
                successValidator.validator.lastname +
                " " +
                successValidator.validator.firstname,
              date: formatDate(successValidator.revocateAt),
            })
          }}
        </span>
      </template>
    </succesCard>
  </div>
</template>

<script>
import { computed, onMounted, inject, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import Status from "../global/status.vue";
import revoc from "../svg/revoc.vue";
import succesCard from "../global/succesCard.vue";
import { WorkflowWsEvents } from "@/socket";
import { $locale } from "@/main";
import threeDot from "@/components/svg/threeDot.vue";
export default {
  props: {},
  components: {
    Status,
    threeDot,
    revoc,
    succesCard,
  },
  setup(_, { emit }) {
    const $t = inject("$t");
    const showNotif = inject("$showNotif");
    const store = useStore();
    const PushUpIo = inject("$PushUpIo");
    const show = ref("");
    const showWite = ref(false);
    function formatPhone(rawNumber) {
      if (rawNumber) {
        const phoneNumberLength = rawNumber.length;
        let cute = rawNumber.substr(3, phoneNumberLength);
        cute = cute.replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, " ");
        return `(${rawNumber.substr(0, 3)}) ${cute} `;
      }
      return "";
    }
    const revocateUser = computed(() => {
      return store.getters["getRevocateUser"];
    });
    const UserInfo = computed(() => {
      return store.getters["getMeInfo"];
    });
    const succesCard = ref(false);
    function closeSuccessCard() {
      succesCard.value = false;
      emit("closeSuccessCard");
    }
    function formatDate(data) {
      const date = new Date(data);
      return date
        .toLocaleString($locale, {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(",", $t("index.at"));
    }
    function getStatus(key) {
      return {
        SUCCESS: $t("index.accept"),
        PENDING: $t("index.waiting"),
        REVOCATING: $t("index.revocating"),
      }[key];
    }
    async function resendLink(data) {
      const payload = {
        validatorId: data,
        id: UserInfo.value.id,
      };
      console.log(payload);
      showWite.value = false;
      try {
        await store.dispatch("RESEND_CHANGE_VALIDATOR_LINK", payload);
        showNotif({
          success: true,
          note: $t("index.successResentLink"),
        });
      } catch (error) {
        showNotif({
          error: true,
          note: $t("index.errorMessage"),
        });
      }
    }
    function joinSettingRoom(settingId) {
      if (PushUpIo?.isSocketConnected) {
        PushUpIo?.joinRoom(settingId);
      } else {
        PushUpIo?.on("connect", () => {
          PushUpIo?.joinRoom(settingId);
        });
      }
    }
    const successValidator = ref({
      id: "",
      validator: {
        lastname: "",
        firstname: "",
        phone: "",
      },
    });
    onMounted(async () => {
      joinSettingRoom(UserInfo.value.ownerSettings[0].id);
      await store.dispatch("GET_REVOC_VALIDATORS", { id: UserInfo.value.id });
      console.log(revocateUser.value);

      PushUpIo.on(WorkflowWsEvents.REVOCATION_CONFIRMED, async (data) => {
        await store.dispatch("GET_REVOC_VALIDATORS", { id: UserInfo.value.id });
        succesCard.value = true;
        successValidator.value = data;
      });
    });
    return {
      successValidator,
      closeSuccessCard,
      succesCard,
      succesCard,
      formatPhone,
      revocateUser,
      getStatus,
      formatDate,
      resendLink,
      show,
      showWite,
    };
  },
};
</script>

<style></style>
