<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2111 18.4001H7.57812L12.0001 9.5556L14.8218 15.1999H10.9664L10.1665 16.8H17.4112L18.2111 18.4001Z"
      fill="#F11C33"
    />
    <path
      d="M11.9999 2.40002L2.3999 21.6H21.5999L11.9999 2.40002ZM11.9999 5.97818L19.0108 19.9999H4.98902L11.9999 5.97818Z"
      fill="#4661B9"
    />
  </svg>
</template>
