/* eslint-disable */
import { MutationTree } from "vuex";
import {
  MutationTypes,
  NotificationType,
  SetNotificationsSettingsPayload,
} from "./store.types";
import { Campaign, CampaignTransaction, State, StoreState } from "./state";
import { ExistenceCheckResult } from "./getters";

export type Mutations<String = StoreState> = unknown;

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SET_CURRENT_CAMPAIGN](store, campaign: Campaign) {
    console.log("setCurrentCampaign MUTATION CALLED");
    store.currentCampaign = campaign;
  },
  [MutationTypes.SET_ALL_TRANSACTIONS](store, data) {
    console.log("setCurrentCampaign MUTATION CALLED");
    store.allTransaction = data;
  },

  [MutationTypes.SET_LOCAL_LOADING](store, data) {
    store.globalLoading = data;
  },
  [MutationTypes.SET_MODE](store, data) {
    store.mode = data;
  },

  [MutationTypes.SET_USER_FORGOT_ACCOUNT](state, payload) {
    state.usersForgot = payload;
  },
  [MutationTypes.SET_REVOCATING_VALIDATOR](state, payload) {
    state.revocateUser = payload;
  },

  [MutationTypes.SET_ACCOUNT_INFO](state, payload) {
    state.account = payload;
  },
  [MutationTypes.SET_CONTACTS](state, payload) {
    state.contacts = payload;
  },

  [MutationTypes.ALL_DISPATCHING](state, payload) {
    state.allCampaign = payload;
  },
  [MutationTypes.SET_APPROS](state, payload) {
    state.allAppro = payload;
  },
  [MutationTypes.SET_PLANS](state, payload) {
    state.plans = payload;
  },
  [MutationTypes.SET_USER_PLAN](state, payload) {
    state.userPlan = payload;
  },
  [MutationTypes.BALANCE](state, payload) {
    state.balance = payload;
  },

  [MutationTypes.FUNDING_SOURCE](state, payload) {
    state.funding = payload;
  },
  [MutationTypes.RESET](state, payload) {
    state.campaigns = payload;
  },
  [MutationTypes.AMOUNT](state, payload) {
    state.amount = payload?.stats?.balance ?? 0;
  },

  [MutationTypes.DISPATCHING](state, payload) {
    state.campaigns = payload;
  },
  [MutationTypes.CAMPAIGN_BY_ID](state, payload) {
    state.campaignsById = payload;
  },
  [MutationTypes.SET_ACCOUNT_VALIDATOR](state, payload) {
    state.accountValidator = payload;
  },
  [MutationTypes.SET_BUSINESS_ID](state, businessId) {
    console.log(businessId, "businessId-------------------");
    state.businessId = businessId;
  },
  [MutationTypes.SET_TRANSACTIONS](state, transactions) {
    state.campaignTransactions = transactions;
  },
  [MutationTypes.SET_ME](state, data) {
    state.meInfo = data;
  },

  [MutationTypes.SET_TRANSACTIONS_TOTAL](state, total) {
    state.totalTransaction = total;
  },
  [MutationTypes.TOTAL](state, data) {
    state.total = data?.stats?.totalCount ?? 0;
  },
  [MutationTypes.TOTAL_APPROS](state, data) {
    state.totalAppro = data;
  },
  [MutationTypes.REFERENCE](state, data) {
    state.reference = data?.reference ?? "";
  },

  [MutationTypes.ACCOUNT_NUMBER](state, data) {
    state.accountNumber = data?.source?.accountNumber ?? "";
  },

  [MutationTypes.ADD_TRANSACTION](
    state,
    payload: {
      transaction: CampaignTransaction;
      existenceCheckResult: ExistenceCheckResult;
    },
  ) {
    const { transaction, existenceCheckResult } = payload;

    if (existenceCheckResult.exist) {
      state.campaignTransactions[existenceCheckResult.index!] = transaction;
      return;
    }
    state.campaignTransactions.push(transaction);
  },
  [MutationTypes.SET_BUSINESS_NOTIFICATION_SETTING](
    state,
    payload: SetNotificationsSettingsPayload,
  ) {
    state.account.ownerSettings[0][
      payload.eventType === NotificationType.CREATE
        ? "sendReportOnDispatchingCreate"
        : "sendReportOnDispatchingEnd"
    ] = payload.value;
  },
};
