<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3438 8.125H10.7812C10.695 8.125 10.625 8.19496 10.625 8.28125V9.84375C10.625 9.93004 10.695 10 10.7812 10H12.3438C12.43 10 12.5 9.93004 12.5 9.84375V8.28125C12.5 8.19496 12.43 8.125 12.3438 8.125Z"
      fill="#4D4C4C"
    />
    <path
      d="M15.4688 8.125H13.9062C13.82 8.125 13.75 8.19496 13.75 8.28125V9.84375C13.75 9.93004 13.82 10 13.9062 10H15.4688C15.555 10 15.625 9.93004 15.625 9.84375V8.28125C15.625 8.19496 15.555 8.125 15.4688 8.125Z"
      fill="#4D4C4C"
    />
    <path
      d="M12.3438 11.25H10.7812C10.695 11.25 10.625 11.32 10.625 11.4062V12.9688C10.625 13.055 10.695 13.125 10.7812 13.125H12.3438C12.43 13.125 12.5 13.055 12.5 12.9688V11.4062C12.5 11.32 12.43 11.25 12.3438 11.25Z"
      fill="#4D4C4C"
    />
    <path
      d="M15.4688 11.25H13.9062C13.82 11.25 13.75 11.32 13.75 11.4062V12.9688C13.75 13.055 13.82 13.125 13.9062 13.125H15.4688C15.555 13.125 15.625 13.055 15.625 12.9688V11.4062C15.625 11.32 15.555 11.25 15.4688 11.25Z"
      fill="#4D4C4C"
    />
    <path
      d="M6.09375 11.25H4.53125C4.44496 11.25 4.375 11.32 4.375 11.4062V12.9688C4.375 13.055 4.44496 13.125 4.53125 13.125H6.09375C6.18004 13.125 6.25 13.055 6.25 12.9688V11.4062C6.25 11.32 6.18004 11.25 6.09375 11.25Z"
      fill="#4D4C4C"
    />
    <path
      d="M9.21875 11.25H7.65625C7.56996 11.25 7.5 11.32 7.5 11.4062V12.9688C7.5 13.055 7.56996 13.125 7.65625 13.125H9.21875C9.30504 13.125 9.375 13.055 9.375 12.9688V11.4062C9.375 11.32 9.30504 11.25 9.21875 11.25Z"
      fill="#4D4C4C"
    />
    <path
      d="M6.09375 14.375H4.53125C4.44496 14.375 4.375 14.445 4.375 14.5313V16.0938C4.375 16.18 4.44496 16.25 4.53125 16.25H6.09375C6.18004 16.25 6.25 16.18 6.25 16.0938V14.5313C6.25 14.445 6.18004 14.375 6.09375 14.375Z"
      fill="#4D4C4C"
    />
    <path
      d="M9.21875 14.375H7.65625C7.56996 14.375 7.5 14.445 7.5 14.5313V16.0938C7.5 16.18 7.56996 16.25 7.65625 16.25H9.21875C9.30504 16.25 9.375 16.18 9.375 16.0938V14.5313C9.375 14.445 9.30504 14.375 9.21875 14.375Z"
      fill="#4D4C4C"
    />
    <path
      d="M12.3438 14.375H10.7812C10.695 14.375 10.625 14.445 10.625 14.5313V16.0938C10.625 16.18 10.695 16.25 10.7812 16.25H12.3438C12.43 16.25 12.5 16.18 12.5 16.0938V14.5313C12.5 14.445 12.43 14.375 12.3438 14.375Z"
      fill="#4D4C4C"
    />
    <path
      d="M17.5 2.5H15.625V1.25H14.0625V2.5H5.9375V1.25H4.375V2.5H2.5C2.16848 2.5 1.85054 2.6317 1.61612 2.86612C1.3817 3.10054 1.25 3.41848 1.25 3.75V17.5C1.25 17.8315 1.3817 18.1495 1.61612 18.3839C1.85054 18.6183 2.16848 18.75 2.5 18.75H17.5C17.8315 18.75 18.1495 18.6183 18.3839 18.3839C18.6183 18.1495 18.75 17.8315 18.75 17.5V3.75C18.75 3.41848 18.6183 3.10054 18.3839 2.86612C18.1495 2.6317 17.8315 2.5 17.5 2.5V2.5ZM17.0312 17.0312H2.96875V6.875H17.0312V17.0312Z"
      fill="#4D4C4C"
    />
  </svg>
</template>
