<template>
  <div class="relative border-btnBorder rounded-md border-2 outline-none">
    <div>
      <div
        @click="toggle()"
        class="cursor-pointer select-none flex relative items-center"
      >
        <span class="m-1">
          <calendarSharp />
        </span>
        <span>
          {{ formated() }}
        </span>
      </div>
      <div
        v-if="show"
        class="right-0 z-1 absolute xs:right-0-0 bg-white p-4 mt-[7px] datepicker-custom-css"
      >
        <DatePicker
          v-model="date"
          :attributes="calendarAttributes"
          title-position="left"
          is-expanded
          :locale="{ id: $i18n.locale, masks: { weekdays: 'W' } }"
          transition="fade"
          mode="date"
        >
          <template #header-left-button>
            <calendar-left-icon />
          </template>
          <template #header-right-button>
            <calendar-right-icon />
          </template>
        </DatePicker>

        <div class="w-full !bg-E8EFFB h-[1px] mt-[10.5px]"></div>

        <!-- <Datepicker v-model="date" inline autoApply :enableTimePicker="false">
          <template #calendar-header="{ index, day }">
            <div :class="index === 5 || index === 6 ? 'red-color' : ''">
              {{ day }}
            </div>
          </template>
        </Datepicker> -->

        <!-- <primary-button
          class="w-full mt-4 !bg-E8EFFB"
          :label="$t('index.dateFilterDisableText')"
          @click="dateFilterDisable"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import "vue3-date-time-picker/dist/main.css";
import PrimaryButton from "./primaryButton.vue";
import calendarSharp from "@/components/svg/calendar-sharp.vue";
import { DatePicker } from "v-calendar";
import CalendarLeftIcon from "../svg/calendarLeftIcon.vue";
import CalendarRightIcon from "../svg/calendarRightIcon.vue";

const calendarAttributes = [
  {
    highlight: {
      color: "#F11C33",
      fillMode: "solid",
    },
  },
];

export default {
  name: "Demo",
  components: {
    PrimaryButton,
    calendarSharp,
    DatePicker,
    CalendarLeftIcon,
    CalendarRightIcon,
  },
  // props: {
  //   mode: {
  //     type: String,
  //     required: true,
  //   },
  // },
  setup(_, { emit }) {
    const show = ref(false);
    const date = ref(new Date());

    const toggle = () => {
      show.value = !show.value;
    };

    // const dateFilterDisable = () => {
    //   emit("dateFilterDisable");

    //   show.value = !show.value;
    // };

    watch(date, (newValue, oldValue) => {
      // console.log(newValue);
      datePicked(newValue);
    });

    const datePicked = (date) => {
      emit("dateSelected", date);
      show.value = false;
    };

    const formated = () => {
      let currentDate = date.value;
      let day = currentDate.getDate();
      let month = currentDate.getMonth()+1;
      let year = currentDate.getFullYear();
      return `${day}/${month}/${year}`;
    };

    return {
      date,
      datePicked,
      toggle,
      show,
      calendarAttributes,
      formated,
      // dateFilterDisable,
    };
  },
  emits: ["dateSelected"],
};
</script>

<style lang="scss" scoped>
:deep(.primary-button-label) {
  color: #566c80;
}

:deep(.vc-arrow.is-right) {
  margin-left: 11px;
}

:deep(.vc-container) {
  z-index: 1;
  border: 0;
  border-color: transparent;
  box-shadow: none;
}
:deep(.vc-header) {
  padding-top: 5px;
  text-transform: capitalize;
}
:deep(.vc-weeks),
:deep(.vc-weekday) {
  font-family: "Source sans pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #131516;
}
:deep(.vc-day.is-not-in-month *) {
  color: #a7afb2;
  opacity: 1;
}
:deep(.vc-highlights .vc-highlight) {
  background: #f11c33 !important;
}
:deep(.vc-weekday) {
  color: #a5bce0;
  font-size: 14px;
  line-height: 17px;
  /* border-bottom: solid 1px #a7afb2; */
}
* {
  --vdp-hover-bg-color: red;
  --vdp-selected-color: black;
  --vdp-selected-bg-color: rgba(46, 50, 56, 0.05);
}

.datepicker-custom-css {
  background: #ffffff;

  box-shadow: 0px 4px 6px rgba(153, 167, 179, 0.2);
  border-radius: 4px;
}
</style>
